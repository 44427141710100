export const getDailyrentUrl = (url: string) => {
  const [baseUrl, paramsStr] = url.split('?');
  const params = new URLSearchParams(paramsStr);
  const keysToKeep = [
    'center',
    'engine_version',
    'zoom',
    'deal_type',
    'offer_type',
    'type',
    'region',
    'location[0]',
    'house[0]',
  ];
  const filteredParams = new URLSearchParams();

  for (const key of keysToKeep) {
    if (params.has(key)) {
      filteredParams.set(key, params.get(key) as string);
    }
  }

  filteredParams.set('type', '2');
  filteredParams.set('deal_type', 'rent');

  return `${baseUrl}?${filteredParams.toString()}`;
};
