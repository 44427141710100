import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setVisibleFeaturesCount } from 'shared/map-search/actions/infrastructure';
import {
  selectActiveType,
  selectActiveFeaturesCount,
  selectFeaturesStatus,
} from 'shared/map-search/selectors/infrastructure';
import { selectMapBounds } from 'shared/map-search/selectors/mapBounds';
import { EAsyncStatus } from 'shared/map-search/types/asyncStatus';

import { useInfrastructureFeaturesContext } from '../context';

export const InfrastructureFeaturesCounter: React.FC = () => {
  const dispatch = useDispatch();
  const mapBounds = useSelector(selectMapBounds);
  const activeLayer = useSelector(selectActiveType);
  const status = useSelector(selectFeaturesStatus);

  const { rbush } = useInfrastructureFeaturesContext();

  const activeFeaturesCount = useSelector(selectActiveFeaturesCount);

  React.useEffect(() => {
    if (!mapBounds.bounds || !activeLayer || [EAsyncStatus.Loading, EAsyncStatus.Initial].includes(status)) {
      return;
    }

    const featuresInBbox = rbush.search({
      minX: mapBounds.bounds[0][0],
      minY: mapBounds.bounds[0][1],
      maxX: mapBounds.bounds[1][0],
      maxY: mapBounds.bounds[1][1],
    });

    dispatch(setVisibleFeaturesCount({ count: featuresInBbox.length, id: activeLayer.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds, dispatch, activeFeaturesCount, status]);

  return null;
};
