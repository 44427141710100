import { NonEmptyArray, nonEmptyArrayOr } from 'shared/common/packages/JsonQuery';

import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

export function removeSubsidizedMortgageFromValues(values: NonEmptyArray<string> | null) {
  if (!values) {
    return values;
  }

  const newPromoSearchTypeValues = values.filter(item => item !== SUBSIDIZED_MORTGAGE_OPTION_VALUE);

  return nonEmptyArrayOr(newPromoSearchTypeValues);
}
