import { TooltipDesktop } from '@cian/ui-kit/tooltip';
import cx from 'clsx';

import { SIZE_TO_CLASS } from './constants';
import * as styles from './styles.css';
import { IControlLinkButtonProps } from './types';

export function ControlLinkButton({
  children,
  className,
  size = 'M',
  rounded,
  tooltip,
  ...sharedProps
}: IControlLinkButtonProps) {
  const classNames = cx(
    styles['container'],
    SIZE_TO_CLASS[size],
    {
      [styles['rounded']]: rounded,
    },
    className,
  );

  if (!tooltip) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a {...sharedProps} className={classNames}>
        {children}
      </a>
    );
  }

  return (
    <TooltipDesktop placement="left" theme="black" title={tooltip}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a {...sharedProps} className={classNames}>
        {children}
      </a>
    </TooltipDesktop>
  );
}
