export function toPosition(positionOrEvent: YMaps.TCoord | YMaps.IEvent, yOffset: number = 0): YMaps.TCoord {
  if (Array.isArray(positionOrEvent)) {
    return positionOrEvent;
  }

  const touches: TouchList | undefined = positionOrEvent.get('touches');

  if (touches) {
    const touch = touches[0];

    return [touch.pageX, touch.pageY - yOffset];
  }

  return [positionOrEvent.get('offsetX'), positionOrEvent.get('offsetY')];
}
