import * as React from 'react';

import { getTermValue } from 'shared/common/packages/JsonQuery';

import { Finishing } from '../../../components/advancedFilters/Finishing';
import { useContext } from '../../../utils/useContext';

export const FinishingContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const hasFinishing = getTermValue('has_decoration')(jsonQuery);

  const handleChange = React.useCallback(
    (hasFinishing: boolean | null) => {
      onChange({ action: 'setHasFinishing', arguments: [hasFinishing] });
    },
    [onChange],
  );

  return <Finishing value={hasFinishing} onChange={handleChange} />;
};
