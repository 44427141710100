import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { EParkingType } from 'shared/common/packages/api-models/common/json_query';

import { Parking } from '../../../components/advancedFilters/Parking';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const ParkingContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const parkingType = getTermsValue('parking_type')(jsonQuery);

  const handleChange = React.useCallback(
    (parkingType: EParkingType[] | null) => {
      onChange({ action: 'setParkingType', arguments: [parkingType] });
    },
    [onChange],
  );

  return (
    <Parking offerType={offerType} value={parkingType as NonEmptyArray<EParkingType> | null} onChange={handleChange} />
  );
};
