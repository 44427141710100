import loadable from '@loadable/component';

export const NewbuildingPromoPopupLoadable = loadable(
  () => {
    return import('./NewbuildingPromoPopupContainer');
  },
  {
    ssr: false,
  },
);
