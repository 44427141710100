import { TFeature, IRBushItem } from '../../types/map';

export function geoJsonFeatureToRBushItem(feature: TFeature): IRBushItem {
  switch (feature.geometry.type) {
    case 'Point':
      return {
        minX: feature.geometry.coordinates[0],
        maxX: feature.geometry.coordinates[0],
        minY: feature.geometry.coordinates[1],
        maxY: feature.geometry.coordinates[1],
        feature,
      };
  }
}
