import { ca } from '@cian/analytics';

import { TPlatform } from '../../types/platform';

export const BANNER_NAME = 'Карта временно недоступна';

export const trackShowBanner = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    category: 'Install_app_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: BANNER_NAME,
      },
    },
  });
};

export const trackListingClick = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'back_to_list',
    category: 'Install_app_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: BANNER_NAME,
      },
    },
  });
};

export const trackMobileAppClick = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'Install_app_banner',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        bannerName: BANNER_NAME,
      },
    },
  });
};
