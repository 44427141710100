import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import * as styles from './Bedrooms.css';
import { getBedroomsLabel } from './helpers';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';

interface IBedroomsProps {
  bedrooms: IJsonQueryRangeValue | null;
  onBedroomsMinChange(gte: number | null): void;
  onBedroomsMaxChange(lte: number | null): void;
}

export const Bedrooms: React.FC<IBedroomsProps> = ({ bedrooms, onBedroomsMinChange, onBedroomsMaxChange }) => {
  const { gte: bedroomsMin = null, lte: bedroomsMax = null } = bedrooms || {};
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const bedroomsLabel = getBedroomsLabel(bedroomsMin, bedroomsMax);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleBedroomsMinChange = useDebouncedCallback((gte: number | null) => {
    onBedroomsMinChange(gte);
  }, 300);

  const handleBedroomsMaxChange = useDebouncedCallback((lte: number | null) => {
    onBedroomsMaxChange(lte);
  }, 300);

  return (
    <Outside onOutside={handleClose} insideRefs={[dropdownRef]} active={isOpen}>
      <div className={styles['container']} data-name="FilterBedrooms">
        <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
          {bedroomsLabel || 'Спальни'}
        </Button>
        {isOpen && (
          <div className={styles['dropdown']} ref={dropdownRef}>
            <RangeInput
              min={0}
              max={50}
              joined={false}
              valueMin={bedroomsMin}
              valueMax={bedroomsMax}
              onChangeMin={handleBedroomsMinChange}
              onChangeMax={handleBedroomsMaxChange}
            />
          </div>
        )}
      </div>
    </Outside>
  );
};
