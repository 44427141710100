import { openPhone } from 'shared/map-search/actions/callPopup';
import { addFavorite, deleteFavorite } from 'shared/map-search/actions/favorites';
import { setSearchResultsSidebarReady } from 'shared/map-search/actions/searchResults';
import { TThunkDispatch } from 'shared/map-search/types/redux';
import { callAuthenticationPopup } from 'shared/map-search/utils/authentication';

import { EAddFavoritesActionTypes, EDeleteFavoritesActionTypes } from '../actions/favorites';
import { ELayoutForMapActionTypes } from '../actions/layoutForMap';
import { EPhoneActionType } from '../actions/phone';
import { ERequestAuthenticationActionType } from '../actions/requestAuthentication';
import { IReduxActionMessage } from '../types';

export function reduxActionMessageHandler(message: IReduxActionMessage, dispatch: TThunkDispatch) {
  switch (message.value.type) {
    case EDeleteFavoritesActionTypes.Failure:
    case EAddFavoritesActionTypes.Request:
      dispatch(addFavorite(message.value.payload.offerId));
      break;
    case EDeleteFavoritesActionTypes.Request:
    case EAddFavoritesActionTypes.Failure:
      dispatch(deleteFavorite(message.value.payload.offerId));
      break;
    case EPhoneActionType.Open:
      dispatch(
        openPhone({
          builders: message.value.payload.builders,
          sellers: message.value.payload.sellers,
          gaLabel: message.value.payload.gaLabel,
          newbuildingId: message.value.payload.newbuildingId,
        }),
      );
      break;
    case ERequestAuthenticationActionType.Modal:
      callAuthenticationPopup(message.value.payload);
      break;
    case ELayoutForMapActionTypes.PostReadyMessage:
      dispatch(setSearchResultsSidebarReady(true));
      break;
  }
}
