import * as R from 'ramda';

import { TReduxActions } from '../../actions';
import { EFavoritesActionType } from '../../actions/favorites';
import { EFeaturesActionType } from '../../actions/features';
import { EResultsActionType } from '../../actions/results';
import { EMarkAsViewedActionType } from '../../actions/viewed';
import { prepareFeatures } from '../../mappers/features';
import { IFeaturesMap, ITilesMap, TFeature } from '../../types/map';
import { IPrecisionFeatures } from '../../types/redux/store/features';

const defaultState: IPrecisionFeatures = {};

export function featuresReducer(state: IPrecisionFeatures = defaultState, action: TReduxActions): IPrecisionFeatures {
  switch (action.type) {
    case EResultsActionType.Succeed: {
      const { clean } = action.payload;
      const tiles = action.payload.tiles || [];
      const precision = action.payload.precision as number;

      const extended = prepareFeatures(action.payload.extended || [], { isExtended: true });
      const filtered = prepareFeatures(action.payload.filtered || []);

      if (clean) {
        const uniqueFeatures: IFeaturesMap = {};
        const uniqueTiles: ITilesMap = {};

        extended.forEach(o => {
          uniqueFeatures[o.id] = o;
        });
        filtered.forEach(o => {
          uniqueFeatures[o.id] = o;
        });

        tiles.forEach(([x, y]) => {
          uniqueTiles[`${x},${y}`] = [x, y];
        });

        return {
          [precision]: {
            features: uniqueFeatures,
            tiles: uniqueTiles,
          },
        };
      }

      const uniqueFeatures: IFeaturesMap = { ...R.pathOr({}, [precision, 'features'], state) };
      const uniqueTiles: ITilesMap = { ...R.pathOr({}, [precision, 'tiles'], state) };

      extended.forEach(o => {
        uniqueFeatures[o.id] = o;
      });
      filtered.forEach(o => {
        uniqueFeatures[o.id] = o;
      });

      tiles.forEach(([x, y]) => {
        uniqueTiles[`${x},${y}`] = [x, y];
      });

      return {
        ...state,
        [precision]: {
          ...state[precision],
          features: uniqueFeatures,
          tiles: uniqueTiles,
        },
      };
    }

    case EFavoritesActionType.Added: {
      const { currentPrecision, selectedFeatureId } = action.payload;

      if (state[currentPrecision].features[selectedFeatureId]) {
        return {
          [currentPrecision]: {
            ...state[currentPrecision],
            features: {
              ...state[currentPrecision].features,
              [selectedFeatureId]: {
                ...state[currentPrecision].features[selectedFeatureId],
                properties: {
                  ...state[currentPrecision].features[selectedFeatureId].properties,
                  favoriteIds: [
                    ...(state[currentPrecision].features[selectedFeatureId].properties.favoriteIds || []),
                    action.payload.offerId,
                  ],
                },
              },
            },
          },
        };
      }

      return state;
    }

    case EFavoritesActionType.Removed: {
      const { currentPrecision, selectedFeatureId } = action.payload;

      if (state[currentPrecision].features[selectedFeatureId]) {
        return {
          [currentPrecision]: {
            ...state[currentPrecision],
            features: {
              ...state[currentPrecision].features,
              [selectedFeatureId]: removeFavoriteFromFeature(
                state[currentPrecision].features[selectedFeatureId],
                action.payload.offerId,
              ),
            },
          },
        };
      }

      return state;
    }

    case EMarkAsViewedActionType.Succeed: {
      const { precision, featureId } = action.payload;

      if (state[precision] && state[precision].features[featureId]) {
        return {
          [precision]: {
            ...state[precision],
            features: {
              ...state[precision].features,
              [featureId]: {
                ...state[precision].features[featureId],
                properties: {
                  ...state[precision].features[featureId].properties,
                  isViewed: true,
                },
              },
            },
          },
        };
      }

      return state;
    }

    case EFeaturesActionType.FeaturePropertiesUpdated: {
      const { precision, featureId, properties } = action.payload;

      if (state[precision] && state[precision].features[featureId]) {
        return {
          ...state,
          [precision]: {
            ...state[precision],
            features: {
              ...state[precision].features,
              [featureId]: {
                ...state[precision].features[featureId],
                properties: {
                  ...state[precision].features[featureId].properties,
                  ...properties,
                },
              },
            },
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
}

function removeFavoriteFromFeature(feature: TFeature, offerId: number): TFeature {
  let nextFavoriteIds = feature.properties.favoriteIds;
  if (!nextFavoriteIds) {
    return feature;
  }

  nextFavoriteIds = nextFavoriteIds.filter(oid => oid !== offerId);
  if (nextFavoriteIds.length === 0) {
    nextFavoriteIds = undefined;
  }

  return {
    ...feature,
    properties: {
      ...feature.properties,
      favoriteIds: nextFavoriteIds,
    },
  };
}
