import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface IConstructionYearProps {
  constructionYear: IJsonQueryRangeValue;
  onConstructionYearMinChange(gte: number | null): void;
  onConstructionYearMaxChange(lte: number | null): void;
}

export const ConstructionYear: React.FC<IConstructionYearProps> = ({
  constructionYear: { gte: constructionYearMin = null, lte: constructionYearMax = null },
  onConstructionYearMinChange,
  onConstructionYearMaxChange,
}) => {
  const handleConstructionYearMinChange = useDebouncedCallback((gte: number | null) => {
    onConstructionYearMinChange(gte);
  }, 300);

  const handleConstructionYearMaxChange = useDebouncedCallback((lte: number | null) => {
    onConstructionYearMaxChange(lte);
  }, 300);

  const showWelcome = constructionYearMin === 1337 && constructionYearMax === 1337;

  return (
    <Filter id="advancedFilter_constructionYear" label="Год постройки">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            min={1336}
            max={2038}
            thousandsSeparator=""
            valueMin={constructionYearMin}
            valueMax={constructionYearMax}
            onChangeMin={handleConstructionYearMinChange}
            onChangeMax={handleConstructionYearMaxChange}
          />
        </InlineFilter>
        {showWelcome && <InlineFilter>h3ll0 my d34r fr13nd!</InlineFilter>}
      </InlineFilterGroup>
    </Filter>
  );
};
