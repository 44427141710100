import { useMemo } from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';
import { getPromoSearchFilterValuesByType } from 'shared/common/packages/Filters/shared/utils/getPromoSearchFilterValuesByType';
import { JsonQuery, NonEmptyArray, nonEmptyArrayOr } from 'shared/common/packages/JsonQuery';

import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

interface IParams {
  allValues: NonEmptyArray<string> | null;
  promoSearchTypes: TPromoSearchTypes;
  jsonQuery: TJsonQuery;
}

export const useMortgagePromoSearchTypeValues = ({
  allValues,
  promoSearchTypes,
  jsonQuery,
}: IParams): NonEmptyArray<string> | null => {
  const isSubsidisedMortgage = new JsonQuery(jsonQuery).getIsSubsidisedMortgage();

  return useMemo(() => {
    const result: string[] = [];

    if (isSubsidisedMortgage) {
      result.push(SUBSIDIZED_MORTGAGE_OPTION_VALUE);
    }

    if (allValues && promoSearchTypes) {
      const availableFilterValues = getPromoSearchFilterValuesByType('mortgage', promoSearchTypes);
      const filteredValues = allValues.filter(value => availableFilterValues.includes(value));

      result.push(...filteredValues);
    }

    return nonEmptyArrayOr(result);
  }, [allValues, promoSearchTypes, isSubsidisedMortgage]);
};
