import { IClusterSchema } from '../../../common/repositories/search-offers-index/entities/cluster/ClusterSchema';

interface IPolygonFeatures {
  geohashIds: string[];
  houseIds: number[];
}

export function preparePolygonFeatures(filtered: IClusterSchema[] | null | undefined): IPolygonFeatures {
  const geohashIds: string[] = [];
  const houseIds: number[] = [];

  if (filtered) {
    filtered.map(feature => {
      const { geohash, newbuildingPin } = feature;
      geohashIds.push(geohash);

      if (newbuildingPin?.houseId) {
        houseIds.push(newbuildingPin.houseId);
      }
    });
  }

  return {
    geohashIds,
    houseIds,
  };
}
