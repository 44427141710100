import * as R from 'ramda';

import { nonEmptyArrayOr } from 'shared/common/packages/JsonQuery/utils';

import { dateRange, geo, range, term, terms } from './constructors';
import {
  NonEmptyArray,
  NonUndefinable,
  TJsonQuery,
  TJsonQueryDateRangeKeys,
  TJsonQueryOptionalKeys,
  TJsonQueryRangeKeys,
  TJsonQueryTermKeys,
  TJsonQueryTermsKeys,
  Unpacked,
} from './types';
import {
  IJsonQueryDateRange,
  IJsonQueryGeo,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryType,
  TGeoValue,
} from '../api-models/common/json_query';

export function setRootType(value: IJsonQueryType): (jsonQuery: TJsonQuery) => TJsonQuery {
  return jsonQuery => ({
    ...jsonQuery,
    _type: value,
  });
}

export function setTerm<K extends TJsonQueryTermKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (value: NonUndefinable<TJsonQuery[K]>['value'] | null) => TJsonQuery {
  // @ts-expect-error Expression produces a union type that is too complex to represent
  return jsonQuery => value => R.assoc<TJsonQuery[K], TJsonQuery, K>(key, term(value), jsonQuery) as TJsonQuery;
}

export function setTerms<K extends TJsonQueryTermsKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (value: Unpacked<NonUndefinable<TJsonQuery[K]>['value']>[] | null) => TJsonQuery {
  // @ts-expect-error Expression produces a union type that is too complex to represent
  return jsonQuery => value => R.assoc<TJsonQuery[K], TJsonQuery, K>(key, terms(value), jsonQuery) as TJsonQuery;
}

export function setRange<K extends TJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (gte: number | null, lte: number | null) => TJsonQuery {
  return jsonQuery => (gte, lte) => R.assoc(key, range(gte, lte), jsonQuery) as TJsonQuery;
}

export function setDateRange<K extends TJsonQueryDateRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (gte: string, lt: string) => TJsonQuery {
  return jsonQuery => (gte, lt) => R.assoc(key, dateRange(gte, lt), jsonQuery) as TJsonQuery;
}

export function setRangeMin<K extends TJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (gte: number | null) => TJsonQuery {
  return jsonQuery => gte =>
    R.assoc(key, range(gte, R.pathOr(null, [key, 'value', 'lte'], jsonQuery)), jsonQuery) as TJsonQuery;
}

export function setRangeMax<K extends TJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => (lte: number | null) => TJsonQuery {
  return jsonQuery => lte =>
    R.assoc(key, range(R.pathOr(null, [key, 'value', 'gte'], jsonQuery), lte), jsonQuery) as TJsonQuery;
}

export function setGeo(): (jsonQuery: TJsonQuery) => (value: TGeoValue[]) => TJsonQuery {
  return jsonQuery => value => R.assoc('geo', geo(value), jsonQuery);
}

export function resetTerms<K extends TJsonQueryOptionalKeys>(keys: K[]): (jsonQuery: TJsonQuery) => TJsonQuery {
  return jsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    keys.forEach(
      key => (nextJsonQuery = R.assoc<undefined, TJsonQuery, K>(key, undefined, nextJsonQuery) as TJsonQuery),
    );

    return nextJsonQuery;
  };
}

export function isTerm<T>(value: unknown): value is IJsonQueryTerm<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'term';
}

export function isTerms<T>(value: unknown): value is IJsonQueryTerms<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'terms';
}

export function isRange(value: unknown): value is IJsonQueryRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'range';
}

export function isDateRange(value: unknown): value is IJsonQueryDateRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'date_range';
}

export function getTermValue<K extends TJsonQueryTermKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => NonUndefinable<TJsonQuery[K]>['value'] | null {
  return jsonQuery => {
    const termField = jsonQuery[key];

    return isTerm<NonUndefinable<TJsonQuery[K]>['value']>(termField) ? termField.value : null;
  };
}

export function getTermsValue<K extends TJsonQueryTermsKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => NonEmptyArray<Unpacked<NonUndefinable<TJsonQuery[K]>['value']>> | null {
  return jsonQuery => {
    const termsField = jsonQuery[key];

    return isTerms<NonUndefinable<TJsonQuery[K]>['value']>(termsField) && termsField.value.length > 0
      ? (termsField.value as NonEmptyArray<Unpacked<NonUndefinable<TJsonQuery[K]>['value']>>)
      : null;
  };
}

export function getRangeValue<K extends TJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => IJsonQueryRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isRange(rangeField) ? rangeField.value : null;
  };
}

export function getDateRangeValue<K extends TJsonQueryDateRangeKeys>(
  key: K,
): (jsonQuery: TJsonQuery) => IJsonQueryDateRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isDateRange(rangeField) ? rangeField.value : null;
  };
}

export function getGeoValue(): (jsonQuery: TJsonQuery) => IJsonQueryGeo['value'] | null {
  return jsonQuery => (jsonQuery.geo ? jsonQuery.geo.value : null);
}

export function getGeoValueByType<T extends TGeoValue>(type: T['type']) {
  return (jsonQuery: TJsonQuery): NonEmptyArray<T> | null => {
    const geoValue = jsonQuery.geo ? jsonQuery.geo.value : [];

    return nonEmptyArrayOr(geoValue.filter((v): v is T => v.type === type));
  };
}
