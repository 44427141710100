import * as Cookies from 'js-cookie';

export function markAsClosed(cookieKey: string, value: string, expires: number): void {
  const values = Cookies.get(cookieKey)?.split(',') || [];

  if (values.indexOf(value) !== -1) {
    return;
  }

  values.push(value);

  Cookies.set(cookieKey, values.join(','), {
    expires,
    domain: '.cian.ru',
  });
}
