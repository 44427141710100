import classNames from 'clsx';

import * as styles from './styles.css';

interface INewbuidlingRatingProps {
  reviewsRate: string;
  reviewsRateUrl: string;
  promoName: string | null;
  promoUrl: string | null;
}

export function NewbuildingRating(props: INewbuidlingRatingProps) {
  const { reviewsRate, reviewsRateUrl, promoName, promoUrl } = props;

  return (
    <div className={styles['tags']}>
      <a
        data-testid="newbuilding-rating"
        href={reviewsRateUrl}
        target="_blank"
        className={classNames(styles['tag'], styles['tag-rate'], reviewsRate !== '0' ? styles['tag--orange'] : '')}
        rel="noreferrer"
      >
        <span className={styles['star']}></span>
        <span className={styles['tag-text']}>{reviewsRate}</span>
      </a>

      {promoName && promoUrl && (
        <a
          data-testid="newbuilding-promo"
          href={promoUrl}
          target="_blank"
          className={classNames(styles['tag'], styles['tag--red'])}
          rel="noreferrer"
        >
          <span className={styles['tag-text']}>{promoName}</span>
        </a>
      )}
    </div>
  );
}
