import { TJsonQueryKeys, TJsonQueryOptionalKeys } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { COMMON_PROPERTIES } from './constants';

export function getUncommonProperties(jsonQuery: IJsonQuery): TJsonQueryOptionalKeys[] {
  return (Object.keys(jsonQuery) as TJsonQueryKeys[]).filter(
    (property): property is TJsonQueryOptionalKeys => !COMMON_PROPERTIES.includes(property),
  );
}
