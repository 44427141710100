import { pathOr } from 'ramda';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { Floors } from '../../../components/advancedFilters/Floors';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const FloorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const floors = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['floorn', 'value'], jsonQuery);
  const isPenthouse = pathOr<boolean | null>(null, ['penthouse', 'value'], jsonQuery);

  const handleFloorsMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFloorsMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFloorsMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFloorsMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleIsPenthouseChange = React.useCallback(
    (isPenthouse: boolean | null) => {
      onChange({ action: 'setPenthouse', arguments: [isPenthouse] });
    },
    [onChange],
  );

  return (
    <Floors
      offerType={offerType}
      floors={floors}
      isPenthouse={isPenthouse}
      onFloorsMinChange={handleFloorsMinChange}
      onFloorsMaxChange={handleFloorsMaxChange}
      onIsPenthouseChange={handleIsPenthouseChange}
    />
  );
};
