import { getTermValue, TJsonQueryKeys } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { COMMON_PROPERTIES, RENTAL_PERIOD_SELECTED_OPTIONS } from './constants';

function isCommonPropertiesSelected(jsonQuery: IJsonQuery) {
  return (Object.keys(jsonQuery) as TJsonQueryKeys[]).some(property => !COMMON_PROPERTIES.includes(property));
}

function isRentalPeriodSelected(jsonQuery: IJsonQuery) {
  const rentalPeriod = getTermValue('for_day')(jsonQuery);

  return rentalPeriod !== null && RENTAL_PERIOD_SELECTED_OPTIONS.includes(rentalPeriod);
}

export function isAdvancedFiltersSelected(jsonQuery: IJsonQuery): boolean {
  return isCommonPropertiesSelected(jsonQuery) || isRentalPeriodSelected(jsonQuery);
}
