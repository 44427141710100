import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IApartments {
  label: string;
  value: boolean | null;
}

const APARTMENTS: IApartments[] = [
  { label: 'Неважно', value: null },
  { label: 'Без апартаментов', value: false },
  { label: 'Только апартаменты', value: true },
];

interface IApartmentsProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const Apartments: React.FC<IApartmentsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_apartments" label="Апартаменты">
      <RadioButtonGroup
        options={APARTMENTS}
        value={value}
        onChange={(value: boolean | null) => onChange(value)}
        spliced={false}
      />
    </Filter>
  );
};
