import { range, unnest } from 'ramda';

import { TILE_SIZE } from '../../constants/map';
import { toGlobalPixels } from '../projection';

export function getVisibleTiles(mapBounds: [YMaps.TCoord, YMaps.TCoord], zoomRaw: number): YMaps.TTileNumber[] {
  const zoom = Math.round(zoomRaw);
  const pixelBounds = mapBounds.map(coords => toGlobalPixels(coords, zoom)) as [YMaps.TPixelCoord, YMaps.TPixelCoord];
  const cornerTilesNumbers = pixelBounds.map(a => a.map(b => Math.floor(b / TILE_SIZE))) as [
    YMaps.TTileNumber,
    YMaps.TTileNumber,
  ];

  return unnest<YMaps.TTileNumber[][]>(
    range(cornerTilesNumbers[0][0], cornerTilesNumbers[1][0] + 1).map(x =>
      range(cornerTilesNumbers[1][1], cornerTilesNumbers[0][1] + 1).map(y => [x, y]),
    ),
  );
}
