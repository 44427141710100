import * as R from 'ramda';

import { resetTerms, setTerm } from './helpers';
import { EGarageUtility, NonEmptyArray } from './types';
import { IJsonQuery } from '../api-models/common/json_query';

export function setGarageUtilities(jsonQuery: IJsonQuery) {
  return (garageUtilities: NonEmptyArray<EGarageUtility> | null): IJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['has_electricity', 'has_heating', 'has_water', 'has_extinguishing_system'])(
      nextJsonQuery,
    );

    if (garageUtilities) {
      if (garageUtilities.includes(EGarageUtility.Electricity)) {
        nextJsonQuery = setTerm('has_electricity')(nextJsonQuery)(true);
      }

      if (garageUtilities.includes(EGarageUtility.Heating)) {
        nextJsonQuery = setTerm('has_heating')(nextJsonQuery)(true);
      }

      if (garageUtilities.includes(EGarageUtility.Water)) {
        nextJsonQuery = setTerm('has_water')(nextJsonQuery)(true);
      }

      if (garageUtilities.includes(EGarageUtility.ExtinguishingSystem)) {
        nextJsonQuery = setTerm('has_extinguishing_system')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
