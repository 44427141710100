import { Button } from '@cian/ui-kit/button';
import { equals } from 'ramda';
import * as React from 'react';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import * as styles from './AdvancedFiltersContainer.css';
import { getAvailableFilters } from './helpers';
import { AdvancedFilters } from '../../../components/AdvancedFiltersComponent';
import { ADVANCED_FILTERS } from '../../../constants/advancedFilters';
import { isAdvancedFiltersSelected, getUncommonProperties } from '../../../utils/advancedFilters';
import { useContext } from '../../../utils/useContext';

export const AdvancedFiltersContainer: React.FC = () => {
  const {
    appContext: { config },
    jsonQuery,
    onChange,
    onApply,
    onClear,
    onApplyClick,
    onAdvancedFiltersOpen,
    features,
  } = useContext();
  const isAnythingSelected = isAdvancedFiltersSelected(jsonQuery);
  const [isOpen, setIsOpen] = React.useState(false);
  const [jsonQueryBackup, setJsonQueryBackup] = React.useState<IJsonQuery>();
  const [isAwaitingJsonQueryUpdate, setIsAwaitingJsonQueryUpdate] = React.useState<boolean>(false);
  const [isJsonQueryUpdated, setIsJsonQueryUpdated] = React.useState<boolean>(false);

  const availableFilters = getAvailableFilters(jsonQuery);

  const handleClick = React.useCallback(() => {
    setIsOpen(true);

    if (onAdvancedFiltersOpen) {
      onAdvancedFiltersOpen();
    }
  }, [onAdvancedFiltersOpen]);

  const handleClose = React.useCallback(() => {
    if (jsonQueryBackup) {
      onChange({ action: 'setJsonQuery', arguments: [jsonQueryBackup] });
    }

    if (isJsonQueryUpdated || !equals(jsonQuery, jsonQueryBackup)) {
      onApply();
    }

    setIsOpen(false);
  }, [isJsonQueryUpdated, jsonQuery, jsonQueryBackup, onApply, onChange]);

  const handleClear = React.useCallback(() => {
    onChange({ action: 'resetTerms', arguments: [[...getUncommonProperties(jsonQuery), 'for_day']] });

    setIsAwaitingJsonQueryUpdate(true);
    setIsJsonQueryUpdated(true);

    if (onClear) {
      onClear();
    }
  }, [jsonQuery, onChange, onClear]);

  const handleShowClick = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick();
    }

    onApply();

    setIsOpen(false);
  }, [onApply, onApplyClick]);

  React.useEffect(() => {
    if (isOpen) {
      setJsonQueryBackup(jsonQuery);
    } else {
      setJsonQueryBackup(undefined);
      setIsAwaitingJsonQueryUpdate(false);
      setIsJsonQueryUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (isAwaitingJsonQueryUpdate) {
      setJsonQueryBackup(jsonQuery);
      setIsAwaitingJsonQueryUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQuery]);

  return (
    <>
      <div className={styles['button']} data-name="FilterAdvanced">
        <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
          Ещё фильтры
        </Button>
        {isAnythingSelected ? <div className={styles['badge']} /> : null}
      </div>
      <AdvancedFilters open={isOpen} onClearClick={handleClear} onShowClick={handleShowClick} onClose={handleClose}>
        {availableFilters.map(filterKey => {
          const { availability, component: Component } = ADVANCED_FILTERS[filterKey];

          if (availability && !availability(jsonQuery, features, config)) {
            return null;
          }

          return <Component key={`filter_${filterKey}`} />;
        })}
      </AdvancedFilters>
    </>
  );
};
