import { Header4, Image } from '@cian/ui-kit';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as styles from './styles.css';
import { BANNER_ID } from '../../../constants/banners';
import { IMobileBannerTemplateProps, MobileBannerTemplate } from '../MobileBannerTemplate';

interface IDesktopBannerProps extends IMobileBannerTemplateProps {
  title: string;
  description: string;
  href: string;
  newbuildingImage: string;
  developerLogo: string;
  onMouseOver(): void;
  onMouseOut(): void;
}

export function MobileBanner({
  title,
  href,
  newbuildingImage,
  developerLogo,
  onMouseOver,
  onMouseOut,
  addressInfo,
  onClick,
  onClose,
}: IDesktopBannerProps) {
  const [bannerContainer, setContainer] = React.useState<HTMLElement | null>(null);

  /**
   * Находит div контейнер созданный на ноде и маунтит в него точку входа в заявки
   * Нужно чтобы точка входа в заявки отображалась над шапкой
   */
  React.useEffect(() => {
    const container = document.getElementById(BANNER_ID);

    setContainer(container);
  }, []);

  if (!bannerContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <MobileBannerTemplate
        href={href}
        newbuildingImage={<Image src={newbuildingImage} alt={title} width={48} height={48} objectFit="cover" />}
        developerLogo={
          // Может прийти пустая строка, договорились с бэком, что будет исправляться в качестве техдолга
          developerLogo ? (
            <Image src={developerLogo} alt="Застройщик" width={30} height={30} borderRadius={0} objectFit="contain" />
          ) : null
        }
        title={<Header4>{title}</Header4>}
        rootClassName={styles['mobile-banner']}
        addressInfo={addressInfo}
        onClick={onClick}
        onClose={onClose}
      />
    </div>,
    bannerContainer,
  );
}
