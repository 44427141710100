import { ca } from '@cian/analytics';

interface ITrackAddFavoriteParams {
  label: string;
  sc: object;
  products: unknown;
  page: unknown;
}

export function trackAddFavorite({ label, sc, products, page }: ITrackAddFavoriteParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'add_from_listing_in_map',
    label,
    sc,
    products,
    page,
  });
}
