import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry } from '@cian/mf-registry/browser';

import { CancellationTokenManager } from 'shared/common/utils/cancellationTokenManager';
import { IApplicationContext, IApplicationContextCustom } from 'shared/map-search/types/applicationContext';

import { createMicrofrontendsAPI } from '../microfrontends/createMicrofrontendsAPI';

export const createContext = (): IApplicationContext => {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    microfrontendsAPI: config.get<boolean>('header.asMicrofrontend') ? createMicrofrontendsAPI() : undefined,
    cancellationTokenManager: new CancellationTokenManager(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
    microfrontendsRegistry: registry(),
  };
};
