// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IMarkOffersAsViewedRequest,
  TMarkOffersAsViewedModel,
  IMappers,
  TMarkOffersAsViewedResponse,
  IMarkOffersAsViewedResponseError,
} from './types';

export const defaultConfig: TMarkOffersAsViewedModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'viewed-offers',
  pathApi: '/v1/mark-offers-as-viewed/',
  requestType: 'json',
} as TMarkOffersAsViewedModel;

export function createMarkOffersAsViewedModel(parameters: IMarkOffersAsViewedRequest): TMarkOffersAsViewedModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IMarkOffersAsViewedOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
  parameters: IMarkOffersAsViewedRequest;
}

export async function fetchMarkOffersAsViewed<TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IMarkOffersAsViewedOptions<TResponse400>): Promise<TMarkOffersAsViewedResponse | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createMarkOffersAsViewedModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IMarkOffersAsViewedResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TMarkOffersAsViewedResponse;
}
