import { ca } from '@cian/analytics';

import { INewbuilding } from 'shared/map-search/types/map/newbuilding';
import { getPinProducts } from 'shared/map-search/utils/analytics';

interface ITrackPinClickParameters {
  isDetailsVisible: boolean;
  isExtended: boolean;
  isResidentialComplex: boolean;
  isResidentialComplexesListing: boolean;
  zoom: number;
  newbuilding: INewbuilding | null;
  isSuburbanFromKp: boolean;
}

export function trackPinClick({
  isDetailsVisible,
  isExtended,
  isResidentialComplex,
  isResidentialComplexesListing,
  zoom,
  newbuilding,
  isSuburbanFromKp,
}: ITrackPinClickParameters) {
  const products = getPinProducts({ newbuilding });

  ca('eventSite', {
    name: 'oldevent',
    action: 'Click_pin',
    category: 'Map',
    label: `${
      isExtended
        ? 'white'
        : ((isResidentialComplexesListing || isDetailsVisible) && isResidentialComplex) || isSuburbanFromKp
          ? 'green'
          : 'blue'
    }/${zoom}`,
    products,
  });
}
