import { IGetInfrastructureGeoObjectsResponse200 } from '../../../common/repositories/infrastructure-caching/v1/get-infrastructure-geo-objects';
import { IInfrastructurePointItem } from '../../types/infrastructure';

export function prepareInfrastructureItems(
  response: IGetInfrastructureGeoObjectsResponse200,
): IInfrastructurePointItem[] {
  const items: IInfrastructurePointItem[] = [];

  for (const { description, geography, id, name, type } of response.response.infrastructure) {
    if (geography.type !== 'Point') {
      continue;
    }

    items.push({
      description: description || undefined,
      type,
      name,
      id,
      coordinates: geography.coordinates,
    });
  }

  return items;
}
