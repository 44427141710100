import {
  POINT_SHADOW_X_RADIUS,
  POINT_SHADOW_Y_OFFSET,
  POINT_SHADOW_Y_RADIUS,
  TILE_SIZE,
  DOT_SIZE_BIG,
} from '../../constants/map';
import { fromGlobalPixels } from '../projection';
import { getTilePixelCoords } from '../tiles/getTilePixelCoords';

interface IGetExtendedTileBBoxParams {
  tileNumber: YMaps.TTileNumber;
  zoom: number;
  includeShadow?: boolean;
}

const MAX_POINT_RADIUS = DOT_SIZE_BIG;

export function getExtendedTileBBox(params: IGetExtendedTileBBoxParams) {
  const { tileNumber, zoom, includeShadow } = params;

  const [tilePixelCoordX, tilePixelCoordY] = getTilePixelCoords(tileNumber);
  let shadowBottomOffset = 0;
  let shadowSideOffset = 0;

  if (includeShadow) {
    shadowBottomOffset = Math.max(POINT_SHADOW_Y_OFFSET + POINT_SHADOW_Y_RADIUS - MAX_POINT_RADIUS, 0);
    shadowSideOffset = Math.max(POINT_SHADOW_X_RADIUS - MAX_POINT_RADIUS, 0);
  }

  // Расширяем область поиска точек, для отрисовки кусочков точек, находящихся на краях других тайлов
  const pixelCoordMinX = tilePixelCoordX - MAX_POINT_RADIUS - shadowSideOffset;
  const pixelCoordMinY = tilePixelCoordY - MAX_POINT_RADIUS;
  const pixelCoordMaxX = tilePixelCoordX + TILE_SIZE + MAX_POINT_RADIUS + shadowSideOffset;
  const pixelCoordMaxY = tilePixelCoordY + TILE_SIZE + MAX_POINT_RADIUS + shadowBottomOffset;

  const [coordMaxX, coordMinY] = fromGlobalPixels([pixelCoordMinX, pixelCoordMinY], zoom);
  const [coordMinX, coordMaxY] = fromGlobalPixels([pixelCoordMaxX, pixelCoordMaxY], zoom);

  return {
    minX: coordMinX,
    minY: coordMinY,
    maxX: coordMaxX,
    maxY: coordMaxY,
  };
}
