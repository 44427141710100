import { FDealType, FOfferType } from 'shared/common/packages/JsonQuery';

import { TFilter } from '../../types/filters';

interface IFilterList {
  dealType: FDealType;
  offerType: FOfferType;
  list: TFilter[];
}

const FILTERS_LIST: IFilterList[] = [
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Land,
    list: ['dealType', 'offerType', 'posessor', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Suburban & ~FOfferType.Land,
    list: ['dealType', 'offerType', 'posessor', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Suburban,
    list: ['dealType', 'offerType', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Any,
    list: ['dealType', 'offerType', 'price', 'beds', 'roomType', 'dates', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Sale | FDealType.RentLongterm,
    offerType: FOfferType.Commercial & ~FOfferType.CommercialLand,
    list: ['dealType', 'offerType', 'price', 'area', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.Flat,
    list: ['dealType', 'offerType', 'roomType', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.Urban & ~FOfferType.Flat,
    list: ['dealType', 'offerType', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.Other,
    list: ['dealType', 'offerType', 'price', 'advancedFilters', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.CommercialLand,
    list: ['dealType', 'offerType', 'price', 'landArea', 'advancedFilters', 'geo'],
  },
];

export function getAvailableFilters(dealType: FDealType, offerType: FOfferType): TFilter[] {
  const type = FILTERS_LIST.find(f => (f.dealType & dealType) !== 0 && (f.offerType & offerType) !== 0);

  return (type && type.list) || [];
}
