import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackAddNewbuildingToFavorite, trackDeleteNewbuildingFromFavorite } from './tracking';
import { FavoriteButton } from '../../../components/FavoriteButton';
import { selectNewbuildingId } from '../../../selectors/features';
import {
  addNewbuildingToFavorites,
  deleteNewbuildingFromFavorites,
  getIsNewbuildingInFavorites,
} from '../../../services/favorites';
import { useApplicationContext } from '../../ApplicationContext';

export const FavoriteButtonContainer: React.FC = () => {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const context = useApplicationContext();

  const newbuildingId = useSelector(selectNewbuildingId);

  const handleClickFavorite = React.useCallback(async () => {
    try {
      if (isFavorite) {
        await deleteNewbuildingFromFavorites(context, newbuildingId as number);
        trackDeleteNewbuildingFromFavorite(newbuildingId as number);
        setIsFavorite(false);
      } else {
        await addNewbuildingToFavorites(context, newbuildingId as number);
        trackAddNewbuildingToFavorite(newbuildingId as number);
        setIsFavorite(true);
      }
    } catch {
      // ignore section
    }
  }, [isFavorite, newbuildingId, context]);

  const checkIsInFavorites = React.useCallback(async () => {
    try {
      const result = await getIsNewbuildingInFavorites(context, newbuildingId as number);
      setIsFavorite(result);
    } catch {
      setIsFavorite(false);
    }
  }, [newbuildingId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    checkIsInFavorites();
  }, [checkIsInFavorites]);

  const tooltipText = isFavorite ? 'Удалить ЖК из избранного' : 'Добавить ЖК в избранное';

  return <FavoriteButton tooltip={tooltipText} isFavorite={isFavorite} onClick={handleClickFavorite} />;
};

FavoriteButtonContainer.displayName = 'FavoriteButtonContainer';
