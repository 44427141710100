import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { EFacilityType, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { Layout } from './components/Layout';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IFacility {
  label: string;
  value: EFacilityType;
}

const FACILITIES: IFacility[] = [
  { label: 'Кондиционер', value: EFacilityType.Conditioner },
  { label: 'Без мебели', value: EFacilityType.WithoutFurniture },
  { label: 'Интернет', value: EFacilityType.Internet },
  { label: 'Холодильник', value: EFacilityType.Fridge },
  { label: 'Комнатная мебель', value: EFacilityType.RoomFurniture },
  { label: 'Телефон', value: EFacilityType.Phone },
  { label: 'Посудомоечная машина', value: EFacilityType.Dishwasher },
  { label: 'Кухонная мебель', value: EFacilityType.KitchenFurniture },
  { label: 'Телевизор', value: EFacilityType.TV },
  { label: 'Стиральная машина', value: EFacilityType.Washer },
];

interface IFacilitiesProps {
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const Facilities: React.FC<IFacilitiesProps> = ({ value, onChange }) => {
  const handleFacilitiesChange = (event: React.ChangeEvent<HTMLInputElement>, facility: EFacilityType) => {
    const { checked } = event.target;
    let nextValue: EFacilityType[] = value || [];

    switch (true) {
      case facility === EFacilityType.WithoutFurniture && checked:
        nextValue = nextValue.filter(v => ![EFacilityType.RoomFurniture, EFacilityType.KitchenFurniture].includes(v));
        nextValue.push(facility);
        break;
      case [EFacilityType.RoomFurniture, EFacilityType.KitchenFurniture].includes(facility) && checked:
        nextValue = nextValue.filter(v => v !== EFacilityType.WithoutFurniture);
        nextValue.push(facility);
        break;
      case checked:
        nextValue.push(facility);
        break;
      default:
        nextValue = nextValue.filter(v => v !== facility);
    }

    onChange(nextValue.length > 0 ? (nextValue as NonEmptyArray<EFacilityType>) : null);
  };

  return (
    <Filter id="advancedFilter_facilities" label="Удобства">
      <InlineFilterGroup>
        <Layout>
          {FACILITIES.map(facility => (
            <InlineFilter key={`${facility.label}_${facility.value}`}>
              <Checkbox
                label={facility.label}
                checked={value ? value.includes(facility.value) : false}
                onChange={event => handleFacilitiesChange(event, facility.value)}
              />
            </InlineFilter>
          ))}
        </Layout>
      </InlineFilterGroup>
    </Filter>
  );
};
