import { equals } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IFeaturesMap, IRBushItem } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { getTilesToUpdate } from 'shared/map-search/utils/tiles';

import { useFeaturesContext } from '../context';

export const FeaturesPropertiesUpdater: React.FC = () => {
  const { rbush, pointsLayer, balloonManager } = useFeaturesContext();
  const { bounds, zoom } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    state.features[state.mapBounds.precision] ? state.features[state.mapBounds.precision].features : {},
  );
  const prevFeaturesRef = React.useRef<IFeaturesMap>(features);
  const prevFeatures = prevFeaturesRef.current;

  React.useEffect(() => {
    const updatedFeatures = Object.values(features).filter(({ id, properties }) => {
      const prevFeature = prevFeatures[id];
      if (!prevFeature) {
        return false;
      }

      if (!equals(properties, prevFeature.properties)) {
        return true;
      }

      return false;
    });

    const rbushItemsToUpdate: IRBushItem[] = [];
    for (const feature of updatedFeatures) {
      if (!feature.properties.isExtended || feature.properties.specialPromo) {
        balloonManager.updateBalloonProperties(feature.id, feature.properties);
      }

      const featuresInRbush = rbush.search({
        minX: feature.geometry.coordinates[0],
        maxX: feature.geometry.coordinates[0],
        minY: feature.geometry.coordinates[1],
        maxY: feature.geometry.coordinates[1],
      });

      const featureInRbush = featuresInRbush.find(({ feature: { id } }) => id === feature.id);
      if (featureInRbush) {
        featureInRbush.feature.properties = feature.properties;
        rbushItemsToUpdate.push(featureInRbush);
      }
    }

    if (pointsLayer && rbushItemsToUpdate.length > 0) {
      const tilesToUpdate = getTilesToUpdate({ rbushItems: rbushItemsToUpdate, bounds, zoom, includeShadow: true });
      pointsLayer.layer.update(tilesToUpdate);
    }

    prevFeaturesRef.current = features;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  return null;
};
