import * as React from 'react';

import * as styles from './Filters.css';
import { Filter } from './components/Filter';
import { Group } from './components/Group';

interface IFiltersProps {
  advancedFilters?: React.ReactNode;
  area?: React.ReactNode;
  bedrooms?: React.ReactNode;
  beds?: React.ReactNode;
  dates?: React.ReactNode;
  dealType?: React.ReactNode;
  geo?: React.ReactNode;
  landArea?: React.ReactNode;
  offerType?: React.ReactNode;
  posessor?: React.ReactNode;
  price?: React.ReactNode;
  roomType?: React.ReactNode;
  savedSearch?: React.ReactNode;
}

export const Filters: React.FC<IFiltersProps> = ({
  advancedFilters,
  area,
  bedrooms,
  beds,
  dates,
  dealType,
  geo,
  landArea,
  offerType,
  posessor,
  price,
  roomType,
  savedSearch,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['layout']}>
        <Group>
          {[dealType, dates, beds, offerType, roomType, posessor, bedrooms, price, area, landArea, advancedFilters]
            .filter(Boolean)
            .map((filter, index) => (
              <Filter key={`filter_${index}`}>{filter}</Filter>
            ))}
        </Group>
        <Group className={styles['secondary-group']}>
          {geo && <Filter className={styles['geo']}>{geo}</Filter>}
          {savedSearch && (
            <div className={styles['saved-search']} data-name="SaveSearch">
              {savedSearch}
            </div>
          )}
        </Group>
      </div>
    </div>
  );
};
