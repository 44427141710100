import * as React from 'react';

/* istanbul ignore next */
export const Filters: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.62602 5H0V3H6.62602C7.07006 1.27477 8.63616 0 10.5 0C12.3638 0 13.9299 1.27477 14.374 3H16V5H14.374C13.9299 6.72523 12.3638 8 10.5 8C8.63616 8 7.07006 6.72523 6.62602 5ZM8.5 4C8.5 2.89543 9.39543 2 10.5 2C11.6046 2 12.5 2.89543 12.5 4C12.5 5.10457 11.6046 6 10.5 6C9.39543 6 8.5 5.10457 8.5 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37398 13H16V11H9.37398C8.92994 9.27477 7.36384 8 5.5 8C3.63616 8 2.07006 9.27477 1.62602 11H0V13H1.62602C2.07006 14.7252 3.63616 16 5.5 16C7.36384 16 8.92994 14.7252 9.37398 13ZM7.5 12C7.5 10.8954 6.60457 10 5.5 10C4.39543 10 3.5 10.8954 3.5 12C3.5 13.1046 4.39543 14 5.5 14C6.60457 14 7.5 13.1046 7.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

Filters.displayName = 'Filters';
