import { IConfig } from '@cian/config/shared';

import { IZoomToPrecisionMap } from './types';

export function zoomToPrecision(config: IConfig, zoomRaw: number): number {
  const zoom = Math.round(zoomRaw);
  const zoomToPrecisionMap = config.getStrict<IZoomToPrecisionMap>('SEARCH_ENGINE_ZOOM_TO_PRECISION_MAP');

  return zoomToPrecisionMap[zoom];
}
