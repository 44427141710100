import { TJsonQueryKeys, ERentTime } from 'shared/common/packages/JsonQuery';

export const COMMON_PROPERTIES: TJsonQueryKeys[] = [
  '_type',
  'bbox',
  'beds_count',
  'bs_center',
  'building_status',
  'category',
  'currency',
  'dates',
  'engine_version',
  'for_day',
  'geo',
  'kp_id',
  'object_type',
  'office_type',
  'price',
  'price_sm',
  'region',
  'room',
  'site',
  'suburban_offer_filter',
  'with_newobject',
];

export const RENTAL_PERIOD_SELECTED_OPTIONS = [ERentTime.FromYear, ERentTime.FewMonths];
