import * as React from 'react';
import { useSelector } from 'react-redux';

import { ControlsContainer as Controls } from './Controls';
import { Features, IFeaturesApi } from './Features';
import { InfrastructureFeatures, IInfrastructureFeaturesApi } from './Infrastructure';
import { NewbuildingPromoPins } from './NewbuildingPromoPins';
import { NewbuildingsPolygonsContainer } from './NewbuildingsPolygons';
import { Polygons } from './Polygons';
import { Tags } from './Tags';
import { selectIsInfrastructureOnMapEnabled } from '../../../selectors/infrastructure';

export function MapLayers() {
  const featuresRef = React.useRef<IFeaturesApi>(null);
  const infrastructureFeaturesRef = React.useRef<IInfrastructureFeaturesApi>(null);
  const isInfrastructureEnabled = useSelector(selectIsInfrastructureOnMapEnabled);

  React.useEffect(() => {
    const featuresApi = featuresRef.current;
    const infrastructureFeaturesApi = infrastructureFeaturesRef.current;

    infrastructureFeaturesApi?.init();
    featuresApi?.init();

    return () => {
      infrastructureFeaturesApi?.destroy();
      featuresApi?.destroy();
    };
  }, []);

  return (
    <>
      <Tags />
      <Features ref={featuresRef} />
      {isInfrastructureEnabled && <InfrastructureFeatures ref={infrastructureFeaturesRef} />}
      <Controls />
      <Polygons />
      <NewbuildingsPolygonsContainer />
      <NewbuildingPromoPins />
    </>
  );
}
