import { IconList16, Spinner } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';
import { ControlLinkButton } from '../../ControlLinkButton';

interface IListingButtonProps {
  url: string;
  onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  text: string;
  loading?: boolean;
  isMobile: boolean;
}

export function ListingButton({ url, onClick, text, loading, isMobile }: IListingButtonProps) {
  return (
    <ControlLinkButton
      href={url}
      className={cx(styles['navigation-btn'], styles['listing-btn'], isMobile && styles['listing-btn--mobile'])}
      onClick={onClick}
      size={isMobile ? 'L' : 'M'}
    >
      {loading ? (
        <div className={styles['loader']}>
          <Spinner size={16} color="primary_100" display="inline-block" />
        </div>
      ) : (
        <>
          <IconList16 color="black_100" />
          <span className={cx(styles['listing-title'], isMobile && styles['listing-title--mobile'])}>{text}</span>
        </>
      )}
    </ControlLinkButton>
  );
}
