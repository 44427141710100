// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface INewbuildingContactForPromoSnippetOnMap {
  /** ID ЖК **/
  newbuildingId: number;
  /** ID компании **/
  companyId?: number;
  /** Название компании **/
  companyName?: string;
  /** Телефон **/
  userPhone: string;
  /** Id Динамической подмены **/
  siteBlockId?: number;
  /** Тип контакта - застройщик или представитель **/
  contactType: EContactType;
  /** Id пользователя **/
  userId: number;
  /** Тип тарифа **/
  tariffType: ETariffType;
}
export enum EContactType {
  /** Застройщик **/
  'Builder' = 'Builder',
  /** Представитель застройщика **/
  'Seller' = 'Seller',
}
export enum ETariffType {
  /** Pro+ **/
  'ProPlus' = 'ProPlus',
  /** Premium **/
  'Premium' = 'Premium',
}
