import * as React from 'react';

export const DrawingNew: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 15H8L5 15C2.79086 15 1 13.2091 1 11V11" stroke="currentColor" strokeWidth="2" />
      <path d="M4.5 8.49999V11.5H7.5L12.5 6.50002L9.5 3.50002L4.5 8.49999Z" stroke="currentColor" strokeWidth="2" />
      <path
        d="M14.5 4.4998L11.5 1.49981L11.5526 1.44727L14.5526 4.44726L14.5 4.4998Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

DrawingNew.displayName = 'DrawingNew';
