import * as React from 'react';

import { getTermsValue, isSuburban, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { EHouseMaterial } from 'shared/common/packages/api-models/common/json_query';

import { ConstructionType } from '../../../components/advancedFilters/ConstructionType';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const ConstructionTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const label = isSuburban(offerType) ? 'Материал дома' : 'Тип дома';
  const constructionType = getTermsValue('house_material')(jsonQuery);

  const handleChange = React.useCallback(
    (constructionType: NonEmptyArray<EHouseMaterial> | null) => {
      onChange({ action: 'setConstructionTypes', arguments: [constructionType] });
    },
    [onChange],
  );

  return (
    <ConstructionType
      offerType={offerType}
      value={constructionType as NonEmptyArray<EHouseMaterial> | null}
      onChange={handleChange}
      label={label}
    />
  );
};
