import { equals } from 'ramda';

import { TReduxActions } from '../../actions';
import { ETagsActionType } from '../../actions/filters';
import { ETagsStatus, ITagsState } from '../../types/filters';

const defaultState: ITagsState = {
  status: ETagsStatus.Initial,
  items: null,
};

export function tagsReducer(state: ITagsState = defaultState, action: TReduxActions): ITagsState {
  switch (action.type) {
    case ETagsActionType.Loading:
      return {
        ...state,
        status: ETagsStatus.Loading,
      };
    case ETagsActionType.Succeed:
      return {
        ...state,
        status: ETagsStatus.Succeed,
        items: action.payload,
      };
    case ETagsActionType.Failed:
      return {
        ...state,
        status: ETagsStatus.Failed,
      };
    case ETagsActionType.Removed: {
      let nextItems = state.items;
      if (nextItems && nextItems.length > 0) {
        nextItems = nextItems.filter(item => !equals(item, action.payload));

        if (nextItems.length === 0) {
          nextItems = null;
        }
      }

      return {
        ...state,
        items: nextItems,
      };
    }
  }

  return state;
}
