import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { ERepair } from 'shared/common/packages/api-models/common/json_query';

import { Repair } from '../../../components/advancedFilters/Repair';
import { useContext } from '../../../utils/useContext';

export const RepairContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const repair = getTermsValue('repair')(jsonQuery);

  const handleChange = React.useCallback(
    (repair: NonEmptyArray<ERepair> | null) => {
      onChange({ action: 'setRepair', arguments: [repair] });
    },
    [onChange],
  );

  return <Repair value={repair as NonEmptyArray<ERepair> | null} onChange={handleChange} />;
};
