import * as React from 'react';

import { NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { EGarageType } from 'shared/common/packages/api-models/common/json_query';

import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IGarageTypeOption {
  label: string;
  value: EGarageType | null;
}

const GARAGE_TYPE_OPTIONS: IGarageTypeOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Гараж', value: EGarageType.Garage },
  { label: 'Машиноместо', value: EGarageType.CarPlace },
  { label: 'Бокс', value: EGarageType.Box },
];

interface IGarageTypeProps {
  value: NonEmptyArray<EGarageType> | null;
  onChange(value: NonEmptyArray<EGarageType> | null): void;
}

export const GarageType: React.FC<IGarageTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_garageType" label="Тип">
      <CheckboxButtonGroupUnselectable options={GARAGE_TYPE_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
