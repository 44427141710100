import { IMortgageState } from '../../types/mortgage/mortgageRates';

const defaultState: IMortgageState = {
  rates: {
    newBuilding: undefined,
    used: undefined,
  },
};

export function mortgageReducer(state: IMortgageState = defaultState): IMortgageState {
  return state;
}
