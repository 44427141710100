import * as React from 'react';

import { FiltersButtonContainer } from './FiltersButton';
import { SaveSearchButtonContainer } from './SaveSearchButton';
import { Navigation, NavigationButtons } from '../../components/Navigation';

export const NavigationMobileContainer: React.FC = () => {
  return (
    <Navigation indent="L">
      <NavigationButtons indent="M">
        <FiltersButtonContainer />
        <SaveSearchButtonContainer />
      </NavigationButtons>
    </Navigation>
  );
};

NavigationMobileContainer.displayName = 'NavigationMobileContainer';
