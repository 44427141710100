import classNames from 'clsx';
import RBush from 'rbush';

import { commarize } from 'shared/common/utils/number';

import { FAVORITE_ICON } from './constants';
import { getSpecialPromoMarkup } from './markups/specialPromo';
import * as styles from './styles.css';
import { formatBalloonCount } from './utils';
import { IRBushItem, TFeaturePropertiesRequiredPrice, TFeature } from '../../../types/map';

interface IBalloonManagerOptions {
  map: YMaps.Map;
  rbush: RBush<IRBushItem>;
  // @todo выпилить эксп CD-159979
  showOnlyPrice: boolean;
}

export function defineBalloonManager(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('BalloonManager')) {
    return;
  }

  ymaps.modules.define<
    [YMaps.ITemplateLayoutFactory, typeof YMaps.shape.Rectangle, typeof YMaps.geometry.pixel.Rectangle]
  >(
    'BalloonManager',
    ['templateLayoutFactory', 'shape.Rectangle', 'geometry.pixel.Rectangle'],
    (
      provide,
      templateLayoutFactory: YMaps.ITemplateLayoutFactory,
      shapeRectangle: typeof YMaps.shape.Rectangle,
      geometryPixelRectangle: typeof YMaps.geometry.pixel.Rectangle,
    ) => {
      class BalloonManager {
        public readonly objectManager: YMaps.ObjectManager;

        private map: YMaps.Map;
        private rbush: RBush<IRBushItem>;
        // @todo выпилить эксп CD-159979
        private showOnlyPrice: boolean;

        public constructor(options: IBalloonManagerOptions) {
          this.map = options.map;
          this.rbush = options.rbush;
          // @todo выпилить эксп CD-159979
          this.showOnlyPrice = options.showOnlyPrice;
          this.objectManager = new ymaps.ObjectManager();
        }

        public updateBalloons = () => {
          const bounds = this.map.getBounds();
          const rBushItems = this.rbush.search({
            minX: bounds[0][0],
            minY: bounds[0][1],
            maxX: bounds[1][0],
            maxY: bounds[1][1],
          });

          this.objectManager.removeAll();

          this.objectManager.add({
            type: 'FeatureCollection',
            features: rBushItems.map(this.prepareBalloon).filter(feature => !!feature) as TFeature[],
          });
        };

        public updateBalloonProperties = (featureId: string, properties: TFeaturePropertiesRequiredPrice) => {
          this.objectManager.objects.setObjectOptions(featureId, {
            iconLayout: this.prepareBalloonLayout(properties as TFeaturePropertiesRequiredPrice),
          });
        };

        private prepareBalloon = ({ feature }: IRBushItem) => {
          const { properties } = feature;

          if (!properties.minPrice && !properties.specialPromo) {
            return null;
          }

          return {
            ...feature,
            options: {
              iconLayout: this.prepareBalloonLayout(properties as TFeaturePropertiesRequiredPrice),
              zIndex: 1,
            },
          };
        };

        private prepareBalloonLayout = (properties: TFeaturePropertiesRequiredPrice) => {
          const content = this.prepareBalloonContent(properties);
          // @todo выпилить эксп CD-159979
          const showOnlyPrice = this.showOnlyPrice;

          return templateLayoutFactory.createClass(content, {
            getShape() {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const element: HTMLElement = (this as any).getElement();
              if (!element) {
                return null;
              }

              const wrapperElement = element.firstElementChild;
              if (!wrapperElement) {
                return null;
              }

              const boundingRect = wrapperElement.getBoundingClientRect();
              const offsetX = -boundingRect.width / 2;
              let offsetY = -boundingRect.height - 4;

              // @todo выпилить эксп CD-159979
              if (showOnlyPrice) {
                offsetY += 12;
              }

              return new shapeRectangle(
                new geometryPixelRectangle([
                  [offsetX, offsetY],
                  [offsetX + boundingRect.width, offsetY + boundingRect.height],
                ]),
              );
            },
          });
        };

        private prepareBalloonContent(feature: TFeaturePropertiesRequiredPrice) {
          const {
            count,
            minPrice,
            favoriteIds,
            isViewed,
            hasNewobject,
            isActive,
            specialPromo,
            newbuilding,
            isAnyFromKp,
            clusterOfferIds,
          } = feature;

          // Если спец. проект (например, ПИК)
          if (specialPromo) {
            return getSpecialPromoMarkup(feature);
          }

          const price = `${count > 1 ? 'от ' : ''}${commarize(minPrice)}`;
          const countMarkup = count > 1 ? `<div class="${styles['count']}">${formatBalloonCount(count)}</div>` : '';
          const isFavorite = clusterOfferIds ? clusterOfferIds.some(id => favoriteIds?.includes(id)) : false;
          const favoriteMarkup = isFavorite ? `<div class="${styles['favorite']}">${FAVORITE_ICON}</div>` : '';
          const newbuildingId = newbuilding?.id ?? 0;
          const isNewbuildingOnProPlus = !!newbuilding?.isAnyFicheringPlus || isAnyFromKp;

          return `
            <div
              data-testid="${isNewbuildingOnProPlus ? 'promo-pin' : 'pin'}"
              data-newbuilding="${newbuildingId}"
              class="${classNames(
                // @todo выпилить эксп CD-159979
                this.showOnlyPrice ? styles['posutochno_wrapper'] : styles['wrapper'],
                isViewed && styles['viewed'],
              )}
            ">
              <div class="${classNames(
                styles['balloon'],
                (hasNewobject || isAnyFromKp) && styles['residential-complex'],
                isNewbuildingOnProPlus && styles['colorized'],
                isActive && styles['active'],
                // @todo выпилить эксп CD-159979
                this.showOnlyPrice && styles['posutochno_balloon'],
              )}">
                ${countMarkup}
                <div class="${styles['price']}">${price}</div>
                ${favoriteMarkup}
              </div>
            </div>
          `;
        }
      }

      provide(BalloonManager);
    },
  );
}
