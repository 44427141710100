import { ECCENTRICITY, SUBRADIUS, PI_UNDER_180 } from './constants';
import { cycleRestrict } from '../math';

// Предвычисленные коэффициенты для быстрого обратного преобразования Меркатора
// Подробнее см. тут: http://mercator.myzen.co.uk/mercator.pdf формула 6.52
// Работает только при небольших значения эксцентриситета!
const e2 = ECCENTRICITY * ECCENTRICITY;
const e4 = e2 * e2;
const e6 = e4 * e2;
const e8 = e4 * e4;
const d2 = e2 / 2 + (5 * e4) / 24 + e6 / 12 + (13 * e8) / 360;
const d4 = (7 * e4) / 48 + (29 * e6) / 240 + (811 * e8) / 11520;
const d6 = (7 * e6) / 120 + (81 * e8) / 1120;
const d8 = (4279 * e8) / 161280;

export function toWgs84(mercatorCoords: [number, number]): YMaps.TCoord {
  return [yToLatitude(mercatorCoords[1]), xToLongitude(mercatorCoords[0])];
}

export function yToLatitude(y: number): number {
  const xphi = Math.PI * 0.5 - 2 * Math.atan(1 / Math.exp(y * SUBRADIUS));
  const latitude =
    xphi + d2 * Math.sin(2 * xphi) + d4 * Math.sin(4 * xphi) + d6 * Math.sin(6 * xphi) + d8 * Math.sin(8 * xphi);

  return latitude * PI_UNDER_180;
}

function xToLongitude(x: number): number {
  return cycleRestrict(x * SUBRADIUS, -Math.PI, Math.PI) * PI_UNDER_180;
}
