export function simplifyCoordinates(coordinates: YMaps.TCoord[], tolerance: number) {
  const toleranceSquared = tolerance * tolerance;
  const simplified = [coordinates[0]];

  let prev = coordinates[0];
  for (let i = 1; i < coordinates.length; i++) {
    const current = coordinates[i];

    if (Math.pow(prev[0] - current[0], 2) + Math.pow(prev[1] - current[1], 2) > toleranceSquared) {
      simplified.push(current);
      prev = current;
    }
  }

  return simplified;
}
