import { TReduxActions } from '../../actions';
import { EResultsActionType } from '../../actions/results';

const initialState = '';

export function nonGeoQueryStringReducer(state: string = initialState, action: TReduxActions): string {
  switch (action.type) {
    case EResultsActionType.Succeed:
      return action.payload.nonGeoQueryString;
    default:
      return state;
  }
}
