import { EFetchNewbuildingsForPromoInventoryActionType, EPromoInventoryActionType } from '../../actions/promoInventory';
import { EBannerStatus, ENewbuildingsForPromoInventoryStatus, IPromoInventory } from '../../types/promoInventory';
import { IApplicationState, TReduxActions } from '../../types/redux';

const initialState: IPromoInventory = {
  newbuildings: [],
  activeBannerIndex: null,
  bannerStatus: EBannerStatus.Active,
  isForceClosedBanner: false,
  fetchingStatus: ENewbuildingsForPromoInventoryStatus.Initial,
};

export function promoInventoryReducer(
  state: IApplicationState['promoInventory'] = initialState,
  action: TReduxActions,
): IApplicationState['promoInventory'] {
  switch (action.type) {
    case EFetchNewbuildingsForPromoInventoryActionType.Succeed:
      return {
        ...state,
        fetchingStatus: ENewbuildingsForPromoInventoryStatus.Succeed,
        newbuildings: action.payload,
      };

    case EPromoInventoryActionType.ActiveBannerIndexChanged:
      return { ...state, activeBannerIndex: action.payload };

    case EPromoInventoryActionType.BannerStatusChanged:
      return { ...state, bannerStatus: action.payload };

    case EPromoInventoryActionType.ForceClosedBannerChanged:
      return { ...state, isForceClosedBanner: action.payload };

    default:
      return state;
  }
}
