import * as React from 'react';

import { useDeviceType, TDeviceType } from '../../utils/responsive';

interface IDeviceType {
  children(deviceType: TDeviceType): React.ReactNode;
}

export const DeviceType: React.FC<IDeviceType> = ({ children }) => <>{children(useDeviceType())}</>;

DeviceType.displayName = 'ResponsiveDeviceType';
