import { pathOr } from 'ramda';
import * as React from 'react';

import { EWindowsType } from 'shared/common/packages/api-models/common/json_query';

import { Windows } from '../../../components/advancedFilters/Windows';
import { useContext } from '../../../utils/useContext';

export const WindowsContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();
  const windows = pathOr<EWindowsType | null>(null, ['windows_type', 'value'], jsonQuery);

  const handleChange = React.useCallback(
    (windows: EWindowsType | null) => {
      onChange({ action: 'setWindowsType', arguments: [windows] });
    },
    [onChange],
  );

  return <Windows value={windows} onChange={handleChange} />;
};
