import * as React from 'react';

import { ControlLinkButton } from 'shared/map-search/components/ControlLinkButton';
import { Help } from 'shared/map-search/components/Icons';

import * as styles from './styles.css';

interface IHelpLinkButtonProps {
  href: string;
  onClick(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export const HelpLinkButton: React.FC<IHelpLinkButtonProps> = ({ href, onClick }) => {
  return (
    <ControlLinkButton
      href={href}
      title="Помощь"
      className={styles['container']}
      target="_blank"
      rel="noreferrer"
      size="XL"
      tooltip="Помощь"
      rounded
      onClick={onClick}
    >
      <Help />
    </ControlLinkButton>
  );
};

HelpLinkButton.displayName = 'HelpLinkButton';
