import {
  IconCar16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroMsk16,
  IconMetroNN16,
  IconMetroNovosib16,
  IconMetroSam16,
  IconMetroSpb16,
  IconWalk16,
} from '@cian/ui-kit';
import * as React from 'react';

import { ETransportType, EUndergroundType } from '../../../types/promoInventory';

export const transportIconMap: Record<ETransportType, React.FC> = {
  [ETransportType.Walk]: IconWalk16,
  [ETransportType.Transport]: IconCar16,
};

export const metroIconMap: Record<EUndergroundType, React.FunctionComponent> = {
  [EUndergroundType.Spb]: IconMetroSpb16,
  [EUndergroundType.Mow]: IconMetroMsk16,
  [EUndergroundType.Ekb]: IconMetroEkb16,
  [EUndergroundType.Kzn]: IconMetroKzn16,
  [EUndergroundType.Nsk]: IconMetroNovosib16,
  [EUndergroundType.Nn]: IconMetroNN16,
  [EUndergroundType.Smr]: IconMetroSam16,
};
