import { getGlobalEventsLogs } from '@cian/events-log';

import { TModifier } from '../../../common/packages/Filters';
import { NonEmptyArray } from '../../../common/packages/JsonQuery';
import { normalizeRegionId } from '../region';

export function produceGeoChangeEvent(appliedModifiers: [TModifier, ...TModifier[]]): void {
  const setRegionModifier = appliedModifiers.find(modifier => modifier.action === 'setRegion');

  if (setRegionModifier !== undefined) {
    const regionId = normalizeRegionId(setRegionModifier.arguments[0] as NonEmptyArray<number> | null);

    const globalLog = getGlobalEventsLogs();

    globalLog.produce('geo', {
      type: 'updateRegion',
      value: { regionId },
    });
  }
}
