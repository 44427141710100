import { TDeviceType } from '@cian/ui-kit';

import { bannersMobileHeight, EBanners } from '../../constants/banners';

export function setBannerBlockHeight(
  container: HTMLElement | null,
  deviceType: TDeviceType,
  bannerType: EBanners | null,
) {
  if (!container || !bannerType) {
    return;
  }

  if (deviceType === 'desktop') {
    container.style.height = '0';
  } else {
    container.style.height = `${bannersMobileHeight[bannerType]}px`;
  }
}
