import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsNewbuildingActive } from '../../selectors/features';
import { SidebarCommonHeaderContainer } from '../SidebarCommonHeaderContainer';
import { SidebarNewbuildingHeaderContainer } from '../SidebarNewbuildingHeaderContainer';

export const SidebarHeaderContainer: React.FC = () => {
  const isNewbuilding = useSelector(selectIsNewbuildingActive);

  if (isNewbuilding) {
    return <SidebarNewbuildingHeaderContainer />;
  }

  return <SidebarCommonHeaderContainer />;
};

SidebarHeaderContainer.displayName = 'SidebarHeaderContainer';
