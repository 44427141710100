import * as React from 'react';

import { getRangeValue } from 'shared/common/packages/JsonQuery';

import { Bedrooms } from '../../../components/advancedFilters/Bedrooms';
import { useContext } from '../../../utils/useContext';

export const BedroomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const bedrooms = getRangeValue('bedroom_total')(jsonQuery);

  const handleBedroomsMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setBedroomsCountMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleBedroomsMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setBedroomsCountMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <Bedrooms
      bedrooms={bedrooms}
      onBedroomsMinChange={handleBedroomsMinChange}
      onBedroomsMaxChange={handleBedroomsMaxChange}
    />
  );
};
