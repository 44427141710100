import { JsonQuery } from '../../../common/packages/JsonQuery';
import { TGeoValue } from '../../../common/packages/api-models/common/json_query';
import { TThunkAction } from '../../types/redux';
import { changeJsonQuery, fetchTags } from '../filters';
import { fetchClusters } from '../results';

/** Deprecated. Use changeJsonQuery */
export function addGeo(value: TGeoValue): TThunkAction {
  return async (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();

    const jq = new JsonQuery(jsonQuery);
    jq.selectGeo(value);

    dispatch(
      changeJsonQuery({
        appliedModifiers: [{ action: 'selectGeo', arguments: [value] }],
        prevJsonQuery: jsonQuery,
        nextJsonQuery: jq.toJSON(),
      }),
    );
    dispatch(fetchClusters({ updateBounds: true }));
    dispatch(fetchTags());
  };
}
