import * as React from 'react';

import * as styles from './SidebarNewbuildingHeader.css';
import { CloseButton } from '../CloseButton';

interface ISidebarNewbuildingHeaderProps {
  favoriteButton?: React.ReactNode;
  onClose(): void;
}

export const SidebarNewbuildingHeader: React.FC<ISidebarNewbuildingHeaderProps> = ({ favoriteButton, onClose }) => {
  return (
    <div className={styles['container']}>
      {favoriteButton}
      <CloseButton onClick={onClose} />
    </div>
  );
};
