import * as React from 'react';

import { EKitchenStove } from 'shared/common/packages/JsonQuery';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IKitchenStoveOption {
  label: string;
  value: EKitchenStove | null;
}

const KITCHEN_STOVE_OPTIONS: IKitchenStoveOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Газовая', value: EKitchenStove.Gas },
  { label: 'Электрическая', value: EKitchenStove.Electric },
];

interface IKitchenStoveProps {
  value: EKitchenStove | null;
  onChange(value: EKitchenStove | null): void;
}

export const KitchenStove: React.FC<IKitchenStoveProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_kitchenStove" label="Кухонная плита">
      <RadioButtonGroup options={KITCHEN_STOVE_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
