import { FOfferType } from 'shared/common/packages/JsonQuery';

import { IOfferType } from '../../types';

export const OFFER_TYPES_SALE_RESIDENTIAL: IOfferType[][] = [
  [
    { label: 'Квартира в новостройке', value: FOfferType.FlatNew },
    { label: 'Квартира во вторичке', value: FOfferType.FlatOld },
  ],
  [
    { label: 'Комната', value: FOfferType.Room },
    { label: 'Доля', value: FOfferType.FlatShared },
  ],
  [
    { label: 'Дом, дача', value: FOfferType.House },
    { label: 'Часть дома', value: FOfferType.HousePart },
    { label: 'Таунхаус', value: FOfferType.Townhouse },
    { label: 'Участок', value: FOfferType.Land },
  ],
  [{ label: 'Гараж', value: FOfferType.Garage }],
];
