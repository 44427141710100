import { TReduxActions } from '../../actions';
import { EOffersCountActionType } from '../../actions/offersCount';

const defaultState = 0;

export function offersCountReducer(state = defaultState, action: TReduxActions): number {
  switch (action.type) {
    case EOffersCountActionType.OffersCountUpdated:
      return action.payload;
    default:
      return state;
  }
}
