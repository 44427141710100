import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { setBannerBlockHeight } from './helpers';
import { useGetCurrentBanner } from './utils';
import { changeForceClosedBanner } from '../../actions/promoInventory';
import { BANNER_ID, EBanners } from '../../constants/banners';
import { FskBannerContainer } from '../FskBannerContainer';
import { LeadsContainer } from '../LeadsContainer';
import { ProPlusBannerContainer } from '../ProPlusBannerContainer';
import { RealtorReportBannerContainer } from '../RealtorReportBannerContainer';

/**
 * Здесь предполагаю, что будет логика для баннеров для пакетов
 * https://conf.cian.tech/pages/viewpage.action?pageId=1299053455
 */
/**
 * Добавление новых баннеров:
 *  - добавляем в энаму (EBanners) баннеров новый баннер src/shared/constants/banners.ts
 *  - добавляем в константу мобильных размеров баннера (bannersMobileHeight) новый баннер src/shared/constants/banners.ts
 *  - добавляем в хук useGetCurrentBanner условия рендера нового баннера
 *  - добавляем условия установки высоты для мобильной версии баннера src/node/services/getBanner/getBanner.tsx
 */
export function BannersContainer() {
  const [open, setOpen] = React.useState(false);
  const currentBanner = useGetCurrentBanner();
  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const handleClose = React.useCallback(() => {
    setOpen(false);
    dispatch(changeForceClosedBanner(true));

    if (deviceType !== 'desktop') {
      // Выключил проверку наличия контейнера
      /* istanbul ignore next */
      document.getElementById(BANNER_ID)?.remove();
    }
  }, [deviceType]);

  /**
   * Массив deps пустой т.к. за одну сессию показываем только 1 тип баннера
   */
  React.useEffect(() => {
    if (currentBanner) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const container = document.getElementById(BANNER_ID);

    setBannerBlockHeight(container, deviceType, currentBanner);
  }, [currentBanner, deviceType]);

  switch (currentBanner) {
    case EBanners.Fsk:
      return <FskBannerContainer open={open} onClose={handleClose} />;

    case EBanners.Leads:
      return <LeadsContainer open={open} onClose={handleClose} />;

    case EBanners.ProPlus:
      return <ProPlusBannerContainer open={open} onClose={handleClose} />;

    case EBanners.RealtorReport:
      return <RealtorReportBannerContainer open={open} onClose={handleClose} />;
  }

  return null;
}
