import { reverse } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { term } from 'shared/common/packages/JsonQuery';
import { SaveSearchProvider as Provider } from 'shared/common/packages/SaveSearchModal';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { selectMapBounds } from 'shared/map-search/selectors/mapBounds';
import { selectJsonQuery } from 'shared/map-search/selectors/results';
import { getSaveSearchMicrofrontend } from 'shared/map-search/services/saveSearch';

import { selectQueryString } from '../../selectors/queryString';
import { selectUser } from '../../selectors/user';
import { useApplicationContext } from '../ApplicationContext';

enum ESource {
  Card = 'card',
  Listing = 'listing',
  Map = 'map',
}

export const SaveSearchModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useApplicationContext();
  const fetchManifest = React.useCallback(async () => getSaveSearchMicrofrontend(context), [context]);

  const [title, setTitle] = React.useState<string | null>(null);
  const user = useSelector(selectUser);
  const email = React.useMemo(() => (user?.isAuthenticated ? user.email : null), [user]);
  const isAuthenticated = !!user?.isAuthenticated;

  const queryString = useSelector(selectQueryString);
  const jsonQuery = useSelector(selectJsonQuery);
  const mapBounds = useSelector(selectMapBounds);
  const jsonQueryWithBounds: IJsonQuery = {
    ...jsonQuery,
    bbox: term(mapBounds.bounds?.map(bounds => reverse(bounds)) as [YMaps.TCoord, YMaps.TCoord]),
  };

  React.useEffect(() => {
    setTitle(document.title);
  }, []);

  const params = {
    email,
    title,
    isAuthenticated,
    source: ESource.Map,
    jsonQuery: jsonQueryWithBounds,
    analyticsParams: {
      pageType: 'Listing',
      listingType: ESource.Map,
      isFromBanner: false,
      queryString,
    },
  };

  return (
    <Provider fetchManifest={fetchManifest} params={params}>
      {children}
    </Provider>
  );
};
