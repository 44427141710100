import { TMapBounds } from '../../types/mapBounds';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EMapBoundsActionType {
  Changed = 'mapBounds/changed',
}

export type TMapBoundsChanged = ITypedReduxAction<EMapBoundsActionType.Changed, TMapBounds>;

export type TMapBoundsActions = TMapBoundsChanged;
