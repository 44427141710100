import { IRBushItem, TFeature } from 'shared/map-search/types/map';

export function sortPins(a: IRBushItem, b: IRBushItem, viewedNewbuildings: string[], selectedFeature: TFeature | null) {
  if (selectedFeature?.properties.isNewbuildingPromo) {
    if (a.feature.id === selectedFeature.id) {
      return -1;
    }

    if (b.feature.id === selectedFeature.id) {
      return 1;
    }
  }

  const hasA = viewedNewbuildings.includes(a.feature.id);
  const hasB = viewedNewbuildings.includes(b.feature.id);

  if (hasA && !hasB) {
    return -1;
  }
  if (hasB && !hasA) {
    return 1;
  }

  return 0;
}
