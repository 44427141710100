import { ca } from '@cian/analytics';

export function trackFiltersClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'Map',
    label: 'Filters',
  });
}
