import classNames from 'clsx';
import RBush from 'rbush';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { commarize } from 'shared/common/utils/number';

import * as styles from './styles.css';
import { formatBalloonCount, sortPins } from './utils';
import { IRBushItem, TFeaturePropertiesRequiredPrice, TFeature, IFeatureProperties } from '../../../types/map';
import { geoJsonFeatureToRBushItem } from '../../features';
import { trackPromoPinsLoad } from '../../tracking';

interface INewbuildingsPromoPinsManagerOptions {
  map: YMaps.Map;
  rbush: RBush<IRBushItem>;
}

export function defineNewbuildingsPromoPinsManager(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('NewbuildingsPromoPinsManager')) {
    return;
  }

  ymaps.modules.define<
    [YMaps.ITemplateLayoutFactory, typeof YMaps.shape.Rectangle, typeof YMaps.geometry.pixel.Rectangle]
  >(
    'NewbuildingsPromoPinsManager',
    ['templateLayoutFactory', 'shape.Rectangle', 'geometry.pixel.Rectangle'],
    (
      provide,
      templateLayoutFactory: YMaps.ITemplateLayoutFactory,
      shapeRectangle: typeof YMaps.shape.Rectangle,
      geometryPixelRectangle: typeof YMaps.geometry.pixel.Rectangle,
    ) => {
      class NewbuildingsPromoPinsManager {
        public readonly objectManager: YMaps.ObjectManager;

        private map: YMaps.Map;
        private rbush: RBush<IRBushItem>;
        private selectedFeature: TFeature | null;
        private viewedNewbuildings: string[];
        private currentNewbuildings: IRBushItem[];
        private randomizeNextStep: boolean;
        private sendShowAnalytics: boolean;

        public constructor(options: INewbuildingsPromoPinsManagerOptions) {
          this.map = options.map;
          this.rbush = options.rbush;
          this.selectedFeature = null;
          this.viewedNewbuildings = [];
          this.currentNewbuildings = [];
          this.randomizeNextStep = true;
          this.sendShowAnalytics = true;
          this.objectManager = new ymaps.ObjectManager();
        }

        public setShowAnalyticsState = (value: boolean) => {
          this.sendShowAnalytics = value;
        };

        public setSelectedFeature = (clickedFeature: TFeature | null) => {
          const feature: TFeature | null = clickedFeature
            ? this.objectManager.objects.getById(clickedFeature.properties.featureId || clickedFeature.id)
            : null;

          if (feature) {
            this.updateBalloonProperties(feature.id, {
              ...feature.properties,
              isActive: true,
            });
          }

          if (this.selectedFeature && feature?.id !== this.selectedFeature?.id) {
            this.updateBalloonProperties(this.selectedFeature.id, {
              ...this.selectedFeature.properties,
              isActive: false,
            });
          }

          this.sendShowAnalytics = false;
          this.selectedFeature = feature;
        };

        public clear = () => {
          this.rbush.clear();
          this.objectManager.removeAll();
          this.viewedNewbuildings = [];
          this.currentNewbuildings = [];
          this.randomizeNextStep = true;
        };

        public loadNewbuildings = (newbuildings: TFeature[], jsonQuery: IJsonQuery) => {
          this.sendShowAnalytics = true;
          this.clear();
          this.rbush.load(newbuildings.map(geoJsonFeatureToRBushItem));
          this.randomizeNextStep = true;
          setTimeout(() => this.drawPinsWithBalloons(false, jsonQuery), 10);
        };

        public drawPinsWithBalloons = (forceRandomize: boolean = false, jsonQuery: IJsonQuery) => {
          this.objectManager.removeAll();
          const randomize = forceRandomize || this.randomizeNextStep;

          const bounds = this.map.getBounds();
          const rBushItems = randomize
            ? this.rbush
                .search({
                  minX: bounds[0][0],
                  minY: bounds[0][1],
                  maxX: bounds[1][0],
                  maxY: bounds[1][1],
                })
                .sort(() => Math.random() - 0.5)
                .sort((a, b) => sortPins(a, b, this.viewedNewbuildings, this.selectedFeature))
                .slice(0, 5)
            : this.currentNewbuildings;

          this.viewedNewbuildings = rBushItems.map(el => el.feature.id);
          this.currentNewbuildings = rBushItems;
          this.randomizeNextStep = false;

          this.objectManager.add({
            type: 'FeatureCollection',
            features: [
              ...(rBushItems.map(this.prepareBalloon).filter(feature => !!feature) as TFeature[]),
              ...(rBushItems.map(this.preparePin).filter(feature => !!feature) as TFeature[]),
            ],
          });

          if (this.sendShowAnalytics) {
            trackPromoPinsLoad({
              pins: rBushItems,
              zoom: Math.round(this.map.getZoom()),
              jsonQuery,
            });

            this.sendShowAnalytics = false;
          }
        };

        public updateBalloonProperties = (featureId: string, properties: IFeatureProperties) => {
          this.objectManager.objects.setObjectOptions(featureId, {
            iconLayout: this.prepareBalloonLayout(properties),
          });
        };

        private prepareBalloon = ({ feature }: IRBushItem) => {
          const { properties } = feature;

          if (!properties.minPrice) {
            return null;
          }

          return {
            ...feature,
            options: {
              iconLayout: this.prepareBalloonLayout(properties as TFeaturePropertiesRequiredPrice),
              zIndex: 2,
            },
          };
        };

        private preparePin = ({ feature }: IRBushItem) => {
          return {
            ...feature,
            id: `nbpin_${feature.id}`,
            options: {
              iconLayout: this.preparePinLayout(),
              zIndex: 1,
            },
          };
        };

        private prepareBalloonLayout = (properties: IFeatureProperties) => {
          const content = this.prepareBalloonContent(properties);

          return templateLayoutFactory.createClass(content, {
            getShape() {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const element: HTMLElement = (this as any).getElement();
              if (!element) {
                return null;
              }

              const wrapperElement = element.firstElementChild;
              if (!wrapperElement) {
                return null;
              }

              const boundingRect = wrapperElement.getBoundingClientRect();
              const offsetX = -boundingRect.width / 2;
              const offsetY = -boundingRect.height - 4;

              return new shapeRectangle(
                new geometryPixelRectangle([
                  [offsetX, offsetY],
                  [offsetX + boundingRect.width, offsetY + boundingRect.height],
                ]),
              );
            },
          });
        };

        private preparePinLayout = () => {
          const content = `<div class="${styles['point']}"></div>`;

          return templateLayoutFactory.createClass(content, {
            getShape() {
              return new shapeRectangle(
                new geometryPixelRectangle([
                  [0, 0],
                  [12, 12],
                ]),
              );
            },
          });
        };

        private prepareBalloonContent(feature: IFeatureProperties) {
          const { count, minPrice, isActive, newbuilding } = feature;

          const newbuildingId = newbuilding?.id ?? 0;
          const price = minPrice ? `${count > 1 ? 'от ' : ''}${commarize(minPrice)}` : '';
          const countMarkup = count > 1 ? `<div class="${styles['count']}">${formatBalloonCount(count)}</div>` : '';

          return `
            <div data-testid="promo-pin" data-newbuilding="${newbuildingId}" class="${styles['wrapper']}">
              <div class="${classNames(styles['balloon'], isActive && styles['active'])}">
                ${countMarkup}
                <div class="${styles['price']}">${price}</div>
              </div>
            </div>
          `;
        }
      }

      provide(NewbuildingsPromoPinsManager);
    },
  );
}
