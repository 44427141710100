import cx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useMapContext } from 'shared/map-search/containers/Map/context';
import { IHotspotProperties, TFeature } from 'shared/map-search/types/map';
import { IApplicationState } from 'shared/map-search/types/redux';
import { useDailyRentAvailabilityFeatureEnabled } from 'shared/map-search/utils/dailyRentAvailability';

import * as styles from './DotHover.css';
import { useFeaturesContext } from '../context';

interface IDotHoverProps {
  resultsAvailable: boolean;
  detailsVisible: boolean;
}

interface IIconLayout {
  isNewbuildingsListing: boolean;
  isSmall: boolean;
  isSuburbanFromKp: boolean;
}

export const DotHover: React.FC<IDotHoverProps> = ({
  resultsAvailable: isResultsAvailable,
  detailsVisible: isDetailsVisible,
}) => {
  const { ymaps, map } = useMapContext();
  const { hotspotsLayer } = useFeaturesContext();
  const selectedFeature = useSelector<IApplicationState, TFeature | null>(state => state.activeFeature);
  const isNewbuildingsListing = useSelector<IApplicationState, boolean>(state => state.isNewbuildingsListing);
  // @todo выпилить эксп CD-159979
  const hideTileOnHover = useDailyRentAvailabilityFeatureEnabled();
  const iconLayout = React.useRef(({ isNewbuildingsListing, isSmall, isSuburbanFromKp }: IIconLayout) =>
    ymaps.templateLayoutFactory.createClass(
      // @todo выпилить эксп CD-159979
      `<div class="${cx(hideTileOnHover ? styles['dailyrent-container'] : styles['container'], {
        [styles['container--small']]: isSmall,
        [styles['container--blue']]: !isNewbuildingsListing,
        [styles['container--green']]:
          isNewbuildingsListing || selectedFeature?.properties.newbuilding?.isAnyFicheringPlus || isSuburbanFromKp,
      })}">{% if properties.count > 1 %}{% if properties.count <= 9 %}{{properties.count}}{% else %}9+{% endif %}{% endif %}</div>`,
    ),
  );
  const placemarkObject = React.useRef<YMaps.GeoObject | null>(null);

  const handleHotspotMouseEnter = React.useCallback(
    (event: YMaps.IEvent) => {
      if (!isResultsAvailable || isDetailsVisible) {
        return;
      }

      const activeObject = event.get<YMaps.IHotspotLayerObject<IHotspotProperties>>('activeObject');
      const originalFeature = activeObject.getProperties().originalFeature;

      if (selectedFeature && selectedFeature.id === originalFeature.id) {
        return;
      }

      const isSmallPlacemark = originalFeature.properties.count === 1;

      placemarkObject.current = new ymaps.GeoObject(
        {
          ...originalFeature,
          type: 'Feature',
          id: 'DotHoverFeature',
        },
        {
          iconLayout: iconLayout.current({
            isNewbuildingsListing,
            isSmall: isSmallPlacemark,
            isSuburbanFromKp: !!originalFeature.properties.isAnyFromKp,
          }),
        },
      );

      map.geoObjects.add(placemarkObject.current);
    },
    [isDetailsVisible, isNewbuildingsListing, isResultsAvailable, map, selectedFeature, ymaps],
  );

  const handleHotspotMouseLeave = React.useCallback(() => {
    if (placemarkObject.current) {
      map.geoObjects.remove(placemarkObject.current);
      placemarkObject.current = null;
    }
  }, [map]);

  React.useEffect(() => {
    hotspotsLayer.layer.events.add('mouseenter', handleHotspotMouseEnter);
    hotspotsLayer.layer.events.add('mouseleave', handleHotspotMouseLeave);

    return () => {
      hotspotsLayer.layer.events.remove('mouseenter', handleHotspotMouseEnter);
      hotspotsLayer.layer.events.remove('mouseleave', handleHotspotMouseLeave);
    };
  }, [handleHotspotMouseEnter, handleHotspotMouseLeave, hotspotsLayer]);

  React.useEffect(() => {
    if (selectedFeature && placemarkObject.current) {
      map.geoObjects.remove(placemarkObject.current);
      placemarkObject.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  return null;
};
