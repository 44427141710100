import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openFeature as openFeatureAction, IOpenFeatureParams } from 'shared/map-search/actions/features';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { TFeature } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';

import { trackPinClick } from './tracking';
import { useNewbuildingPromoPinsContext } from '../context';

export const ClickHandlers: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const deviceType = useDeviceType();
  const { map } = useMapContext();
  const { precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const { newbuildingsPromoPinsManager } = useNewbuildingPromoPinsContext();

  const openFeature = React.useCallback(
    (params: Omit<IOpenFeatureParams, 'deviceType'>) => {
      dispatch(openFeatureAction({ ...params, deviceType }));
    },
    [dispatch, deviceType],
  );

  const handleFeatureOpen = React.useCallback(
    (callbackFeature: TFeature) => {
      if (!callbackFeature) {
        return;
      }

      const feature = newbuildingsPromoPinsManager.objectManager.objects.getById<TFeature>(
        callbackFeature.properties.featureId || callbackFeature.id,
      );

      const currentZoom = map.getZoom();

      if (feature.properties.newbuilding) {
        trackPinClick({
          zoom: Math.round(currentZoom),
          newbuilding: feature.properties.newbuilding,
        });
      }

      openFeature({ feature, precision, isDetailed: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, openFeature, precision],
  );

  const handleBalloonClick = React.useCallback(
    (event: YMaps.IEvent) => {
      const objectId = event.get<string>('objectId');
      const feature = newbuildingsPromoPinsManager.objectManager.objects.getById<TFeature>(objectId);

      handleFeatureOpen(feature);
    },
    [newbuildingsPromoPinsManager, handleFeatureOpen],
  );

  React.useEffect(() => {
    newbuildingsPromoPinsManager.objectManager.objects.events.add('click', handleBalloonClick);

    return () => {
      newbuildingsPromoPinsManager.objectManager.objects.events.remove('click', handleBalloonClick);
    };
  }, [newbuildingsPromoPinsManager, handleBalloonClick]);

  return null;
};
