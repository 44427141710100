import { IconClose12 } from '@cian/ui-kit/icons';
import classNames from 'clsx';
import * as React from 'react';

import * as styles from './BannerWrapper.css';

interface IBannerWrapperProps {
  children: React.ReactChild | React.ReactChild[];
  attributes?: React.HTMLAttributes<HTMLDivElement> & { [key: `data-${string}`]: string };
  /**
   * Когда тру, баннер двигается вправо на ширину боковой панели
   */
  moveRight?: boolean;
  onClose?(): void;
}

export function BannerWrapper({ children, attributes = {}, moveRight, onClose }: IBannerWrapperProps) {
  return (
    <div
      {...attributes}
      className={classNames(styles['container'], { [styles['container--moved']]: moveRight }, attributes.className)}
    >
      {onClose && (
        <button className={styles['close']} onClick={onClose} data-testid="close">
          <IconClose12 />
        </button>
      )}

      {children}
    </div>
  );
}
