import { EFavoritesActionType, IFavoriteParameters } from './types';
import { decrementHeaderFavorites } from './utils/updateHeaderFavorites';
import { TThunkAction } from '../../types/redux';
import { zoomToPrecision } from '../../utils/precision';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const favoriteRemove = actionGenerator<EFavoritesActionType.Removed, IFavoriteParameters>(EFavoritesActionType.Removed);

export function deleteFavorite(offerId: number): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { config, logger } = context;
    const {
      mapBounds: { zoom: currentZoom },
      activeFeature: selectedFeature,
    } = getState();
    if (!currentZoom) {
      logger.error('Zoom is undefined', { domain: 'actions/favorites/deleteFavorite' });

      return;
    }
    if (!selectedFeature) {
      logger.error('SelectedFeature is undefined', { domain: 'actions/favorites/deleteFavorite' });

      return;
    }
    const currentPrecision = zoomToPrecision(config, currentZoom);

    dispatch(favoriteRemove({ offerId, currentPrecision, selectedFeatureId: selectedFeature.id }));
    decrementHeaderFavorites();
  };
}
