import { ca } from '@cian/analytics';

import { TPlatform } from '../../types/platform';

export const trackShowBanner = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    category: 'Better_in_app',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
  });
};

export const trackClickOnBanner = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'Better_in_app',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        buttonName: 'open_app',
      },
    },
  });
};

export const trackOnCloseBanner = (platform: TPlatform) => {
  return ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'Better_in_app',
    label: platform === 'ios' ? 'app_iOS' : 'app_android',
    page: {
      extra: {
        buttonName: 'stay_on_site',
      },
    },
  });
};
