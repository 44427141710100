import { useSelector } from 'react-redux';

import { PropPlusBanner } from './PropPlusBanner';
import { selectCurrentNewbuildingForBanner } from '../../selectors/promoInventory';

export interface IProPlusBannerContainerProps {
  open: boolean;
  onClose(): void;
}

export function ProPlusBannerContainer({ open, onClose }: IProPlusBannerContainerProps) {
  const currentNewbuilding = useSelector(selectCurrentNewbuildingForBanner);

  if (!currentNewbuilding || !open) {
    return null;
  }

  return <PropPlusBanner currentNewbuilding={currentNewbuilding} onClose={onClose} />;
}
