import * as React from 'react';

import { ELandStatusType, getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { LandStatus } from '../../../components/advancedFilters/LandStatus';
import { useContext } from '../../../utils/useContext';

export const LandStatusContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const landStatus = getTermsValue('land_status')(jsonQuery);

  const handleChange = React.useCallback(
    (landStatus: NonEmptyArray<ELandStatusType> | null) => {
      onChange({ action: 'setLandStatusTypes', arguments: [landStatus] });
    },
    [onChange],
  );

  return <LandStatus value={landStatus as NonEmptyArray<ELandStatusType> | null} onChange={handleChange} />;
};
