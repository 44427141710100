const DEFAULT_OPTIONS: YMaps.IPolygonOptions = {
  fillColor: 'rgba(35, 133, 222, 0.1)',
  strokeColor: '#2385de',
  // Делаем полигон прозрачным для событий карты
  interactivityModel: 'default#transparent',
  strokeWidth: 3,
};

export function createPolygon(
  ymaps: YMaps.IYMaps,
  coords: YMaps.TCoord[],
  properties?: YMaps.IPolygonProperties,
  options?: YMaps.IPolygonOptions,
) {
  const polygon = new ymaps.Polygon([coords], { ...properties }, { ...DEFAULT_OPTIONS, ...options });

  return polygon;
}
