import { TFeature, TFeatureWithPrecision } from '../../types/map';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EFeaturesActionType {
  FeatureSelected = 'FEATURES/SELECTED',
  FeaturePropertiesUpdated = 'features/propertiesUpdated',
}

export interface IFeaturePropertiesUpdateParameters {
  precision: number;
  featureId: TFeature['id'];
  properties: Partial<TFeature['properties']>;
}

export type TFeatureSelectedAction = ITypedReduxAction<EFeaturesActionType.FeatureSelected, TFeatureWithPrecision>;
export type TFeaturePropertiesUpdatedAction = ITypedReduxAction<
  EFeaturesActionType.FeaturePropertiesUpdated,
  IFeaturePropertiesUpdateParameters
>;

export type TFeaturesActions = TFeatureSelectedAction | TFeaturePropertiesUpdatedAction;
