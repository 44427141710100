import * as React from 'react';

import { NBSP } from 'shared/common/constants/symbols';
import { getTermsValue, JsonQuery, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { useDiscountPromoSearchTypeOptions, useDiscountPromoSearchTypeValues } from './hooks';
import { FilterChips } from '../../../components/FilterChips';
import { Filter } from '../../../components/advancedFilters/common/Filter';
import { getPromoSearchFilterValuesByType } from '../../../utils/getPromoSearchFilterValuesByType';
import { useContext } from '../../../utils/useContext';

export const DiscountPromoSearchTypeContainer: React.FC = () => {
  const { jsonQuery, promoSearchTypes, onChange } = useContext();

  const apartments = new JsonQuery(jsonQuery).getApartments();

  const allValues = getTermsValue('promo_search_types')(jsonQuery);
  const discountValues = useDiscountPromoSearchTypeValues(allValues, promoSearchTypes);
  const discountOptions = useDiscountPromoSearchTypeOptions(promoSearchTypes, apartments);

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange({
        action: 'setPromoSearchTypes',
        arguments: [
          {
            allValues,
            newValues,
            availableFilterValues: getPromoSearchFilterValuesByType('discount', promoSearchTypes),
          },
        ],
      });
    },
    [allValues, promoSearchTypes, onChange],
  );

  if (!promoSearchTypes) {
    return null;
  }

  return (
    <Filter id="advancedFilter_discountPromoSearchType" label={`Скидки и акции от${NBSP}застройщиков`}>
      <div data-name="DiscountPromoSearchTypeChips">
        <FilterChips options={discountOptions} value={discountValues} onChange={handleChange} selectType="multiple" />
      </div>
    </Filter>
  );
};
