import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext } from 'shared/map-search/types/applicationContext';

import { eventHandler } from './eventHandler';

export async function consumeYmapsLoaderEvents(context: IApplicationContext) {
  try {
    const telemetry = getTelemetry();
    const ymapsLoaderManager = window._cianYmapsLoaderManager;

    const eventsQueue = await ymapsLoaderManager.getEventsQueue();
    for (const event of eventsQueue) {
      eventHandler({ ...context, telemetry }, event);
    }
  } catch (ex) {
    context.logger.error(ex, { domain: 'eventsConsumer' });
  }
}
