import * as React from 'react';

import * as styles from './Tags.css';
import { ITagProps } from '../Tag';
import { TagsList } from '../TagsList';

interface ITagsProps {
  forwardedRef: React.Ref<HTMLDivElement>;
  // children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
}

const TagsComponent: React.FC<React.PropsWithChildren<ITagsProps>> = ({ forwardedRef, children }) => {
  const tags = React.Children.toArray(children) as React.ReactElement<ITagProps>[];

  return (
    <div ref={forwardedRef} className={styles['container']}>
      <TagsList tags={tags} />
    </div>
  );
};

export const Tags = React.forwardRef<HTMLDivElement, Omit<React.PropsWithChildren<ITagsProps>, 'forwardedRef'>>(
  (props, ref) => <TagsComponent forwardedRef={ref} {...props} />,
);
