import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { getAvailableFilters } from './helpers';
import { Filters } from '../../components/FiltersComponent';
import { FILTERS } from '../../constants/filters';
import { TFilter } from '../../types/filters';
import { useContext } from '../../utils/useContext';
import { useDealType } from '../../utils/useDealType';
import { useOfferType } from '../../utils/useOfferType';

interface IFiltersContainerProps {
  savedSearch?: React.ReactNode;
}

export const FiltersContainer: React.FC<IFiltersContainerProps> = ({ savedSearch }) => {
  const context = useContext();
  if (!context) {
    throw new ConfigurationError({
      domain: 'Filters',
      message: "Filters used outside of it's context",
    });
  }

  const dealType = useDealType();
  const offerType = useOfferType();
  const availableFilters = getAvailableFilters(dealType, offerType);

  const availableFiltersComponents = availableFilters.reduce(
    (acc, filterKey) => {
      const { component: Component, availability } = FILTERS[filterKey];
      if (availability && availability?.(context.jsonQuery, context.features)) {
        acc[filterKey] = <Component />;
      }

      return acc;
    },
    {} as { [key in TFilter]: React.ReactNode },
  );

  return <Filters {...availableFiltersComponents} savedSearch={savedSearch} />;
};
