import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openNewbuildingPolygon } from 'shared/map-search/actions/newbuildingPolygon';
import { useNewbuildingPolygonsContext } from 'shared/map-search/containers/Map/MapLayers/NewbuildingsPolygons/context';
import { MAP_SET_CENTER_OPTIONS } from 'shared/map-search/containers/Map/constants';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { selectActiveNewbuildingPolygon } from 'shared/map-search/selectors/newbuildingPolygons';
import { selectQueryString } from 'shared/map-search/selectors/queryString';
import { IActiveNewbuildingPolygon } from 'shared/map-search/types/map/newbuildingPolygon';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';

import { trackClickPolygon } from './tracking';

export const PolygonClickHandlers: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { newbuildingsPolygonsManager } = useNewbuildingPolygonsContext();
  const { map } = useMapContext();

  const queryString = useSelector<IApplicationState, string>(selectQueryString);
  const activePolygon = useSelector<IApplicationState, IActiveNewbuildingPolygon>(selectActiveNewbuildingPolygon);
  const deviceType = useDeviceType();

  const handleClick = React.useCallback(
    (event: YMaps.IEvent) => {
      const objectId = event.get<string>('objectId');
      const coords = event.get<YMaps.TCoord>('coords');

      const newbuilding = {
        id: Number(objectId),
      };

      if (activePolygon.newbuildingId !== newbuilding.id) {
        map.setCenter(coords, undefined, MAP_SET_CENTER_OPTIONS);
        dispatch(openNewbuildingPolygon(newbuilding));
        trackClickPolygon(queryString, deviceType, newbuilding.id);
      }
    },
    [queryString, deviceType, activePolygon],
  );

  React.useEffect(() => {
    newbuildingsPolygonsManager.objectManager.objects.events.add('click', handleClick);

    return () => {
      newbuildingsPolygonsManager.objectManager.objects.events.remove('click', handleClick);
    };
  }, [handleClick, newbuildingsPolygonsManager]);

  return null;
};
