import * as React from 'react';

import * as styles from './SidebarCommonHeader.css';
import { CloseButton } from '../CloseButton';

interface ISidebarCommonHeaderProps {
  onClose(): void;
}

export const SidebarCommonHeader: React.FC<ISidebarCommonHeaderProps> = ({ onClose }) => {
  return (
    <div className={styles['close-button']}>
      <CloseButton onClick={onClose} />
    </div>
  );
};
