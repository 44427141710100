import { EGetUserStatusActionType } from '../../actions/user';
import { TReduxActions } from '../../types/redux';
import { TUserState, EGetUserStatus } from '../../types/user';

const defaultState: TUserState = {
  status: EGetUserStatus.Initial,
  isAuthenticated: false,
};

export function userReducer(state: TUserState = defaultState, action: TReduxActions): TUserState {
  switch (action.type) {
    case EGetUserStatusActionType.Loading:
      return {
        ...state,
        status: EGetUserStatus.Loading,
      };
    case EGetUserStatusActionType.Succeed:
      return {
        status: EGetUserStatus.Succeed,
        ...action.payload,
      };
    case EGetUserStatusActionType.Failed:
      return {
        ...state,
        status: EGetUserStatus.Failed,
      };
    default:
      return state;
  }
}
