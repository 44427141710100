import { EAsyncStatus } from '../../types/asyncStatus';
import { IApplicationState } from '../../types/redux';

export function selectVisibleItemsCount(state: IApplicationState): number | undefined {
  const { activeType, data } = state.infrastructure;
  if (!activeType) {
    return undefined;
  }

  if ([EAsyncStatus.Initial, EAsyncStatus.Loading].includes(data[activeType].featuresStatus)) {
    return undefined;
  }

  return data[activeType].visibleItemsCount;
}
