import { ca } from '@cian/analytics';

export function trackGoToApplicationForm() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click',
    label: 'GoToApplicationForm/main',
  });
}
