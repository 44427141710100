// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function normalizePage(eventParameters: any): any {
  const modifiedEventParameters = { ...eventParameters };

  if (modifiedEventParameters.page) {
    modifiedEventParameters.page.listingType = 'map';
  }

  return modifiedEventParameters;
}
