import { withPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { markAsClosed } from 'browser/map-search/utils/banner';

import { IFskBanner } from 'shared/map-search/types/fskContent/fskContent';
import { IApplicationState } from 'shared/map-search/types/redux';

import { trackClickBanner, trackCloseBanner, trackShowBanner } from './tracking';
import { DesktopBanner, MobileBanner } from '../../components/FskBanner';
import { FSK_CLOSED_COOKIE_KEY, EBanners, EXPIRES_TIME_FOR_FSK } from '../../constants/banners';
import { FSK_BANNER, FSK_BANNER_PLACE } from '../../constants/fsk';
import { selectQueryString } from '../../selectors/queryString';
import { selectIsOpen } from '../../selectors/searchResults';
import { selectUserId } from '../../selectors/user';
import { useApplicationContext } from '../ApplicationContext';

interface IFskBannerContainerProps {
  open: boolean;
  onClose(): void;
}

export function FskBannerContainer({ open, onClose }: IFskBannerContainerProps) {
  const deviceType = useDeviceType();
  const queryString = useSelector(selectQueryString);
  const context = useApplicationContext();
  const userId = useSelector(selectUserId);
  const isSearchResultsOpen = useSelector(selectIsOpen);
  const fskBanner = useSelector<IApplicationState, IFskBanner>(state => state.fskBanner);
  const Component = React.useMemo(() => (deviceType === 'desktop' ? DesktopBanner : MobileBanner), [deviceType]);

  const toggleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      markAsClosed(FSK_CLOSED_COOKIE_KEY, EBanners.Fsk, EXPIRES_TIME_FOR_FSK);
      onClose();
      trackCloseBanner(queryString, deviceType);
    },
    [deviceType, queryString, onClose],
  );

  const handleBannerClick = React.useCallback(() => {
    trackClickBanner(queryString, deviceType);
  }, [context, deviceType, queryString, userId]);

  React.useEffect(() => {
    trackShowBanner(queryString, deviceType);

    // Не добавляю в зависимости queryString, deviceType, чтобы аналитика отправилась только один раз при показе
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!open) {
    return null;
  }

  const FSKBannerWithPostView = withPostViewAnalytics(
    Component,
    { isSearchResultsOpen, onClose: toggleOpen, onClick: handleBannerClick },
    {
      trackShowOncePerPage: true,
      banner: {
        id: FSK_BANNER.developerId,
        name: 'Special_project',
        place: FSK_BANNER_PLACE[deviceType],
        hitUrl: fskBanner.hitUrl,
        extra: {
          offerType: 'builder',
        },
      },
    },
  );

  return <FSKBannerWithPostView />;
}
