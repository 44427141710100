import { EPriceType, FDealType } from 'shared/common/packages/JsonQuery';

import { beautify, commarize, deunit, getUnit, round } from '../../../utils/number';

interface IPriceType {
  label: string;
  value: EPriceType;
}

const SHRUG = '¯\\_(ツ)_/¯';
export const PRICE_TYPES: IPriceType[] = [
  { label: 'За всё', value: EPriceType.Total },
  { label: 'За м\u00B2', value: EPriceType.SM },
];

export function getPriceLabelDefault(offerType: FDealType): string {
  if (offerType === FDealType.RentDaily) {
    return 'Цена за сутки';
  }

  return 'Цена';
}

export function getPriceLabel(min: number | null, max: number | null): string | null {
  let priceMin: string | null = null;
  const priceMax: string | null = max !== null ? commarize(max) : null;

  if (min !== null && max !== null) {
    if (min === 0 && max === 0) {
      return SHRUG;
    }

    const minUnit = getUnit(min);
    const maxUnit = getUnit(max);
    const minWithUnit = min > max || minUnit !== maxUnit;

    const minDeunited = deunit(min, minUnit);

    if (minDeunited >= 100 && maxUnit - minUnit === 1) {
      priceMin = round(minDeunited / 1000, 1)
        .toString()
        .replace(/\./g, ',');
    } else {
      priceMin = commarize(min, !minWithUnit);
    }
  } else if (min !== null) {
    priceMin = commarize(min);
  }

  if (priceMin && !priceMax) {
    return `от ${priceMin} \u20bd`;
  } else if (!priceMin && priceMax) {
    return `до ${priceMax} \u20bd`;
  } else if (priceMin && priceMax) {
    return `${priceMin} - ${priceMax} \u20bd`;
  }

  return null;
}

export function getPriceTitle(min: number | null, max: number | null): string | null {
  if (min !== null && max === null) {
    return `от ${beautify(min)} \u20bd`;
  } else if (min === null && max !== null) {
    return `до ${beautify(max)} \u20bd`;
  } else if (min !== null && max !== null) {
    return `${beautify(min)} - ${beautify(max)} \u20bd`;
  }

  return null;
}

export function getPriceType(isSquareMeterPrice: boolean | null) {
  return isSquareMeterPrice ? EPriceType.SM : EPriceType.Total;
}

export function getIsSquareMeterPrice(priceType: EPriceType): boolean | null {
  return priceType === EPriceType.SM ? true : null;
}

export function getPriceTypeLabel(value: EPriceType) {
  const priceType = PRICE_TYPES.find(p => p.value === value);

  return priceType ? priceType.label.toLowerCase() : undefined;
}
