/* eslint-disable @typescript-eslint/no-explicit-any */
import { ca } from '@cian/analytics';

interface ITackMyHomeTrap {
  products: any;
  page: any;
}

export function trackMyHomeTrapShow({ products, page }: ITackMyHomeTrap) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'show_sopr',
    label: `add_address`,
    products,
    // Вынужденый костыль для прокидывания дополнительного extra
    page: {
      ...page,
      extra: {
        ...page.extra,
        trap_type: 'MyHomePin',
      },
    },
  });
}

export function trackMyHomeTrapClick({ products, page }: ITackMyHomeTrap) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'click',
    label: `add_address`,
    products,
    // Вынужденый костыль для прокидывания дополнительного extra
    page: {
      ...page,
      extra: {
        ...page.extra,
        trap_type: 'MyHomePin',
      },
    },
  });
}
