import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NotFound } from 'shared/map-search/components/NotFound';
import { IFeaturesMap } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { EResultsStatus } from 'shared/map-search/types/results';

import { useFeaturesContext } from '../context';

const SHOW_TIMEOUT = 1500;

export const NotFoundContainer: React.FC = () => {
  const { rbush } = useFeaturesContext();
  const { bounds, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const resultsStatus = useSelector<IApplicationState, EResultsStatus>(state => state.results.status);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>({}, [precision, 'features'], state.features),
  );
  const [isNothingFound, setIsNothingFound] = React.useState(false);
  const showTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    if (showTimeout.current) {
      clearTimeout(showTimeout.current);
    }

    if (!bounds || resultsStatus === EResultsStatus.Loading) {
      return;
    }

    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    if (featuresInBbox.length === 0) {
      showTimeout.current = setTimeout(() => setIsNothingFound(true), SHOW_TIMEOUT);
    } else {
      setIsNothingFound(false);
    }
  }, [bounds, features, resultsStatus, precision, rbush]);

  React.useEffect(() => {
    setIsNothingFound(false);
  }, [bounds]);

  if (!isNothingFound) {
    return null;
  }

  return <NotFound />;
};
