import { IGetActiveTypesByBboxResponse200 } from 'shared/common/repositories/infrastructure-caching/v1/get-active-types-by-bbox';

import { IInfrastructureType } from '../../types/infrastructure';

export function prepareInfrastructureTypes(response: IGetActiveTypesByBboxResponse200): IInfrastructureType[] {
  const layers: IInfrastructureType[] = [];
  for (const layer of response.response.types) {
    if (layer.drawType === 'pin') {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { drawType, ...rest } = layer;
      layers.push(rest);
    }
  }

  return layers;
}
