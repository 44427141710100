import RBush from 'rbush';

import { createTileClass } from './InfrastructurePointsTile';
import { IInfrastructureRBushItem, TInfrastructureFeature } from '../../../types/infrastructure';
import { getInfrastructureFeaturesExtendedTileBBox } from '../../tile/getInfrastructureFeaturesExtendedTileBBox';

interface IPointsLayerOptions {
  map: YMaps.Map;
  rbush: RBush<IInfrastructureRBushItem>;
}

export function defineInfrastructurePointsLayer(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('InfrastructurePointsLayer')) {
    return;
  }

  ymaps.modules.define<[YMaps.util.IHd, YMaps.util.IPixelBounds, YMaps.option.Manager, typeof YMaps.Layer]>(
    'InfrastructurePointsLayer',
    ['util.hd', 'util.pixelBounds', 'option.Manager', 'Layer', 'layer.tileContainer.DomContainer'],
    (provide, utilHd, _utilPixelBounds, _optionManager, Layer) => {
      class InfrastructurePointsLayer {
        public readonly layer: YMaps.Layer;

        private map: YMaps.Map;
        private mapProjection: YMaps.projection.wgs84Mercator;
        private rbush: RBush<IInfrastructureRBushItem>;

        public constructor(options: IPointsLayerOptions) {
          this.map = options.map;
          this.mapProjection = this.map.options.get('projection');
          this.rbush = options.rbush;

          this.layer = new Layer(() => null, {
            tileTransparent: true,
            tileClass: createTileClass({
              getFeaturesForTile: (tileNumber, tileZoom) => this.getFeaturesForTile(tileNumber, tileZoom),
              mapProjection: this.mapProjection,
              dpr: utilHd.getPixelRatio(),
            }),
            tileContainerClass: 'default#dom',
          });
        }

        private getFeaturesForTile(tileNumber: YMaps.TTileNumber, zoom: number): TInfrastructureFeature[] {
          const extendedTileBox = getInfrastructureFeaturesExtendedTileBBox({
            tileNumber,
            zoom,
          });
          const rBushItems = this.rbush.search(extendedTileBox);

          return rBushItems.map(({ feature }) => feature);
        }
      }

      provide(InfrastructurePointsLayer);
    },
  );
}
