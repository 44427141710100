import { compose } from 'ramda';
import { connect } from 'react-redux';

import { IJsonQueryPolygon, TCoordinate } from 'shared/common/packages/api-models/common/json_query';
import { addGeo } from 'shared/map-search/actions/jsonQuery';
import { preparePolygons } from 'shared/map-search/mappers/polygons';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';

import { IPolygonsProps, Polygons as PolygonsComponent } from './Polygons';
import { IMapContext, withMapContext } from '../../context';

type TStateProps = Pick<IPolygonsProps, 'polygons' | 'isDrawingMode'>;

type TContextProps = Pick<IPolygonsProps, 'ymaps' | 'map'>;

interface IDispatchProps {
  addPolygon(polygon: IJsonQueryPolygon): void;
}

const mapStateToProps = (state: IApplicationState): TStateProps => ({
  isDrawingMode: state.drawing.isDrawingMode,
  polygons: preparePolygons(state.filters.jsonQuery).map(polygon => ({
    ...polygon,
    coordinates: polygon.coordinates.map(([a, b]) => [b, a]),
  })),
});

const mapDispatchToProps = (dispatch: TThunkDispatch): IDispatchProps => ({
  addPolygon: polygon => {
    const coordinates: TCoordinate[] = polygon.coordinates.map(([a, b]) => [
      parseFloat(Number(b).toFixed(7)).toString(),
      parseFloat(Number(a).toFixed(7)).toString(),
    ]);

    coordinates.push(coordinates[0]);

    dispatch(
      addGeo({
        ...polygon,
        coordinates,
      }),
    );
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapContextToProps({ map, ymaps }: IMapContext): TContextProps | any {
  return {
    map,
    ymaps,
  };
}

export const Polygons = compose(
  withMapContext(mapContextToProps),
  connect<TStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps),
)(PolygonsComponent);
