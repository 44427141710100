import { IGetClustersForMapResponse } from '../../../common/repositories/search-offers-map/v1/get-clusters-for-map';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EResultsActionType {
  Loading = 'results/fetchClusters/loading',
  Succeed = 'results/fetchClusters/succeed',
  Failed = 'results/fetchClusters/failed',
  SetSubdomain = 'results/setSubdomain',
}

export interface IFetchClustersSucceedParameters extends IGetClustersForMapResponse {
  clean: boolean;
  tiles: YMaps.TTileNumber[];
  updateBounds: boolean;
}

export type TFetchClustersLoading = ITypedReduxAction<EResultsActionType.Loading>;
export type TFetchClustersSucceed = ITypedReduxAction<EResultsActionType.Succeed, IFetchClustersSucceedParameters>;
export type TFetchClustersFailed = ITypedReduxAction<EResultsActionType.Failed>;
export type TSetSubdomain = ITypedReduxAction<EResultsActionType.SetSubdomain, string>;

export type TResultsActions = TFetchClustersLoading | TFetchClustersSucceed | TFetchClustersFailed | TSetSubdomain;
