import { IMicrofrontendsAPI } from 'shared/common/types/microfrontends/types';

import { setRegionId } from '../../../common/repositories/header-frontend/v1/set-region-id/helpers';

export function createMicrofrontendsAPI(): IMicrofrontendsAPI {
  return {
    header: {
      setRegionId,
    },
  };
}
