import * as React from 'react';

import { NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { TagsInput } from '../../TagsInput';
import { Filter } from '../common/Filter';

interface IExcludeWordsProps {
  value: string[] | null;
  onChange(value: string[] | null): void;
}

export const ExcludeWords: React.FC<IExcludeWordsProps> = ({ value: values, onChange }) => {
  const handleAdd = React.useCallback(
    (value: string) => {
      onChange(values ? [...values, value] : [value]);
    },
    [values, onChange],
  );

  const handleRemove = React.useCallback(
    (value: string) => {
      let nextValue = values ? values.filter(v => v !== value) : null;
      if (nextValue && nextValue.length === 0) {
        nextValue = null;
      }

      onChange(nextValue);
    },
    [values, onChange],
  );

  return (
    <Filter id="advancedFilter_excludeWords" label="Исключить слова в объявлении">
      <TagsInput
        id="advancedFilter_excludeWords_tagsInput"
        placeholder="Например: переуступка"
        value={values && values.length > 0 ? (values as NonEmptyArray<string>) : null}
        onAdd={handleAdd}
        onRemove={handleRemove}
      />
    </Filter>
  );
};
