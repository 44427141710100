// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IDeleteOfferFromFavoritesRequestSchema {
  /** Тип сделки **/
  dealType: EDealType;
  /** realty Id объекта(объявка/ЖК) **/
  entityId: number;
  /** тип объекта(объявка/ЖК) **/
  entityType: EEntityType;
  /** Флаг принудительного удаления из папок **/
  forceRemoveFromFolders?: boolean;
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EEntityType {
  /** ЖК **/
  'Newbuilding' = 'newbuilding',
  /** Коммерческая **/
  'OfferCommercial' = 'offerCommercial',
  /** Жилая **/
  'OfferResidental' = 'offerResidental',
  /** Загородка **/
  'OfferSuburban' = 'offerSuburban',
}
