import { useMemo } from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';
import { getPromoSearchFilterValuesByType } from 'shared/common/packages/Filters/shared/utils/getPromoSearchFilterValuesByType';
import { getAllowedPromoSearchTypes } from 'shared/common/packages/JsonQuery';

export const useDiscountPromoSearchTypeOptions = (promoSearchTypes: TPromoSearchTypes, apartments: boolean | null) => {
  return useMemo(() => {
    if (!promoSearchTypes) {
      return [];
    }

    const promoSearchTypeValues = getPromoSearchFilterValuesByType('discount', promoSearchTypes);
    const allowedPromoSearchTypes = getAllowedPromoSearchTypes(apartments, promoSearchTypeValues);

    return [
      { label: 'Неважно', value: null },
      ...allowedPromoSearchTypes.map(value => {
        const label = promoSearchTypes.discount.find(type => type.value === value)?.name ?? '';

        return {
          value,
          label,
        };
      }),
    ];
  }, [promoSearchTypes, apartments]);
};
