import { IBBox } from '../../types/map/bbox';
import { IMapBBoxBounds } from '../../types/mapBounds';

export function mapBBoxBoundsToBBox({ bounds }: IMapBBoxBounds): IBBox {
  const [[latMin, lngMin], [latMax, lngMax]] = bounds;

  return {
    bottomRight: { lat: latMin, lng: lngMax },
    topLeft: { lat: latMax, lng: lngMin },
  };
}
