import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './Footer.css';

interface IFooterProps {
  onClearClick(): void;
  onShowClick(): void;
}

export const Footer: React.FC<IFooterProps> = ({ onClearClick, onShowClick }) => {
  return (
    <div className={styles['container']}>
      <Button theme="fill_white_primary" onClick={onClearClick}>
        Сбросить фильтры
      </Button>
      <Button theme="fill_primary" onClick={onShowClick}>
        Показать объекты
      </Button>
    </div>
  );
};
