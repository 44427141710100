import { plural } from '@cian/utils';
import * as React from 'react';

import { EMetroTravelType } from 'shared/common/packages/api-models/common/json_query';

import { MaskedInput } from '../../MaskedInput';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IUndergroundTimeOption {
  value: EMetroTravelType | null;
  label: string;
}

const UNDERGROUND_TRAVEL_OPTIONS: IUndergroundTimeOption[] = [
  {
    value: null,
    label: 'Неважно',
  },
  {
    value: EMetroTravelType.MapFoot,
    label: 'Пешком',
  },
  {
    value: EMetroTravelType.MapTransport,
    label: 'Транспортом',
  },
];

interface IUndergroundTimeProps {
  travelType: EMetroTravelType | null;
  travelTime: number | null;
  onTravelTypeChange(type: EMetroTravelType | null): void;
  onTravelTimeChange(time: number | null): void;
}

export const UndergroundTime: React.FC<IUndergroundTimeProps> = ({
  travelType,
  travelTime,
  onTravelTypeChange,
  onTravelTimeChange,
}) => {
  const setDefaultTimeRef = React.useRef<number | null>(null);
  const resetTimeRef = React.useRef<boolean>(false);

  const handleTravelTimeChange = React.useCallback(
    (time: string) => {
      const nextTime = time ? Number(time) : null;
      onTravelTimeChange(nextTime);
    },
    [onTravelTimeChange],
  );

  const handleTravelTypeChange = React.useCallback(
    (type: EMetroTravelType | null) => {
      onTravelTypeChange(type);

      if (type !== null && travelTime === null) {
        setDefaultTimeRef.current = getDefaultTravelTime(type);
      } else if (type === null && travelTime !== null) {
        // TODO выпилить, когда будет готов магический unset jsonQuery
        resetTimeRef.current = true;
      }
    },
    [travelTime, onTravelTypeChange],
  );

  React.useEffect(() => {
    if (travelType !== null && setDefaultTimeRef.current !== null) {
      onTravelTimeChange(setDefaultTimeRef.current);
      setDefaultTimeRef.current = null;
    } else if (travelType === null && resetTimeRef.current) {
      onTravelTimeChange(null);
      resetTimeRef.current = false;
    }
  }, [onTravelTimeChange, travelType]);

  return (
    <Filter id="advancedFilter_undergroundTime" label="До метро">
      <InlineFilterGroup>
        <InlineFilter>
          <RadioButtonGroup
            options={UNDERGROUND_TRAVEL_OPTIONS}
            value={travelType}
            onChange={handleTravelTypeChange}
            spliced={false}
          />
        </InlineFilter>
        {travelType !== null && (
          <InlineFilter
            prefix="Не более"
            postfix={(travelTime && plural(travelTime, ['минуты', 'минут', 'минут'])) || 'минут'}
          >
            <MaskedInput
              inline
              width={50}
              placeholder="10"
              min={1}
              max={300}
              mask={Number}
              value={travelTime === null ? '' : String(travelTime)}
              onComplete={handleTravelTimeChange}
            />
          </InlineFilter>
        )}
      </InlineFilterGroup>
    </Filter>
  );
};

function getDefaultTravelTime(type: EMetroTravelType): number {
  return type === EMetroTravelType.MapFoot ? 45 : 30;
}
