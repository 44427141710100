import { OnboardingTooltip as UiKitOnboardingTooltip } from '@cian/ui-kit/tooltip';
import * as React from 'react';

import * as styles from './OnboardingTooltip.css';

interface IOnboardingTooltipProps {
  children: React.ReactElement;
  tooltip: React.ReactNode;
  open?: boolean;
  onClose(): void;
}

export const OnboardingTooltip: React.FC<IOnboardingTooltipProps> = ({ children, tooltip, open, onClose }) => {
  return (
    <UiKitOnboardingTooltip
      open={open || false}
      onClose={onClose}
      placement="left-start"
      content={<div className={styles['container']}>{tooltip}</div>}
      theme="blue"
    >
      {children}
    </UiKitOnboardingTooltip>
  );
};
