import { fetchNewbuildingClusters } from './fetchNewbuildingClusters';
import { setActiveNewbuildingPolygon } from './setActiveNewbuildingPolygon';
import { selectIsUserAgent } from '../../selectors/user';
import { INewbuilding } from '../../types/map/newbuilding';
import { IApplicationState, TThunkAction } from '../../types/redux';
import { getNewbuildingQuery } from '../../utils/queryString';
import { removeNewbuildingsFromUrl } from '../../utils/removeNewbuildingsFromUrl';
import { changeSearchResultsUrl, openSearchResultsSidebar } from '../searchResults';

export function openNewbuildingPolygon(newbuilding: INewbuilding): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const baseUrl = context.config.get<string>('mobileSearchFrontend.browserUrl');
    const {
      activeNewbuildingPolygon: { geohashIds },
    } = getState();

    dispatch(changeSearchResultsUrl(''));

    dispatch(setActiveNewbuildingPolygon({ prevActiveGeoHashIds: geohashIds }));
    dispatch(openSearchResultsSidebar());
    await dispatch(fetchNewbuildingClusters(newbuilding.id, !!newbuilding.isAnyFicheringPlus));

    const qs = getNewbuildingPolygonFeaturesQueryString(newbuilding, getState());
    const url = `${baseUrl}v1/get-results-for-map?${qs}&parentOrigin=${window.location.origin}`;
    const urlWithoutNewbuildings = removeNewbuildingsFromUrl(url);

    dispatch(changeSearchResultsUrl(urlWithoutNewbuildings));
  };
}

export function getNewbuildingPolygonFeaturesQueryString(newbuilding: INewbuilding, state: IApplicationState) {
  const {
    queryString,
    extendedQueryString,
    activeNewbuildingPolygon: { houseIds },
  } = state;

  const qs = extendedQueryString ? extendedQueryString : queryString;
  const query = getNewbuildingQuery({
    qs,
    newbuilding,
    houseIds,
    selectAllHouseIds: true,
    preselectedFromDeveloper: true,
  });
  const userIsAgent = selectIsUserAgent(state);

  const isLeadsBannerVisibleParam = !userIsAgent ? '&isLeadsBannerVisible=1' : '';

  return `${query}&isNavigationDisabled=1${isLeadsBannerVisibleParam}`;
}
