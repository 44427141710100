/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'clsx';
import * as React from 'react';

import { DeviceType } from 'shared/common/packages/Responsive';

import * as styles from './Select.css';
import { NativeSelect } from './nativeSelect';
import { Option } from './option';
import { SelectButton } from './selectButton';
import { SelectButtonLabel } from './selectButtonLabel';
import { SelectContainer, ISelectActions } from './selectContainer';
import { SelectPopup } from './selectPopup';
import { ISelectProps, IOptionProps, ISelectOption } from './types';
import { getDropdownDirection, EDropdownDirection } from './utils/getDropdownDirection';

interface ISelectState {
  options: ISelectOption[];
  children?: React.ReactNode;
  opened: boolean;
  upward: boolean;
}

const getOptionsFromChildren = (children: React.ReactNode): ISelectOption[] => {
  return React.Children.toArray(children).reduce(
    (options, child: React.ReactElement<IOptionProps>): ISelectOption[] => {
      if (child.type === Option) {
        return [
          ...options,
          {
            value: child.props.value,
            label: React.Children.toArray(child.props.children).join(''),
            marker: child.props.marker,
          },
        ];
      }

      return options as ISelectOption[];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [] as any,
  );
};

export class Select extends React.Component<ISelectProps, ISelectState> {
  private ref = React.createRef<HTMLDivElement>();
  private nativeSelectRef = React.createRef<NativeSelect>();
  private selectPopupRef = React.createRef<SelectPopup>();
  private selectPopupContainerRef = React.createRef<HTMLDivElement>();

  public static defaultProps = {
    'data-name': 'Select',
  };

  public constructor(props: ISelectProps) {
    super(props);

    this.state = {
      options: getOptionsFromChildren(props.children),
      children: props.children,
      opened: false,
      upward: false,
    };
  }

  public static getDerivedStateFromProps = ({ children }: ISelectProps, state: ISelectState) => {
    if (children === state.children) {
      return null;
    }

    return {
      options: getOptionsFromChildren(children),
      children,
    };
  };

  public componentDidUpdate(_prevProps: ISelectProps, prevState: ISelectState) {
    if (this.state.opened && !prevState.opened) {
      this.setPopupDirection();
    }
  }

  public render() {
    const { value, disabled, multiple, custom = {}, styles: selectStyles, buttonAppearance } = this.props;
    const { options } = this.state;
    const { invalid, size } = custom;

    return (
      <DeviceType>
        {deviceType => {
          const isMobile = deviceType !== 'desktop';

          return (
            <SelectContainer
              {...this.props}
              forwardedRef={this.ref}
              className={styles['select']}
              opened={this.state.opened}
              actions={this.actions}
              style={selectStyles}
            >
              <SelectButton
                onClick={isMobile ? this.handleMobileButtonClick : this.handleButtonClick}
                disabled={disabled}
                active={this.state.opened}
                tabIndex={-1}
                invalid={invalid}
                size={size}
                style={selectStyles}
                withoutArrow={this.props.withoutArrow}
                appearance={buttonAppearance}
              >
                {this.props.label || (
                  <SelectButtonLabel value={value} options={options} placeholder={this.props.placeholder} />
                )}
              </SelectButton>

              <SelectPopup
                ref={this.selectPopupRef}
                forwardedRef={this.selectPopupContainerRef}
                className={classNames(styles['select-popup'], this.state.upward && styles['upward'])}
                opened={this.state.opened}
                multiple={multiple}
                value={value}
                options={options}
                setOptionState={this.handleSetOptionState}
                toggleOptionState={this.handleToggleOptionState}
                postfix={this.props.popupPostfix}
              />

              <NativeSelect
                ref={this.nativeSelectRef}
                multiple={multiple}
                disabled={disabled}
                value={value}
                onChange={this.props.onChange}
                options={options}
                className={classNames(styles['native-select'], isMobile && styles['mobile'])}
              />
            </SelectContainer>
          );
        }}
      </DeviceType>
    );
  }

  private openDrowdown = () => {
    this.setState({
      opened: true,
    });
  };

  private closeDropdown = () => {
    this.setState({
      opened: false,
    });
  };

  private handleButtonClick = () => {
    if (this.state.opened) {
      this.closeDropdown();
    } else {
      this.openDrowdown();
    }
  };

  private handleMobileButtonClick = () => {
    this.nativeSelectRef.current!.click();
  };

  private handleSetOptionState = (index: number, selected: boolean) => {
    this.nativeSelectRef.current!.setOptionState(index, selected);
    if (!this.props.multiple) {
      this.closeDropdown();
    }
  };

  private handleToggleOptionState = (index: number) => {
    this.nativeSelectRef.current!.toggleOptionState(index);
  };

  private setPopupDirection = () => {
    const direction = getDropdownDirection(this.ref.current!, this.selectPopupContainerRef.current!);
    const upward = direction === EDropdownDirection.Up;

    if (!upward !== !this.state.upward) {
      this.setState({
        upward,
      });
    }
  };

  private actions: ISelectActions = {
    openDropdown: this.openDrowdown,
    closeDropdown: this.closeDropdown,
    moveFocus: (delta: -1 | 1) => this.selectPopupRef.current!.moveFocusIndex(delta),
    setFocusedOptionState: (selected: boolean) => this.selectPopupRef.current!.setFocusedOptionState(selected),
    toggleFocusedOptionState: () => this.selectPopupRef.current!.toggleFocusedOptionState(),
  };
}
