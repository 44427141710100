import { ca } from '@cian/analytics';

type TAreaSelectLabel = 'start' | 'success' | 'close';

export function trackAreaSelect(label: TAreaSelectLabel) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'select_area',
    category: 'Mob_map',
    label,
  });
}
