import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchInfrastructureFeatures, fetchInfrastructureTypesByBbox } from '../../../actions/infrastructure';
import { selectActiveType, selectMinZoom } from '../../../selectors/infrastructure';
import { selectMapBounds } from '../../../selectors/mapBounds';
import { TThunkDispatch } from '../../../types/redux';

export const InfrastructureFeaturesFetcher: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const mapBounds = useSelector(selectMapBounds);
  const activeType = useSelector(selectActiveType);
  const minZoom = useSelector(selectMinZoom);

  React.useEffect(() => {
    if (mapBounds.zoom > minZoom) {
      dispatch(fetchInfrastructureFeatures());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds, activeType?.id]);

  React.useEffect(() => {
    dispatch(fetchInfrastructureTypesByBbox());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds]);

  return null;
};
