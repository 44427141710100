import { colors } from '@cian/ui-kit/colors';

import * as styles from './InfrastructureLegend.css';

interface IInfrastructureLegendProps {
  text: string;
  color?: string | null;
}

export function InfrastructureLegend({ color, text }: IInfrastructureLegendProps) {
  return (
    <>
      <div className={styles['legend']} style={{ background: `${color || colors.black_100}` }} />
      <div className={styles['text']}>{text}</div>
    </>
  );
}
