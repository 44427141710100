import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IFromDeveloperOption {
  label: string;
  value: boolean | null;
}

const FROM_DEVELOPER_OPTIONS: IFromDeveloperOption[] = [
  { label: 'Неважно', value: null },
  { label: 'От застройщиков', value: true },
  { label: 'От агентов и частных лиц', value: false },
];

interface IOfferTypeProps {
  specialPromoLabel: string | null;
  specialPromoActive: boolean | null;
  fromDeveloper: boolean | null;
  onSpecialPromoChange(value: boolean | null): void;
  onFromDeveloperChange(value: boolean | null): void;
}

export const OfferType: React.FC<IOfferTypeProps> = ({
  specialPromoLabel,
  specialPromoActive,
  fromDeveloper,
  onSpecialPromoChange: onPikPromoChange,
  onFromDeveloperChange,
}) => {
  return (
    <Filter id="advancedFilter_offerType" label="Тип объявления">
      <InlineFilterGroup>
        <InlineFilter>
          <RadioButtonGroup
            options={FROM_DEVELOPER_OPTIONS}
            value={fromDeveloper}
            onChange={onFromDeveloperChange}
            spliced={false}
          />
        </InlineFilter>
        {specialPromoLabel && (
          <InlineFilter>
            <Checkbox
              label={specialPromoLabel}
              checked={!!specialPromoActive}
              onChange={event => onPikPromoChange(event.target.checked ? true : null)}
            />
          </InlineFilter>
        )}
      </InlineFilterGroup>
    </Filter>
  );
};
