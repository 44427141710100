import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { BANNER_ID } from '../../../constants/banners';

/**
 * Определяет способ рендера баннера в зав-ти от платформы
 * Рендер на десктопе - в основное DOM дерево
 * Рендер в мобайле - через портал в специальное место для баннеров
 * */
export const useRenderProp = () => {
  const deviceType = useDeviceType();
  const [bannerContainer, setContainer] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const container = document.getElementById(BANNER_ID);

    setContainer(container);
  }, []);

  return React.useCallback(
    (el: React.ReactElement) => {
      if (deviceType === 'desktop') {
        return el;
      }

      if (!bannerContainer) {
        return null;
      }

      return ReactDOM.createPortal(el, bannerContainer);
    },
    [bannerContainer, deviceType],
  );
};
