import * as React from 'react';

import { useSaveSearchContext } from 'shared/common/packages/SaveSearchModal';

import { SaveSearchButton } from './SaveSearchButton';

export const SaveSearchButtonContainer: React.FC = () => {
  const { onOpen } = useSaveSearchContext();

  return <SaveSearchButton onClick={onOpen} />;
};

SaveSearchButtonContainer.displayName = 'SaveSearchButtonContainer';
