import { IPageViewData, TEventQueueItem } from 'shared/map-search/types/analytics';

import { IDependencies } from './types';

export function getPageViewData({ logger }: IDependencies, queue: TEventQueueItem[]): IPageViewData | null {
  const pageViewEvent = getPageViewEvent(queue);

  if (!pageViewEvent) {
    logger.error('При инициализации аналитики не найдено событие pageview', {
      domain: 'browser.utils.ensurePageCa.getPageViewData()',
    });

    return null;
  }

  const pageviewData = pageViewEvent[1] as IPageViewData;
  if (!pageviewData || !pageviewData.page) {
    logger.error('При инициализации аналитики не были получены корректные данные для события pageview', {
      domain: 'browser.utils.ensurePageCa.getPageViewData()',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pageviewData: pageviewData as any,
    });

    return null;
  }

  return pageviewData;
}

function getPageViewEvent(queue: TEventQueueItem[]) {
  return queue.find(([eventType]) => ['pageviewSite', 'pageview'].includes(eventType));
}
