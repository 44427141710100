export enum EGetUserStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

interface INonAuthenticatedUser {
  isAuthenticated: false;
}

interface IAuthenticatedUser {
  isAuthenticated: true;
  userId: number;
  email: string | null;
  isAgent: boolean;
  userType: string | null;
}

export type TUser = INonAuthenticatedUser | IAuthenticatedUser;

interface IUserState {
  status: EGetUserStatus;
}

export type TUserState = IUserState & TUser;

export interface ITrackingUserAuthorized {
  userId: number | null;
  extra: {
    defaultRegionId: number;
  };
}

export type TTrackingUser = ITrackingUserAuthorized | {};
