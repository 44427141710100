import { ca } from '@cian/analytics';

import { trackShowNumberClick } from './analytics';
import { normalizePage } from './utils';
import { TAnalyticsMessagePayload } from '../../types';

type TEventType = 'event' | 'eventEbc' | 'eventEnrich';

export function eventHandler(eventType: TEventType, eventParams: TAnalyticsMessagePayload[1], pageRaw: unknown) {
  const { action, category, label, sc, products } = eventParams;

  const page = normalizePage(pageRaw);

  if (category === 'Phones' && action === 'Open_listing') {
    trackShowNumberClick({ label, sc, products, page });
  } else {
    ca(eventType, eventParams);
  }
}
