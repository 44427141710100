import { ErrorLogComponent } from '@cian/error-log-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { UiKitContextProvider } from '@cian/ui-kit';
import { TDevice } from '@cian/utils';
import { Provider } from 'react-redux';

import { IApplicationContext } from '../../types/applicationContext';
import { TReduxStore } from '../../types/redux';
import { favoritesContext } from '../../utils/favorites';
import { ApplicationContext } from '../ApplicationContext';
import { PageContainer } from '../Page';
import { SaveSearchModalProvider } from '../SaveSearchModalProvider';
import { SubscriptionsContainer } from '../Subscriptions';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: TReduxStore;
  initialDeviceType?: TDevice;
}

export function Application(props: IApplicationProps) {
  const {
    context,
    reduxStore,
    initialDeviceType = context.config.get<TDevice>('initialDeviceType') as TDevice,
  } = props;

  return (
    <ApplicationContext.Provider value={context}>
      <ErrorLogComponent logger={context.logger}>
        <Provider store={reduxStore}>
          <MicrofrontendProvider registry={context.microfrontendsRegistry}>
            <favoritesContext.Provider value={reduxStore}>
              <UiKitContextProvider deviceType={initialDeviceType}>
                <SaveSearchModalProvider>
                  <PageContainer />
                  <SubscriptionsContainer reduxStore={reduxStore} />
                </SaveSearchModalProvider>
              </UiKitContextProvider>
            </favoritesContext.Provider>
          </MicrofrontendProvider>
        </Provider>
      </ErrorLogComponent>
    </ApplicationContext.Provider>
  );
}
