import * as React from 'react';

export const Drawing: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0762 9.19083L12.0762 5.23235C12.0762 4.40213 11.5089 3.63263 10.6895 3.49937C9.64527 3.32955 8.7429 4.13093 8.7429 5.14323V8.47657V15.9766L5.4563 14.0046C4.91888 13.6822 4.24376 13.6982 3.72227 14.0459C2.87072 14.6136 2.75141 15.8184 3.4751 16.5421L8.7429 21.8099H18.7429L20.051 13.3071C20.2523 11.9985 19.3985 10.7599 18.1039 10.4825L12.0762 9.19083Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M20.8261 5.97636C18.7426 3.47636 15.4094 3.47636 15.4094 3.47636"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

Drawing.displayName = 'Drawing';
