import * as React from 'react';

import { NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { ERoomType } from 'shared/common/packages/api-models/common/json_query';

import { getRoomTypeLabel } from './helpers';
import { Select, Option } from '../../Select';

interface IRoomType {
  label: string;
  value: ERoomType;
}

const ROOM_TYPES: IRoomType[] = [
  { label: '1-комнатная', value: ERoomType.FlatOneRoom },
  { label: '2-комнатная', value: ERoomType.FlatTwoRooms },
  { label: '3-комнатная', value: ERoomType.FlatThreeRooms },
  { label: '4-комнатная', value: ERoomType.FlatFourRooms },
  { label: '5-комнатная', value: ERoomType.FlatFiveRooms },
  { label: '6-комнатная', value: ERoomType.FlatManyRooms },
  { label: 'Студия', value: ERoomType.Studio },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

interface IRoomTypeProps {
  value: NonEmptyArray<ERoomType> | null;
  onChange(value: NonEmptyArray<ERoomType>): void;
}

export const RoomType: React.FC<IRoomTypeProps> = ({ value, onChange }) => {
  const roomTypeLabel = value ? getRoomTypeLabel(value) : null;

  return (
    <Select
      data-name="FilterRoomType"
      multiple
      withoutArrow
      label={roomTypeLabel || undefined}
      placeholder="Комнатность"
      value={value}
      onChange={(_, value: NonEmptyArray<ERoomType>) => onChange(value)}
    >
      {ROOM_TYPES.map(roomType => (
        <Option key={`${roomType.label}_${roomType.value}`} value={roomType.value}>
          {roomType.label}
        </Option>
      ))}
    </Select>
  );
};
