import { UIText2 } from '@cian/ui-kit';

import { metroIconMap, transportIconMap } from './helpers';
import { SubwayInfo } from '../../../components/SubwayInfo';
import { IUndergroundInfo } from '../../../types/promoInventory';

interface ISubwayInfoProps {
  subwayInfo?: IUndergroundInfo | null;
}

export function SubwayInfoContainer({ subwayInfo }: ISubwayInfoProps) {
  if (!subwayInfo) {
    return null;
  }

  const TransportIcon = transportIconMap[subwayInfo.transportType];
  const MetroIcon = metroIconMap[subwayInfo.undergroundType];

  return (
    <SubwayInfo
      subwayIcon={<MetroIcon />}
      subwayColor={subwayInfo.lineColor}
      title={<UIText2>{subwayInfo.name}</UIText2>}
      typeIcon={<TransportIcon />}
      time={<UIText2 color="gray60_100">{subwayInfo.timeDisplay}</UIText2>}
    />
  );
}
