import { useDeviceType } from '@cian/ui-kit/responsive';
import { useSelector } from 'react-redux';

import { checkDailyRentAvailabilityRSEnabled } from './checkDailyRentAvailabilityRSEnabled';
import { useApplicationContext } from '../../containers/ApplicationContext';
import { selectJsonQuery } from '../../selectors/results';

// Подробности в README
// @see src/shared/map-search/utils/dailyRentAvailability/README.md
export const useDailyRentAvailabilityFeatureEnabled = () => {
  const { config } = useApplicationContext();
  const jsonQuery = useSelector(selectJsonQuery);
  const deviceType = useDeviceType();

  return checkDailyRentAvailabilityRSEnabled({ config, jsonQuery, deviceType });
};
