import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EDrawingActionType {
  DrawingStarted = 'DRAWING/STARTED',
  DrawingFinished = 'DRAWING/FINISHED',
}

export type TDrawingStartedAction = ITypedReduxAction<EDrawingActionType.DrawingStarted>;
export type TDrawingFinishedAction = ITypedReduxAction<EDrawingActionType.DrawingFinished>;

export type TDrawingActions = TDrawingStartedAction | TDrawingFinishedAction;
