import * as React from 'react';

import { getRangeValue } from 'shared/common/packages/JsonQuery';

import { SuburbanArea } from '../../../components/advancedFilters/SuburbanArea';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SuburbanAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const houseArea = getRangeValue('total_area')(jsonQuery);
  const landArea = getRangeValue('site')(jsonQuery);

  const handleHouseAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleHouseAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleLandAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setLandAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleLandAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setLandAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <SuburbanArea
      offerType={offerType}
      houseArea={houseArea}
      landArea={landArea}
      onHouseAreaMinChange={handleHouseAreaMinChange}
      onHouseAreaMaxChange={handleHouseAreaMaxChange}
      onLandAreaMinChange={handleLandAreaMinChange}
      onLandAreaMaxChange={handleLandAreaMaxChange}
    />
  );
};
