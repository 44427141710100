import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface IFromMKADProps {
  distance: IJsonQueryRangeValue | null;
  onFromMKADMinChange(value: number | null): void;
  onFromMKADMaxChange(value: number | null): void;
}

export const FromMKAD: React.FC<IFromMKADProps> = ({ distance, onFromMKADMinChange, onFromMKADMaxChange }) => {
  const { gte: distanceMin = null, lte: distanceMax = null } = distance || {};

  const handleFromMKADMinChange = useDebouncedCallback((gte: number | null) => {
    onFromMKADMinChange(gte);
  }, 300);

  const handleFromMKADMaxChange = useDebouncedCallback((lte: number | null) => {
    onFromMKADMaxChange(lte);
  }, 300);

  return (
    <Filter id="advancedFilter_fromMKAD" label="От МКАД">
      <InlineFilterGroup>
        <InlineFilter postfix="км">
          <RangeInput
            min={0}
            max={999}
            valueMin={distanceMin}
            valueMax={distanceMax}
            onChangeMin={handleFromMKADMinChange}
            onChangeMax={handleFromMKADMaxChange}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
