import * as React from 'react';

import { FDealType } from 'shared/common/packages/JsonQuery';

import { getDailyrentUrl } from './utils';
import { DealType } from '../../../components/filters/DealType';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const DealTypeContainer: React.FC = () => {
  const { onChange, onApply } = useContext();
  const dealType = useDealType();

  const handleChange = React.useCallback(
    (dealType: FDealType) => {
      if (dealType === FDealType.RentDaily) {
        window.location.href = getDailyrentUrl(window.location.href);

        return;
      }
      onChange({ action: 'setDealType', arguments: [dealType] });
      onApply('dealType');
    },
    [onChange, onApply],
  );

  return <DealType value={dealType} onChange={handleChange} />;
};
