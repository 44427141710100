import { pathOr } from 'ramda';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import {
  AVAILABLE_AREA_TYPES,
  DEFAULT_AREA_TYPES,
  MAX_SQUARE_TO_ACTION_MAP,
  MIN_SQUARE_TO_ACTION_MAP,
} from './constants';
import {
  ISquareMeterAreaValue,
  SquareMeterArea,
  TSquareMeterAreaType,
} from '../../../components/advancedFilters/SquareMeterArea';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SquareMeterAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();

  const values = React.useMemo<ISquareMeterAreaValue[]>(() => {
    const totalArea = pathOr<IJsonQueryRangeValue>(
      { gte: undefined, lte: undefined },
      ['total_area', 'value'],
      jsonQuery,
    );
    const roomArea = pathOr<IJsonQueryRangeValue>(
      { gte: undefined, lte: undefined },
      ['room_area', 'value'],
      jsonQuery,
    );
    const kitchenArea = pathOr<IJsonQueryRangeValue>(
      { gte: undefined, lte: undefined },
      ['kitchen', 'value'],
      jsonQuery,
    );
    const livingArea = pathOr<IJsonQueryRangeValue>(
      { gte: undefined, lte: undefined },
      ['living_area', 'value'],
      jsonQuery,
    );

    const areaTypeToValue: { [key in TSquareMeterAreaType]: IJsonQueryRangeValue } = {
      general: totalArea,
      room: roomArea,
      kitchen: kitchenArea,
      living: livingArea,
    };
    const availableTypes = AVAILABLE_AREA_TYPES[offerType] || DEFAULT_AREA_TYPES;
    const keys = Object.keys(areaTypeToValue) as TSquareMeterAreaType[];

    return keys.filter(type => availableTypes.includes(type)).map(type => ({ type, value: areaTypeToValue[type] }));
  }, [offerType, jsonQuery]);

  const handleMinChange = React.useCallback(
    (type: TSquareMeterAreaType, gte: number | null) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange({ action: MIN_SQUARE_TO_ACTION_MAP[type] as any, arguments: [gte] });
    },
    [onChange],
  );

  const handleMaxChange = React.useCallback(
    (type: TSquareMeterAreaType, lte: number | null) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange({ action: MAX_SQUARE_TO_ACTION_MAP[type] as any, arguments: [lte] });
    },
    [onChange],
  );

  return <SquareMeterArea values={values} onMinChange={handleMinChange} onMaxChange={handleMaxChange} />;
};
