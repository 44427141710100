import { CancellationToken } from '@cian/http-api/shared';

import { IGetNewbuildingPromoPinsForMapResponse } from 'shared/common/repositories/search-offers-index/v1/get-newbuilding-promo-pins-for-map';
import { IApplicationContext } from 'shared/map-search/types/applicationContext';

import { assertUnknownError } from './assert';
import { fetchGetNewbuildingPromoPinsForMap } from './internal/fetchGetNewbuildingPromoPinsForMap';

interface IParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any | null;
}

const CANCEL_PREVIOUS_REQUEST = true;

/*
 * Сервис для получения пинов ЖК для промоутинга на карте,
 * Пины запрашиваются при зуме, когда у пинов объявок не отображается цена
 * В противном случае эти пины берутся из основной ручки с пинами офферов
 */
export async function fetchNewbuildingPromoPins(
  context: IApplicationContext,
  parameters: IParams,
): Promise<IGetNewbuildingPromoPinsForMapResponse> {
  const { cancellationTokenManager } = context;

  function getOr<T>(key: string, defaultValue: T): T {
    const value: T | null | undefined = context.config.get(key);

    if (value === null || value === undefined) {
      return defaultValue;
    }

    return value;
  }

  const cancelPreviousRequest: boolean = getOr(
    'mapSearchFrontend.fetchNewbuildingPromoPins.cancelPreviousRequest',
    CANCEL_PREVIOUS_REQUEST,
  );

  let cancellationToken: CancellationToken | undefined;

  if (cancelPreviousRequest && cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious('fetchNewbuildingPromoPins');
  }

  const response = await fetchGetNewbuildingPromoPinsForMap(context, {
    parameters,
    config: { cancellationToken },
  });

  assertUnknownError(response);

  return response.response;
}
