import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EMarkAsViewedActionType {
  Loading = 'VIEWED/MARK_AS_VIEWED/LOADING',
  Succeed = 'VIEWED/MARK_AS_VIEWED/SUCCEED',
  Failed = 'VIEWED/MARK_AS_VIEWED/FAILED',
}

export interface IMarkAsViewedSucceedPayload {
  precision: number;
  featureId: string;
}

export type TMarkAsViewedLoading = ITypedReduxAction<EMarkAsViewedActionType.Loading>;
export type TMarkAsViewedSucceed = ITypedReduxAction<EMarkAsViewedActionType.Succeed, IMarkAsViewedSucceedPayload>;
export type TMarkAsViewedFailed = ITypedReduxAction<EMarkAsViewedActionType.Failed>;

export type TViewedActions = TMarkAsViewedLoading | TMarkAsViewedSucceed | TMarkAsViewedFailed;
