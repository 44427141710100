import { ResponseError } from 'shared/common/errors';
import { IBboxSchema } from 'shared/common/repositories/newbuilding-search/entities/get_newbuildings_for_map_promo_slider/BboxSchema';
import {
  fetchGetNewbuildingsForPromoSliderOnMap,
  TGetNewbuildingsForPromoSliderOnMapResponse,
} from 'shared/common/repositories/newbuilding-search/v1/get-newbuildings-for-promo-slider-on-map';

import { newbuildingsForPromoInventoryMapper } from '../../mappers/promoInventory';
import { IApplicationContext } from '../../types/applicationContext';
import { INewbuildingForPromoInventory } from '../../types/promoInventory';

interface IResolveBannersArgs {
  bbox: IBboxSchema;
  zoom: number;
  context: IApplicationContext;
}

export async function resolveNewbuildingsForPromoInventory({
  context: { httpApi, logger },
  bbox,
  zoom,
}: IResolveBannersArgs): Promise<INewbuildingForPromoInventory[]> {
  try {
    const newbuildingsForPromoBannerData: TGetNewbuildingsForPromoSliderOnMapResponse =
      await fetchGetNewbuildingsForPromoSliderOnMap({
        httpApi,
        parameters: { bbox, zoom },
      });

    if (newbuildingsForPromoBannerData.statusCode !== 200) {
      throw new ResponseError({
        domain: 'resolveNewbuildingsForPromoInventory',
        message: newbuildingsForPromoBannerData.response.message || '',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: newbuildingsForPromoBannerData.response.errors as any,
        },
      });
    }

    return newbuildingsForPromoInventoryMapper(newbuildingsForPromoBannerData.response);
  } catch (error) {
    logger.warning('[Degradation] Newbuildings for promo slider on map', error);

    return [];
  }
}
