import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EFavoritesActionType {
  Added = 'favorites/favorite/added',
  Removed = 'favorites/favorite/removed',
}

export interface IFavoriteParameters {
  offerId: number;
  selectedFeatureId: string;
  currentPrecision: number;
}

export type TFavoriteAdded = ITypedReduxAction<EFavoritesActionType.Added, IFavoriteParameters>;
export type TFavoriteRemoved = ITypedReduxAction<EFavoritesActionType.Removed, IFavoriteParameters>;

export type TFavoritesActions = TFavoriteAdded | TFavoriteRemoved;
