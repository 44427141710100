import { Spinner } from './Spinner';
import { POPUP_DEFAULT_OPTIONS } from './constants';
import * as styles from './styles.css';
import { getContainerPopupPlacement, EPopupPlacement } from './utils';

export function definePopupManager(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('PopupManager')) {
    return;
  }

  ymaps.modules.define<[typeof YMaps.Balloon, YMaps.ITemplateLayoutFactory]>(
    'PopupManager',
    ['Balloon', 'templateLayoutFactory'],
    (provide, Balloon: typeof YMaps.Balloon, templateLayoutFactory) => {
      class PopupManager {
        private map: YMaps.Map;
        private balloon: YMaps.Balloon;

        public constructor({ map }: YMaps.IPopupManagerParameters) {
          this.map = map;
          this.balloon = new Balloon(this.map, {
            ...POPUP_DEFAULT_OPTIONS,
            layout: this.preparePopupLayout(),
          });
          this.balloon.options.setParent(this.map.options);
        }

        public setIndent = (indent: YMaps.TPopupIndent) => {
          this.balloon.options.set<YMaps.TPopupIndent>('indent', indent);
        };

        public setState = (state: YMaps.TPopupManagerState) => {
          this.balloon.options.set<YMaps.TPopupManagerState>(state);
        };

        public open = (coordinates: YMaps.TCoord) => {
          const mapBounds = this.map.getBounds({ useMapMargin: true });
          const zoom = this.map.getZoom();
          const containerPopupPlacement = getContainerPopupPlacement(coordinates, mapBounds, zoom);

          this.balloon.options.set<EPopupPlacement>('placement', containerPopupPlacement);
          this.balloon.open(coordinates);
        };

        public close = () => {
          this.balloon.close();
        };

        private preparePopupLayout = () => {
          const content = this.preparePopupContent();

          return templateLayoutFactory.createClass(content);
        };

        private preparePopupContent() {
          return `
            <div class="${styles['wrapper']}
              {% if options.indent === 'S' %} ${styles['indent--small']}
              {% elseif options.indent === 'M' %} ${styles['indent--medium']}
              {% elseif options.indent === 'L' %} ${styles['indent--large']}
              {% elseif options.indent === 'XL' %} ${styles['indent--extra-large']}{% endif %}
              {% if options.placement === '${EPopupPlacement.Left}' %} ${styles['placement--left']}
              {% elseif options.placement === '${EPopupPlacement.Right}' %} ${styles['placement--right']}
              {% elseif options.placement === '${EPopupPlacement.Top}' %} ${styles['placement--top']}
              {% elseif options.placement === '${EPopupPlacement.Bottom}' %} ${styles['placement--bottom']}
              {% elseif options.placement === '${EPopupPlacement.TopRight}' %} ${styles['placement--top-right']}
              {% elseif options.placement === '${EPopupPlacement.TopLeft}' %} ${styles['placement--top-left']}
              {% elseif options.placement === '${EPopupPlacement.BottomRight}' %} ${styles['placement--bottom-right']}
              {% elseif options.placement === '${EPopupPlacement.BottomLeft}' %} ${
                styles['placement--bottom-left']
              }{% endif %}
            ">
              <div
                class="${styles['container']}
                {% if options.status === 'pending' %} ${styles['loading']}{% endif %}
              ">
                <div class="${styles['image-block']}">
                  ${Spinner({ className: styles['spinner'] })}
                  <img src="{{options.imageUrl}}" alt="" class="${styles['image']}">
                  {% if options.label %}<div class="${styles['label']}">{{options.label}}</div>{% endif %}
                </div>
                <div class="${styles['content-block']}">
                  <div class="${styles['title']}">{{options.title}}</div>
                  <ul class="${styles['features']}">
                    {% for feature in options.features %}
                      <li class="${styles['features-item']}">{{feature}}</li>
                    {% endfor %}
                  </ul>
                </div>
              </div>
            </div>
          `;
        }
      }

      provide(PopupManager);
    },
  );
}
