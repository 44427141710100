import { IJsonQueryPolygon } from '../../../common/packages/api-models/common/json_query';
import { IPolygon } from '../../types/map/polygon';
import { hash } from '../../utils/hash';

function getPolygonHash(jsonQueryPolygon: IJsonQueryPolygon): number {
  const hashComponents = [jsonQueryPolygon.type, jsonQueryPolygon.name, String(jsonQueryPolygon.coordinates)];

  return hashComponents.map(hash).reduce((acc, h) => acc ^ h, 0);
}

export function toPolygon(jsonQueryPolygon: IJsonQueryPolygon): IPolygon {
  const { name, coordinates } = jsonQueryPolygon;

  return {
    id: String(getPolygonHash(jsonQueryPolygon)),
    title: name,
    coordinates: coordinates.map(([x, y]) => [Number(x), Number(y)]),
  };
}
