import * as React from 'react';

import { PolygonClickHandlers } from './PolygonClickHandlers';
import { PolygonsManager } from './PolygonsManager';
import { NewbuildingPolygonsContext, INewbuildingPolygonsContext } from './context';
import { useMapContext } from '../../context';

export const NewbuildingsPolygonsContainer: React.FC = () => {
  const { ymaps, map } = useMapContext();
  const newbuildingsPolygonsManagerRef = React.useRef(new ymaps.NewbuildingsPolygonsManager());
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    map.geoObjects.add(newbuildingsPolygonsManagerRef.current.objectManager);

    return () => {
      map.geoObjects.remove(newbuildingsPolygonsManagerRef.current.objectManager);
    };
  }, []);

  const context = React.useMemo<INewbuildingPolygonsContext>(
    () => ({
      newbuildingsPolygonsManager: newbuildingsPolygonsManagerRef.current,
    }),
    [],
  );

  return (
    <NewbuildingPolygonsContext.Provider value={context}>
      <PolygonClickHandlers />
      <PolygonsManager />
    </NewbuildingPolygonsContext.Provider>
  );
};
