import { FOfferType } from 'shared/common/packages/JsonQuery';

import { IOfferType } from '../../types';

export const OFFER_TYPES_DAILY_RESIDENTIAL: IOfferType[][] = [
  [{ label: 'Квартира', value: FOfferType.Flat }],
  [
    { label: 'Комната', value: FOfferType.Room },
    { label: 'Койко-место', value: FOfferType.Bed },
  ],
  [{ label: 'Дом, дача', value: FOfferType.House }],
];
