import { TDeviceType } from '@cian/ui-kit';

import { ERentTime, getTermValue } from '../../../common/packages/JsonQuery';
import { IApplicationState } from '../../types/redux';

export const selectIsDailyRent = (deviceType: TDeviceType) => (state: IApplicationState) => {
  const jsonQuery = state.results.jsonQuery;
  const rentalPeriod = getTermValue('for_day')(jsonQuery);
  const isDailyRent = rentalPeriod === ERentTime.Daily;
  const isCorrectDeviceType = ['desktop', 'tablet'].includes(deviceType);

  return isDailyRent && isCorrectDeviceType;
};
