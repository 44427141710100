import { TDevice } from '@cian/utils';

import { selectFeature } from './selectFeature';
import { toListingUrl } from '../../mappers/queryString';
import { selectIsUserAgent } from '../../selectors/user';
import { TFeature } from '../../types/map';
import { IApplicationState, TThunkAction } from '../../types/redux';
import { convertBboxToString } from '../../utils/convertBboxToString';
import { getNewbuildingQuery } from '../../utils/queryString';
import { removeNewbuildingsFromUrl } from '../../utils/removeNewbuildingsFromUrl';
import { changeSearchResultsUrl, openSearchResultsSidebar } from '../searchResults';
import { markAsViewed } from '../viewed/markAsViewed';

export interface IOpenFeatureParams {
  feature: TFeature;
  precision: number;
  isDetailed: boolean;
  deviceType: TDevice;
}

export function openFeature({
  feature,
  precision,
  isDetailed,
  deviceType,
}: IOpenFeatureParams): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const baseUrl = context.config.get<string>('mobileSearchFrontend.browserUrl');
    const currentSubdomain = context.custom.subdomain;
    const featureSubdomain = feature.properties.subdomain;
    const qs = getFeatureQueryString(feature, getState(), isDetailed);
    const url = `${baseUrl}v1/get-results-for-map?${qs}&parentOrigin=${window.location.origin}`;
    const urlWithoutNewbuildings = removeNewbuildingsFromUrl(url);

    dispatch(selectFeature(feature, precision));
    dispatch(changeSearchResultsUrl(urlWithoutNewbuildings));
    dispatch(openSearchResultsSidebar());

    if (!feature.properties.isViewed) {
      dispatch(markAsViewed({ feature, precision, isDetailed }));
    }

    if (deviceType !== 'desktop') {
      const url = toListingUrl(qs);
      const urlWithoutNewbuildings = removeNewbuildingsFromUrl(url);

      window.open(
        `//${window.location.hostname.replace(
          `${currentSubdomain}.`,
          `${featureSubdomain}.`,
        )}${urlWithoutNewbuildings}`,
        '_blank',
      );
    }
  };
}

export function getFeatureQueryString(feature: TFeature, state: IApplicationState, isDetailed: boolean) {
  const {
    bbox: featureBBox,
    isExtended,
    newbuilding,
    isNewbuildingPromo,
    hasNewobject,
    isAnyFromDeveloper,
    specialPromo,
  } = feature.properties;
  const { queryString, extendedQueryString, nonGeoQueryString } = state;
  const userIsAgent = selectIsUserAgent(state);
  const qs = isExtended && extendedQueryString ? extendedQueryString : queryString;

  const isLeadsBannerVisibleParam = !userIsAgent ? '&isLeadsBannerVisible=1' : '';
  const isPreselectedFromDeveloper = hasNewobject && isAnyFromDeveloper;
  const isPinWithPrice = isDetailed || isNewbuildingPromo || specialPromo ? '&isPinWithPrice=1' : '';
  const bboxString = convertBboxToString(featureBBox);
  const bboxFilter = bboxString ? `&bbox=${bboxString}` : '';

  if (newbuilding) {
    const houseIds = newbuilding.houseId ? [newbuilding.houseId] : undefined;
    const newbuildingQuery = getNewbuildingQuery({
      qs: nonGeoQueryString,
      newbuilding,
      houseIds,
      isNewbuildingPromo,
    });
    const customFilter = isPreselectedFromDeveloper ? '&preselected_from_developer=1' : '&preselected_from_developer=0';

    return `${newbuildingQuery}&isNavigationDisabled=1${isLeadsBannerVisibleParam}${customFilter}${isPinWithPrice}${bboxFilter}`;
  }

  return `${qs}${bboxFilter}&isNavigationDisabled=1${isLeadsBannerVisibleParam}`;
}
