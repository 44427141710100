import { FSK_BANNER_DEFAULT_DATA } from 'shared/map-search/constants/fsk';
import { IFskBanner } from 'shared/map-search/types/fskContent/fskContent';
import { isHttpValid } from 'shared/map-search/utils/url/isHttpValid';

import { IMapBannerContract } from '../../../common/repositories/special-promos-custom/entities/ContentBlocks/MapBannerContract';

export const DEFAULT_FSK_BANNER: IFskBanner = {
  logo: FSK_BANNER_DEFAULT_DATA.logo,
  background: FSK_BANNER_DEFAULT_DATA.background,
  title: FSK_BANNER_DEFAULT_DATA.title,
  description: FSK_BANNER_DEFAULT_DATA.description,
  disclaimer: FSK_BANNER_DEFAULT_DATA.copyright,
  hitUrl: `${FSK_BANNER_DEFAULT_DATA.href}?erid=${FSK_BANNER_DEFAULT_DATA.erid}`,
};

export function prepareFskBanner(data: IMapBannerContract | null): IFskBanner {
  if (!data) {
    return DEFAULT_FSK_BANNER;
  }

  const hitUrl = isHttpValid(data.hitUrl) ? data.hitUrl : FSK_BANNER_DEFAULT_DATA.href;

  const url = new URL(hitUrl);

  if (data.erid) {
    url.searchParams.append('erid', data.erid);
  }

  return {
    logo: data.logo,
    background: data.background,
    title: data.title,
    description: data.description,
    disclaimer: data.disclaimer,
    hitUrl: url.href,
  };
}
