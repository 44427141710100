import { HttpCancelError } from '@cian/peperrors/shared';

import { setNewbuildingPromoFeatures } from './setNewbuildingPromoFeatures';
import {
  dealTypeFromJsonQuery,
  FDealType,
  FOfferType,
  offerTypeFromJsonQuery,
} from '../../../common/packages/JsonQuery';
import { prepareNewbuildingPins } from '../../mappers/newbuildingPins';
import { fetchNewbuildingPromoPins as requestFetchNewbuildingPromoPins } from '../../services/newbuildingPins';
import { EErrorNotificationType } from '../../types/notifications';
import { TThunkAction } from '../../types/redux';
import { pushErrorNotification } from '../notifications';
import { closeSearchResultsSidebar } from '../searchResults';

export function fetchNewbuildingPromoPins(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { logger } = context;
    const {
      filters: { jsonQuery },
      activeFeature,
    } = getState();

    const isFeatureEnabled = context.config.get<boolean>('Map.NewbuildingProPlusFeatures.Enabled');

    if (!isFeatureEnabled) {
      return;
    }

    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    if (![FOfferType.Flat, FOfferType.FlatNew].includes(offerType) || dealType !== FDealType.Sale) {
      dispatch(setNewbuildingPromoFeatures({}));

      if (activeFeature && activeFeature.properties.isNewbuildingPromo) {
        dispatch(closeSearchResultsSidebar());
      }

      return;
    }

    try {
      const clustersData = await requestFetchNewbuildingPromoPins(context, {
        jsonQuery,
      });

      const preparedPins = prepareNewbuildingPins(clustersData);

      dispatch(setNewbuildingPromoFeatures(preparedPins));
    } catch (error) {
      if (error instanceof HttpCancelError) {
        return;
      }

      logger.error(error);

      dispatch(pushErrorNotification(EErrorNotificationType.FetchClusters));
    }
  };
}
