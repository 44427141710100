import { IFeaturesMap } from '../../types/map';
import { IApplicationState } from '../../types/redux';

const EMPTY_PINS: IFeaturesMap = {};

export function selectNewbuildingPromoFeatures(state: IApplicationState) {
  const { features, visible, loading } = state.newbuildingPromoFeatures;

  return visible && !loading ? features : EMPTY_PINS;
}
