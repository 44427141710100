import { IBBoxSchema } from '../../../common/repositories/search-offers-index/entities/bbox/BBoxSchema';
import { EMapBoundsActionType } from '../../actions/mapBounds';
import { EResultsActionType } from '../../actions/results';
import { TMapBounds } from '../../types/mapBounds';
import { IApplicationState, TReduxActions } from '../../types/redux';
import { bboxToMapBounds } from '../../utils/mapBounds';

const initialState: IApplicationState['mapBounds'] = {
  center: [0, 0],
  zoom: 0,
  precision: 0,
  bounds: [
    [0, 0],
    [0, 0],
  ],
};

export function mapBoundsReducer(
  state: IApplicationState['mapBounds'] = initialState,
  action: TReduxActions,
): IApplicationState['mapBounds'] {
  switch (action.type) {
    case EResultsActionType.Succeed: {
      if (!action.payload.updateBounds) {
        return state;
      }

      /**
       * Остальные значения будут получены при следующей перерисовке
       * @see {@link MapBoundsUpdater}
       */
      return bboxToMapBounds(action.payload.bbox as IBBoxSchema) as TMapBounds;
    }

    case EMapBoundsActionType.Changed:
      return action.payload;
  }

  return state;
}
