/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createDrawPolygonProcess } from './DrawPolygonProcess';

interface ICianDrawPolygonOptions {
  map: YMaps.Map;
  unwantedBehaviors?: string[];
  tolerance?: number;
  onCreatePolygon(coordinates: YMaps.TCoord[]): void;
}

const DEFAULT_UNWANTED_BEHAVIORS = ['drag'];

export function defineDrawPolygon(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('cian.DrawPolygon')) {
    return;
  }

  ymaps.modules.define<[YMaps.IMeta, typeof YMaps.pane.EventsPane]>(
    'cian.DrawPolygon',
    ['meta', 'pane.EventsPane'],
    (provide, meta, EventsPane) => {
      const DrawPolygonProcess = createDrawPolygonProcess({
        meta,
        EventsPane,
      });

      class DrawPolygon {
        private unwantedBehaviors: string[];
        private map: YMaps.Map;
        private options: ICianDrawPolygonOptions;
        private process: InstanceType<typeof DrawPolygonProcess> | null = null;

        public constructor(options: ICianDrawPolygonOptions) {
          this.options = options;
          this.map = options.map;
          this.unwantedBehaviors = options.unwantedBehaviors || DEFAULT_UNWANTED_BEHAVIORS;
        }

        public enable() {
          const { map } = this.options;

          map.behaviors.disable(this.unwantedBehaviors);

          this.process = new DrawPolygonProcess({
            map: this.map,
          });

          map.events.add(['mousedown', 'touchstart'], this.handleMouseDown);
          map.events.add(['mouseup', 'touchend'], this.handleMouseUp);
        }

        public disable() {
          const { map } = this.options;

          if (this.process) {
            this.process.destroy();

            this.process = null;
          }

          map.events.remove(['mousedown', 'touchstart'], this.handleMouseDown);
          map.events.remove(['mouseup', 'touchend'], this.handleMouseUp);

          map.behaviors.enable(this.unwantedBehaviors);
        }

        public destroy() {
          this.disable();
        }

        private handleMouseDown = (e: YMaps.IEvent) => {
          this.process!.start(e, { tolerance: this.options.tolerance });
        };

        private handleMouseUp = (e: YMaps.IEvent) => {
          const result = this.process!.finish(e);

          if (result) {
            this.options.onCreatePolygon(result);
          }
        };
      }

      provide(DrawPolygon);
    },
  );
}
