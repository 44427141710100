import { ResponseError } from 'shared/common/errors';
import {
  fetchQueryToGeoTags,
  TQueryToGeoTagsResponse,
} from 'shared/common/repositories/monolith-python/v1/query-to-geo-tags';

import { ETagsActionType } from './types';
import { prepareGeoTags } from '../../mappers/geoTags';
import { TThunkAction } from '../../types/redux';
import { TTag } from '../../types/tags';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<ETagsActionType.Loading>(ETagsActionType.Loading);
const setStatusSucceed = actionGenerator<ETagsActionType.Succeed, TTag[] | null>(ETagsActionType.Succeed);
const setStatusFailed = actionGenerator<ETagsActionType.Failed>(ETagsActionType.Failed);

export function fetchTags(): TThunkAction<Promise<TTag[] | null>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    try {
      const {
        custom: { subdomain },
      } = context;
      const {
        filters: { jsonQuery },
      } = getState();

      const geoTagsData: TQueryToGeoTagsResponse = await fetchQueryToGeoTags({
        httpApi: context.httpApi,
        parameters: {
          jsonQuery,
        },
        config: {
          subdomain,
        },
      });

      if (geoTagsData.statusCode !== 200) {
        throw new ResponseError({
          domain: 'fetchTags',
          message: geoTagsData.response.message,
          details: {
            error: geoTagsData.response.errors,
          },
        });
      }

      const {
        response: { data },
      } = geoTagsData;

      const tags = prepareGeoTags(data, jsonQuery);

      dispatch(setStatusSucceed(tags));

      return tags;
    } catch (error) {
      const { logger } = context;

      logger.error(error);

      dispatch(setStatusFailed());

      throw error;
    }
  };
}
