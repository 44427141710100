import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { MapBlockingBanner } from '@cian/mobile-banner-component';
import { parseCookies } from '@cian/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsMapBlockingExperimentGroupC } from 'shared/map-search/selectors/experiments/selectIsAudMobileBannerToAppExperimentActive';
import { selectPlatform } from 'shared/map-search/selectors/platform';
import { selectListingUrl } from 'shared/map-search/selectors/urls';
import { IApplicationState } from 'shared/map-search/types/redux';
import { EResultsStatus } from 'shared/map-search/types/results';
import { TUser } from 'shared/map-search/types/user';

import { DO_NOT_SHOW_MAP_BLOCKING_BANNER, MOBILE_BANNER_TITLE } from './constants';
import { trackListingClick, trackShowBanner, trackMobileAppClick } from './tracking';
import { useApplicationContext } from '../ApplicationContext';

export function MapBlockingBannerContainer() {
  const {
    config,
    custom: { subdomain },
  } = useApplicationContext();

  const defaultBrowserUrl = config.getStrict<string>('mobileBanner.browserUrl');
  const isMapBlockingBannerEnabled = config.getStrict<boolean>('mapSearchFrontend.mobileMapBlockingBanner') || false;

  const platform = useSelector(selectPlatform);
  const url = useSelector(selectListingUrl);
  const targetSubdomain = useSelector<IApplicationState, string>(state => state.results.subdomain);
  const resultsStatus = useSelector<IApplicationState, EResultsStatus>(state => state.results.status);
  const user = useSelector<IApplicationState, TUser>(state => state.user);
  const isMapBlockingExperimentGroupC = useSelector(selectIsMapBlockingExperimentGroupC);

  const [canShowBanner, setCanShowBanner] = React.useState<boolean>(false);

  const [appUrl, setAppUrl] = React.useState<string | null>(defaultBrowserUrl);
  const [targetDomain, setTargetDomain] = React.useState<string>(`${targetSubdomain}.cian.ru`);

  const withOffersCounter = !user.isAuthenticated || user.userType === 'searcher' || user.userType === 'homeowner';
  const isLoading = resultsStatus === EResultsStatus.Loading && withOffersCounter;

  React.useEffect(() => {
    setAppUrl(generateOneLinkUrl(appUrl, 'mobile_site', MOBILE_BANNER_TITLE));
    setCanShowBanner(!!parseCookies(document.cookie)[DO_NOT_SHOW_MAP_BLOCKING_BANNER]);
  }, []);

  React.useEffect(() => {
    setTargetDomain(window.location.hostname.replace(`${subdomain}.`, `${targetSubdomain}.`));
  }, [subdomain, targetSubdomain]);

  const handleShowBanner = React.useCallback(() => {
    trackShowBanner(platform);
  }, [platform]);

  const handleMobileAppClick = React.useCallback(() => {
    trackMobileAppClick(platform);
  }, [platform]);

  const handleListClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (isLoading) {
        return;
      }

      const href = event.currentTarget.href;
      trackListingClick(platform);

      setTimeout(() => {
        document.cookie = 'flavour=mobile;domain=.cian.ru;path=/';
        window.location.href = href;
      }, 300);
    },
    [isLoading, platform],
  );

  if (!(isMapBlockingBannerEnabled && isMapBlockingExperimentGroupC) || canShowBanner) {
    return null;
  }

  return (
    <MapBlockingBanner
      appUrl={appUrl || defaultBrowserUrl}
      listUrl={`//${targetDomain}${url}`}
      handleShowBanner={handleShowBanner}
      handleMobileAppClick={handleMobileAppClick}
      handleListClick={handleListClick}
    />
  );
}
