import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ERulerActionType {
  RulerEnabled = 'RULER/ENABLED',
  RulerDisabled = 'RULER/DISABLED',
}

export type TRulerEnabledAction = ITypedReduxAction<ERulerActionType.RulerEnabled>;
export type TRulerDisabledAction = ITypedReduxAction<ERulerActionType.RulerDisabled>;

export type TRulerActions = TRulerEnabledAction | TRulerDisabledAction;
