import { EMapBoundsActionType } from '../../actions/mapBounds';
import { EResultsActionType } from '../../actions/results';
import { toListingUrl } from '../../mappers/queryString';
import { TReduxActions } from '../../types/redux';
import { UrlWithBoundsBuilder } from '../../utils/urlWithBoundsBuilder';

export function listingUrlReducer(state: string = '#', action: TReduxActions): string {
  switch (action.type) {
    case EResultsActionType.Succeed:
      if (action.payload.clean) {
        return toListingUrl(action.payload.queryString);
      }

      return state;
    case EMapBoundsActionType.Changed:
      return new UrlWithBoundsBuilder(state, action.payload).withCenter().withZoom().withBbox().build();
    default:
      return state;
  }
}
