import { flatten, without } from 'ramda';
import * as React from 'react';

import { FDealType, FOfferType, isAvailableOfferTypeCombination } from 'shared/common/packages/JsonQuery';

import { CommercialLink } from './components/CommercialLink';
import { getOfferTypeList } from './helpers';
import { getOfferTypeLabel } from '../../../utils/offerType';
import { Select, Option } from '../../Select';

interface IOfferTypeProps {
  dealType: FDealType;
  value: FOfferType;
  commercialLink: string;
  isSearchFlatShareEnabled: boolean;
  onChange(value: FOfferType): void;
}

export const OfferType: React.FC<IOfferTypeProps> = ({
  dealType,
  value,
  commercialLink,
  isSearchFlatShareEnabled,
  onChange,
}) => {
  const offerTypes = React.useMemo(
    () => getOfferTypeList({ dealType, offerType: value, isSearchFlatShareEnabled }),
    [dealType, value, isSearchFlatShareEnabled],
  );
  const offerTypeLabel = React.useMemo(() => getOfferTypeLabel(dealType, value), [dealType, value]);

  const selectedOptions = React.useMemo(
    () =>
      flatten(offerTypes)
        .filter(offerType => (value & offerType.value) !== 0)
        .map(offerType => offerType.value),
    [offerTypes, value],
  );

  const handleOnChange = React.useCallback(
    (_: React.ChangeEvent, selectedValues: FOfferType[]) => {
      const selectedOfferType = without(
        selectedOptions.length < selectedValues.length ? selectedOptions : selectedValues,
        selectedOptions.length < selectedValues.length ? selectedValues : selectedOptions,
      )[0];

      if (selectedOfferType === value) {
        return;
      }

      const nextOfferType: FOfferType = selectedValues.reduce(
        (previousValue, currentValue) => previousValue | currentValue,
        FOfferType.Unexpected,
      );

      if (selectedOptions.length > selectedValues.length) {
        onChange(nextOfferType);
      } else {
        if (isAvailableOfferTypeCombination(nextOfferType)) {
          onChange(nextOfferType);
        } else {
          onChange(selectedOfferType);
        }
      }
    },
    [onChange, selectedOptions, value],
  );

  return (
    <Select
      data-name="FilterOfferType"
      multiple
      withoutArrow
      label={offerTypeLabel || undefined}
      placeholder="Тип предложения"
      value={selectedOptions}
      onChange={handleOnChange}
      popupPostfix={dealType !== FDealType.RentDaily ? <CommercialLink href={commercialLink} /> : null}
    >
      {offerTypes.map(parts =>
        parts.map(offerType => (
          <Option key={`${offerType.label}_${offerType.value}`} value={offerType.value} marker={offerType.marker}>
            {offerType.label}
          </Option>
        )),
      )}
    </Select>
  );
};
