import RBush from 'rbush';

import { HOTSPOTS_LAYER_ZINDEX } from './constants';
import { TILE_SIZE, POINT_RADIUS, DOT_SIZE_SMALL, DOT_SIZE_BIG } from '../../../constants/map';
import { IHotspotProperties, IRBushItem, TFeature } from '../../../types/map';
import { getTilePixelCoords } from '../../tiles';

interface IHotspotsLayerOptions {
  map: YMaps.Map;
  rbush: RBush<IRBushItem>;
}

export function defineHotspotsLayer(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('HotspotsLayer')) {
    return;
  }

  ymaps.modules.define<
    [YMaps.util.IHd, YMaps.util.IPixelBounds, typeof YMaps.hotspot.Layer, typeof YMaps.hotspot.ObjectSource]
  >(
    'HotspotsLayer',
    ['util.hd', 'util.pixelBounds', 'hotspot.Layer', 'hotspot.ObjectSource'],
    (provide, _utilHd, _utilPixelBounds, hsLayer, hsObjectSource) => {
      class HotspotsLayer {
        public readonly layer: YMaps.hotspot.Layer;
        public readonly objectSource: YMaps.hotspot.ObjectSource;
        public isDetailsVisible: boolean;
        public isResultable: boolean;
        private map: YMaps.Map;
        private mapProjection: YMaps.projection.wgs84Mercator;
        private rbush: RBush<IRBushItem>;

        public constructor(options: IHotspotsLayerOptions) {
          this.map = options.map;
          this.mapProjection = this.map.options.get('projection');
          this.rbush = options.rbush;

          this.objectSource = new hsObjectSource(() => null);
          this.objectSource.requestObjects = this.requestObjects;

          this.layer = new hsLayer(this.objectSource, { zIndex: HOTSPOTS_LAYER_ZINDEX });
        }

        private requestObjects = (
          layer: YMaps.hotspot.Layer,
          tileNumber: YMaps.TTileNumber,
          zoom: number,
          callback: Function,
        ): void => {
          if (this.objectSource.restrict(layer, tileNumber, zoom)) {
            callback([]);

            return;
          }

          const tilePixelCoords = getTilePixelCoords(tileNumber);
          const tileFeatures = this.getFeaturesForTile(tilePixelCoords, zoom);
          if (tileFeatures.length === 0) {
            callback([]);

            return;
          }

          const response = this.convertFeaturesToHotspotsResponse(tileFeatures, tilePixelCoords, zoom);
          this.objectSource.parseResponse(layer, response, callback, tileNumber, zoom);
        };

        private calculateHotspotCoordinates(
          feature: TFeature,
          tilePixelCoords: YMaps.TPixelCoord,
          tileZoom: number,
        ): [[number, number], [number, number]] {
          const [coordX, coordY] = feature.geometry.coordinates;
          const [pixelCoordX, pixelCoordY] = this.mapProjection.toGlobalPixels([coordX, coordY], tileZoom);

          const [tilePixelCoordX, tilePixelCoordY] = tilePixelCoords;

          // вычислим координаты x и y относительно тайла
          const [relativeX, relativeY] = [pixelCoordX - tilePixelCoordX, pixelCoordY - tilePixelCoordY];

          let radius = POINT_RADIUS;
          if (this.isResultable && !this.isDetailsVisible) {
            if (feature.properties.count === 1) {
              radius = DOT_SIZE_SMALL;
            } else if (feature.properties.count > 1) {
              radius = DOT_SIZE_BIG;
            }
          }

          if (!this.isResultable && feature.properties.isExtended) {
            radius -= 1;
          }

          // вычислим пиксельные координаты точек прямоугольника, описывающего окружность
          const rectLeftTop: [number, number] = [relativeX - radius, relativeY - radius];
          const rectRightBottom: [number, number] = [relativeX + radius, relativeY + radius];

          return [rectLeftTop, rectRightBottom];
        }

        private convertPointToHotspot(feature: TFeature, tilePixelCoords: YMaps.TPixelCoord, zoom: number): object {
          const coordinates = this.calculateHotspotCoordinates(feature, tilePixelCoords, zoom);
          const properties: IHotspotProperties = {
            originalFeature: feature,
            HotspotMetaData: {
              id: feature.id,
              RenderedGeometry: {
                type: 'Rectangle',
                // Координаты передаются в пиксельных координатах, отсчитываемых от верхнего левого угла тайла.
                coordinates,
              },
            },
          };

          return {
            type: 'Feature',
            properties,
          };
        }

        private convertFeaturesToHotspotsResponse(
          features: TFeature[],
          tilePixelCoords: YMaps.TPixelCoord,
          zoom: number,
        ): object {
          const convertedFeatures = features.map(feature => this.convertPointToHotspot(feature, tilePixelCoords, zoom));

          return {
            data: {
              type: 'FeatureCollection',
              features: convertedFeatures,
            },
          };
        }

        private getFeaturesForTile(tilePixelCoords: YMaps.TPixelCoord, zoom: number): TFeature[] {
          const minX = tilePixelCoords[0] - POINT_RADIUS;
          const minY = tilePixelCoords[1] - POINT_RADIUS;
          const maxX = minX + TILE_SIZE + POINT_RADIUS * 2;
          const maxY = minY + TILE_SIZE + POINT_RADIUS * 2;

          const [coordMaxX, coordMinY] = this.mapProjection.fromGlobalPixels([minX, minY], zoom);
          const [coordMinX, coordMaxY] = this.mapProjection.fromGlobalPixels([maxX, maxY], zoom);

          const rBushItems = this.rbush.search({
            minX: coordMinX,
            minY: coordMinY,
            maxX: coordMaxX,
            maxY: coordMaxY,
          });

          return rBushItems.map(({ feature }) => feature);
        }
      }

      provide(HotspotsLayer);
    },
  );
}
