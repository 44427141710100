// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetNewbuildingPromoPinsForMapRequest,
  TGetNewbuildingPromoPinsForMapModel,
  IMappers,
  TGetNewbuildingPromoPinsForMapResponse,
  IGetNewbuildingPromoPinsForMapResponse,
  IGetNewbuildingPromoPinsForMapResponseError,
} from './types';

export const defaultConfig: TGetNewbuildingPromoPinsForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'search-offers-index',
  pathApi: '/v1/get-newbuilding-promo-pins-for-map/',
  requestType: 'json',
} as TGetNewbuildingPromoPinsForMapModel;

export function createGetNewbuildingPromoPinsForMapModel(
  parameters: IGetNewbuildingPromoPinsForMapRequest,
): TGetNewbuildingPromoPinsForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingPromoPinsForMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetNewbuildingPromoPinsForMapRequest;
}

export async function fetchGetNewbuildingPromoPinsForMap<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingPromoPinsForMapOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingPromoPinsForMapResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingPromoPinsForMapModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingPromoPinsForMapResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingPromoPinsForMapResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingPromoPinsForMapResponse;
}
