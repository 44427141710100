export function getGroceryIcon(): string {
  return `
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_432_706)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2.77539H14V0.775391H2V2.77539ZM2 4.77539L0 8.10872V9.77539H1V15.7754H10V12.2754C10 11.447 10.6716 10.7754 11.5 10.7754C12.3284 10.7754 13 11.447 13 12.2754V15.7754H15V9.77539H16V8.10872L14 4.77539H2ZM3 10.7754H8V13.7754H3V10.7754Z" fill="currentColor" />
  </g>
  <defs>
    <clipPath id="clip0_432_706">
      <rect width="16" height="16" fill="currentColor" transform="translate(0 0.775391)" />
    </clipPath>
  </defs>
</svg>
`;
}
