import * as React from 'react';

/* istanbul ignore next */
export const ZoomOut: React.FC = () => {
  return (
    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="16" y1="1" y2="1" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

ZoomOut.displayName = 'ZoomOut';
