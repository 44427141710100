export enum EErrorNotificationType {
  FetchClusters = 'fetch-clusters',
  OffersNotFound = 'offers-not-found',
  GeoLocation = 'geolocation',
}

export interface INotificationError {
  id: string;
  type: EErrorNotificationType;
}

export interface INotifications {
  errors: INotificationError[];
}
