import * as React from 'react';

import { JsonQuery, NonEmptyArray, EGarageUtility } from 'shared/common/packages/JsonQuery';

import { GarageUtilities } from '../../../components/advancedFilters/GarageUtilities';
import { useContext } from '../../../utils/useContext';

export const GarageUtilitiesContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const garageUtilities = new JsonQuery(jsonQuery).getGarageUtilities();

  const handleGarageUtilitiesChange = React.useCallback(
    (garageUtilities: NonEmptyArray<EGarageUtility> | null) => {
      onChange({ action: 'setGarageUtilities', arguments: [garageUtilities] });
    },
    [onChange],
  );

  return <GarageUtilities value={garageUtilities} onChange={handleGarageUtilitiesChange} />;
};
