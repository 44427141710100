import { ArticleParagraph2, IconClose12 } from '@cian/ui-kit';
import * as React from 'react';

import userFolder from './icons/userFolder.svg';
import * as styles from './styles.css';
import { BannerWrapper } from '../../BannerWrapper';

export interface IBannerProps {
  onClick(): void;
  onClose(): void;
  isSearchResultsOpen?: boolean;
  rootClassName?: string;
}

export const Banner: React.FC<IBannerProps> = ({ onClick, onClose, isSearchResultsOpen, rootClassName }) => {
  return (
    <BannerWrapper
      moveRight={isSearchResultsOpen}
      attributes={{ className: rootClassName, 'data-testid': 'Lead-Banner' }}
    >
      <button className={styles['close']} onClick={onClose}>
        <IconClose12 />
      </button>
      <div className={styles['content']}>
        <div className={styles['img']}>
          <img src={userFolder} alt="" />
        </div>
        <div>
          <ArticleParagraph2>
            Риелтор-партнёр Циан поможет купить
            <br />
            или продать любую недвижимость
          </ArticleParagraph2>
          <a href="/application-form/" onClick={onClick} className={styles['link']}>
            Подобрать риелтора
          </a>
        </div>
      </div>
    </BannerWrapper>
  );
};

Banner.displayName = 'Banner';
