export function hash(value: string): number {
  let hash = 5381;
  let i = value.length;

  while (i) {
    hash = (hash * 33) ^ value.charCodeAt(--i);
  }

  return hash;
}
