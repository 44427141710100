import RBush from 'rbush';
import * as React from 'react';

import { Desktop } from 'shared/common/packages/Responsive';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { IRBushItem } from 'shared/map-search/types/map';

import { ClickHandlers } from './ClickHandlers';
import { NewbuildingPromoPinsUpdater } from './NewbuildingPromoPinsUpdater';
import { PopupManager } from './PopupManager';
import { NewbuildingPromoPinsContext, INewbuildingPromoPinsContext } from './context';

export const NewbuildingPromoPins: React.FC = () => {
  const { ymaps, map } = useMapContext();
  const rbushRef = React.useRef(new RBush<IRBushItem>());
  const newbuildingsPromoPinsManagerRef = React.useRef(
    new ymaps.NewbuildingsPromoPinsManager({ map, rbush: rbushRef.current }),
  );
  const popupManagerRef = React.useRef(new ymaps.NewbuildingPromoPopupManager({ map }));

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    map.geoObjects.add(newbuildingsPromoPinsManagerRef.current.objectManager);

    return () => {
      map.geoObjects.remove(newbuildingsPromoPinsManagerRef.current.objectManager);
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const context = React.useMemo<INewbuildingPromoPinsContext>(
    () => ({
      rbush: rbushRef.current,
      newbuildingsPromoPinsManager: newbuildingsPromoPinsManagerRef.current,
      popupManager: popupManagerRef.current,
    }),
    [],
  );

  return (
    <NewbuildingPromoPinsContext.Provider value={context}>
      <ClickHandlers />
      <NewbuildingPromoPinsUpdater />
      <Desktop>
        <PopupManager />
      </Desktop>
    </NewbuildingPromoPinsContext.Provider>
  );
};
