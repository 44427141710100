import { ca } from '@cian/analytics';

interface ITrackShowNumberClickParams {
  label: string;
  sc: object;
  products: unknown;
  page: unknown;
}

export function trackShowNumberClick({ label, sc, products, page }: ITrackShowNumberClickParams) {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_listing_in_map',
    sc,
    label,
    products,
    page,
  });
}
