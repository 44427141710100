import { equals } from 'ramda';

import { IBBox } from '../../types/map/bbox';
import { IMapCenterZoomBounds, IMapBBoxBounds } from '../../types/mapBounds';

export function bboxToMapBounds({ topLeft, bottomRight }: IBBox): IMapCenterZoomBounds | IMapBBoxBounds {
  const { lat: top, lng: left } = topLeft;
  const { lat: bottom, lng: right } = bottomRight;

  const bounds: [YMaps.TCoord, YMaps.TCoord] = [
    [bottom, left],
    [top, right],
  ];

  if (equals(bounds[0], bounds[1])) {
    return {
      center: bounds[0],
      zoom: 18,
    };
  }

  return {
    bounds,
  };
}
