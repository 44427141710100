import * as R from 'ramda';

import { setGeo } from './helpers';
import { NonEmptyArray, TJsonQuery } from './types';
import { TGeoValue } from '../api-models/common/json_query';

export function selectGeo(jsonQuery: TJsonQuery): (...geoValues: NonEmptyArray<TGeoValue>) => TJsonQuery {
  return (...geoValues) => {
    const nextGeo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    const addedGeoValues = geoValues.filter(v => !nextGeo.some(g => R.equals(v, g)));

    return setGeo()(jsonQuery)([...nextGeo, ...addedGeoValues]);
  };
}
