import * as React from 'react';

import { EFacilityType, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface ILivingConditionsOption {
  label: string;
  value: EFacilityType | null;
}

const LIVING_CONDITIONS_OPTIONS: ILivingConditionsOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Можно с детьми', value: EFacilityType.KidsAllowed },
  { label: 'Можно с животными', value: EFacilityType.PetsAllowed },
];

interface ILivingConditionsProps {
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const LivingConditions: React.FC<ILivingConditionsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_livingConditions" label="Условия проживания">
      <CheckboxButtonGroupUnselectable options={LIVING_CONDITIONS_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
