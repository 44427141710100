import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { useMortgagePromoSearchTypeOptions, useMortgagePromoSearchTypeValues } from './hooks';
import { PromoSearchTypesOnboardingContainer } from './parts';
import { getSubsidizedMortgageModifierValue, removeSubsidizedMortgageFromValues } from './utils';
import { FilterChips } from '../../../components/FilterChips';
import { Filter } from '../../../components/advancedFilters/common/Filter';
import { getPromoSearchFilterValuesByType } from '../../../utils/getPromoSearchFilterValuesByType';
import { useContext } from '../../../utils/useContext';

export const MortgagePromoSearchTypeContainer: React.FC = () => {
  const { appContext, jsonQuery, promoSearchTypes, onChange } = useContext();

  const allValues = getTermsValue('promo_search_types')(jsonQuery);
  const mortgageValues = useMortgagePromoSearchTypeValues({
    allValues,
    promoSearchTypes,
    jsonQuery,
  });
  const mortgageOptions = useMortgagePromoSearchTypeOptions({
    promoSearchTypes,
    jsonQuery,
    config: appContext.config,
  });

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange(
        {
          action: 'setIsSubsidisedMortgage',
          arguments: [getSubsidizedMortgageModifierValue(newValues)],
        },
        {
          action: 'setPromoSearchTypes',
          arguments: [
            {
              allValues,
              newValues: removeSubsidizedMortgageFromValues(newValues),
              availableFilterValues: getPromoSearchFilterValuesByType('mortgage', promoSearchTypes),
            },
          ],
        },
      );
    },
    [allValues, promoSearchTypes, onChange],
  );

  if (!promoSearchTypes) {
    return null;
  }

  return (
    <Filter id="advancedFilter_mortgagePromoSearchType" label="Ипотечные программы">
      <PromoSearchTypesOnboardingContainer>
        <div data-name="MortgagePromoSearchTypeChips">
          <FilterChips options={mortgageOptions} value={mortgageValues} onChange={handleChange} selectType="multiple" />
        </div>
      </PromoSearchTypesOnboardingContainer>
    </Filter>
  );
};
