import * as React from 'react';

import { FOfferType } from 'shared/common/packages/JsonQuery';
import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface ISuburbanAreaProps {
  offerType: FOfferType;
  houseArea: IJsonQueryRangeValue | null;
  landArea: IJsonQueryRangeValue | null;
  onHouseAreaMinChange(value: number | null): void;
  onHouseAreaMaxChange(value: number | null): void;
  onLandAreaMinChange(value: number | null): void;
  onLandAreaMaxChange(value: number | null): void;
}

export const SuburbanArea: React.FC<ISuburbanAreaProps> = ({
  offerType,
  houseArea,
  landArea,
  onHouseAreaMinChange,
  onHouseAreaMaxChange,
  onLandAreaMinChange,
  onLandAreaMaxChange,
}) => {
  const { gte: houseAreaMin = null, lte: houseAreaMax = null } = houseArea || {};
  const { gte: landAreaMin = null, lte: landAreaMax = null } = landArea || {};

  const isHouseAreaAvailable = (offerType & ~FOfferType.Land) !== 0;

  const handleHouseAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onHouseAreaMinChange(gte);
  }, 300);

  const handleHouseAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onHouseAreaMaxChange(lte);
  }, 300);

  const handleLandAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onLandAreaMinChange(gte);
  }, 300);

  const handleLandAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onLandAreaMaxChange(lte);
  }, 300);

  const isBunchOfFilters = isHouseAreaAvailable;

  return (
    <Filter id="advancedFilter_suburbanArea" label="Площадь">
      <InlineFilterGroup>
        {isHouseAreaAvailable && (
          <InlineFilter prefix={isBunchOfFilters ? 'Дом' : undefined} postfix={'м\u00b2'}>
            <RangeInput
              min={1}
              max={99999}
              valueMin={houseAreaMin}
              valueMax={houseAreaMax}
              onChangeMin={handleHouseAreaMinChange}
              onChangeMax={handleHouseAreaMaxChange}
            />
          </InlineFilter>
        )}
        <InlineFilter prefix={isBunchOfFilters ? 'Участок' : undefined} postfix="соток">
          <RangeInput
            min={1}
            max={999999}
            valueMin={landAreaMin}
            valueMax={landAreaMax}
            onChangeMin={handleLandAreaMinChange}
            onChangeMax={handleLandAreaMaxChange}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
