import {
  FSK_DESKTOP_MAP_ZOOM_MAX,
  FSK_DESKTOP_MAP_ZOOM_MIN,
  FSK_MOBILE_MAP_ZOOM_MIN,
  FSK_MOBILE_MAP_ZOOM_MAX,
} from '../../constants/fsk';

export function isShowFskBannerOnMapZoom(zoom = 0, isDesktop: boolean) {
  return isDesktop
    ? zoom <= FSK_DESKTOP_MAP_ZOOM_MAX && zoom >= FSK_DESKTOP_MAP_ZOOM_MIN
    : zoom <= FSK_MOBILE_MAP_ZOOM_MAX && zoom >= FSK_MOBILE_MAP_ZOOM_MIN;
}
