import { ca } from '@cian/analytics';

interface ITrackAdsBannerTeaserEventParams {
  sc: object;
  product: unknown;
  page: unknown;
}

export function trackAdsBannerTeaserEvent({ sc, product, page }: ITrackAdsBannerTeaserEventParams) {
  ca('teaser', {
    sc,
    product,
    page,
  });
}
