import { CancellationToken } from '@cian/http-api/shared';

import { assertFetchGetInfrastructureGeoObjectsResponse } from './asserts';
import {
  fetchGetInfrastructureGeoObjects,
  IGetInfrastructureGeoObjectsRequest,
  TGetInfrastructureGeoObjectsResponse,
} from '../../../common/repositories/infrastructure-caching/v1/get-infrastructure-geo-objects';
import { prepareInfrastructureItems } from '../../mappers/infrastructure';
import { IApplicationContext } from '../../types/applicationContext';
import { IInfrastructurePointItem } from '../../types/infrastructure';

export async function fetchInfrastructureFeatures(
  parameters: IGetInfrastructureGeoObjectsRequest,
  { httpApi, logger, config, cancellationTokenManager }: IApplicationContext,
): Promise<IInfrastructurePointItem[]> {
  const cancelPreviousRequest: boolean =
    config.get<boolean>('mapSearchFrontend.fetchInfrastructureFeatures.cancelPreviousRequest') ?? false;

  let cancellationToken: CancellationToken | undefined;
  if (cancelPreviousRequest && cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious('fetchAdditionalFeatures');
  }

  try {
    const res: TGetInfrastructureGeoObjectsResponse = await fetchGetInfrastructureGeoObjects({
      httpApi,
      parameters,
      config: {
        cancellationToken,
      },
    });

    assertFetchGetInfrastructureGeoObjectsResponse(res);

    return prepareInfrastructureItems(res);
  } catch (e) {
    logger.warning(e, {
      domain: 'fetchInfrastructureFeatures',
      degradation: 'Гео-объекты дополнительных слоев',
    });

    return [];
  }
}
