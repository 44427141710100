import { IConfig } from '@cian/config/shared';
import { useMemo } from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';
import { TJsonQuery } from 'shared/common/packages/JsonQuery';

import { useSubsidizedMortgageOption } from './useSubsidizedMortgageOption';

interface IParams {
  promoSearchTypes: TPromoSearchTypes;
  jsonQuery: TJsonQuery;
  config: IConfig;
}

export const useMortgagePromoSearchTypeOptions = ({ promoSearchTypes, jsonQuery, config }: IParams) => {
  const subsidizedMortgageOption = useSubsidizedMortgageOption({ jsonQuery, config });

  return useMemo(() => {
    if (!promoSearchTypes) {
      return [];
    }

    return [
      { label: 'Неважно', value: null },
      ...(subsidizedMortgageOption ? [subsidizedMortgageOption] : []),
      ...promoSearchTypes.mortgage.map(({ value, name: label }) => ({
        value,
        label,
      })),
    ];
  }, [promoSearchTypes, subsidizedMortgageOption]);
};
