export class IntervalTimer {
  private callbackStartTime: number;
  private remaining: number;
  private paused = false;
  private timerId: ReturnType<typeof setInterval> | null;
  protected callback: () => void;
  protected delay: number;

  public constructor(callback: () => void, delay: number) {
    this.callback = callback;
    this.delay = delay;
    this.callbackStartTime = 0;
    this.remaining = 0;
    this.paused = false;
    this.timerId = null;
  }

  public pause() {
    // istanbul ignore else
    if (!this.paused) {
      this.clear();

      this.remaining = Date.now() - this.callbackStartTime;
      this.paused = true;
    }
  }

  public resume() {
    if (!this.paused) {
      return;
    }

    if (this.remaining) {
      setTimeout(() => {
        this.run();
        this.paused = false;
        this.start();
      }, this.remaining);
    } else {
      this.paused = false;
      this.start();
    }
  }

  public clear() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  public start() {
    this.clear();

    this.timerId = setInterval(() => {
      this.run();
    }, this.delay);
  }

  public run() {
    this.callbackStartTime = Date.now();
    this.callback();
  }
}
