import { CANDY_BUTTON_RADIUS, LOLLIPOP_RADIUS, LOLLIPOP_STICK } from '../../constants/infrastructureFeatures';
import { TILE_SIZE } from '../../constants/map';
import { fromGlobalPixels } from '../projection';
import { getTilePixelCoords } from '../tiles';

interface IGetExtendedTileBBoxParams {
  tileNumber: YMaps.TTileNumber;
  zoom: number;
}

export function getInfrastructureFeaturesExtendedTileBBox(params: IGetExtendedTileBBoxParams) {
  const maxPointRadius = getInfrastructureFeaturesMaxDelta(params.zoom);
  const { tileNumber, zoom } = params;

  const [tilePixelCoordX, tilePixelCoordY] = getTilePixelCoords(tileNumber);

  // Расширяем область поиска точек, для отрисовки кусочков точек, находящихся на краях других тайлов
  const pixelCoordMinX = tilePixelCoordX - maxPointRadius;
  const pixelCoordMinY = tilePixelCoordY - maxPointRadius;
  const pixelCoordMaxX = tilePixelCoordX + TILE_SIZE + maxPointRadius;
  const pixelCoordMaxY = tilePixelCoordY + TILE_SIZE + maxPointRadius;

  const [coordMaxX, coordMinY] = fromGlobalPixels([pixelCoordMinX, pixelCoordMinY], zoom);
  const [coordMinX, coordMaxY] = fromGlobalPixels([pixelCoordMaxX, pixelCoordMaxY], zoom);

  return {
    minX: coordMinX,
    minY: coordMinY,
    maxX: coordMaxX,
    maxY: coordMaxY,
  };
}

function getInfrastructureFeaturesMaxDelta(zoom: number) {
  if (zoom > 14) {
    return LOLLIPOP_RADIUS * 2 + LOLLIPOP_STICK;
  } else {
    return CANDY_BUTTON_RADIUS;
  }
}
