import { TDevice } from '@cian/utils';

export const FSK_BANNER_DEFAULT_DATA = {
  href: 'https://www.cian.ru/zastroishchik-gk-fsk-5747/',
  title: 'Скидки до 22% на квартиры',
  background: 'https://cdn.cian.site/frontend/newbuilding-b2b-assets/images/fsk/map-banner-bg.png',
  logo: 'https://cdn.cian.site/frontend/newbuilding-b2b-assets/images/fsk/fsk_logo_white.svg',
  description: 'Узнать подробнее',
  developer: 'ГК ФСК',
  copyright: 'Рекламодатель: ООО "НЕГА ЮГ", ОГРН 1072304003200,  1-31.05.2023. Подробнее fsk.ru',
  erid: 'LatgC8rSY',
};

export const FSK_BANNER = {
  developerId: 5747,
};

export const FSK_BANNER_PLACE: Record<TDevice, string> = {
  phone: 'above_header_banner',
  tablet: 'above_header_banner',
  desktop: 'bottom_left_banner',
};
// Все регионы Москвы и МО
export const FSK_REGIONS = [
  1, 4593, 175104, 203780, 218122, 175635, 6169, 274976, 174634, 189484, 175149, 174640, 212017, 174644, 6211, 174659,
  5704, 4690, 6229, 270426, 204891, 175209, 175219, 5749, 175226, 4734, 175231, 175744, 4738, 4741, 5766, 203914, 4750,
  175758, 4756, 176281, 202907, 4768, 175785, 6827, 202926, 202935, 206523, 176316, 204477, 338625, 217282, 176321,
  204998, 5831, 4809, 6858, 4813, 6352, 4817, 174292, 6358, 4822, 202983, 6894, 316654, 4848, 203505, 6386, 4851,
  174832, 175349, 176366, 175864, 5379, 5388, 176401, 175378, 176405, 341782, 175903, 5922, 297257, 6445, 297262, 6452,
  4916, 5948, 5953, 4935, 176463, 4945, 4949, 6486, 4958, 4960, 203112, 5482, 174957, 203630, 203638, 4983, 175996,
  6529, 5510, 226189, 6029, 175004, 203172, 6569, 174508, 5038, 269747, 5044, 176052, 206776, 174522, 6076, 174530,
  203719, 5064, 175051, 174541, 317389, 317396, 175578, 175071, 317408, 175075, 175588, 174573, 5625,
];

export const FSK_DESKTOP_MAP_ZOOM_MAX = 13;
export const FSK_DESKTOP_MAP_ZOOM_MIN = 8;

export const FSK_MOBILE_MAP_ZOOM_MAX = 13;
export const FSK_MOBILE_MAP_ZOOM_MIN = 4;
