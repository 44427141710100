import { selectNewbuildingInfo } from './selectNewbuildingInfo';
import { IApplicationState } from '../../types/redux';
import { selectActiveNewbuildingPolygon } from '../newbuildingPolygons';

export const selectNewbuildingFicheringPlus = (state: IApplicationState): boolean | undefined => {
  const newbuildingInfo = selectNewbuildingInfo(state);
  const activePolygon = selectActiveNewbuildingPolygon(state);

  if (activePolygon.newbuildingId) {
    return activePolygon.isAnyFicheringPlus;
  }

  return newbuildingInfo?.isAnyFicheringPlus;
};
