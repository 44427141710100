import { ArticleHeading4, Image, UIText2 } from '@cian/ui-kit';

import * as styles from './DesktopBanner.css';
import { IDesktopBannerTemplateProps, DesktopBannerTemplate } from '../DesktopBannerTemplate';

interface IDesktopBannerProps extends IDesktopBannerTemplateProps {
  title: string;
  description: string;
  href: string;
  newbuildingImage: string;
  developerLogo: string;
  onMouseOver(): void;
  onMouseOut(): void;
}

export function DesktopBanner({
  title,
  description,
  href,
  newbuildingImage,
  developerLogo,
  onMouseOver,
  onMouseOut,
  ...rest
}: IDesktopBannerProps) {
  return (
    <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <DesktopBannerTemplate
        href={href}
        newbuildingImage={<Image src={newbuildingImage} alt={title} width={64} height={64} objectFit="cover" />}
        developerLogo={
          // Может прийти пустая строка, договорились с бэком, что будет исправляться в качестве техдолга
          developerLogo ? (
            <Image src={developerLogo} alt="Застройщик" width={30} height={30} borderRadius={0} objectFit="contain" />
          ) : null
        }
        title={<ArticleHeading4>{title}</ArticleHeading4>}
        description={<UIText2>{description}</UIText2>}
        rootClassName={styles['desktop-banner']}
        {...rest}
      />
    </div>
  );
}
