import classNames from 'clsx';
import * as React from 'react';

import { NewbuildingDescription } from './NewbuildingDescription';
import { NewbuildingImage } from './NewbuildingImage';
import { NewbuildingLocation } from './NewbuildingLocation';
import { NewbuildingRating } from './NewbuildingRating';
import * as styles from './styles.css';
import { IUndergroundInfo } from '../../types/newbuilding';

interface INewbuildingPromoPopupProps {
  newbuildingName: string;
  newbuildingUrl: string;
  address: string;
  houseName: string | null;
  imageUrl: string;
  reviewsRate: string;
  reviewsRateUrl: string;
  priceFrom: string;
  promoName: string | null;
  promoUrl: string | null;
  undergrounds: IUndergroundInfo[];
  contactsBlock: React.ReactNode;
  isVisibleContacts: boolean;
  onNewbuildingClick(): void;
}

export function NewbuildingPromoPopup({
  newbuildingName,
  newbuildingUrl,
  imageUrl,
  reviewsRate,
  reviewsRateUrl,
  promoName,
  promoUrl,
  houseName,
  priceFrom,
  undergrounds,
  contactsBlock,
  isVisibleContacts,
  address,
  onNewbuildingClick,
}: INewbuildingPromoPopupProps) {
  return (
    <div className={classNames(styles['container'], isVisibleContacts && styles['is-visible-contacts'])}>
      <div className={styles['image-block']}>
        <NewbuildingImage name={newbuildingName} imageUrl={imageUrl} />

        <NewbuildingRating
          reviewsRate={reviewsRate}
          reviewsRateUrl={reviewsRateUrl}
          promoName={promoName}
          promoUrl={promoUrl}
        />
      </div>

      <div className={styles['content-block']}>
        <div className={styles['entry']}>
          <NewbuildingDescription
            newbuildingName={newbuildingName}
            newbuildingUrl={newbuildingUrl}
            houseName={houseName}
            priceFrom={priceFrom}
            onNewbuildingClick={onNewbuildingClick}
          />

          <NewbuildingLocation address={address} undergrounds={undergrounds} />
        </div>

        <div className={styles['footer']}>{contactsBlock}</div>
      </div>
    </div>
  );
}
