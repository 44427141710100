import { EErrorNotificationType, INotificationError } from '../../types/notifications';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EErrorNotificationActionType {
  Pushed = 'ERROR_NOTIFICATIONS/PUSHED',
  Removed = 'ERROR_NOTIFICATIONS/REMOVED',
  Cleared = 'ERROR_NOTIFICATIONS/CLEARED',
}

export type TPushedErrorNotificationAction = ITypedReduxAction<
  EErrorNotificationActionType.Pushed,
  EErrorNotificationType
>;
export type TRemovedErrorNotificationAction = ITypedReduxAction<
  EErrorNotificationActionType.Removed,
  INotificationError['id']
>;
export type TClearedErrorNotificationsAction = ITypedReduxAction<EErrorNotificationActionType.Cleared, void>;

export type TErrorNotificationActions =
  | TPushedErrorNotificationAction
  | TRemovedErrorNotificationAction
  | TClearedErrorNotificationsAction;
