import * as React from 'react';

import { getRangeValue, getTermValue } from 'shared/common/packages/JsonQuery';

import { Price } from '../../../components/filters/Price';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const PriceContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const price = getRangeValue('price')(jsonQuery);
  const isSquareMeterPrice = getTermValue('price_sm')(jsonQuery);

  const applyFilter = React.useCallback(() => {
    onApply('price');
  }, [onApply]);

  const handlePriceMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setPriceMin', arguments: [gte] });
      applyFilter();
    },
    [applyFilter, onChange],
  );

  const handlePriceMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setPriceMax', arguments: [lte] });
      applyFilter();
    },
    [applyFilter, onChange],
  );

  const handlePriceTypeChange = React.useCallback(
    (isSquareMeterPrice: boolean | null) => {
      onChange({ action: 'setPriceType', arguments: [isSquareMeterPrice] });
      applyFilter();
    },
    [applyFilter, onChange],
  );

  return (
    <Price
      dealType={dealType}
      offerType={offerType}
      price={price}
      isSquareMeterPrice={isSquareMeterPrice}
      onPriceMinChange={handlePriceMinChange}
      onPriceMaxChange={handlePriceMaxChange}
      onPriceTypeChange={handlePriceTypeChange}
    />
  );
};
