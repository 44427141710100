declare global {
  interface Window {
    __header_updateFavorites__?(isFavorite: boolean): void;
  }
}

export function incrementHeaderFavorites() {
  if (window.__header_updateFavorites__) {
    window.__header_updateFavorites__(true);
  }
}

export function decrementHeaderFavorites() {
  if (window.__header_updateFavorites__) {
    window.__header_updateFavorites__(false);
  }
}
