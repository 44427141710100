import { pathOr } from 'ramda';
import * as React from 'react';

import { EMetroTravelType } from 'shared/common/packages/api-models/common/json_query';

import { UndergroundTime } from '../../../components/advancedFilters/UndergroundTime';
import { useContext } from '../../../utils/useContext';

export const UndergroundTimeContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();

  const travelType = pathOr<EMetroTravelType | null>(null, ['only_foot', 'value'], jsonQuery);
  const travelTime = pathOr<number | null>(null, ['foot_min', 'value', 'lte'], jsonQuery);

  const handleChangeTravelTime = React.useCallback(
    (time: number | null) => {
      onChange({ action: 'setUndergroundTravelTime', arguments: [time] });
    },
    [onChange],
  );

  const handleChangeTravelType = React.useCallback(
    (type: EMetroTravelType | null) => {
      onChange({ action: 'setUndergroundTravelType', arguments: [type] });
    },
    [onChange],
  );

  return (
    <UndergroundTime
      travelType={travelType}
      travelTime={travelTime}
      onTravelTimeChange={handleChangeTravelTime}
      onTravelTypeChange={handleChangeTravelType}
    />
  );
};
