import { getTermsValue, getTermValue } from '../../../common/packages/JsonQuery';
import { EBuildingStatus, ERoomType } from '../../../common/packages/api-models/common/json_query';
import { IApplicationState } from '../../types/redux';

export function selectNewbuildingPolygons(state: IApplicationState) {
  const {
    filters: { jsonQuery },
    newbuildingsPolygons,
  } = state;

  const buildingStatus = getTermValue('building_status')(jsonQuery);
  const room = getTermsValue('room')(jsonQuery) || [];
  const { _type: type } = jsonQuery;

  const isPrimary = buildingStatus === EBuildingStatus.New;
  const isMixed = buildingStatus === null;
  const isFlatSale = type === 'flatsale';
  const isShared = room.some(r => [ERoomType.FlatShared, ERoomType.Room, ERoomType.Bed].includes(r));

  if (!isFlatSale || (isFlatSale && isShared)) {
    return null;
  }

  if (isPrimary) {
    return newbuildingsPolygons['primary'];
  }

  if (isMixed) {
    return newbuildingsPolygons['mixed'];
  }

  return null;
}
