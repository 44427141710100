import { IConfig } from '@cian/config/shared';

import {
  defaultConfig,
  IGetNewbuildingPromoPinsForMapOptions,
  IGetNewbuildingPromoPinsForMapRequest,
  IGetNewbuildingPromoPinsForMapResponse200,
  IGetNewbuildingPromoPinsForMapResponse400,
  TGetNewbuildingPromoPinsForMapModel,
  TGetNewbuildingPromoPinsForMapResponse,
} from 'shared/common/repositories/search-offers-index/v1/get-newbuilding-promo-pins-for-map';
import { IApplicationContext } from 'shared/map-search/types/applicationContext';
import { withJobName } from 'shared/map-search/utils/withJobName';

import { mapGetNewbuildingPromoPinsForMapResponse, mapGetNewbuildingPromoPinsForMapResponseError } from '../mappers';

export async function fetchGetNewbuildingPromoPinsForMap(
  context: IApplicationContext,
  options: Omit<
    IGetNewbuildingPromoPinsForMapOptions<
      IGetNewbuildingPromoPinsForMapResponse200,
      IGetNewbuildingPromoPinsForMapResponse400
    >,
    'httpApi' | 'mappers'
  >,
) {
  const { httpApi } = context;

  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingPromoPinsForMapModel(context.config, options.parameters),
    options.config,
  );

  if (statusCode === 200) {
    return mapGetNewbuildingPromoPinsForMapResponse(response);
  }

  if (statusCode === 400) {
    return mapGetNewbuildingPromoPinsForMapResponseError(response);
  }

  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingPromoPinsForMapResponse;
}

function createGetNewbuildingPromoPinsForMapModel(
  config: IConfig,
  parameters: IGetNewbuildingPromoPinsForMapRequest,
): TGetNewbuildingPromoPinsForMapModel {
  const requestConfig = {
    ...defaultConfig,
    parameters,
  };

  return withJobName(config, requestConfig);
}
