import { InputAdornment } from '@cian/ui-kit/inputAdornment';
import { InputGroup } from '@cian/ui-kit/inputGroup';
import * as React from 'react';

import * as styles from './RangeInput.css';
import { MaskedInput } from '../MaskedInput';

export interface IRangeInputProps {
  joined?: boolean;
  postfix?: string;
  min?: number;
  max?: number;
  scale?: number;
  thousandsSeparator?: string;
  valueMin?: number | null;
  valueMax?: number | null;
  onChangeMin?(value: number | null): void;
  onChangeMax?(value: number | null): void;
}

export const RangeInput: React.FC<IRangeInputProps> = ({
  joined = true,
  postfix,
  min,
  max,
  scale = 0,
  thousandsSeparator = ' ',
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax,
}) => {
  const [internalValueMin, setInternalValueMin] = React.useState<number | null>(
    typeof valueMin !== 'undefined' ? valueMin : null,
  );

  const handleChangeMin = React.useCallback(
    (valueRaw: string) => {
      const value = valueRaw ? Number(valueRaw) : null;
      if (value === valueMin) {
        return;
      }

      setInternalValueMin(value);

      if (onChangeMin) {
        onChangeMin(value);
      }
    },
    [valueMin, onChangeMin],
  );

  const handleChangeMax = React.useCallback(
    (valueRaw: string) => {
      const value = valueRaw ? Number(valueRaw) : null;
      if (value === valueMax) {
        return;
      }

      if (onChangeMax) {
        onChangeMax(value);
      }
    },
    [valueMax, onChangeMax],
  );

  React.useEffect(() => {
    setInternalValueMin(typeof valueMin !== 'undefined' ? valueMin : null);
  }, [valueMin]);

  const rightAdornment = postfix && (
    <InputAdornment>
      <span className={styles['postfix']}>{postfix}</span>
    </InputAdornment>
  );

  return (
    <InputGroup spliced={joined}>
      <MaskedInput
        rightAdornment={rightAdornment}
        placeholder="от"
        mask={Number}
        min={min}
        max={max}
        scale={scale}
        thousandsSeparator={thousandsSeparator}
        mapToRadix={[',', '.', 'Б', 'Ю']}
        value={getValue(valueMin)}
        onComplete={handleChangeMin}
      />
      <MaskedInput
        rightAdornment={rightAdornment}
        placeholder="до"
        mask={Number}
        min={getMinForMaxField(internalValueMin, min)}
        max={max}
        scale={scale}
        thousandsSeparator={thousandsSeparator}
        mapToRadix={[',', '.', 'Б', 'Ю']}
        value={getValue(valueMax)}
        onComplete={handleChangeMax}
      />
    </InputGroup>
  );
};

function getValue(value: number | null | undefined): string {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }

  return String(value);
}

function getMinForMaxField(internalMin: number | null, min: number | undefined): number | undefined {
  if (internalMin !== null && typeof min !== 'undefined') {
    return internalMin < min ? min : internalMin;
  } else if (internalMin !== null) {
    return internalMin;
  } else if (typeof min !== 'undefined') {
    return min;
  }

  return undefined;
}
