import { getTermValue } from '../../../common/packages/JsonQuery';
import { TReduxActions } from '../../actions';
import { EResultsActionType } from '../../actions/results';
import {
  INewbuildingPolygon,
  INewbuildingsPolygons,
  TNewbuildingsPolygonsCollection,
} from '../../types/map/newbuildingPolygon';

const defaultState: TNewbuildingsPolygonsCollection = { primary: {}, mixed: {} };

export function newbuildingsPolygonsReducer(state = defaultState, action: TReduxActions) {
  switch (action.type) {
    case EResultsActionType.Succeed: {
      const { newbuildingsPolygons, jsonQuery } = action.payload;

      const buildingStatus = getTermValue('building_status')(jsonQuery);

      const isPrimary = buildingStatus === 2;
      const isMixed = buildingStatus === null;

      const data = prepareNewbuildingsPolygon(newbuildingsPolygons);

      if (isPrimary) {
        const prevPrimaryPolygons = state?.primary || {};

        return {
          ...state,
          primary: { ...prevPrimaryPolygons, ...data },
        };
      }

      if (isMixed) {
        const prevMixedPolygons = state?.mixed || {};

        return {
          ...state,
          mixed: { ...prevMixedPolygons, ...data },
        };
      }
    }
  }

  return state;
}

function prepareNewbuildingsPolygon(polygons: INewbuildingPolygon[] | null) {
  const map: INewbuildingsPolygons = {};

  if (!polygons || polygons.length === 0) {
    return map;
  }

  polygons.forEach(polygon => {
    map[polygon.newbuildingId] = { ...polygon };
  });

  return map;
}
