import { NonEmptyArray } from '../../../common/packages/JsonQuery';

export function normalizeRegionId(regionIds: NonEmptyArray<number> | null) {
  if (regionIds === null) {
    return null;
  }

  // для расширенных регионов типа Москва и МО, Санкт-Петербург и ЛО
  // отправляем только id Москвы и Санкт-Петербурга соответственно
  if (regionIds.length > 1 && regionIds.includes(1)) {
    return 1;
  }
  if (regionIds.length > 1 && regionIds.includes(2)) {
    return 2;
  }

  return regionIds[0];
}
