import { IJsonQuery, TGeoValue } from '../../../common/packages/api-models/common/json_query';
import { IQueryToGeoTagsResponseSchema } from '../../../common/repositories/monolith-python/entities/schemas/QueryToGeoTagsResponseSchema';
import { TTag } from '../../types/tags';

const geoTypesMap: { [key: string]: TGeoValue['type'] } = {
  Location: 'location',
  Street: 'street',
  Road: 'highway',
  House: 'house',
};

export function prepareGeoTags(response: IQueryToGeoTagsResponseSchema, jsonQuery: IJsonQuery): TTag[] | null {
  const tags: TTag[] = [];

  const { region } = response;

  if (
    region &&
    (!response.geoTags.address_tags ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !response.geoTags.address_tags.some((tag: any) => tag.addressNodes[tag.addressNodes.length - 1].id === region.id))
  ) {
    switch (region.id) {
      case -1: {
        tags.push({ type: 'region', id: 1, name: 'Москва' }, { type: 'region', id: 4593, name: 'Московская область' });
        break;
      }

      case -2: {
        tags.push(
          { type: 'region', id: 2, name: 'Санкт-Петербург' },
          { type: 'region', id: 4588, name: 'Ленинградская область' },
        );
        break;
      }

      default: {
        if (jsonQuery.geo?.value.some(geo => geo.type === 'location' && geo.id === region.id)) {
          tags.push({ type: 'address', geoType: 'location', id: region.id, name: region.fullname });
        } else {
          tags.push({ type: 'region', id: region.id, name: region.fullname });
        }
      }
    }
  }

  if (response.geoTags.address_tags && response.geoTags.address_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.address_tags.map((tag: any) => {
        const addressNode = tag.addressNodes[tag.addressNodes.length - 1];

        return {
          type: 'address',
          geoType: geoTypesMap[addressNode.geoType],
          id: addressNode.id,
          name: tag.text,
        };
      }),
    );
  }

  if (response.geoTags.developer_tags && response.geoTags.developer_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.developer_tags.map((tag: any) => ({
        type: 'developer',
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.metro_tags && response.geoTags.metro_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.metro_tags.map((tag: any) => ({
        type: 'underground',
        id: tag.id,
        color: tag.color,
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.polygon_tags && response.geoTags.polygon_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.polygon_tags.map((tag: any) => ({
        type: 'polygon',
        coordinates: tag.geoObject.source.geometry.coordinates[0].map((coords: YMaps.TCoord) =>
          coords.map(coord => parseFloat(coord.toFixed(7)).toString()),
        ),
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.railway_tags && response.geoTags.railway_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.railway_tags.map((tag: any) => ({
        type: 'railway',
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.district_tags && response.geoTags.district_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.district_tags.map((tag: any) => ({
        type: 'district',
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.newobject_tags && response.geoTags.newobject_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.newobject_tags.map((tag: any) => ({
        type: 'residentialComplex',
        id: Number(tag.id),
        name: tag.text,
      })),
    );
  }

  if (response.geoTags.regional_district_tags && response.geoTags.regional_district_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...response.geoTags.regional_district_tags.map((tag: any) => ({
        type: 'regional_district_id',
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  return tags.length > 0 ? tags : null;
}
