import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EAuthenticationActionType {
  Succeed = 'authentication/succeed',
  Cancelled = 'authentication/cancelled',
}

export type TAuthenticationSucceed = ITypedReduxAction<EAuthenticationActionType.Succeed>;
export type TAuthenticationCancelled = ITypedReduxAction<EAuthenticationActionType.Cancelled>;

export type TAuthenticationActions = TAuthenticationSucceed | TAuthenticationCancelled;
