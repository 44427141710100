import { ca } from '@cian/analytics';
import { TDevice } from '@cian/utils';

import { getSiteType } from 'shared/map-search/utils/analytics';

export function trackClickPolygon(queryString: string, deviceType: TDevice, newbuildingId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click_polygon',
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
    products: [
      // TODO: в extra сложить is_promo: yes для фичерингпро+
      { id: newbuildingId },
    ],
  });
}
