import RBush from 'rbush';

import { createTileClass } from './pointTile';
import { IRBushItem, TFeature } from '../../../types/map';
import { getExtendedTileBBox } from '../../tile';

interface IPointsLayerOptions {
  map: YMaps.Map;
  rbush: RBush<IRBushItem>;
}

export function definePointsLayer(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('PointsLayer')) {
    return;
  }

  ymaps.modules.define<[YMaps.util.IHd, YMaps.util.IPixelBounds, YMaps.option.Manager, typeof YMaps.Layer]>(
    'PointsLayer',
    ['util.hd', 'util.pixelBounds', 'option.Manager', 'Layer', 'layer.tileContainer.DomContainer'],
    (provide, utilHd, _utilPixelBounds, _optionManager, Layer) => {
      class PointsLayer {
        public readonly layer: YMaps.Layer;
        public isDetailsVisible: boolean;
        public isNewbuildingsListing: boolean;
        public isResultable: boolean;
        public showOnlyPrice: boolean;

        private map: YMaps.Map;
        private mapProjection: YMaps.projection.wgs84Mercator;
        private rbush: RBush<IRBushItem>;

        public constructor(options: IPointsLayerOptions) {
          this.map = options.map;
          this.mapProjection = this.map.options.get('projection');
          this.rbush = options.rbush;

          this.layer = new Layer(() => null, {
            tileTransparent: true,
            tileClass: createTileClass({
              getFeaturesForTile: (tileNumber, tileZoom) => this.getFeaturesForTile(tileNumber, tileZoom),
              isDetailsVisible: () => this.isDetailsVisible,
              isNewbuildingsListing: () => this.isNewbuildingsListing,
              isResultable: () => this.isResultable,
              mapProjection: this.mapProjection,
              dpr: utilHd.getPixelRatio(),
              // @todo выпилить эксп CD-159979
              showOnlyPrice: () => this.showOnlyPrice,
            }),
            tileContainerClass: 'default#dom',
          });
        }

        private getFeaturesForTile(tileNumber: YMaps.TTileNumber, zoom: number): TFeature[] {
          const extendedTileBox = getExtendedTileBBox({
            tileNumber,
            zoom,
            includeShadow: true,
          });
          const rBushItems = this.rbush.search(extendedTileBox);

          return rBushItems.map(({ feature }) => feature);
        }
      }

      provide(PointsLayer);
    },
  );
}
