import { pathOr } from 'ramda';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { ConstructionYear } from '../../../components/advancedFilters/ConstructionYear';
import { useContext } from '../../../utils/useContext';

export const ConstructionYearContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const constructionYear = pathOr<IJsonQueryRangeValue>(
    { gte: undefined, lte: undefined },
    ['house_year', 'value'],
    jsonQuery,
  );

  const handleConstructionYearMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setConstructionYearMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleConstructionYearMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setConstructionYearMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <ConstructionYear
      constructionYear={constructionYear}
      onConstructionYearMinChange={handleConstructionYearMinChange}
      onConstructionYearMaxChange={handleConstructionYearMaxChange}
    />
  );
};
