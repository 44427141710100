import * as React from 'react';

import { TFeature } from 'shared/map-search/types/map';
import { useNewbuildingPopup } from 'shared/map-search/utils/newbuildingPins';
import { trackNewbuildingPopupShow } from 'shared/map-search/utils/tracking';

import { CLOSE_POPUP_DELAY, OPEN_POPUP_DELAY } from './constants';
import { useNewbuildingPromoPinsContext } from '../context';
export const PopupManager: React.FC = () => {
  const { popupManager, newbuildingsPromoPinsManager } = useNewbuildingPromoPinsContext();
  const [popupState, setCurrentFeature] = useNewbuildingPopup();
  const openTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const balloon = popupManager.getBalloon();

  React.useEffect(() => {
    popupManager.setState(popupState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState]);

  const handleFeatureHover = React.useCallback(
    (feature: TFeature) => {
      const newbuilding = feature.properties.newbuilding;
      const [x, y] = feature.geometry.coordinates;
      const indentType = 'XL';

      setCurrentFeature(feature);

      popupManager.setIndent(indentType);
      popupManager.open([x, y]);

      if (newbuilding) {
        trackNewbuildingPopupShow({ newbuilding });
      }
    },
    [popupManager, setCurrentFeature],
  );

  const closePopup = React.useCallback(() => {
    if (openTimeoutHandler.current) {
      clearTimeout(openTimeoutHandler.current);
    }
    if (closeTimeoutHandler.current) {
      clearTimeout(closeTimeoutHandler.current);
    }

    closeTimeoutHandler.current = setTimeout(() => {
      closeTimeoutHandler.current = null;
      setCurrentFeature(null);

      popupManager.close();
    }, CLOSE_POPUP_DELAY);
  }, [popupManager, setCurrentFeature]);

  React.useEffect(() => {
    const handleBalloonMouseEnter = (event: YMaps.IEvent) => {
      if (openTimeoutHandler.current) {
        clearTimeout(openTimeoutHandler.current);
      }
      if (closeTimeoutHandler.current) {
        clearTimeout(closeTimeoutHandler.current);
      }

      openTimeoutHandler.current = setTimeout(() => {
        openTimeoutHandler.current = null;

        const objectId = event.get<string>('objectId');
        const featureObj = newbuildingsPromoPinsManager.objectManager.objects.getById<TFeature>(objectId);

        if (!featureObj) {
          return;
        }

        const feature: TFeature = {
          ...featureObj,
          id: featureObj.properties.featureId || featureObj.id,
        };

        handleFeatureHover(feature);
      }, OPEN_POPUP_DELAY);
    };

    newbuildingsPromoPinsManager.objectManager.objects.events.add('mouseenter', handleBalloonMouseEnter);
    balloon.events.add('mouseenter', handleBalloonMouseEnter);

    return () => {
      newbuildingsPromoPinsManager.objectManager.objects.events.remove('mouseenter', handleBalloonMouseEnter);
      balloon.events.remove('mouseenter', handleBalloonMouseEnter);
    };
  });

  React.useEffect(() => {
    newbuildingsPromoPinsManager.objectManager.objects.events.add('mouseleave', closePopup);
    balloon.events.add('mouseleave', closePopup);

    return () => {
      newbuildingsPromoPinsManager.objectManager.objects.events.remove('mouseleave', closePopup);
      balloon.events.remove('mouseleave', closePopup);
    };
  }, [newbuildingsPromoPinsManager, closePopup]);

  return null;
};
