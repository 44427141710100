import * as React from 'react';
import { useSelector } from 'react-redux';

import { useMapContext } from 'shared/map-search/containers/Map/context';
import { TFeature } from 'shared/map-search/types/map';
import { IApplicationState } from 'shared/map-search/types/redux';
import { useFavoritesSubscriber, EFavoriteAction } from 'shared/map-search/utils/favorites';

import { useFeaturesContext } from '../context';

export const FavoritesUpdater: React.FC = () => {
  const { map } = useMapContext();
  const { rbush, balloonManager } = useFeaturesContext();
  const lastFavoritesAction = useFavoritesSubscriber();
  const selectedFeature = useSelector<IApplicationState, TFeature | null>(state => state.activeFeature);

  React.useEffect(() => {
    if (!selectedFeature || !lastFavoritesAction) {
      return;
    }

    const bounds = map.getBounds();
    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    const wantedRbushItem = featuresInBbox.find(rbushItem => rbushItem.feature.id === selectedFeature.id);

    if (!wantedRbushItem) {
      return;
    }

    const { feature } = wantedRbushItem;

    if (lastFavoritesAction.action === EFavoriteAction.Added) {
      feature.properties.favoriteIds = [...(feature.properties.favoriteIds || []), lastFavoritesAction.offerId];
    } else {
      feature.properties.favoriteIds =
        feature.properties.favoriteIds &&
        feature.properties.favoriteIds.filter(id => id !== lastFavoritesAction.offerId);
    }

    if (!feature.properties.isExtended) {
      balloonManager.updateBalloonProperties(selectedFeature.id, feature.properties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFavoritesAction]);

  return null;
};
