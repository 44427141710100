import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openFeature as openFeatureAction, IOpenFeatureParams } from 'shared/map-search/actions/features';
import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { MAP_SET_CENTER_OPTIONS } from 'shared/map-search/containers/Map/constants';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { IHotspotProperties, TFeature } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';
import { zoomToPrecision, precisionToMinZoom } from 'shared/map-search/utils/precision';

import { trackPinClick } from './tracking';
import { useFeaturesContext } from '../context';

interface IClickHandlersProps {
  resultsAvailable: boolean;
  detailsVisible: boolean;
}

export const ClickHandlers: React.FC<IClickHandlersProps> = ({
  resultsAvailable: isResultsAvailable,
  detailsVisible: isDetailsVisible,
}) => {
  const { config } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const deviceType = useDeviceType();
  const { map } = useMapContext();
  const { precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const isResidentialComplexesListing = useSelector<IApplicationState, boolean>(state => state.isNewbuildingsListing);
  const { hotspotsLayer, balloonManager } = useFeaturesContext();

  const openFeature = React.useCallback(
    (params: Omit<IOpenFeatureParams, 'deviceType'>) => dispatch(openFeatureAction({ ...params, deviceType })),
    [dispatch, deviceType],
  );

  const handleHotspotClick = React.useCallback(
    (event: YMaps.IEvent) => {
      const currentZoom = map.getZoom();
      const [, maxPossibleZoom] = map.zoomRange.getCurrent();
      const activeObject = event.get<YMaps.IHotspotLayerObject<IHotspotProperties>>('activeObject');
      const { originalFeature } = activeObject.getProperties();

      trackPinClick({
        isDetailsVisible,
        isExtended: !!originalFeature.properties.isExtended,
        isResidentialComplex: originalFeature.properties.hasNewobject,
        isResidentialComplexesListing,
        zoom: Math.round(currentZoom),
        newbuilding: originalFeature.properties.newbuilding,
        isSuburbanFromKp: !!originalFeature.properties.isAnyFromKp,
      });

      if (!isResultsAvailable && currentZoom < maxPossibleZoom) {
        const currentPrecision = zoomToPrecision(config, currentZoom);
        const nextPrecision = currentPrecision + 1;
        const nextPrecisionZoom = precisionToMinZoom(config, nextPrecision);
        const nextFallbackZoom = currentZoom + 2;
        const coords = originalFeature.geometry.coordinates;

        const nextZoom = Math.min(nextPrecisionZoom || nextFallbackZoom, maxPossibleZoom);
        const center: YMaps.TCoord = [coords[0], coords[1]];

        map.setCenter(center, nextZoom, MAP_SET_CENTER_OPTIONS);

        return;
      }

      openFeature({ feature: originalFeature, precision, isDetailed: isDetailsVisible });
    },
    [map, isResultsAvailable, isResidentialComplexesListing, openFeature, precision, isDetailsVisible, config],
  );

  const handleBalloonClick = React.useCallback(
    (event: YMaps.IEvent) => {
      const objectId = event.get<string>('objectId');
      const feature = balloonManager.objectManager.objects.getById<TFeature>(objectId);
      const currentZoom = map.getZoom();

      trackPinClick({
        isDetailsVisible,
        isExtended: !!feature.properties.isExtended,
        isResidentialComplex: feature.properties.hasNewobject,
        isResidentialComplexesListing,
        zoom: Math.round(currentZoom),
        newbuilding: feature.properties.newbuilding,
        isSuburbanFromKp: !!feature.properties.isAnyFromKp,
      });

      openFeature({ feature, precision, isDetailed: isDetailsVisible });
    },
    [balloonManager, map, isResidentialComplexesListing, openFeature, precision, isDetailsVisible],
  );

  React.useEffect(() => {
    hotspotsLayer.layer.events.add('click', handleHotspotClick);
    balloonManager.objectManager.objects.events.add('click', handleBalloonClick);

    return () => {
      hotspotsLayer.layer.events.remove('click', handleHotspotClick);
      balloonManager.objectManager.objects.events.remove('click', handleBalloonClick);
    };
  }, [balloonManager, handleBalloonClick, handleHotspotClick, hotspotsLayer]);

  return null;
};
