import { EFavoritesActionType, IFavoriteParameters } from './types';
import { incrementHeaderFavorites } from './utils/updateHeaderFavorites';
import { TThunkAction } from '../../types/redux';
import { zoomToPrecision } from '../../utils/precision';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const favoriteAdd = actionGenerator<EFavoritesActionType.Added, IFavoriteParameters>(EFavoritesActionType.Added);

export function addFavorite(offerId: number): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { config, logger } = context;
    const {
      mapBounds: { zoom: currentZoom },
      activeFeature: selectedFeature,
    } = getState();
    if (!currentZoom) {
      logger.error('Zoom is undefined', { domain: 'actions/favorites/addFavorite' });

      return;
    }
    if (!selectedFeature) {
      logger.error('SelectedFeature is undefined', { domain: 'actions/favorites/addFavorite' });

      return;
    }
    const currentPrecision = zoomToPrecision(config, currentZoom);

    dispatch(favoriteAdd({ offerId, currentPrecision, selectedFeatureId: selectedFeature.id }));
    incrementHeaderFavorites();
  };
}
