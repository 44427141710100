// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetNewbuildingsForPromoSliderOnMapRequest,
  TGetNewbuildingsForPromoSliderOnMapModel,
  IMappers,
  TGetNewbuildingsForPromoSliderOnMapResponse,
  IGetNewbuildingsForPromoSliderOnMapResponse,
  IGetNewbuildingsForPromoSliderOnMapResponseError,
} from './types';

export const defaultConfig: TGetNewbuildingsForPromoSliderOnMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-search',
  pathApi: '/v1/get-newbuildings-for-promo-slider-on-map/',
  requestType: 'json',
} as TGetNewbuildingsForPromoSliderOnMapModel;

export function createGetNewbuildingsForPromoSliderOnMapModel(
  parameters: IGetNewbuildingsForPromoSliderOnMapRequest,
): TGetNewbuildingsForPromoSliderOnMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingsForPromoSliderOnMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetNewbuildingsForPromoSliderOnMapRequest;
}

export async function fetchGetNewbuildingsForPromoSliderOnMap<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingsForPromoSliderOnMapOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingsForPromoSliderOnMapResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingsForPromoSliderOnMapModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingsForPromoSliderOnMapResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingsForPromoSliderOnMapResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingsForPromoSliderOnMapResponse;
}
