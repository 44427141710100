import { TReduxActions } from '../../actions';
import { EFeaturesActionType } from '../../actions/features';
import { ENewbuildingPromoFeaturesActionType } from '../../actions/newbuildingPromoFeatures';
import { EResultsActionType } from '../../actions/results';
import { INewbuildingPromoFeatures } from '../../types/redux/store/newbuildingPromoFeatures';

const defaultState: INewbuildingPromoFeatures = {
  features: {},
  visible: false,
  loading: true,
};

export function newbuildingPromoFeaturesReducer(
  state: INewbuildingPromoFeatures = defaultState,
  action: TReduxActions,
): INewbuildingPromoFeatures {
  switch (action.type) {
    case ENewbuildingPromoFeaturesActionType.SetNewbuildingPromoFeatures: {
      return {
        ...state,
        features: action.payload,
      };
    }
    case ENewbuildingPromoFeaturesActionType.SetNewbuildingPromoVisibility: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case EResultsActionType.Succeed:
    case EResultsActionType.Failed: {
      return {
        ...state,
        loading: false,
      };
    }

    case EFeaturesActionType.FeaturePropertiesUpdated: {
      const { featureId, properties } = action.payload;

      if (state.features[featureId]) {
        return {
          ...state,
          features: {
            ...state.features,
            [featureId]: {
              ...state.features[featureId],
              properties: {
                ...state.features[featureId].properties,
                ...properties,
              },
            },
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
}
