import * as QueryString from 'query-string';

import { IMapCenterZoomBounds, TMapBounds } from '../../types/mapBounds';

export function queryStringWithMapBounds(queryString: string, mapBounds: IMapCenterZoomBounds): string {
  const queryObject = QueryString.parse(queryString, { arrayFormat: 'index', parseNumbers: true, parseBooleans: true });

  queryObject['center'] = mapBounds.center.join(',');
  queryObject['zoom'] = mapBounds.zoom;

  return QueryString.stringify(queryObject, { arrayFormat: 'index' });
}

export function isCenterZoomBounds(bounds: Partial<TMapBounds>): bounds is IMapCenterZoomBounds {
  return 'center' in bounds && 'zoom' in bounds;
}

export function mapBoundsFromUrl(url: string): IMapCenterZoomBounds | null {
  const { query: queryObject } = QueryString.parseUrl(url, {
    arrayFormat: 'index',
    parseNumbers: true,
    parseBooleans: true,
  });

  const { center: centerRaw, zoom } = queryObject;
  if (typeof centerRaw === 'string' && typeof zoom === 'number') {
    const center = centerRaw
      .split(',')
      .map(part =>
        !Number.isNaN(Number(part)) && typeof part === 'string' && part.trim() !== '' ? Number(part) : null,
      );

    if (!isValidCenter(center)) {
      return null;
    }

    if (zoom < 0 || zoom > 23) {
      return null;
    }

    return {
      center,
      zoom,
    };
  }

  return null;
}

function isValidCenter(value: (number | null)[]): value is YMaps.TCoord {
  if (value.length !== 2) {
    return false;
  }

  const [lat, lng] = value;

  return lat !== null && lng !== null && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
}
