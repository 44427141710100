import { IBBox } from '../types/map/bbox';

export function convertBboxToString(bbox?: IBBox) {
  if (!bbox) {
    return '';
  }

  const { bottomRight, topLeft } = bbox;

  return `${bottomRight.lat},${topLeft.lng},${topLeft.lat},${bottomRight.lng}`;
}
