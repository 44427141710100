import {
  IInfrastructureFeatureProperties,
  IInfrastructurePointItem,
  IInfrastructureFeaturesMap,
} from '../../types/infrastructure';

export function prepareInfrastructureFeaturesMap(
  pointFeatures: IInfrastructurePointItem[],
  { icon, pinColor, iconColor }: IInfrastructureFeatureProperties,
): IInfrastructureFeaturesMap {
  return pointFeatures.reduce((result: IInfrastructureFeaturesMap, item) => {
    const [lng, lat] = item.coordinates[0];
    const id = item.id.toString();
    result[id] = {
      type: 'Feature',
      id,
      properties: {
        icon,
        pinColor,
        iconColor,
      },
      geometry: {
        type: 'Point',
        coordinates: [lat, lng],
      },
    };

    return result;
  }, {});
}
