import * as React from 'react';

import * as styles from './styles.css';
import { Banner, IBannerProps } from '../Banner';

type TDesktopBannerProps = Omit<IBannerProps, 'rootClassName'>;

export const DesktopBanner: React.FC<TDesktopBannerProps> = props => {
  return <Banner {...props} rootClassName={styles['desktop-banner']} />;
};

DesktopBanner.displayName = 'DesktopBanner';
