import { IFastFiltersUserSchema } from '../../../common/repositories/monolith-python/entities/schemas/FastFiltersUserSchema';

const AGENT_TYPES = ['realtor_based', 'realtor_prof', 'agency', 'official_representative_agency'];

export const getIsAgent = (response: IFastFiltersUserSchema) => {
  const { isAuthenticated, userType } = response;

  if (!isAuthenticated) {
    return false;
  } else {
    return Boolean(userType && AGENT_TYPES.includes(userType));
  }
};
