import * as React from 'react';

import { useSaveSearchContext } from 'shared/common/packages/SaveSearchModal';

import { SaveSearchButton } from '../../../components/Navigation/buttons';

export const SaveSearchButtonContainer: React.FC = () => {
  const { onOpen } = useSaveSearchContext();

  return <SaveSearchButton onClick={onOpen} />;
};
