import * as React from 'react';

import { ControlButton } from 'shared/map-search/components/ControlButton';
import { Ruler } from 'shared/map-search/components/Icons';

export interface IRulerButtonProps {
  map: YMaps.Map;
  isRulerEnabled: boolean;
  onClick(): void;
}

export const RulerButton: React.FC<IRulerButtonProps> = ({ map, isRulerEnabled, onClick }) => {
  React.useEffect(() => {
    if (isRulerEnabled) {
      map.behaviors.enable(['ruler']);
    } else {
      map.behaviors.disable(['ruler']);
    }

    return () => {
      map.behaviors.disable(['ruler']);
    };
  }, [map, isRulerEnabled]);

  return (
    <ControlButton active={isRulerEnabled} tooltip="Линейка" onClick={onClick}>
      <Ruler />
    </ControlButton>
  );
};
