import { IconClose16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import { ControlButton } from 'shared/map-search/components/ControlButton';

import { ICON_SIZE_MODIFIER_TO_CLASS } from './constants';
import { IDrawButtonProps } from './types';

export const DrawButton: React.FC<IDrawButtonProps> = React.forwardRef(
  (
    { active, hasPolygons, iconSizeModifier = 'M', onActivateClick, onCancelClick, icon, ...sharedProps },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const className = ICON_SIZE_MODIFIER_TO_CLASS[iconSizeModifier];

    const handleClick = React.useCallback(() => {
      if (hasPolygons) {
        onCancelClick();
      } else {
        onActivateClick();
      }
    }, [hasPolygons, onActivateClick, onCancelClick]);

    return (
      <ControlButton
        {...sharedProps}
        data-name="DrawButton"
        ref={ref}
        active={active}
        className={className}
        tooltip="Выделение области"
        onClick={handleClick}
      >
        {hasPolygons ? <IconClose16 color={active ? 'white_100' : 'primary_100'} /> : icon}
      </ControlButton>
    );
  },
);

DrawButton.displayName = 'DrawButton';
