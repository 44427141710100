import { registry } from '@cian/mf-registry/browser';

import { ISetRegionIdParameters } from 'shared/common/repositories/header-frontend/v3/get-header-microfrontend/microfrontend/api/v1/set-region-id/types';

export async function setRegionId(parameters: ISetRegionIdParameters): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (await registry().microfrontendStrict('header-frontend').api()!.ensure()).call(
    '/v3/set-region-id/',
    parameters,
  );
}
