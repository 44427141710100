import cx from 'clsx';
import * as React from 'react';

import { ControlButton } from 'shared/map-search/components/ControlButton';
import { TControlButtonSize } from 'shared/map-search/components/ControlButton/types';

import * as styles from './styles.css';

export interface IZoomInButtonProps {
  map: YMaps.Map;
  size: TControlButtonSize;
  icon: React.ReactNode;
  isMobile?: boolean;
}

export const ZoomInButton: React.FC<IZoomInButtonProps> = props => {
  const { map, size, icon, isMobile } = props;

  const handleZoomInClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const zoom = map.getZoom();
      const [, maxZoom] = map.zoomRange.getCurrent();
      if (zoom >= maxZoom) {
        return;
      }

      return map.setZoom(zoom + 1);
    },
    [map],
  );

  return (
    <ControlButton className={cx(!isMobile && styles['button'])} onClick={handleZoomInClick} size={size}>
      {icon}
    </ControlButton>
  );
};
