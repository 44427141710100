import { TGeoValue } from 'shared/common/packages/api-models/common/json_query';
import { IGeoCodedSearchDetails } from 'shared/common/repositories/monolith-cian-realty/entities/Models/GeoCodedSearchDetails';

const geoTypesMap: { [key: string]: TGeoValue['type'] } = {
  Location: 'location',
  Street: 'street',
  Road: 'highway',
  District: 'district',
  Underground: 'underground',
  House: 'house',
};

export function geoObjectDetailsToGeoValue(geoObjectDetails: IGeoCodedSearchDetails): TGeoValue {
  const { geoType, id } = geoObjectDetails;

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: (geoTypesMap as any)[geoType],
    id,
  };
}
