import * as React from 'react';

/* istanbul ignore next */
export const GeoLocationNew: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6885 0.404217C14.8721 -0.01577 16.0169 1.1178 15.6087 2.30548L11.5766 14.0351C11.1225 15.3562 9.26773 15.3938 8.76046 14.0921L6.8433 9.17259L1.95841 7.38362C0.639141 6.90047 0.648547 5.0313 1.97263 4.56147L13.6885 0.404217ZM13.3803 2.63578L3.94849 5.98253L7.74304 7.37219C8.14732 7.52025 8.46849 7.83489 8.62483 8.23604L10.1287 12.0949L13.3803 2.63578Z"
        fill="#152242"
      />
    </svg>
  );
};

GeoLocationNew.displayName = 'GeoLocationNew';
