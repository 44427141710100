import * as React from 'react';
import { flushSync } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';

import { changeBounds } from '../../../actions/mapBounds';
import { TMapBounds } from '../../../types/mapBounds';
import { TThunkDispatch, IApplicationState } from '../../../types/redux';
import { zoomToPrecision } from '../../../utils/precision';
import { useApplicationContext } from '../../ApplicationContext';
import { useMapContext } from '../context';

export const MapBoundsUpdater: React.FC = () => {
  const { config } = useApplicationContext();
  const { map } = useMapContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const storeMapBounds = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const [currentMapBounds, setCurrentMapBounds] = React.useState<TMapBounds>();

  const handleBoundsChange = React.useCallback(
    throttle(300, () => {
      const zoom = map.getZoom();

      const nextBounds: TMapBounds = {
        bounds: map.getBounds(),
        center: map.getCenter(),
        precision: zoomToPrecision(config, zoom),
        zoom,
      };

      // Нужно уносить в следующую таску/микротаску, иначе насыпает ошибки в консоли о том,
      // что flushSync работает некорректно, ведь в текущей таске уже происходит рендеринг,
      // поэтому требуется уносить рендеринг при помощи flushSync в следующую таску.
      setTimeout(() => {
        // Если flushSync убрать, то зацикливается получение кластеров (после перехода на React 18)
        flushSync(() => {
          setCurrentMapBounds(nextBounds);
          dispatch(changeBounds(nextBounds));
        });
      }, 0);
    }),
    [map, dispatch],
  );

  React.useEffect(() => {
    map.events.add('boundschange', handleBoundsChange);

    handleBoundsChange();

    return () => {
      map.events.remove('boundschange', handleBoundsChange);
    };
  }, [map, handleBoundsChange]);

  React.useEffect(() => {
    if (storeMapBounds !== currentMapBounds) {
      if (storeMapBounds.bounds) {
        map.setBounds(storeMapBounds.bounds).then(() => handleBoundsChange());
      } else if (storeMapBounds.center && storeMapBounds.zoom) {
        map.setCenter(storeMapBounds.center, storeMapBounds.zoom).then(() => handleBoundsChange());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeMapBounds]);

  return null;
};
