import { TReduxActions } from '../../actions';
import { EErrorNotificationActionType } from '../../actions/notifications';
import { INotifications } from '../../types/notifications';
import { createUniqueIdGenerator } from '../../utils/createUniqueIdGenerator';

const createUniqueId = createUniqueIdGenerator('ErrorNotification');

const defaultState: INotifications = {
  errors: [],
};

export function notificationsReducer(state: INotifications = defaultState, action: TReduxActions): INotifications {
  switch (action.type) {
    case EErrorNotificationActionType.Cleared:
      return defaultState;
    case EErrorNotificationActionType.Pushed:
      return { errors: [{ id: createUniqueId(), type: action.payload }] };
    case EErrorNotificationActionType.Removed:
      return { errors: state.errors.filter(({ id }) => id !== action.payload) };
  }

  return state;
}
