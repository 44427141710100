import * as React from 'react';
import { useSelector } from 'react-redux';

import { InfrastructureFeaturesFetcher } from './InfrastructureFeaturesFetcher';
import { MapBoundsUpdater } from './MapBoundsUpdater';
import { MapFeaturesFetcher } from './MapFeaturesFetcher';
import { MapLayers } from './MapLayers';
import { MapMarginManager } from './MapMarginManager';
import { MAP_DEFAULT_OPTIONS, MAP_DEFAULT_STATE } from './constants';
import { IMapContext, MapContext } from './context';
import * as styles from './styles.css';
import { NotDesktop } from '../../../common/packages/Responsive';
import { MODULES } from '../../constants/map';
import { selectIsInfrastructureOnMapEnabled } from '../../selectors/infrastructure';
import { IApplicationState } from '../../types/redux';
import { loadYMapsApi } from '../../utils/loadYMapsApi';
import { useApplicationContext } from '../ApplicationContext';
import { ListingButtonContainer } from '../Navigation/ListingButton';

enum EMapStatus {
  Loading = 'Loading',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export function Map() {
  const applicationContext = useApplicationContext();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const resultsStatus = useSelector((state: IApplicationState) => state.results.status);
  const initialBounds = useSelector((state: IApplicationState) => state.mapBounds);
  const isInfrastructureEnabled = useSelector(selectIsInfrastructureOnMapEnabled);

  const [, setStatus] = React.useState<EMapStatus>(EMapStatus.Loading);
  const [ymaps, setYMaps] = React.useState<YMaps.IYMaps>();
  const [map, setMap] = React.useState<YMaps.Map>();

  React.useEffect(() => {
    try {
      loadYMapsApi(MODULES).then(setYMaps);
    } catch (e) {
      setStatus(EMapStatus.Failed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!ymaps) {
      return;
    }

    const options = applicationContext.config.get<YMaps.IMapOptions>('mapSearchFrontend.ymapsMapConstructorOptions');
    const map = new ymaps.Map(
      elementRef.current as HTMLDivElement,
      {
        ...MAP_DEFAULT_STATE,
        ...initialBounds,
      },
      {
        ...MAP_DEFAULT_OPTIONS,
        ...options,
      },
    );

    setMap(map);
    setStatus(EMapStatus.Succeed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ymaps]);

  const context = React.useMemo(() => ({ ymaps, map }), [map, ymaps]);

  return (
    <>
      <div
        ref={elementRef}
        className={styles['map']}
        /** Deprecated */
        data-clusters-status={resultsStatus}
        data-results-status={resultsStatus}
      />
      {!!context.ymaps && !!context.map ? (
        <MapContext.Provider value={context as IMapContext}>
          <MapBoundsUpdater />
          <MapFeaturesFetcher />
          {isInfrastructureEnabled && <InfrastructureFeaturesFetcher />}
          <MapLayers />
          <MapMarginManager />
        </MapContext.Provider>
      ) : null}
      <NotDesktop>
        <div className={styles['toggle']}>
          <ListingButtonContainer />
        </div>
      </NotDesktop>
    </>
  );
}
