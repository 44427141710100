import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { EBanners } from '../../../constants/banners';
import { selectMapBounds } from '../../../selectors/mapBounds';
import { selectJsonQuery, selectResults } from '../../../selectors/results';
import { isFSKBannerAvailable } from '../../../utils/bannerPackages';
import { useApplicationContext } from '../../ApplicationContext';

export function useGetFskBanner(proPlusBannerType: EBanners.ProPlus | null): EBanners | null {
  const deviceType = useDeviceType();
  const jsonQuery = useSelector(selectJsonQuery);
  const results = useSelector(selectResults);
  const { zoom } = useSelector(selectMapBounds);
  const context = useApplicationContext();
  const isDesktop = deviceType === 'desktop';

  const mobileFskBannerEnabled = React.useMemo(
    () => context.config.get<boolean>('mapSearchFrontend.mobileFskBanner') || false,
    [],
  );
  const desktopFskBannerEnabled = React.useMemo(
    () => context.config.get<boolean>('mapSearchFrontend.desktopFskBanner') || false,
    [],
  );

  // Отображать ли фск баннер в качестве заглушки, когда нет промо баннеров ЖК
  const isShowWithPromoBannerOnMobile = !proPlusBannerType && !isDesktop;

  const isFSKAvailable = isFSKBannerAvailable({
    jsonQuery,
    deviceType,
    subdomain: results.subdomain,
    isFskBannerEnabled: isDesktop ? desktopFskBannerEnabled : mobileFskBannerEnabled,
    isShowWithPromoBannerOnMobile,
    zoom,
  });

  if (isFSKAvailable) {
    return EBanners.Fsk;
  }

  return null;
}
