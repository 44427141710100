import {
  IGetNewbuildingPromoPinsForMapResponse200,
  TGetNewbuildingPromoPinsForMapResponse,
} from 'shared/common/repositories/search-offers-index/v1/get-newbuilding-promo-pins-for-map';
import { ResponseError } from 'shared/map-search/utils/errors/ResponseError';

export function assertUnknownError(
  response: TGetNewbuildingPromoPinsForMapResponse,
): asserts response is IGetNewbuildingPromoPinsForMapResponse200 {
  if (response.statusCode !== 400) {
    return;
  }

  const { message, errors } = response.response;

  const mergedMessage = `${message}${
    errors &&
    typeof errors === 'object' &&
    ` (${errors
      .filter((e): e is { message: string } => 'message' in e)
      .map(e => e.message)
      .join('; ')})`
  }`;

  throw new ResponseError({
    domain: 'shared/services/newbuildingPins/assert#assertUnknownError()',
    message: mergedMessage,
    details: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors: errors as any,
    },
  });
}
