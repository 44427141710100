import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMapLayerProps extends React.ComponentPropsWithoutRef<'div'> {}

export function MapLayer(props: IMapLayerProps) {
  return <div {...props} className={cx(props.className, styles['layer'])} />;
}
