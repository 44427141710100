import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { FOfferType, isSuburban } from 'shared/common/packages/JsonQuery';

import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

export interface IDealTypeProps {
  offerType: FOfferType;
  isByHomeowner: boolean | null;
  isFromOffrep: boolean | null;
  onIsByHomeownerChange(value: boolean | null): void;
  onIsFromOffrepChange(value: boolean | null): void;
}

export const DealType: React.FC<IDealTypeProps> = ({
  offerType,
  isByHomeowner,
  isFromOffrep,
  onIsByHomeownerChange,
  onIsFromOffrepChange,
}) => {
  const isByHomeownerAvailable = (offerType & (FOfferType.Residential & ~FOfferType.FlatNew)) !== 0;
  const isFromOffrepAvailable = (offerType & FOfferType.Commercial) !== 0;
  const isSuburbanType = isSuburban(offerType);
  const label = isSuburbanType ? 'Продавец' : 'Тип сделки';

  return (
    <Filter id="advancedFilter_dealType" label={label}>
      <InlineFilterGroup>
        <InlineFilter>
          {isByHomeownerAvailable && (
            <Checkbox
              label="От собственника"
              checked={!!isByHomeowner}
              onChange={event => onIsByHomeownerChange(event.target.checked || null)}
            />
          )}
          {isFromOffrepAvailable && (
            <Checkbox
              label="От официального представителя"
              checked={!!isFromOffrep}
              onChange={event => onIsFromOffrepChange(event.target.checked || null)}
            />
          )}
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
