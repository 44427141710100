import { ca } from '@cian/analytics';

export function trackFocus() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'GeoSuggest',
    label: '',
  });
}

export function trackEmptyResults(value: string) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'NotFound',
    category: 'GeoSuggest',
    label: value,
  });
}

export function trackChange(owner: 'cian' | 'yandex', type: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Change',
    label: `${mapChangeType(type)}/${owner}`,
  });
}

function mapChangeType(type: string): string {
  switch (type) {
    case 'newbuilding':
      return 'jk';
    case 'village':
      return 'KP';
  }

  return type;
}
