import { TReduxActions } from '../../actions';
import { EFeaturesActionType } from '../../actions/features';
import { TFeatureWithPrecision } from '../../types/map';

const initialState: TFeatureWithPrecision | null = null;

export function activeFeatureReducer(
  state: TFeatureWithPrecision | null = initialState,
  action: TReduxActions,
): TFeatureWithPrecision | null {
  switch (action.type) {
    case EFeaturesActionType.FeatureSelected:
      return action.payload;
  }

  return state;
}
