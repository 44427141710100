import { trackAdsBannerTeaserEvent } from './analytics';
import { normalizePage } from './utils';
import { TAnalyticsMessagePayload } from '../../types';

const EVENT_NAMES = ['Listing_show_TGB', 'Listing_click_TGB', 'show_card'];

export function teaserHandler([, eventParams]: TAnalyticsMessagePayload, pageRaw: unknown) {
  const { sc, product, page: eventPage } = eventParams;

  const page = normalizePage(pageRaw);

  if (eventPage?.extra) {
    page.extra = {
      ...page.extra,
      ...eventPage.extra,
    };
  }

  switch (true) {
    case EVENT_NAMES.includes(product.name): {
      trackAdsBannerTeaserEvent({ sc, product, page });
      break;
    }
  }
}
