import { INewbuildingPinSchema } from '../../../common/repositories/search-offers-index/entities/newbuilding/NewbuildingPinSchema';
import { INewbuilding } from '../../types/map/newbuilding';

export const prepareNewbuilding = (newbuilding?: INewbuildingPinSchema | null): INewbuilding | null => {
  if (!newbuilding) {
    return null;
  }

  return {
    id: newbuilding.id,
    houseId: newbuilding.houseId || undefined,
    isAnyFicheringPlus: newbuilding.isAnyFicheringPlus,
  };
};
