import { debounce } from 'throttle-debounce';

import { IApplicationState } from 'shared/map-search/types/redux';

import { getBrowserHistory } from './getBrowserHistory';

function queryStringUpdaterFn(state: IApplicationState) {
  const history = getBrowserHistory();
  const isSeoUrl = state.seoUrl !== null;

  /**
   * Если сервис работает в контексте ЧПУ, то не нужно обновлять queryString
   * @todo будет удалено в рамках CD-169251
   */
  if (isSeoUrl) {
    return;
  }

  if (window.location.search.slice(1) !== state.queryString) {
    const newLocation = {
      /**
       * нужно чтобы сбросить ЧПУ в браузере
       * @todo будет удалено в рамках CD-169251
       */
      pathname: history.location.pathname.startsWith('/map') ? history.location.pathname : '/map/',
      search: `?${state.queryString}`,
      hash: history.location.hash,
      state: history.location.state,
    };

    history.replace(newLocation);
  }
}

export const queryStringUpdater = debounce(300, queryStringUpdaterFn);
