import { ILogger } from '@cian/logger';
import { equals } from 'ramda';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { objectDiff } from '../../../utils/objectDiff';

const reportedProblems: unknown[] = [];

export function assertJsonQueryValidity(logger: ILogger, ownJsonQuery: IJsonQuery, backendJsonQuery: IJsonQuery): void {
  if (!equals(ownJsonQuery, backendJsonQuery)) {
    const diff = objectDiff(ownJsonQuery, backendJsonQuery);

    if (!reportedProblems.some(equals(diff))) {
      reportedProblems.push(diff);

      logger.warning('jsonQuery фронтенда и бэкенда разошлись', {
        diff: JSON.stringify(diff),
      });
    }
  }
}
