import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGeoValueByType } from 'shared/common/packages/JsonQuery';
import { IJsonQuery, IJsonQueryNewbuilding } from 'shared/common/packages/api-models/common/json_query';
import { setNewbuildingPromoVisibility } from 'shared/map-search/actions/newbuildingPromoFeatures';
import { selectNewbuildingPromoFeatures } from 'shared/map-search/selectors/newbuildingPromoFeatures';
import { TFeature } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { filterPromoPinsByKeys } from 'shared/map-search/utils/newbuildingPins';

import { useNewbuildingPromoPinsContext } from '../context';
export const NewbuildingPromoPinsUpdater: React.FC = () => {
  const dispatch = useDispatch();
  const { newbuildingsPromoPinsManager } = useNewbuildingPromoPinsContext();

  const { bounds, zoom, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const promotedNewbuildingsMap = useSelector(selectNewbuildingPromoFeatures);
  const selectedFeature = useSelector<IApplicationState, TFeature | null>(state => state.activeFeature);
  const detailsVisiblePrecision = useSelector<IApplicationState, number>(state => state.detailsVisiblePrecision);
  const jsonQuery = useSelector<IApplicationState, IJsonQuery>(state => state.filters.jsonQuery);

  const selectedNewObjects = React.useMemo(
    () => getGeoValueByType<IJsonQueryNewbuilding>('newobject')(jsonQuery),
    [jsonQuery],
  );

  const promotedNewbuildings = React.useMemo(() => {
    const selectedPromoPinIds = Array.isArray(selectedNewObjects)
      ? selectedNewObjects.map(item => `promo_pin_${item.id}`)
      : [];

    const filteredPromotedNewbuildingsMap = filterPromoPinsByKeys(promotedNewbuildingsMap, selectedPromoPinIds);

    return Object.values(selectedPromoPinIds.length ? filteredPromotedNewbuildingsMap : promotedNewbuildingsMap);
  }, [promotedNewbuildingsMap, selectedNewObjects]);

  const isDetailsVisibleByPrecision = precision >= detailsVisiblePrecision;

  React.useEffect(() => {
    newbuildingsPromoPinsManager.setSelectedFeature(selectedFeature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  React.useEffect(() => {
    newbuildingsPromoPinsManager.setShowAnalyticsState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQuery]);

  React.useEffect(() => {
    newbuildingsPromoPinsManager.setShowAnalyticsState(true);
    newbuildingsPromoPinsManager.drawPinsWithBalloons(true, jsonQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  React.useEffect(() => {
    if (promotedNewbuildings.length) {
      newbuildingsPromoPinsManager.loadNewbuildings(promotedNewbuildings, jsonQuery);
    } else {
      newbuildingsPromoPinsManager.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotedNewbuildings]);

  React.useEffect(() => {
    if (zoom < 9 || isDetailsVisibleByPrecision) {
      dispatch(setNewbuildingPromoVisibility(false));
    } else {
      dispatch(setNewbuildingPromoVisibility(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, isDetailsVisibleByPrecision]);

  return null;
};
