import * as React from 'react';

import { EFacilityType, JsonQuery, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { LivingConditions } from '../../../components/advancedFilters/LivingConditions';
import { useContext } from '../../../utils/useContext';

export const LivingConditionsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const livingConditions = new JsonQuery(jsonQuery).getLivingConditions();

  const handleChange = React.useCallback(
    (livingConditions: NonEmptyArray<EFacilityType> | null) => {
      onChange({ action: 'setLivingConditions', arguments: [livingConditions] });
    },
    [onChange],
  );

  return <LivingConditions value={livingConditions} onChange={handleChange} />;
};
