import { IApplicationState } from '../../types/redux/store';

export const selectIsOpen = (state: IApplicationState) => {
  return state.searchResults.isOpen;
};

export const selectUrl = (state: IApplicationState) => {
  return state.searchResults.url;
};

export const selectSidebarReady = (state: IApplicationState) => {
  return state.searchResults.isReady;
};
