import { makeContextModule } from '@cian/react-utils';
import RBush from 'rbush';

import { IInfrastructureRBushItem } from 'shared/map-search/types/infrastructure';

export interface IInfrastructureFeaturesContext {
  rbush: RBush<IInfrastructureRBushItem>;
  infrastructurePointsLayer: YMaps.InfrastructurePointsLayer;
  infrastructureHTMLPointsLayer: YMaps.InfrastructureHTMLPointsLayer;
}

export const {
  Context: InfrastructureFeaturesContext,
  useContext: useInfrastructureFeaturesContext,
  withContext: withInfrastructureFeaturesContext,
} = makeContextModule<IInfrastructureFeaturesContext>({
  contextName: 'InfrastructureFeaturesContext',
});
