import { beautify } from '../../../utils/number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getLandAreaLabel(min: number | null, max: number | null): string | null {
  if (min === 0 && max === 0) {
    return SHRUG;
  }

  if (min !== null && max === null) {
    return `от ${beautify(min)} сот.`;
  } else if (min === null && max !== null) {
    return `до ${beautify(max)} сот.`;
  } else if (min !== null && max !== null) {
    return `${beautify(min)} - ${beautify(max)} сот.`;
  }

  return null;
}
