import * as React from 'react';

import { useDeviceType } from '../../utils/responsive';

export const Desktop: React.FC<React.PropsWithChildren> = ({ children }) => {
  const deviceType = useDeviceType();

  return deviceType === 'desktop' ? (children as React.ReactElement) : null;
};

Desktop.displayName = 'ResponsiveDesktop';
