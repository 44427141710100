import { UIHeading4 } from '@cian/ui-kit/typography';

import * as styles from './styles.css';

interface INewbuildingDescriptionProps {
  newbuildingName: string;
  newbuildingUrl: string;
  houseName: string | null;
  priceFrom: string | null;
  onNewbuildingClick(): void;
}

export function NewbuildingDescription({
  newbuildingName,
  newbuildingUrl,
  houseName,
  priceFrom,
  onNewbuildingClick,
}: INewbuildingDescriptionProps) {
  return (
    <>
      <a
        data-testid="newbuilding-name"
        className={styles['title']}
        href={newbuildingUrl}
        target="_blank"
        rel="noreferrer"
        onClick={onNewbuildingClick}
      >
        <UIHeading4>ЖК «{newbuildingName}»</UIHeading4>
      </a>

      <div data-testid="newbuilding-price" className={styles['subtitle']}>
        От {priceFrom}
      </div>
      {houseName && (
        <div data-testid="newbuilding-house-name" className={styles['house']}>
          {houseName}
        </div>
      )}
    </>
  );
}
