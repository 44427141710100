import { type FC, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH } from '../../../constants/css';
import { selectIsOpen } from '../../../selectors/searchResults';
import { useMapContext } from '../context';

export const MapMarginManager: FC = () => {
  const { map } = useMapContext();
  const isSidebarOpen = useSelector(selectIsOpen);
  const currentMarginRef = useRef<YMaps.map.margin.Accessor | null>(null);

  useEffect(() => {
    if (currentMarginRef.current) {
      currentMarginRef.current.remove();
    }

    if (isSidebarOpen) {
      currentMarginRef.current = map.margin.addArea({ top: 0, left: 0, width: SIDEBAR_WIDTH, height: '100%' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen]);

  return null;
};
