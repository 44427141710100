import { ILogger } from '@cian/logger/shared';
import { ITelemetryLogger } from '@cian/telemetry/shared';

import type { TYmapsLoaderEvent } from '../ymapsLoaderManager/types';

interface IDependencies {
  logger: ILogger;
  telemetry: ITelemetryLogger;
}

export function eventHandler(dependencies: IDependencies, event: TYmapsLoaderEvent) {
  const { logger, telemetry } = dependencies;

  switch (event.type) {
    case 'error': {
      logger.error(...event.payload);
      break;
    }
    case 'warning': {
      logger.warning(...event.payload);
      break;
    }
    case 'telemetry': {
      telemetry.increment(event.key);
      break;
    }
    default: {
      logger.warning('Получено неизвестное событие', {
        domain: 'browser.services.ymapsLoaderEventsConsumer.eventHandler',
      });
    }
  }
}
