import { IconPlus16 } from '@cian/ui-kit/icons';
import { Input } from '@cian/ui-kit/input';
import { InputAdornmentButton } from '@cian/ui-kit/inputAdornment';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './TagsInput.css';
import { Tags, Tag } from '../Tags';

interface ITagsInputProps {
  id: string;
  placeholder?: string;
  maxLength?: number;
  value: [string, ...string[]] | null;
  onAdd(value: string): void;
  onRemove(value: string): void;
}

export const TagsInput: React.FC<ITagsInputProps> = ({
  id,
  placeholder,
  maxLength = 30,
  value: values,
  onAdd,
  onRemove,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string>('');

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = event;

      setValue(value);
    },
    [setValue],
  );

  const handleAdd = React.useCallback(() => {
    const trimmedValue = value.trim();

    if (!trimmedValue || values?.some(v => v === trimmedValue)) {
      return;
    }

    onAdd(trimmedValue);
    setValue('');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [values, value, onAdd, setValue]);

  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleAdd();
      }
    },
    [handleAdd],
  );

  return (
    <div>
      <div id={`${id}_input`} className={cx(!!values && styles['input-container--with-tags'])}>
        <Input
          ref={inputRef}
          width={260}
          rightAdornment={
            <InputAdornmentButton
              title="Добавить тег"
              aria-controls={`${id}_input`}
              aria-keyshortcuts="Enter"
              aria-label="Добавить тег"
              onClick={handleAdd}
            >
              <IconPlus16 color="current_color" />
            </InputAdornmentButton>
          }
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
        />
      </div>
      {values && (
        <Tags id={`${id}_tags`} onRemove={onRemove}>
          {values.map(value => (
            <Tag key={value} value={value} />
          ))}
        </Tags>
      )}
    </div>
  );
};
