import * as QueryString from 'query-string';

import { TMapBounds } from '../types/mapBounds';

export class UrlWithBoundsBuilder {
  private readonly parsedUrl: QueryString.ParsedUrl;
  private readonly mapBounds: TMapBounds;

  public constructor(sourceUrl: string, mapBounds: TMapBounds) {
    this.parsedUrl = QueryString.parseUrl(sourceUrl, { arrayFormat: 'index', parseNumbers: true, parseBooleans: true });
    this.mapBounds = mapBounds;
  }

  public withCenter() {
    if (this.mapBounds.center) {
      this.parsedUrl.query['center'] = this.mapBounds.center.join(',');
    }

    return this;
  }

  public withZoom() {
    if (this.mapBounds.zoom) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.parsedUrl.query['zoom'] = this.mapBounds.zoom as any;
    }

    return this;
  }

  public withBbox() {
    if (this.mapBounds.bounds) {
      const [[latMin, lngMin], [latMax, lngMax]] = this.mapBounds.bounds;
      this.parsedUrl.query['bbox'] = `${latMin},${lngMin},${latMax},${lngMax}`;
    }

    return this;
  }

  public build() {
    return QueryString.stringifyUrl(this.parsedUrl, { arrayFormat: 'index' });
  }
}
