import { ca } from '@cian/analytics';

import { INewbuilding } from '../../types/map/newbuilding';

interface IParams {
  newbuilding: INewbuilding;
}

export function trackNewbuildingPopupShow({ newbuilding }: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    category: 'Map',
    label: 'pin_snippet',
    products: [
      {
        extra: {
          newbuilding_id: newbuilding.id,
          house_id: newbuilding.houseId,
          is_promo: true,
        },
      },
    ],
  });
}
