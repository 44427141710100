import { TThunkAction } from '../../types/redux';
import { updateFeatureProperties } from '../features';
import { markAsViewed } from '../viewed/markAsViewed';

interface IToggleFeaturesActiveStatus {
  geohashIds?: string[];
  isActive: boolean;
}

export function toggleFeaturesActiveStatus({ geohashIds, isActive }: IToggleFeaturesActiveStatus): TThunkAction {
  return (dispatch, getState) => {
    const {
      features,
      mapBounds: { precision },
    } = getState();

    if (geohashIds && geohashIds.length > 0) {
      geohashIds.map(geohash => {
        dispatch(
          updateFeatureProperties({
            featureId: geohash,
            precision,
            properties: { isActive },
          }),
        );
      });

      if (isActive) {
        geohashIds.map(geohash => {
          const feature = features[precision].features[geohash];
          dispatch(markAsViewed({ feature, precision, isDetailed: false }));
        });
      }
    }
  };
}
