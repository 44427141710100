import RBush from 'rbush';

import { getVisibleTiles } from '.';
import { IRBushItem } from '../../types/map';
import { getExtendedTileBBox } from '../tile';

interface IGetTilesToUpdateParams {
  rbushItems: IRBushItem[];
  bounds: [YMaps.TCoord, YMaps.TCoord];
  zoom: number;
  includeShadow?: boolean;
}

export function getTilesToUpdate(params: IGetTilesToUpdateParams): YMaps.TTileNumber[] {
  const { rbushItems, zoom: zoomRaw, bounds, includeShadow } = params;

  const sapling = new RBush();
  sapling.load(rbushItems);

  const zoom = Math.round(zoomRaw);
  const visibleTilesNumbers = getVisibleTiles(bounds, zoom);

  const tilesToUpdate: YMaps.TTileNumber[] = [];
  visibleTilesNumbers.forEach(tileNumber => {
    const extendedTileBox = getExtendedTileBBox({ tileNumber, zoom, includeShadow });

    if (sapling.collides(extendedTileBox)) {
      tilesToUpdate.push(tileNumber);
    }
  });

  return tilesToUpdate;
}
