// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IInfrastructureTypeSchema {
  /** Описание **/
  description?: string;
  /** Тип отрисовки **/
  drawType: EDrawType;
  /** Иконка **/
  icon: EIcon;
  /** Цвет пина **/
  iconColor?: EIconColor;
  /** ID **/
  id: number;
  /** Название **/
  name: string;
  /** Цвет пина **/
  pinColor?: EPinColor;
}
export enum EDrawType {
  /** Тепловая карта **/
  'Heatmap' = 'heatmap',
  /** Пина на карте **/
  'Pin' = 'pin',
}
export enum EIcon {
  /** Велопарковки **/
  'BikeParking' = 'bikeParking',
  /** Автобусные остановки **/
  'BusStop' = 'busStop',
  /** Зарядки электромобилей **/
  'ChargingStation' = 'chargingStation',
  /** Иконка поликлиники **/
  'Clinic' = 'clinic',
  /** Без иконки **/
  'Empty' = 'empty',
  /** Станция метро, которая откроется в будущем **/
  'FutureMetroStation' = 'futureMetroStation',
  /** Дорога, которая откроется в будущем **/
  'FutureRoad' = 'futureRoad',
  /** Будущая инфраструктура **/
  'FutureSocialObject' = 'futureSocialObject',
  /** Иконка магазина **/
  'Grocery' = 'grocery',
  /** Иконка детского сада **/
  'Nursery' = 'nursery',
  /** Парковки **/
  'Parking' = 'parking',
  /** Иконка школы **/
  'School' = 'school',
  /** Парковки для самокатов **/
  'ScooterParking' = 'scooterParking',
  /** Трамвайные остановки **/
  'TramStop' = 'tramStop',
}
export enum EIconColor {
  /** Тёмный **/
  '#152242' = '#152242',
  /** Белый **/
  '#FFFFFF' = '#FFFFFF',
}
export enum EPinColor {
  /** Синий **/
  '#2C65F6' = '#2C65F6',
  /** Серый **/
  '#35415E' = '#35415E',
  /** Зелёный **/
  '#65C039' = '#65C039',
  /** Пурпурный **/
  '#8729FF' = '#8729FF',
  /** Красный **/
  '#DB1F36' = '#DB1F36',
  /** Оранжевый **/
  '#FDC221' = '#FDC221',
}
