import * as React from 'react';
import { useSelector } from 'react-redux';

import { markAsClosed } from 'browser/map-search/utils/banner';

import { useRenderProp } from './utils/useRenderProp';
import { RealtorReportBanner } from '../../components/RealtorReportBanner';
import { EBanners, EXPIRES_TIME_FOR_REALTOR_REPORT, REALTOR_REPORT_CLOSED_COOKIE_KEY } from '../../constants/banners';
import { selectIsOpen } from '../../selectors/searchResults';

const REALTOR_REPORT_URL = '/kalkulator-nedvizhimosti/';

interface IRealtorReportBannerContainerProps {
  open: boolean;
  onClose(): void;
}

export const RealtorReportBannerContainer: React.FC<IRealtorReportBannerContainerProps> = props => {
  const { onClose, open } = props;
  const isSearchResultsOpen = useSelector(selectIsOpen);
  const render = useRenderProp();

  const handleClose = React.useCallback(() => {
    onClose();
    markAsClosed(REALTOR_REPORT_CLOSED_COOKIE_KEY, EBanners.RealtorReport, EXPIRES_TIME_FOR_REALTOR_REPORT);
  }, [onClose]);

  /**
   * Если открыли выдачу по пину и баннер отображался - скрываем баннер
   */
  React.useEffect(() => {
    if (open && isSearchResultsOpen) {
      onClose();
    }
  }, [open, isSearchResultsOpen, onClose]);

  if (!open) {
    return null;
  }

  return <RealtorReportBanner onClose={handleClose} redirectUrl={REALTOR_REPORT_URL} render={render} />;
};
