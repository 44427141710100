import { EFeaturesActionType } from './types';
import { updateFeatureProperties } from './updateFeatureProperies';
import { TFeature, TFeatureWithPrecision } from '../../types/map';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { toggleFeaturesActiveStatus } from '../newbuildingPolygon';

export const setSelectedFeature = actionGenerator<EFeaturesActionType.FeatureSelected, TFeatureWithPrecision>(
  EFeaturesActionType.FeatureSelected,
);

export function selectFeature(feature: TFeature, precision: number): TThunkAction {
  return (dispatch, getState) => {
    const {
      activeFeature: selectedFeature,
      activeNewbuildingPolygon: { geohashIds },
    } = getState();

    dispatch(toggleFeaturesActiveStatus({ geohashIds, isActive: false }));

    dispatch(setSelectedFeature({ ...feature, precision }));

    if (selectedFeature) {
      dispatch(
        updateFeatureProperties({
          featureId: selectedFeature.id,
          precision: selectedFeature.precision,
          properties: { isActive: false },
        }),
      );
    }
    dispatch(updateFeatureProperties({ featureId: feature.id, precision, properties: { isActive: true } }));
  };
}
