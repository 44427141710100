import { IResponseResultSchema } from 'node/common/repositories/favorites/entities/newbuilding_favorites/ResponseResultSchema';

import {
  fetchIsNewbuildingFavorites,
  TIsNewbuildingFavoritesResponse,
} from 'shared/common/repositories/favorites/v1/is-newbuilding-favorites';

import { IApplicationContext } from '../../types/applicationContext';
import { ResponseError } from '../../utils/errors/ResponseError';

export async function getIsNewbuildingInFavorites(
  context: IApplicationContext,
  newbuildingId: number,
): Promise<boolean> {
  const { httpApi, logger } = context;

  try {
    const response: TIsNewbuildingFavoritesResponse = await fetchIsNewbuildingFavorites({
      httpApi,
      parameters: {
        ids: [newbuildingId],
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'favorites#getIsNewbuildingInFavorites',
        message: response.response.message || '',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: response.response.errors as any,
        },
      });
    }

    const result: IResponseResultSchema | undefined = (response.response.results || [])[0];

    return (result && result.id === newbuildingId && result.isFavorite) || false;
  } catch (error) {
    logger.error(error);

    throw error;
  }
}
