import * as React from 'react';

import { EFacilityType, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IBathType {
  label: string;
  value: EFacilityType | null;
}

const BATH_TYPES: IBathType[] = [
  { label: 'Неважно', value: null },
  { label: 'Ванна', value: EFacilityType.Bath },
  { label: 'Душевая кабина', value: EFacilityType.Shower },
];

interface IBathTypeProps {
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const BathType: React.FC<IBathTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_bathType" label="Ванна/Душ">
      <CheckboxButtonGroupUnselectable options={BATH_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
