import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { OnlineBookingContainer } from '../../containers/advancedFilters/OnlineBookingContainer';
import { IFilter } from '../../types/advancedFilters';
import { IExperimentalFeatures } from '../../types/filters';

export const onlineBooking: IFilter = {
  component: OnlineBookingContainer,
  availability(_jsonQuery: IJsonQuery, features: IExperimentalFeatures): boolean {
    return features.onlineBookingEnabled;
  },
};
