import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { NonEmptyArray, FOfferType } from 'shared/common/packages/JsonQuery';
import { ESaleType } from 'shared/common/packages/api-models/common/json_query';

import { getSaleTypesList } from './helpers';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface ISaleTypeProps {
  offerType: FOfferType;
  saleType: NonEmptyArray<ESaleType> | null;
  mortgageAvailable: boolean | null;
  onSaleTypeChange(value: NonEmptyArray<ESaleType> | null): void;
  onMortgageAvailableChange(value: boolean | null): void;
}

export const SaleType: React.FC<ISaleTypeProps> = ({
  offerType,
  saleType,
  mortgageAvailable,
  onSaleTypeChange,
  onMortgageAvailableChange,
}) => {
  const saleTypes = getSaleTypesList(offerType);

  return (
    <Filter id="advancedFilter_saleType" label="Тип продажи">
      <InlineFilterGroup>
        <InlineFilter>
          <CheckboxButtonGroupUnselectable options={saleTypes} value={saleType} onChange={onSaleTypeChange} />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Возможна ипотека"
            checked={!!mortgageAvailable}
            onChange={event => onMortgageAvailableChange(event.target.checked ? true : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
