import RBush from 'rbush';

import { getVisibleTiles } from './getVisibleTiles';
import { IInfrastructureRBushItem } from '../../types/infrastructure';
import { getInfrastructureFeaturesExtendedTileBBox } from '../tile/getInfrastructureFeaturesExtendedTileBBox';

interface IGetExtraTilesToUpdateParams {
  rbushItems: IInfrastructureRBushItem[];
  bounds: [YMaps.TCoord, YMaps.TCoord];
  zoom: number;
}

export function getInfrastructurePointsTilesToUpdate(params: IGetExtraTilesToUpdateParams): YMaps.TTileNumber[] {
  const { rbushItems, zoom: zoomRaw, bounds } = params;

  const sapling = new RBush();
  sapling.load(rbushItems);

  const zoom = Math.round(zoomRaw);
  const visibleTilesNumbers = getVisibleTiles(bounds, zoom);

  const tilesToUpdate: YMaps.TTileNumber[] = [];
  visibleTilesNumbers.forEach(tileNumber => {
    const extendedTileBox = getInfrastructureFeaturesExtendedTileBBox({ tileNumber, zoom });

    if (sapling.collides(extendedTileBox)) {
      tilesToUpdate.push(tileNumber);
    }
  });

  return tilesToUpdate;
}
