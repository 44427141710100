import { configureStore } from '@reduxjs/toolkit';

import { reducer } from '../../reducers';
import { IApplicationContext } from '../../types/applicationContext';
import { IApplicationStateInitial } from '../../types/redux';

/**
 * Хелпер для создания Redux стора
 * @param preloadedState IApplicationState - предзагруженное состояние
 * @param context IApplicationContext - контекст приложения
 */
export function createReduxStore(preloadedState: IApplicationStateInitial, context: IApplicationContext) {
  const projectName = context.config.getStrict<string>('projectName');

  return configureStore({
    devTools: process.env.NODE_ENV === 'development' && process.env.IS_BROWSER_BUILD ? { name: projectName } : false,
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: { extraArgument: context } }),
  });
}
