import { LOCAL_STORAGE_KEY, VIEWED_CLUSTERS_LIMIT } from './constants';

export function markAsViewed(geoHash: string) {
  const viewedClusters = localStorage.getItem(LOCAL_STORAGE_KEY)?.split(',') || [];

  if (viewedClusters.length >= VIEWED_CLUSTERS_LIMIT) {
    viewedClusters.shift();
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, [...viewedClusters, geoHash].join(','));
}
