import {
  dealTypeFromJsonQuery as jqDealTypeFromJsonQuery,
  offerTypeFromJsonQuery as jqOfferTypeFromJsonQuery,
  FDealType,
  FOfferType,
} from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { EDealType, EOfferType } from 'shared/common/repositories/geo-suggest/v2/suggest';

export function dealTypeFromJsonQuery(jsonQuery: IJsonQuery): EDealType {
  const dealType = jqDealTypeFromJsonQuery(jsonQuery);

  return (dealType & FDealType.Sale) !== 0 ? EDealType.Sale : EDealType.Rent;
}

export function offerTypeFromJsonQuery(jsonQuery: IJsonQuery): EOfferType | null {
  const offerType = jqOfferTypeFromJsonQuery(jsonQuery);

  switch (true) {
    case offerType === FOfferType.FlatNew: {
      return EOfferType.Newobject;
    }

    case (offerType & FOfferType.Urban) !== 0:
      return EOfferType.Flat;

    case (offerType & FOfferType.Suburban) !== 0:
      return EOfferType.Suburban;

    case (offerType & FOfferType.Commercial) !== 0:
      return EOfferType.Commercial;
  }

  return null;
}
