import { RADIUS, ECCENTRICITY, PI_OVER_180 } from './constants';
import { restrict, cycleRestrict } from '../math';

const EPSILON = 1e-10;

export function toMercator(point: YMaps.TCoord): [number, number] {
  return [longitudeToX(point[1]), latitudeToY(point[0])];
}

function latitudeToY(lat: number): number {
  const latitude = restrict(lat, -90 + EPSILON, 90 - EPSILON) * PI_OVER_180;
  const esinLat = ECCENTRICITY * Math.sin(latitude);

  const tanTemp = Math.tan(Math.PI * 0.25 + latitude * 0.5);
  const powTemp = Math.pow(Math.tan(Math.PI * 0.25 + Math.asin(esinLat) * 0.5), ECCENTRICITY);
  const U = tanTemp / powTemp;

  return RADIUS * Math.log(U);
}

function longitudeToX(lng: number): number {
  const longitude = cycleRestrict(lng * PI_OVER_180, -Math.PI, Math.PI);

  return RADIUS * longitude;
}
