import { Image } from '@cian/ui-kit/image';

interface INewbuildingImageProps {
  name: string;
  imageUrl: string;
}

export function NewbuildingImage({ imageUrl, name }: INewbuildingImageProps) {
  return (
    <Image
      data-testid="newbuilding-image"
      preloader
      src={imageUrl}
      alt={name}
      width="100%"
      height={180}
      borderRadius={0}
      objectFit="cover"
    />
  );
}
