import * as React from 'react';

import * as styles from './styles.css';

interface IPageLayoutProps {
  navigation: React.ReactNode;
  sidebar: React.ReactNode;
  map: React.ReactNode;
}

export function PageLayout(props: IPageLayoutProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['navigation-container']}>{props.navigation}</div>
      <div className={styles['map-container']}>
        {props.sidebar}
        {props.map}
      </div>
    </div>
  );
}
