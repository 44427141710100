import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IFinishing {
  label: string;
  value: boolean | null;
}

const FINISHING: IFinishing[] = [
  { label: 'Неважно', value: null },
  { label: 'Без отделки', value: false },
  { label: 'С отделкой', value: true },
];

interface IFinishingProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const Finishing: React.FC<IFinishingProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_finishing" label="Отделка">
      <RadioButtonGroup options={FINISHING} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
