import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';

import { IntervalTimer } from './intervalTimer';
import {
  changeActiveBannerIndex,
  changeBannerStatus,
  fetchNewbuildingsForPromoInventory,
} from '../../../actions/promoInventory';
import { EBanners, FETCH_PROMO_NEWBUILDINGS_DELAY, PROMO_BANNER_SWITCH_DELAY } from '../../../constants/banners';
import { selectMapBounds } from '../../../selectors/mapBounds';
import {
  selectActiveBannerIndex,
  selectBannerStatus,
  selectNewbuildingsForPromoInventory,
  selectNewbuildingsForPromoInventoryStatus,
  selectIsForceClosedBanner,
} from '../../../selectors/promoInventory';
import { EBannerStatus, ENewbuildingsForPromoInventoryStatus } from '../../../types/promoInventory';
import { TThunkDispatch } from '../../../types/redux';
import { useApplicationContext } from '../../ApplicationContext';

let wasFirstFetching = false;

export function useProPlusBanner(): EBanners.ProPlus | null {
  const { config } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const deviceType = useDeviceType();
  const mapBounds = useSelector(selectMapBounds);
  const isForceClosedBanner = useSelector(selectIsForceClosedBanner);
  const newbuildings = useSelector(selectNewbuildingsForPromoInventory);
  const bannerStatus = useSelector(selectBannerStatus);
  const fetchingStatus = useSelector(selectNewbuildingsForPromoInventoryStatus);
  const activeBannerIndex = useSelector(selectActiveBannerIndex);
  const fetchPromoNewbuildingsDelay = React.useMemo<number>(
    () => config.get<number>('mapSearchFrontend.fetchPromoNewbuildingsDelay') || FETCH_PROMO_NEWBUILDINGS_DELAY,
    // Не добавляю конфиг в зависимость, т.к. конфиг не меняется
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const promoBannerSwitchDelay = React.useMemo<number>(
    () => config.get<number>('mapSearchFrontend.promoBannerSwitchDelay') || PROMO_BANNER_SWITCH_DELAY,
    // Не добавляю конфиг в зависимость, т.к. конфиг не меняется
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isMobileNewbuildingPromoBannerEnabled = React.useMemo(
    () => (config.get<boolean>('mapSearchFrontend.mobileNewbuildingPromoBanner') && deviceType !== 'desktop') || false,
    [],
  );

  const getNewbuildings = React.useCallback(() => dispatch(fetchNewbuildingsForPromoInventory()), []);

  const throttleGetNewbuildings = React.useCallback(throttle(fetchPromoNewbuildingsDelay, getNewbuildings), []);

  /**
   * Добавляем каунтер для показа следующего баннера раз в определенный интервал
   * Если номер текущего баннера больше, чем длина массива с ЖК, тогда паузим интервал
   * Пауза нужна для того, чтобы выйти из данного хендлера
   */
  const handleUpdateActiveBanner = React.useCallback(() => {
    const newbuildingsLength = newbuildings.length;

    if (activeBannerIndex === null) {
      return;
    }

    if (activeBannerIndex > newbuildingsLength - 1) {
      dispatch(changeActiveBannerIndex(0));
      dispatch(changeBannerStatus(EBannerStatus.Paused));

      return;
    }

    dispatch(changeActiveBannerIndex(activeBannerIndex + 1));
  }, [activeBannerIndex, dispatch, newbuildings.length]);

  /**
   * Делаем запрос данных при первом открытии карты
   * Затем на любое изменение mapBounds делаем запрос раз в fetchPromoNewbuildingsDelay сек
   */
  React.useEffect(() => {
    if (isForceClosedBanner) {
      return;
    }

    if (!wasFirstFetching) {
      getNewbuildings();
      wasFirstFetching = true;
    } else {
      throttleGetNewbuildings();
    }
  }, [getNewbuildings, mapBounds, throttleGetNewbuildings]);

  React.useEffect(() => {
    const interval = new IntervalTimer(handleUpdateActiveBanner, promoBannerSwitchDelay);

    interval.start();

    if (!newbuildings.length) {
      interval.clear();
    }

    if (bannerStatus === EBannerStatus.Paused) {
      interval.pause();
    }

    if (bannerStatus === EBannerStatus.Active) {
      interval.resume();
    }

    return () => {
      interval.clear();
    };
  }, [bannerStatus, handleUpdateActiveBanner, mapBounds, newbuildings, promoBannerSwitchDelay]);

  if (fetchingStatus === ENewbuildingsForPromoInventoryStatus.Initial) {
    return EBanners.ProPlus;
  }

  if (!newbuildings.length) {
    return null;
  }

  /**
   * Если текущий канутер баннера больше, чем длина списка ЖК
   * То показываем баннер Заявок (на мобилке скипаем пока баннера заявок)
   * Через promoBannerSwitchDelayTime ставим каунтер на первый баннер
   */
  if (activeBannerIndex !== null && activeBannerIndex > newbuildings.length - 1) {
    const delay = isMobileNewbuildingPromoBannerEnabled ? 0 : promoBannerSwitchDelay;
    setTimeout(() => {
      dispatch(changeActiveBannerIndex(0));
      dispatch(changeBannerStatus(EBannerStatus.Active));
    }, delay);

    return null;
  }

  return EBanners.ProPlus;
}
