import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';

export enum EResultsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IResultsState {
  status: EResultsStatus;
  version?: number;
  subdomain: string;
  jsonQuery: IJsonQuery;
}
