import { getConfig } from '@cian/config/browser';
import * as React from 'react';

import { getTermValue } from 'shared/common/packages/JsonQuery';

import { FlatShare } from '../../../components/advancedFilters/FlatShare';
import { useContext } from '../../../utils/useContext';

export const FlatShareContainer: React.FC = () => {
  const config = getConfig();
  const { jsonQuery, onChange } = useContext();
  const flatShare = getTermValue('flat_share')(jsonQuery);
  const isSearchFlatShareEnabled = config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled');

  const handleChange = React.useCallback(
    (flatShareType: number | null) => {
      onChange({ action: 'setFlatShare', arguments: [flatShareType] });
    },
    [onChange],
  );

  if (!isSearchFlatShareEnabled) {
    return null;
  }

  return <FlatShare value={flatShare} onChange={handleChange} />;
};
