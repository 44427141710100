import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { ERoomsTotal } from 'shared/common/packages/api-models/common/json_query';

import { FlatRooms } from '../../../components/advancedFilters/FlatRooms';
import { useContext } from '../../../utils/useContext';

export const FlatRoomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const rooms = getTermsValue('rooms_count')(jsonQuery);

  const handleChange = React.useCallback(
    (value: NonEmptyArray<ERoomsTotal> | null) => {
      onChange({ action: 'setRoomsCount', arguments: [value] });
    },
    [onChange],
  );

  return <FlatRooms value={rooms as NonEmptyArray<ERoomsTotal> | null} onChange={handleChange} />;
};
