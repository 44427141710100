import { isAvailableRegion } from 'shared/common/packages/JsonQuery';

import { DemolitionContainer } from '../../containers/advancedFilters/DemolitionContainer';
import { IFilter } from '../../types/advancedFilters';
import { MOSCOW_ID } from '../regions';

export const demolition: IFilter = {
  availability: isAvailableRegion([MOSCOW_ID]),
  component: DemolitionContainer,
};
