import RBush from 'rbush';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsWebGlRendering, selectLargePinsZoom, selectMinZoom } from 'shared/map-search/selectors/infrastructure';
import { IInfrastructureRBushItem } from 'shared/map-search/types/infrastructure';

import { InfrastructureFeaturesCounter } from './InfrastructureFeaturesCounter';
import { InfrastructureFeaturesUpdater } from './InfrastructureFeaturesUpdater';
import { InfrastructureHTMLFeaturesUpdater } from './InfrastructureHTMLFeaturesUpdater';
import { InfrastructureFeaturesContext, IInfrastructureFeaturesContext } from './context';
import { useMapContext } from '../../context';

export interface IInfrastructureFeaturesApi {
  init(): void;
  destroy(): void;
}

export const InfrastructureFeatures = React.forwardRef((_, ref) => {
  const { ymaps, map } = useMapContext();
  const rbushRef = React.useRef(new RBush<IInfrastructureRBushItem>());
  const minZoom = useSelector(selectMinZoom);
  const largePinsZoom = useSelector(selectLargePinsZoom);
  const isWebGlRendering = useSelector(selectIsWebGlRendering);
  const infrastructurePointsLayerRef = React.useRef(
    new ymaps.InfrastructurePointsLayer({
      map,
      rbush: rbushRef.current,
    }),
  );

  const infrastructureHTMLPointsLayerRef = React.useRef(
    new ymaps.InfrastructureHTMLPointsLayer({
      map,
      rbush: rbushRef.current,
      minZoom,
      largePinsZoom,
    }),
  );

  React.useImperativeHandle(
    ref,
    () => ({
      init: () => {
        if (isWebGlRendering) {
          map.layers.add(infrastructurePointsLayerRef.current.layer);
        } else {
          map.geoObjects.add(infrastructureHTMLPointsLayerRef.current.objectManager);
        }
      },
      destroy: () => {
        if (isWebGlRendering) {
          map.layers.remove(infrastructurePointsLayerRef.current.layer);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const context = React.useMemo<IInfrastructureFeaturesContext>(
    () => ({
      rbush: rbushRef.current,
      infrastructurePointsLayer: infrastructurePointsLayerRef.current,
      infrastructureHTMLPointsLayer: infrastructureHTMLPointsLayerRef.current,
    }),
    [],
  );

  return (
    <InfrastructureFeaturesContext.Provider value={context}>
      {isWebGlRendering && <InfrastructureFeaturesUpdater />}
      {!isWebGlRendering && <InfrastructureHTMLFeaturesUpdater />}
      <InfrastructureFeaturesCounter />
    </InfrastructureFeaturesContext.Provider>
  );
});

InfrastructureFeatures.displayName = 'InfrastructureFeatures';
