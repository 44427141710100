import * as R from 'ramda';

import { DEFAULT_LONGTERM_RENT_OPTION, LONGTERM_RENT_OPTIONS } from './constants';
import { getTermValue, resetTerms as resetJsonQueryTerms, setTerm } from './helpers';
import { TJsonQuery, TJsonQueryOptionalKeys } from './types';

export function resetTerms(jsonQuery: TJsonQuery) {
  return (terms: TJsonQueryOptionalKeys[]): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    if (terms.includes('for_day')) {
      const rentTime = getTermValue('for_day')(nextJsonQuery);
      if (rentTime !== null && LONGTERM_RENT_OPTIONS.includes(rentTime)) {
        nextJsonQuery = setTerm('for_day')(nextJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
      }
    }

    const ignoredTerms = ['for_day'];
    const termsToReset = terms.filter(t => !ignoredTerms.includes(t));
    nextJsonQuery = resetJsonQueryTerms(termsToReset)(nextJsonQuery);

    return nextJsonQuery;
  };
}
