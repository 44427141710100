import * as React from 'react';

import { EWindowsType } from 'shared/common/packages/api-models/common/json_query';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IWindows {
  label: string;
  value: EWindowsType | null;
}

const WINDOWS_TYPES: IWindows[] = [
  { label: 'Неважно', value: null },
  { label: 'Во двор', value: EWindowsType.Courtyard },
  { label: 'На улицу', value: EWindowsType.Street },
];

interface IWindowsProps {
  value: EWindowsType | null;
  onChange(value: EWindowsType | null): void;
}

export const Windows: React.FC<IWindowsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_windows_type" label="Вид из окна">
      <RadioButtonGroup options={WINDOWS_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
