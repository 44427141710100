import * as React from 'react';

import * as styles from './TagsList.css';
import { ITagProps } from '../Tag';

interface ITagsProps {
  tags: React.ReactElement<ITagProps>[];
}

export const TagsList: React.FC<ITagsProps> = ({ tags }) => (
  <>
    {tags.map((tag, index) => {
      return (
        <div key={`tag_${index}`} className={styles['tag']}>
          {tag}
        </div>
      );
    })}
  </>
);
