import * as React from 'react';

import * as styles from './ArrowButton.css';
import { IArrowButtonProps } from './types';

export const ArrowButton: React.FC<React.PropsWithChildren<IArrowButtonProps>> = ({ onClick, children }) => {
  return (
    <button type="button" className={styles['button']} onClick={onClick}>
      {children}
    </button>
  );
};
