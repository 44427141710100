import { FDealType, FOfferType, isAvailable, isAvailableStrict } from 'shared/common/packages/JsonQuery';

import { DiscountPromoSearchTypeContainer } from '../../containers/advancedFilters/DiscountPromoSearchTypeContainer';
import { IFilter } from '../../types/advancedFilters';

export const discountPromoSearchType: IFilter = {
  component: DiscountPromoSearchTypeContainer,
  availability: (jsonQuery, _, config) => {
    // TODO: Удалить развилку в CD-219236.
    const isMixedListingEnabled = !!config.get('newbuildingPromoSearchTypes.mixedListingEnabled');
    const availableFunction = isMixedListingEnabled ? isAvailable : isAvailableStrict;

    return availableFunction(FDealType.Sale, FOfferType.FlatNew)(jsonQuery);
  },
};
