import { hydrateRoot } from 'react-dom/client';

import { Application } from 'shared/map-search/containers/App';
import { IApplicationContext } from 'shared/map-search/types/applicationContext';
import { TReduxStore } from 'shared/map-search/types/redux';

interface IDependencies {
  store: TReduxStore;
  context: IApplicationContext;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context } = dependencies;

  const projectName = context.config.getStrict<string>('projectName');

  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(rootElement, <Application context={context} reduxStore={store} />);
}
