import * as React from 'react';

import { ESuburbanWCType } from 'shared/common/packages/api-models/common/json_query';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IBathroom {
  label: string;
  value: ESuburbanWCType | null;
}

const BATHROOM_TYPES: IBathroom[] = [
  { label: 'Неважно', value: null },
  { label: 'На улице', value: ESuburbanWCType.Outside },
  { label: 'В доме', value: ESuburbanWCType.Inside },
];

interface IBathroomProps {
  value: ESuburbanWCType | null;
  onChange(value: ESuburbanWCType | null): void;
}

export const SuburbanBathroom: React.FC<IBathroomProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_suburbanBathroom" label="Санузел">
      <RadioButtonGroup options={BATHROOM_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
