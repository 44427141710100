import * as React from 'react';

import { getRangeValue } from 'shared/common/packages/JsonQuery';

import { MAX_VALUE, MIN_VALUE, DEFAULT_VALUE } from './constants';
import { getNormalizedValue, useHandleChange } from './utils';
import { Beds } from '../../../components/filters/Beds';
import { useContext } from '../../../utils/useContext';

export const BedsContainer: React.FC = () => {
  const { jsonQuery } = useContext();
  const bedrooms = getRangeValue('beds_count')(jsonQuery);
  const handleChange = useHandleChange();

  const value = getNormalizedValue(bedrooms?.gte) || DEFAULT_VALUE;

  return <Beds value={value} onChange={handleChange} min={MIN_VALUE} max={MAX_VALUE} />;
};
