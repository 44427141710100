import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InfrastructureLegendContainer } from './InfrastructureLegendContainer';
import { trackLayerSelect } from './tracking';
import { setActiveType } from '../../../actions/infrastructure';
import { setIsDropdownOpen } from '../../../actions/infrastructure/setIsDropdownOpen';
import { InfrastructureControl, InfrastructureDropdown } from '../../../components/Navigation/Infrastructure';
import {
  selectActiveType,
  selectIsDropdownOpen,
  selectIsInfrastructureOnMapEnabled,
  selectTypes,
} from '../../../selectors/infrastructure';
import { IInfrastructureType } from '../../../types/infrastructure';

export function InfrastructureDropdownContainer() {
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const isOpen = useSelector(selectIsDropdownOpen);

  const dispatch = useDispatch();
  const activeLayer = useSelector(selectActiveType);
  const layers = useSelector(selectTypes);
  const isInfrastructureOnMapEnabled = useSelector(selectIsInfrastructureOnMapEnabled);

  const handleChangeLayer = React.useCallback(
    (layer: IInfrastructureType | null) => {
      if (layer === null) {
        dispatch(setActiveType(undefined));
      } else if (layer.id !== activeLayer?.id) {
        dispatch(setActiveType(layer.id));
        trackLayerSelect(layer.name);
      }
    },
    [activeLayer?.id, dispatch],
  );

  const handleClose = React.useCallback(() => dispatch(setIsDropdownOpen(false)), []);

  if (!isInfrastructureOnMapEnabled || layers.length === 0) {
    return null;
  }

  return (
    <InfrastructureDropdown open={isOpen} insideRefs={[popoverRef]} onClose={handleClose}>
      <InfrastructureControl
        legend={<InfrastructureLegendContainer />}
        activeType={activeLayer?.id}
        types={layers}
        onChange={handleChangeLayer}
        ref={popoverRef}
      />
    </InfrastructureDropdown>
  );
}
