import { UIText3 } from '@cian/ui-kit';

import { IUndergroundInfo } from '../../../types/promoInventory';
import { SubwayInfoContainer } from '../SubwayInfoContainer';

interface IAddressInfoProps {
  subwayInfo?: IUndergroundInfo | null;
  address: string;
}

export function AddressInfo({ address, subwayInfo }: IAddressInfoProps) {
  if (!subwayInfo) {
    return (
      <UIText3 display="block" color="gray60_100">
        {address}
      </UIText3>
    );
  }

  return <SubwayInfoContainer subwayInfo={subwayInfo} />;
}
