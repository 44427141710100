import * as React from 'react';

import { useContext } from 'shared/common/packages/Filters/shared/utils/useContext';

export const useHandleChange = () => {
  const { onChange, onApply } = useContext();

  return React.useCallback(
    (value: number) => {
      const gte = value;
      const lte = null;
      onChange({ action: 'setBeds', arguments: [gte > 1 ? gte : null, lte] });
      onApply();
    },
    [onApply, onChange],
  );
};
