import { TAuthenticationModalType } from 'shared/map-search/types/authenticationModal';
import { ITypedReduxAction } from 'shared/map-search/types/redux/actionType';

export enum ERequestAuthenticationActionType {
  Modal = 'requestAuthentication/modal',
}

type TRequestAuthenticationModal = ITypedReduxAction<ERequestAuthenticationActionType.Modal, TAuthenticationModalType>;

export type TRequestAuthenticationReduxActions = TRequestAuthenticationModal;
