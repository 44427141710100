import { TReduxActions } from '../../actions';
import { EMapBoundsActionType } from '../../actions/mapBounds';
import { EResultsActionType } from '../../actions/results';
import { isCenterZoomBounds, queryStringWithMapBounds } from '../../utils/mapBounds';

const initialState = null;

export function extendedQueryStringReducer(state: string | null = initialState, action: TReduxActions): string | null {
  switch (action.type) {
    case EResultsActionType.Succeed:
      return action.payload.extendedQueryString || state;
    case EMapBoundsActionType.Changed:
      if (!isCenterZoomBounds(action.payload)) {
        return state;
      }

      return queryStringWithMapBounds(state || '', action.payload);
    default:
      return state;
  }
}
