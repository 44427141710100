import { TFeature } from 'shared/map-search/types/map';

interface IOptions {
  isResultsAvailable?: boolean;
  isDetailsVisible?: boolean;
}

export function getPopupIndent(feature: TFeature, options: IOptions = {}): YMaps.TPopupIndent {
  const { isDetailsVisible, isResultsAvailable } = options;
  let indentType: YMaps.TPopupIndent = 'S';

  if (isDetailsVisible) {
    indentType = 'XL';
  } else if (isResultsAvailable) {
    indentType = feature.properties.count === 1 ? 'M' : 'L';
  }

  return indentType;
}
