import { ECallPopupActionType } from './types';
import { INewbuildingContacts } from '../../types/phone/phone';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export const openPhoneAction = actionGenerator<ECallPopupActionType.Open, INewbuildingContacts>(
  ECallPopupActionType.Open,
);
export const closePhoneAction = actionGenerator<ECallPopupActionType.Close>(ECallPopupActionType.Close);

export function openPhone(phoneInfo: INewbuildingContacts): TThunkAction<Promise<void>> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, _getState, _context) => {
    dispatch(openPhoneAction(phoneInfo));
  };
}
