import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import combine from '@turf/combine';
import { featureCollection, BBox, MultiPolygon, Polygon, FeatureCollection, Feature } from '@turf/helpers';
import { map, compose, pipe, flatten, splitEvery } from 'ramda';

export const combineBboxes = pipe<
  [YMaps.TCoord, YMaps.TCoord][],
  Feature<Polygon>[],
  FeatureCollection<Polygon>,
  FeatureCollection<MultiPolygon>,
  BBox,
  [YMaps.TCoord, YMaps.TCoord]
>(
  map(compose(bboxPolygon, flatten)),
  featureCollection,
  combine as (fc: FeatureCollection<Polygon>) => FeatureCollection<MultiPolygon>,
  bbox,
  splitEvery(2) as unknown as (list: number[]) => [YMaps.TCoord, YMaps.TCoord],
);
