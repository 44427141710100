import * as QueryString from 'query-string';
import * as React from 'react';

import { FOfferType } from 'shared/common/packages/JsonQuery';

import { OfferType } from '../../../components/filters/OfferType';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const OfferTypeContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply, features } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const [commercialLink, setCommercialLink] = React.useState('#');

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action: 'setOfferType', arguments: [offerType] });
      onApply('offerType');
    },
    [onChange, onApply],
  );

  React.useEffect(() => {
    setCommercialLink(getCommercialLink());
  }, [jsonQuery]);

  return (
    <OfferType
      isSearchFlatShareEnabled={features.searchFlatShareEnabled}
      dealType={dealType}
      value={offerType}
      commercialLink={commercialLink}
      onChange={handleChange}
    />
  );
};

function getCommercialLink(): string {
  if (typeof window === 'undefined') {
    return '#';
  }

  const parsedUrl = QueryString.parseUrl(document.URL, {
    arrayFormat: 'index',
    parseNumbers: true,
    parseBooleans: true,
  });

  return [
    '/map/?engine_version=2&offer_type=offices&office_type%5B0%5D=1',
    parsedUrl.query['deal_type'] ? `deal_type=${parsedUrl.query['deal_type']}` : null,
    parsedUrl.query['region'] ? `region=${parsedUrl.query['region']}` : null,
    parsedUrl.query['zoom'] ? `zoom=${parsedUrl.query['zoom']}` : null,
    parsedUrl.query['center'] ? `center=${parsedUrl.query['center']}` : null,
  ]
    .filter(Boolean)
    .join('&');
}
