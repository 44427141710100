import { IConfig } from '@cian/config/shared';
import * as React from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';

import { IJsonQuery } from '../../../api-models/common/json_query';
import { IExperimentalFeatures, TFilter } from '../types/filters';
import { TModifier } from '../types/modifier';
import { ISpecialPromo } from '../types/specialPromo';

export interface IContext {
  appContext: {
    config: IConfig;
  };
  jsonQuery: IJsonQuery;
  specialPromo?: ISpecialPromo;
  promoSearchTypes: TPromoSearchTypes;
  features: IExperimentalFeatures;
  onChange(...modifiers: [TModifier, ...TModifier[]]): void;
  onApply(filterKey?: TFilter): void;
  onClear?(): void;
  onApplyClick?(): void;
  onAdvancedFiltersOpen?(): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const context = React.createContext<IContext>(null as any);
