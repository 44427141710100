import { ca } from '@cian/analytics';
import { TDevice } from '@cian/utils';

import { TUser } from '../../../types/user';
import { getSiteType } from '../../../utils/analytics';

interface IShowPhoneClickTrack {
  label: string;
  newbuildingId: number;
  isPromo: boolean;
  deviceType: TDevice;
  user: TUser;
}
export const onShowPhoneClickTrack = ({ label, newbuildingId, isPromo, deviceType, user }: IShowPhoneClickTrack) => {
  const extra = isPromo ? { is_promo: 'yes' } : {};

  ca('eventEbc', {
    name: 'oldevent',
    action: 'Open_listing_in_map_jk_head',
    category: 'Phones',
    label,
    user,
    page: {
      pageType: 'Listing',
      listingType: 'map',
      siteType: getSiteType(deviceType),
    },
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra,
      },
    ],
  });
};
