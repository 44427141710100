import { ca } from '@cian/analytics';

interface ITrackFavoritesAssignParams {
  label: string;
  sc: object;
  products: unknown;
  page: unknown;
}

export function trackCreateFolder({ sc, products, page, label }: ITrackFavoritesAssignParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_collection',
    label,
    sc,
    products,
    page,
  });
}

export function trackSaveToFolder({ sc, products, page, label }: ITrackFavoritesAssignParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_to_collection',
    label,
    products,
    page,
    sc,
  });
}

export function trackFolderNameClick({ sc, products, page, label }: ITrackFavoritesAssignParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'click_collection_name',
    page,
    label,
    products,
    sc,
  });
}

export function trackNotificationFolder({ sc, products, page, label }: ITrackFavoritesAssignParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'Click',
    label,
    page,
    sc,
    products,
  });
}
