import { TReduxActions } from '../../actions';
import { EInfrastructureActionType } from '../../actions/infrastructure';
import { EAsyncStatus } from '../../types/asyncStatus';
import { IInfrastructureTypesMap } from '../../types/infrastructure';
import { ITilesMap } from '../../types/map';
import { IInfrastructureFeatures, IInfrastructureState } from '../../types/redux/store/infrastructure';

export const defaultState: IInfrastructureState = {
  types: {},
  tiles: {},
  data: {},
  config: {
    largePinsZoom: 14,
    minZoom: 13,
    webGl: false,
    enabled: false,
  },
  isDropdownOpen: false,
};

export function infrastructureReducer(
  state: IInfrastructureState = defaultState,
  action: TReduxActions,
): IInfrastructureState {
  switch (action.type) {
    case EInfrastructureActionType.SetActiveType:
      return {
        ...state,
        activeType: action.payload,
        data: {
          ...Object.keys(state.data).reduce((result, key) => {
            const typeId = Number(key);

            result[typeId] = {
              ...state.data[typeId],
              featuresStatus: EAsyncStatus.Initial,
              visibleItemsCount: undefined,
            };

            return result;
          }, {} as IInfrastructureFeatures),
          ...(action.payload
            ? {
                [action.payload]: {
                  ...state.data[action.payload],
                  featuresStatus: EAsyncStatus.Initial,
                  visibleItemsCount: undefined,
                },
              }
            : {}),
        },
      };

    case EInfrastructureActionType.SetTypesByBbox:
      return {
        ...state,
        types: {
          ...state.types,
          ...action.payload.types.reduce((result, item) => {
            result[item.id] = item;

            return result;
          }, {} as IInfrastructureTypesMap),
        },
        tiles: {
          ...state.tiles,
          ...action.payload.tiles.reduce((result, tile) => {
            result[tile.join()] = tile;

            return result;
          }, {} as ITilesMap),
        },
      };
    case EInfrastructureActionType.SetFeaturesStatus:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            featuresStatus: action.payload.status,
          },
        },
      };
    case EInfrastructureActionType.SetFeatures: {
      const layerData = state.data[action.payload.id] || {};

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            features: {
              ...layerData.features,
              ...action.payload.features,
            },
            tiles: {
              ...layerData.tiles,
              ...action.payload.tiles.reduce((res, tile) => {
                res[tile.join()] = tile;

                return res;
              }, {} as ITilesMap),
            },
          },
        },
      };
    }
    case EInfrastructureActionType.SetVisibleFeaturesCount:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            visibleItemsCount: action.payload.count,
          },
        },
      };

    case EInfrastructureActionType.SetIsDropdownOpen:
      return {
        ...state,
        isDropdownOpen: action.payload,
      };
    default:
      return state;
  }
}
