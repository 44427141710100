import { ITypedReduxAction } from 'shared/map-search/types/redux/actionType';

export enum EDeleteFavoritesActionTypes {
  Request = 'DELETE_FAVORITE/REQUEST',
  Success = 'DELETE_FAVORITE/SUCCESS',
  Failure = 'DELETE_FAVORITE/FAILURE',
}

interface IPayload {
  offerId: number;
}

type TDeleteFavoriteRequest = ITypedReduxAction<EDeleteFavoritesActionTypes.Request, IPayload>;
type TDeleteFavoriteSuccess = ITypedReduxAction<EDeleteFavoritesActionTypes.Success, IPayload>;
type TDeleteFavoriteFailure = ITypedReduxAction<EDeleteFavoritesActionTypes.Failure, IPayload>;

export type TDeleteFavorite = TDeleteFavoriteRequest | TDeleteFavoriteSuccess | TDeleteFavoriteFailure;
