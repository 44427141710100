import {
  EDealType,
  EEntityType,
} from '../../../common/repositories/favorites/entities/schemas/DeleteOfferFromFavoritesRequestSchema';
import {
  fetchDeleteOfferFromFavorites,
  IDeleteOfferFromFavoritesResponse,
  TDeleteOfferFromFavoritesResponse,
} from '../../../common/repositories/favorites/v1/delete-offer-from-favorites';
import { IApplicationContext } from '../../types/applicationContext';
import { ResponseError } from '../../utils/errors/ResponseError';

export async function deleteNewbuildingFromFavorites(
  context: IApplicationContext,
  newbuildingId: number,
): Promise<IDeleteOfferFromFavoritesResponse> {
  const { httpApi, logger } = context;

  try {
    const response: TDeleteOfferFromFavoritesResponse = await fetchDeleteOfferFromFavorites({
      httpApi,
      parameters: {
        entityId: newbuildingId,
        dealType: EDealType.Sale,
        entityType: EEntityType.Newbuilding,
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'favorites#deleteNewbuildingFromFavorites',
        message: response.response.message || '',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: response.response.errors as any,
        },
      });
    }

    return response.response;
  } catch (error) {
    logger.error(error);

    throw error;
  }
}
