import { TDeviceType } from '@cian/ui-kit';

import { FOfferType, offerTypeFromJsonQuery } from '../../../common/packages/JsonQuery';
import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';

export function isProPlus(jsonQuery: IJsonQuery, deviceType: TDeviceType, isMobileBannerEnabled: boolean) {
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  if (deviceType === 'desktop' || isMobileBannerEnabled) {
    // Новостройки + смешанная
    return (offerType & FOfferType.Flat) !== 0 && offerType !== FOfferType.FlatOld;
  }

  return false;
}
