// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetInfrastructureGeoObjectsRequest,
  TGetInfrastructureGeoObjectsModel,
  IMappers,
  TGetInfrastructureGeoObjectsResponse,
  IGetInfrastructureGeoObjectsResponse,
  IGetInfrastructureGeoObjectsResponseError,
} from './types';

export const defaultConfig: TGetInfrastructureGeoObjectsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'infrastructure-caching',
  pathApi: '/v1/get-infrastructure-geo-objects/',
} as TGetInfrastructureGeoObjectsModel;

export function createGetInfrastructureGeoObjectsModel(
  parameters: IGetInfrastructureGeoObjectsRequest,
): TGetInfrastructureGeoObjectsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetInfrastructureGeoObjectsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetInfrastructureGeoObjectsRequest;
}

export async function fetchGetInfrastructureGeoObjects<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetInfrastructureGeoObjectsOptions<TResponse200, TResponse400>): Promise<
  TGetInfrastructureGeoObjectsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetInfrastructureGeoObjectsModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetInfrastructureGeoObjectsResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetInfrastructureGeoObjectsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetInfrastructureGeoObjectsResponse;
}
