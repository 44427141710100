import { makeContextModule } from '@cian/react-utils';

export interface IMapContext {
  ymaps: YMaps.IYMaps;
  map: YMaps.Map;
}

export const {
  Context: MapContext,
  useContext: useMapContext,
  withContext: withMapContext,
} = makeContextModule<IMapContext>({
  contextName: 'MapContext',
});
