import * as React from 'react';

import * as styles from './styles.css';
import { BannerWrapper } from '../../BannerWrapper';

export interface IDesktopBannerTemplateProps {
  href: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  newbuildingImage: React.ReactNode;
  developerLogo: React.ReactNode;
  addressInfo?: React.ReactNode;
  onClick?(): void;
  onClose?(): void;
  isSearchResultsOpen?: boolean;
  rootClassName?: string;
}

export function DesktopBannerTemplate({
  rootClassName,
  isSearchResultsOpen,
  href,
  title,
  description,
  addressInfo,
  newbuildingImage,
  developerLogo,
  onClose,
  onClick,
}: IDesktopBannerTemplateProps) {
  return (
    <BannerWrapper moveRight={isSearchResultsOpen} attributes={{ className: rootClassName }} onClose={onClose}>
      <a className={styles['wrapper']} href={href} target="_blank" rel="noopener noreferrer" onClick={onClick}>
        <div className={styles['image']}>
          <div className={styles['newbuilding-image']}>{newbuildingImage}</div>
          {developerLogo && <div className={styles['developer-logo']}>{developerLogo}</div>}
        </div>
        <div className={styles['content']}>
          <div className={styles['title']}>{title}</div>
          {description}
          <div className={styles['address']}>{addressInfo}</div>
        </div>
      </a>
    </BannerWrapper>
  );
}
