import * as React from 'react';

import { RoomType } from 'shared/common/packages/Filters/shared/components/filters/RoomType';
import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { ERoomType } from 'shared/common/packages/api-models/common/json_query';

import { useContext } from '../../../utils/useContext';

export const RoomTypeContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply } = useContext();
  const roomType = getTermsValue('room')(jsonQuery);

  const handleChange = React.useCallback(
    (roomType: NonEmptyArray<ERoomType> | null) => {
      onChange({ action: 'setRoomTypes', arguments: [roomType] });
      onApply('roomType');
    },
    [onChange, onApply],
  );

  return <RoomType value={roomType} onChange={handleChange} />;
};
