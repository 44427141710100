import * as R from 'ramda';
import * as React from 'react';

import { IJsonQueryPolygon } from 'shared/common/packages/api-models/common/json_query';
import { IPolygon } from 'shared/map-search/types/map/polygon';

import { createPolygon } from './helpers/createPolygon';
import { trackAreaSelect } from './tracking';

export interface IPolygonsProps {
  ymaps: YMaps.IYMaps;
  map: YMaps.Map;
  polygons: IPolygon[];
  isDrawingMode: boolean;
  addPolygon(polygon: IJsonQueryPolygon): void;
}

export class Polygons extends React.Component<IPolygonsProps> {
  private drawPolygonBehavior: YMaps.DrawPolygon;
  private geoObjects: { [id: string]: null | YMaps.IGeoObject } = {};

  public componentDidMount() {
    const { ymaps, map } = this.props;

    this.drawPolygonBehavior = new ymaps.cian.DrawPolygon({
      map,
      onCreatePolygon: this.handleAddPolygon,
    });

    this.updateGeoObjects();
  }

  public componentDidUpdate(prevProps: IPolygonsProps) {
    if (prevProps.isDrawingMode !== this.props.isDrawingMode) {
      if (this.props.isDrawingMode) {
        this.drawPolygonBehavior.enable();
      } else {
        this.drawPolygonBehavior.disable();
      }
    }

    if (prevProps.polygons !== this.props.polygons) {
      this.updateGeoObjects();
    }
  }

  public componentWillUnmount() {
    this.drawPolygonBehavior.destroy();

    this.removeGeoObjects(Object.keys(this.geoObjects));
  }

  public render() {
    return null;
  }

  private handleAddPolygon = (coordinates: [number, number][]) => {
    this.props.addPolygon({
      type: 'polygon',
      name: 'Область поиска',
      coordinates: coordinates.map(item => item.map(String) as [string, string]),
    });

    this.drawPolygonBehavior.disable();
    trackAreaSelect('success');
  };

  private updateGeoObjects() {
    const aliveKeys = Object.keys(this.geoObjects);
    const actualKeys = this.props.polygons.map(({ id }) => id);
    const toRemove = R.difference(aliveKeys, actualKeys);

    this.removeGeoObjects(toRemove);

    this.props.polygons.forEach((polygon: IPolygon) => {
      const { id } = polygon;

      if (!this.geoObjects[id]) {
        const geoObject = createPolygon(this.props.ymaps, polygon.coordinates);

        this.props.map.geoObjects.add(geoObject);

        this.geoObjects[id] = geoObject;
      }
    });
  }

  private removeGeoObjects(ids: string[]) {
    ids.forEach((id: string) => {
      const geoObject = this.geoObjects[id];

      if (geoObject) {
        this.props.map.geoObjects.remove(geoObject);

        delete this.geoObjects[id];
      }
    });
  }
}
