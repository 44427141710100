import { pipe } from 'ramda';

import {
  isViewed as isViewedCluster,
  isAvailable as isViewedClustersAvailable,
} from 'browser/map-search/utils/viewedClusters';

import { IGetClustersForMapResponse } from '../../../common/repositories/search-offers-map/v1/get-clusters-for-map';
import { TMapBounds } from '../../types/mapBounds';
import { queryStringWithMapBounds, isCenterZoomBounds } from '../../utils/mapBounds';

const POSUTOCHNO_CLUSTER_INDENTS = [
  [0, 0],
  [32, 0],
  [16, 64],
  [-16, 64],
  [-32, 0],
  [-16, -64],
  [16, -64],
];
const POSUTOCHNO_CLUSTER_FACTOR = 0.8;

export interface IArguments {
  data: IGetClustersForMapResponse;
  mapBounds: TMapBounds;
  currentZoom: number;
  isDailyRentAvailabilityFeatureEnabled: boolean;
}

export function prepareClusters(args: IArguments) {
  return pipe(prepareQueryStrings, prepareViewedOffers, prepareDailyRentClusters)(args);
}

function prepareQueryStrings(args: Omit<IArguments, 'experiments'>): IArguments {
  const { data, mapBounds } = args;

  if (!isCenterZoomBounds(mapBounds)) {
    return args;
  }

  return {
    ...args,
    data: {
      ...data,
      queryString: queryStringWithMapBounds(data.queryString, mapBounds),
      extendedQueryString: queryStringWithMapBounds(data.extendedQueryString || '', mapBounds),
    },
  };
}

function prepareViewedOffers(args: IArguments): IArguments {
  const { data } = args;

  if (!isViewedClustersAvailable()) {
    return args;
  }

  return {
    ...args,
    data: {
      ...data,
      filtered: data.filtered?.map(cluster => ({
        ...cluster,
        isViewed: cluster.isViewed || isViewedCluster(cluster.geohash),
      })),
      extended: data.extended?.map(cluster => ({
        ...cluster,
        isViewed: cluster.isViewed || isViewedCluster(cluster.geohash),
      })),
    },
  };
}

function prepareDailyRentClusters({
  data,
  currentZoom,
  isDailyRentAvailabilityFeatureEnabled,
}: IArguments): IGetClustersForMapResponse {
  const factor = POSUTOCHNO_CLUSTER_FACTOR / 2 ** currentZoom;

  if (!isDailyRentAvailabilityFeatureEnabled) {
    return data;
  }

  return {
    ...data,
    filtered: data.filtered?.reduce((result, cluster) => {
      const { count, clusterOfferIds, coordinates, geohash, dailyrentPrices, minPrice } = cluster;

      if (count <= 1) {
        return [...result, cluster];
      }

      return [
        ...result,
        ...(clusterOfferIds?.slice(0, 7).map((currentOfferId, index) => ({
          ...cluster,
          clusterOfferIds: [currentOfferId],
          count: 1,
          coordinates: {
            lat: coordinates.lat + POSUTOCHNO_CLUSTER_INDENTS[index][0] * factor,
            lng: coordinates.lng + POSUTOCHNO_CLUSTER_INDENTS[index][1] * factor * Math.cos(coordinates.lat) * 2,
          },
          geohash: `${geohash}${index}`,
          minPrice: dailyrentPrices?.find(({ offerId }) => offerId === currentOfferId)?.price || minPrice,
        })) || []),
      ];
    }, []),
  };
}
