export const FSK_CLOSED_COOKIE_KEY = 'fsk_banner_closed';
export const REALTOR_REPORT_CLOSED_COOKIE_KEY = 'realtor_report_banner_closed';

/** 3е суток баннер не будет показываться, если его закрыли */
export const EXPIRES_TIME_FOR_REALTOR_REPORT = 3 * 24 * 60 * 60 * 1000;

/** 2 часа баннер не будет показываться, если его закрыли */
export const EXPIRES_TIME_FOR_FSK = 0.085;

export enum EBanners {
  Fsk = 'fsk',
  Leads = 'leads',
  ProPlus = 'proPlus',
  RealtorReport = 'realtorReport',
  Empty = 'empty',
}

export const bannersMobileHeight: { [key in EBanners]: string } = {
  [EBanners.Fsk]: '64',
  [EBanners.Leads]: '80',
  [EBanners.RealtorReport]: '80',
  [EBanners.ProPlus]: '64',
  // В местах, где нет промотируемых баннеров требуется схлопнуть высоту пустого блока
  [EBanners.Empty]: '0',
};

export const BANNER_ID = 'banner-block';
export const FETCH_PROMO_NEWBUILDINGS_DELAY = 10000;
export const PROMO_BANNER_SWITCH_DELAY = 10000;
export const STATUS_CHANGING_DEBOUNCE_TIME = 350;
