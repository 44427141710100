import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { EPriceType, FDealType, FOfferType } from 'shared/common/packages/JsonQuery';
import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import * as styles from './Price.css';
import {
  getIsSquareMeterPrice,
  getPriceLabel,
  getPriceLabelDefault,
  getPriceTitle,
  getPriceType,
  getPriceTypeLabel,
  PRICE_TYPES,
} from './helpers';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';
import { Option, Select } from '../../Select';

interface IPriceProps {
  dealType: FDealType;
  offerType: FOfferType;
  price: IJsonQueryRangeValue | null;
  isSquareMeterPrice: boolean | null;
  onPriceMinChange(gte: number | null): void;
  onPriceMaxChange(lte: number | null): void;
  onPriceTypeChange(isSquareMeterPrice: boolean | null): void;
}

export const Price: React.FC<IPriceProps> = ({
  dealType,
  offerType,
  price,
  isSquareMeterPrice,
  onPriceMinChange,
  onPriceMaxChange,
  onPriceTypeChange,
}) => {
  const { gte: priceMin = null, lte: priceMax = null } = price || {};
  const isPriceTypeAvailable = dealType === FDealType.Sale && (offerType & FOfferType.Urban) !== 0;
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const priceTitle = getPriceTitle(priceMin, priceMax) || undefined;
  const priceLabel = getPriceLabel(priceMin, priceMax);
  const priceLabelDefault = getPriceLabelDefault(dealType);
  const priceType = getPriceType(isSquareMeterPrice);
  const priceTypeLabel = getPriceTypeLabel(priceType);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handlePriceMinChange = useDebouncedCallback((gte: number | null) => {
    onPriceMinChange(gte);
  }, 300);

  const handlePriceMaxChange = useDebouncedCallback((lte: number | null) => {
    onPriceMaxChange(lte);
  }, 300);

  return (
    <Outside onOutside={handleClose} insideRefs={[dropdownRef]} active={isOpen}>
      <div className={styles['container']} data-name="FilterPrice">
        <Button theme="stroke_secondary" size="XS" title={priceTitle} onClick={handleClick}>
          {priceLabel || priceLabelDefault}
        </Button>
        {isOpen && (
          <div ref={dropdownRef} className={styles['dropdown']}>
            <div className={styles['dropdown-filter']}>
              <RangeInput
                min={0}
                max={999999999999}
                joined={false}
                postfix={'\u20bd'}
                valueMin={priceMin}
                valueMax={priceMax}
                onChangeMin={handlePriceMinChange}
                onChangeMax={handlePriceMaxChange}
              />
            </div>
            {isPriceTypeAvailable && (
              <div className={styles['dropdown-filter']}>
                <Select
                  withoutArrow
                  buttonAppearance={'underlined'}
                  value={priceType || EPriceType.Total}
                  label={priceTypeLabel}
                  onChange={(_, value: EPriceType) => onPriceTypeChange(getIsSquareMeterPrice(value))}
                >
                  {PRICE_TYPES.map(priceType => (
                    <Option key={`${priceType.label}_${priceType.value}`} value={priceType.value}>
                      {priceType.label}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        )}
      </div>
    </Outside>
  );
};
