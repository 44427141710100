import {
  IGetClustersForMapResponse,
  IGetClustersForMapResponse200,
  IGetClustersForMapResponse400,
  IGetClustersForMapResponseError,
} from 'shared/common/repositories/search-offers-index/v1/get-clusters-for-map';

export const mapFetchClustersResponse = (response: IGetClustersForMapResponse): IGetClustersForMapResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};

export const mapFetchClustersResponseError = (
  response: IGetClustersForMapResponseError,
): IGetClustersForMapResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
