export const CANDY_PRIMARY_COLOR = '#ff1f34';
export const CANDY_SECONDARY_COLOR = '#ffffff';
export const SHADOW_COLOR = 'rgba(0,0,0,0.2)';
export const SHADOW_BLUR = 5;
export const SHADOW_OFFSET = 2;
export const CANDY_BUTTON_RADIUS = 5;
export const CANDY_BUTTON_BORDER = 2;
export const LOLLIPOP_RADIUS = 10;
export const LOLLIPOP_INNER_RADIUS = 4;
export const LOLLIPOP_STICK = 8;
export const LOLLIPOP_STICK_COLOR = 'rgba(0,0,0,0.6)';
export const LOLLIPOP_BORDER = 1;
