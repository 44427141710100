import * as React from 'react';

import { context } from './context';
import { IFavoriteAction, EFavoriteAction } from './types';
import { TReduxActions } from '../../actions';
import { EFavoritesActionType, TFavoritesActions } from '../../actions/favorites';

const FAVORITES_ACTIONS: TReduxActions['type'][] = [EFavoritesActionType.Added, EFavoritesActionType.Removed];

export function useFavoritesSubscriber(): IFavoriteAction | null {
  const reduxStore = React.useContext(context);
  const [lastFavoritesAction, setLastFavoritesAction] = React.useState<IFavoriteAction | null>(null);

  const storeListener = () => {
    const { lastAction } = reduxStore.getState();

    if (isFavoritesAction(lastAction)) {
      setLastFavoritesAction({
        action: lastAction.type === EFavoritesActionType.Added ? EFavoriteAction.Added : EFavoriteAction.Removed,
        offerId: lastAction.payload.offerId,
      });
    }
  };

  React.useEffect(() => {
    return reduxStore.subscribe(storeListener);
  });

  return lastFavoritesAction;
}

function isFavoritesAction(action: TReduxActions): action is TFavoritesActions {
  return FAVORITES_ACTIONS.includes(action.type);
}
