import { equals } from 'ramda';

import { EFiltersActionType, IJsonQueryChanged } from './types';
import { modifyJsonQuery, TModifier } from '../../../common/packages/Filters';
import { PIK_PROMO } from '../../constants/pik';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { getAvailableSpecialPromo } from '../../utils/specialPromos';

const changeJsonQueryRaw = actionGenerator<EFiltersActionType.JsonQueryChanged, IJsonQueryChanged>(
  EFiltersActionType.JsonQueryChanged,
);

export function changeJsonQuery(payloadRaw: IJsonQueryChanged): TThunkAction {
  return (dispatch, getState) => {
    const payload = { ...payloadRaw };

    const { prevJsonQuery, nextJsonQuery } = payload;
    const { specialPromos } = getState();

    if (!equals(prevJsonQuery.region, nextJsonQuery.region)) {
      const prevSpecialPromo = getAvailableSpecialPromo(specialPromos, prevJsonQuery);
      if (prevSpecialPromo) {
        const isSpecialPromoActive =
          prevJsonQuery.geo && prevSpecialPromo
            ? prevJsonQuery.geo.value.some(
                value => value.type === 'builder' && value.id === prevSpecialPromo.developerId,
              )
            : null;

        if (isSpecialPromoActive) {
          const nextSpecialPromo = getAvailableSpecialPromo(specialPromos, nextJsonQuery);

          if (!equals(prevSpecialPromo, nextSpecialPromo)) {
            const modifier: TModifier = {
              action: 'removeGeo',
              arguments: [{ type: 'builder', id: prevSpecialPromo.developerId }],
            };

            payload.appliedModifiers.push(modifier);

            if (prevSpecialPromo.developerId === PIK_PROMO.developerId) {
              payload.appliedModifiers.push({ action: 'setPikPromo', arguments: [null] });
            }

            payload.nextJsonQuery = modifyJsonQuery(payload.nextJsonQuery, [modifier]);
          }
        }
      }
    }

    dispatch(changeJsonQueryRaw(payload));
  };
}
