import { TReduxActions } from '../../actions';
import { EMapBoundsActionType } from '../../actions/mapBounds';
import { EResultsActionType } from '../../actions/results';
import { isCenterZoomBounds, queryStringWithMapBounds } from '../../utils/mapBounds';

const initialState = '';

export function queryStringReducer(state: string = initialState, action: TReduxActions): string {
  switch (action.type) {
    case EResultsActionType.Succeed:
      if (action.payload.clean) {
        return action.payload.queryString;
      }

      return state;
    case EMapBoundsActionType.Changed:
      if (!isCenterZoomBounds(action.payload)) {
        return state;
      }

      return queryStringWithMapBounds(state, action.payload);
    default:
      return state;
  }
}
