import { term } from '../../../common/packages/JsonQuery/constructors';
import { TReduxActions } from '../../actions';
import { EResultsActionType } from '../../actions/results';
import { IResultsState, EResultsStatus } from '../../types/results';

let uniqVersion = 0;

const defaultState: IResultsState = {
  status: EResultsStatus.Initial,
  jsonQuery: {
    _type: 'flatrent',
    engine_version: term(2),
  },
  subdomain: 'www',
};

export function resultsReducer(state: IResultsState = defaultState, action: TReduxActions): IResultsState {
  switch (action.type) {
    case EResultsActionType.Loading:
      return {
        ...state,
        status: EResultsStatus.Loading,
      };

    case EResultsActionType.Failed:
      return {
        ...state,
        status: EResultsStatus.Failed,
      };

    case EResultsActionType.Succeed:
      return {
        ...state,
        status: EResultsStatus.Succeed,
        jsonQuery: action.payload.jsonQuery,
        version: action.payload.clean ? ++uniqVersion : state.version,
      };

    case EResultsActionType.SetSubdomain:
      return {
        ...state,
        subdomain: action.payload,
      };
  }

  return state;
}
