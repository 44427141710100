import * as React from 'react';

export const Ruler: React.FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.45791"
        y="15.5566"
        width="20"
        height="7"
        rx="1"
        transform="rotate(-45 1.45791 15.5566)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line x1="5.01741" y1="13.2079" x2="6.43162" y2="14.6221" stroke="currentColor" strokeWidth="2" />
      <line x1="10.6744" y1="7.55071" x2="12.0886" y2="8.96492" stroke="currentColor" strokeWidth="2" />
      <line x1="7.13875" y1="9.67278" x2="10.6743" y2="13.2083" stroke="currentColor" strokeWidth="2" />
      <line x1="12.7957" y1="4.01555" x2="16.3313" y2="7.55108" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

Ruler.displayName = 'Ruler';
