/*eslint-disable @typescript-eslint/no-explicit-any */
import { GenericError, IErrorDetails } from '@cian/peperrors/shared';

import { IFetchGetClusterPopupResult } from './types';

export async function fetchDummyGetClusterPopupForMap(
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  _parameters: any,
): Promise<{ response: IFetchGetClusterPopupResult }> {
  throw new NotImplementedError({
    message: 'Функция для получения попапа для кластера не имлпементирована',
    domain: 'shared.containers.Map.MapLayers.Features.PopupManager.services.getClusterPopup',
  });
}

export class NotImplementedError extends GenericError {
  public constructor(configuration: { domain: string; message: string; details?: IErrorDetails }) {
    super({
      ...configuration,
      type: 'NotImplementedError',
    }) /* istanbul ignore next */;

    Object.setPrototypeOf(this, NotImplementedError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotImplementedError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
