import { IconFunctionalInformationCircle16 } from '@cian/ui-kit';

import * as styles from './InfrastructureLegendInformer.css';

interface IInfrastructureLegendInformerProps {
  text: string;
}

export function InfrastructureLegendInformer({ text }: IInfrastructureLegendInformerProps) {
  return (
    <div className={styles['container']}>
      <span className={styles['icon']}>
        <IconFunctionalInformationCircle16 />
      </span>
      <span className={styles['text']}>{text}</span>
    </div>
  );
}
