import { FOfferType } from '../types';

export const AVAILABLE_OFFER_TYPES: FOfferType[] = Object.keys(FOfferType)
  .filter((key): key is keyof typeof FOfferType => isNaN(+key))
  .map(name => FOfferType[name]);

export const DISJOINT_AVAILABLE_OFFER_TYPES: FOfferType[] = AVAILABLE_OFFER_TYPES.filter(o => Math.log2(o) % 1 === 0);

export const AVAILABLE_OFFER_TYPE_COMBINATIONS: FOfferType[] = [
  FOfferType.Flat,
  FOfferType.Urban & ~FOfferType.Flat,
  FOfferType.Suburban,
  FOfferType.Other,
  FOfferType.CommercialLand,
  FOfferType.Commercial & ~FOfferType.CommercialLand,
];
