import { toPolygon } from './toPolygon';
import { IJsonQuery, IJsonQueryPolygon } from '../../../common/packages/api-models/common/json_query';
import { IPolygon } from '../../types/map/polygon';

export function preparePolygons(jsonQuery: IJsonQuery): IPolygon[] {
  const geoValue = jsonQuery.geo?.value || [];
  const jsonQueryPolygons = geoValue.filter(({ type }) => type === 'polygon') as IJsonQueryPolygon[];
  const polygons = jsonQueryPolygons.map(toPolygon);

  return polygons;
}
