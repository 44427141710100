import { getGlobalEventsLogs } from '@cian/events-log';

import { TOPIC_USER } from '../../eventsLogs/topics';
import { TAuthenticationModalType } from '../../types/authenticationModal';

export function callAuthenticationPopup(type: TAuthenticationModalType) {
  const globalLog = getGlobalEventsLogs();

  globalLog.produce(TOPIC_USER, {
    type: 'authenticate',
    value: {
      autoRefresh: true,
      alert: type,
    },
  });
}
