import { INewbuilding } from '../../types/map/newbuilding';

interface IParams {
  newbuilding: INewbuilding | null;
}

interface IExtra {
  newbuilding_id?: number;
  house_id?: number;
  is_promo?: true;
}

export function getPinProducts({ newbuilding }: IParams) {
  if (!newbuilding) {
    return undefined;
  }

  let extra: IExtra | undefined;

  if (newbuilding.id) {
    extra = {
      newbuilding_id: newbuilding.id,
    };
  }

  if (newbuilding.houseId) {
    extra = {
      ...extra,
      house_id: newbuilding.houseId,
    };
  }

  if (newbuilding.isAnyFicheringPlus) {
    extra = {
      ...extra,
      is_promo: true,
    };
  }

  return [{ extra }];
}
