import * as React from 'react';

import { getTermValue } from 'shared/common/packages/JsonQuery';
import { ESuburbanOfferFilter } from 'shared/common/packages/api-models/common/json_query';

import { Posessor } from '../../../components/filters/Posessor';
import { useContext } from '../../../utils/useContext';

export const PosessorContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply } = useContext();
  const posessorType = getTermValue('suburban_offer_filter')(jsonQuery);

  const handleChange = React.useCallback(
    (posessorType: ESuburbanOfferFilter | null) => {
      onChange({ action: 'setPosessor', arguments: [posessorType] });
      onApply('posessor');
    },
    [onChange, onApply],
  );

  return <Posessor value={posessorType} onChange={handleChange} />;
};
