import { CancellationToken } from '@cian/http-api/shared';

import { IApplicationContext } from 'shared/map-search/types/applicationContext';

import { fetchDummyGetClusterPopupForMap } from './fetchDummyGetClusterPopupForMap';
import { IFetchGetClusterPopupParameters, IFetchGetClusterPopupResult } from './types';

export async function fetchGetClusterPopup(
  context: IApplicationContext,
  parameters: IFetchGetClusterPopupParameters,
): Promise<IFetchGetClusterPopupResult> {
  const {
    httpApi,
    cancellationTokenManager,
    custom: { subdomain },
  } = context;

  let cancellationToken: CancellationToken | undefined;
  if (cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious('fetchGetClusterPopup');
  }

  // Чтобы включить фичу, необходимо на бэке реализовать ручку для запроса попапа для кластера
  const response = await fetchDummyGetClusterPopupForMap({
    httpApi,
    parameters: { ...parameters, subdomain },
    config: {
      cancellationToken,
    },
  });

  return response.response;
}
