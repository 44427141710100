import cx from 'clsx';
import * as React from 'react';

import { ControlButton } from 'shared/map-search/components/ControlButton';
import { TControlButtonSize } from 'shared/map-search/components/ControlButton/types';

import * as styles from './styles.css';

export interface IZoomOutButtonProps {
  map: YMaps.Map;
  size: TControlButtonSize;
  icon: React.ReactNode;
  isMobile?: boolean;
}

export const ZoomOutButton: React.FC<IZoomOutButtonProps> = props => {
  const { map, size, icon, isMobile } = props;

  const handleZoomOutClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const zoom = map.getZoom();
      const [minZoom] = map.zoomRange.getCurrent();
      if (zoom <= minZoom) {
        return;
      }

      return map.setZoom(zoom - 1);
    },
    [map],
  );

  return (
    <ControlButton className={cx(!isMobile && styles['button'])} onClick={handleZoomOutClick} size={size}>
      {icon}
    </ControlButton>
  );
};
