import { TReduxActions } from '../../actions';
import { EResultsActionType } from '../../actions/results';

export function isNewbuildingsListingReducer(state: boolean = false, action: TReduxActions): boolean {
  switch (action.type) {
    case EResultsActionType.Succeed:
      return action.payload.isNewobject;
  }

  return state;
}
