import { EAsyncStatus } from '../../types/asyncStatus';
import { IApplicationState } from '../../types/redux';

export function selectFeaturesStatus(state: IApplicationState): EAsyncStatus {
  const { activeType, data } = state.infrastructure;

  if (!activeType) {
    return EAsyncStatus.Initial;
  }

  return data[activeType].featuresStatus;
}
