import { ITypedReduxAction } from 'shared/map-search/types/redux/actionType';

export enum EAddFavoritesActionTypes {
  Request = 'ADD_FAVORITE/REQUEST',
  Success = 'ADD_FAVORITE/SUCCESS',
  Failure = 'ADD_FAVORITE/FAILURE',
}

interface IPayload {
  offerId: number;
}

type TAddFavoriteRequest = ITypedReduxAction<EAddFavoritesActionTypes.Request, IPayload>;
type TAddFavoriteSuccess = ITypedReduxAction<EAddFavoritesActionTypes.Success, IPayload>;
type TAddFavoriteFailure = ITypedReduxAction<EAddFavoritesActionTypes.Failure, IPayload>;

export type TAddFavorite = TAddFavoriteRequest | TAddFavoriteSuccess | TAddFavoriteFailure;
