import { TReduxActions } from '../../actions';
import { EFiltersActionType } from '../../actions/filters';

/**
 * @todo будет удалено в рамках CD-169251
 */
export function seoUrlReducer(state: string | null = null, action: TReduxActions): string | null {
  /**
   * при обновление фильтров удаляем информацию о ЧПУ из состояния,
   * чтобы снова обновлялся queryString в ссылке
   */
  if (action.type === EFiltersActionType.JsonQueryChanged) {
    return null;
  }

  return state;
}
