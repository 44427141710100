import { pathOr } from 'ramda';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { Floor } from '../../../components/advancedFilters/Floor';
import { useContext } from '../../../utils/useContext';

export const FloorContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const floor = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['floor', 'value'], jsonQuery);
  const isFirstFloor = pathOr<boolean | null>(null, ['is_first_floor', 'value'], jsonQuery);
  const notLastFloor = pathOr<boolean | null>(null, ['not_last_floor', 'value'], jsonQuery);

  const handleFloorMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFloorMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFloorMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFloorMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleIsFirstFloorChange = React.useCallback(
    (isFirstFloor: boolean | null) => {
      onChange({ action: 'setIsFirstFloor', arguments: [isFirstFloor] });
    },
    [onChange],
  );

  const handleNotLastFloorChange = React.useCallback(
    (notLastFloor: boolean | null) => {
      onChange({ action: 'setNotLastFloor', arguments: [notLastFloor] });
    },
    [onChange],
  );

  return (
    <Floor
      floor={floor}
      isFirstFloor={isFirstFloor}
      notLastFloor={notLastFloor}
      onFloorMinChange={handleFloorMinChange}
      onFloorMaxChange={handleFloorMaxChange}
      onIsFirstFloorChange={handleIsFirstFloorChange}
      onNotLastFloorChange={handleNotLastFloorChange}
    />
  );
};
