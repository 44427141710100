import { EInfrastructureActionType, ISetTypesByBboxPayload } from './types';
import { fetchInfrastructureTypesByBbox as fetchInfrastructureTypesByBboxService } from '../../services/infrastructure/fetchInfrastructureTypesByBbox';
import { TThunkAction } from '../../types/redux';
import { combineBboxes } from '../../utils/geometry';
import { mapBBoxBoundsToInfrastructureCachingBBox } from '../../utils/infrastructureCaching';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { getRequiredTiles, getVisibleTiles, tileToBbox } from '../../utils/tiles';

const setTypesByBbox = actionGenerator<EInfrastructureActionType.SetTypesByBbox, ISetTypesByBboxPayload>(
  EInfrastructureActionType.SetTypesByBbox,
);

export function fetchInfrastructureTypesByBbox(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      mapBounds: { bounds, zoom },
      infrastructure: { tiles: loadedTiles },
    } = getState();

    if (!bounds || !zoom) {
      return;
    }

    const visibleTiles = getVisibleTiles(bounds, zoom);
    const requiredTiles = getRequiredTiles(loadedTiles, visibleTiles);

    if (requiredTiles.length === 0) {
      context.logger.debug('попытка загрузить уже загруженные тайлы', {
        domain: 'actions/infrastructure/fetchInfrastructureTypesByBbox',
      });

      return;
    }

    const requiredBboxes = requiredTiles.map(t => tileToBbox(t, zoom));
    const combinedBbox = combineBboxes(requiredBboxes);

    const types = await fetchInfrastructureTypesByBboxService(
      {
        bbox: mapBBoxBoundsToInfrastructureCachingBBox({ bounds: combinedBbox }),
      },
      context,
    );

    dispatch(setTypesByBbox({ types, tiles: requiredTiles }));
  };
}
