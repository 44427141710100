import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectActiveType,
  selectActiveFeatures,
  selectLargePinsZoom,
  selectMinZoom,
} from 'shared/map-search/selectors/infrastructure';
import { IInfrastructureFeaturesMap } from 'shared/map-search/types/infrastructure';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { geoJsonFeatureToInfrastructureRBushItem } from 'shared/map-search/utils/features';
import { getInfrastructurePointsTilesToUpdate } from 'shared/map-search/utils/tiles';
import { onYmapsDebug } from 'shared/map-search/utils/ymapsModules/ymapsModulesDebug';

import { useInfrastructureFeaturesContext } from '../context';

export const InfrastructureHTMLFeaturesUpdater: React.FunctionComponent = () => {
  const { rbush, infrastructureHTMLPointsLayer } = useInfrastructureFeaturesContext();
  const { bounds, zoom } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const activeLayer = useSelector(selectActiveType);
  const infrastructureFeatures = useSelector(selectActiveFeatures);
  const prevZoom = React.useRef<number>(zoom);
  const largePinsZoom = useSelector(selectLargePinsZoom);
  const minZoom = useSelector(selectMinZoom);

  const loadedFeatures = React.useRef<IInfrastructureFeaturesMap>({});

  React.useEffect(() => {
    rbush.clear();
    loadedFeatures.current = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayer]);

  React.useEffect(() => {
    const rbushItems = Object.values(infrastructureFeatures)
      .filter(({ id }) => !loadedFeatures.current[id])
      .map(geoJsonFeatureToInfrastructureRBushItem);

    if (rbushItems.length === 0) {
      if (Object.keys(loadedFeatures.current).length === 0) {
        infrastructureHTMLPointsLayer.clear();
      }

      return;
    }

    rbush.load(rbushItems);

    const featuresWereLoaded = Object.keys(loadedFeatures.current).length > 0;
    loadedFeatures.current = infrastructureFeatures;

    if (!featuresWereLoaded) {
      infrastructureHTMLPointsLayer.drawPins();

      return;
    }

    onYmapsDebug(() => console.time('getInfrastructurePointsTilesToUpdate'));
    const tilesToUpdate = getInfrastructurePointsTilesToUpdate({ rbushItems, bounds, zoom });
    onYmapsDebug(() => console.timeEnd('getInfrastructurePointsTilesToUpdate'));

    onYmapsDebug(() => console.time('infrastructureHTMLPointsLayer update'));
    if (tilesToUpdate.length > 0) {
      infrastructureHTMLPointsLayer.drawPins();
    }
    onYmapsDebug(() => console.timeEnd('infrastructureHTMLPointsLayer update'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrastructureFeatures, zoom, bounds]);

  React.useEffect(() => {
    if (zoom <= minZoom) {
      infrastructureHTMLPointsLayer.clear();
    }

    if ((zoom > minZoom && prevZoom.current <= minZoom) || zoom - prevZoom.current < 0) {
      infrastructureHTMLPointsLayer.drawPins();
    }

    if (
      (zoom >= largePinsZoom && prevZoom.current < largePinsZoom) ||
      (zoom < largePinsZoom && prevZoom.current >= largePinsZoom)
    ) {
      infrastructureHTMLPointsLayer.updatePins();
    }

    prevZoom.current = zoom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [largePinsZoom, minZoom, zoom]);

  return null;
};
