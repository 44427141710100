import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IFeaturesMap } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';

import { useFeaturesContext } from '../context';

interface ITestModeProps {
  map: YMaps.Map;
}

export const TestMode: React.FC<ITestModeProps> = ({ map }) => {
  const { rbush } = useFeaturesContext();
  const { bounds, zoom, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>({}, [precision, 'features'], state.features),
  );

  window._cianService = window._cianService || {};
  window._cianService['map-search-frontend'] = window._cianService['map-search-frontend'] || {};
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  window._cianService!['map-search-frontend'].getPoints = React.useCallback(() => {
    if (!bounds) {
      return null;
    }

    const mapProjection: YMaps.projection.wgs84Mercator = map.options.get('projection');

    const [mapPixelCoordX, mapPixelCoordY] = mapProjection.toGlobalPixels([bounds[0][0], bounds[0][1]], zoom);

    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    return featuresInBbox.map(feature => {
      const [featureCoordX, featureCoordY] = feature.feature.geometry.coordinates;

      const [featurePixelCoordX, featurePixelCoordY] = mapProjection.toGlobalPixels(
        [featureCoordX, featureCoordY],
        zoom,
      );

      return {
        feature: feature.feature,
        position: [Math.round(featurePixelCoordX - mapPixelCoordX), Math.floor(mapPixelCoordY - featurePixelCoordY)],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, features, map, rbush, zoom]);

  return null;
};
