import * as React from 'react';

import { getTermValue } from 'shared/common/packages/JsonQuery';

import { DealType } from '../../../components/advancedFilters/DealType';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const DealTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const isByHomeowner = getTermValue('is_by_homeowner')(jsonQuery);
  const isFromOffrep = getTermValue('from_offrep')(jsonQuery);

  const handleIsByHomeownerChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setIsByHomeowner', arguments: [value] });
    },
    [onChange],
  );

  const handleIsFromOffrepChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setFromOffrep', arguments: [value] });
    },
    [onChange],
  );

  return (
    <DealType
      offerType={offerType}
      isByHomeowner={isByHomeowner}
      isFromOffrep={isFromOffrep}
      onIsByHomeownerChange={handleIsByHomeownerChange}
      onIsFromOffrepChange={handleIsFromOffrepChange}
    />
  );
};
