import * as React from 'react';

import { getRangeValue } from 'shared/common/packages/JsonQuery';

import { Area } from '../../../components/filters/Area';
import { useContext } from '../../../utils/useContext';

export const AreaContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply } = useContext();
  const area = getRangeValue('total_area')(jsonQuery);

  const handleAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setAreaMin', arguments: [gte] });
      onApply();
    },
    [onApply, onChange],
  );

  const handleAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setAreaMax', arguments: [lte] });
      onApply();
    },
    [onApply, onChange],
  );

  return <Area area={area} onAreaMinChange={handleAreaMinChange} onAreaMaxChange={handleAreaMaxChange} />;
};
