import * as React from 'react';

import { JsonQuery, ESuburbanUtility, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { SuburbanUtilities } from '../../../components/advancedFilters/SuburbanUtilities';
import { useContext } from '../../../utils/useContext';

export const SuburbanUtilitiesContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const suburbanUtilities = new JsonQuery(jsonQuery).getSuburbanUtilities();

  const handleSuburbanUtilitiesChange = React.useCallback(
    (suburbanUtilities: NonEmptyArray<ESuburbanUtility> | null) => {
      onChange({ action: 'setSuburbanUtilities', arguments: [suburbanUtilities] });
    },
    [onChange],
  );

  return (
    <SuburbanUtilities
      value={suburbanUtilities as NonEmptyArray<ESuburbanUtility> | null}
      onChange={handleSuburbanUtilitiesChange}
    />
  );
};
