import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSearchResultsSidebarReady } from 'shared/map-search/actions/searchResults';
import { selectSidebarReady } from 'shared/map-search/selectors/searchResults';

export const useLoading = () => {
  const [isLoading, setLoading] = React.useState(true);

  const isSidebarReady = useSelector(selectSidebarReady);

  const dispatch = useDispatch();

  const frameLoadStartHandler = React.useCallback(() => {
    setLoading(true);
    dispatch(setSearchResultsSidebarReady(false));
  }, [dispatch]);

  // Фоллбэк на случай, если не пришло сообщение о готовности сайдбара.
  const frameLoadEndHandler = React.useCallback(() => setLoading(false), []);

  React.useEffect(() => {
    if (isLoading && isSidebarReady) {
      setLoading(false);
    }
  }, [isLoading, isSidebarReady]);

  return {
    isLoading,
    frameLoadStartHandler,
    frameLoadEndHandler,
  };
};
