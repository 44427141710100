import * as React from 'react';

/* istanbul ignore next */
export const SaveSearch: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92985 2C3.30651 2 2 3.30724 2 4.90814C2 7.04879 3.47642 9.06938 5.18121 10.6557C6.01024 11.4271 6.84355 12.0495 7.47135 12.4795C7.67187 12.6169 7.85044 12.7339 8 12.8293C8.14955 12.7339 8.32812 12.6169 8.52864 12.4795C9.15645 12.0495 9.98976 11.4271 10.8188 10.6557C12.5236 9.06938 14 7.04879 14 4.90814C14 3.30724 12.6935 2 11.0701 2C10.1422 2 9.31684 2.42687 8.77912 3.09519L8 4.06352L7.22088 3.09519C6.68316 2.42687 5.85776 2 4.92985 2ZM8 14C7.50995 14.8717 7.50968 14.8715 7.50939 14.8714L7.50867 14.871L7.50675 14.8699L7.50102 14.8666L7.48206 14.8558C7.46615 14.8467 7.44376 14.8337 7.41532 14.817C7.35847 14.7837 7.27737 14.7354 7.17548 14.6727C6.97183 14.5475 6.68444 14.3647 6.34114 14.1296C5.65645 13.6606 4.73976 12.9768 3.81879 12.1199C2.02358 10.4494 0 7.92407 0 4.90814C0 2.19223 2.2124 0 4.92985 0C6.09012 0 7.15776 0.399783 8 1.06787C8.84224 0.399783 9.90988 0 11.0701 0C13.7876 0 16 2.19223 16 4.90814C16 7.92407 13.9764 10.4494 12.1812 12.1199C11.2602 12.9768 10.3435 13.6606 9.65885 14.1296C9.31556 14.3647 9.02817 14.5475 8.82452 14.6727C8.72263 14.7354 8.64153 14.7837 8.58467 14.817C8.55624 14.8337 8.53385 14.8467 8.51794 14.8558L8.49898 14.8666L8.49324 14.8699L8.49133 14.871L8.49061 14.8714C8.49031 14.8715 8.49005 14.8717 8 14ZM8 14L8.49005 14.8717L8 15.1472L7.50995 14.8717L8 14ZM7.5093 13.1287C7.5092 13.1287 7.5092 13.1287 7.50931 13.1287L7.5093 13.1287Z"
        fill="currentColor"
      />
    </svg>
  );
};

SaveSearch.displayName = 'SaveSearch';
