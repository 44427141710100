import * as React from 'react';
import { useSelector } from 'react-redux';

import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';

import { useFeaturesContext } from '../context';

interface IDetailsVisibilityManagerProps {
  onVisibilityChange(isVisible: boolean): void;
}

export const DetailsVisibilityManager: React.FC<IDetailsVisibilityManagerProps> = ({ onVisibilityChange }) => {
  const { pointsLayer, hotspotsLayer } = useFeaturesContext();
  const detailsVisiblePrecision = useSelector<IApplicationState, number>(state => state.detailsVisiblePrecision);
  const { precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);

  const isDetailsVisibleByPrecision = precision >= detailsVisiblePrecision;

  React.useEffect(() => {
    const isDetailsVisible = isDetailsVisibleByPrecision;

    pointsLayer.isDetailsVisible = isDetailsVisible;
    pointsLayer.layer.update();
    hotspotsLayer.isDetailsVisible = isDetailsVisible;
    hotspotsLayer.layer.update();

    onVisibilityChange(isDetailsVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailsVisibleByPrecision]);

  return null;
};
