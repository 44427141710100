import * as React from 'react';

import { ControlButton } from 'shared/map-search/components/ControlButton';
import { TControlButtonSize } from 'shared/map-search/components/ControlButton/types';

import * as styles from './styles.css';

export interface IGeoLocationButtonProps {
  ymaps: YMaps.IYMaps;
  map: YMaps.Map;
  onGeoLocationError(error: Error | string): void;
  size: TControlButtonSize;
  icon: React.ReactNode;
}

export const GeoLocationButton: React.FC<IGeoLocationButtonProps> = props => {
  const { ymaps, map, onGeoLocationError, size, icon } = props;
  const [geoLocationMarker, setGeoLocationMarker] = React.useState<YMaps.IGeoObjectCollection | null>(null);

  const handleGeoLocationClick = () => {
    ymaps.geolocation
      .get({
        mapStateAutoApply: true,
      })
      .then(({ geoObjects }) => {
        if (geoLocationMarker != null) {
          map.geoObjects.remove(geoLocationMarker);
        }

        const iconLayoutTemplate = `<div class="${styles['icon']}" />`;
        const iconLayout = ymaps.templateLayoutFactory.createClass(iconLayoutTemplate);

        geoObjects.options.set('iconLayout', iconLayout);
        geoObjects.options.set('zIndex', 1);

        map.geoObjects.add(geoObjects);
        setGeoLocationMarker(geoObjects);
      })
      .catch(onGeoLocationError);
  };

  return (
    <ControlButton tooltip="Моё местоположение" onClick={handleGeoLocationClick} size={size}>
      {icon}
    </ControlButton>
  );
};
