/**
 * FIXME убрать зависимость фильтров от карты
 */
// eslint-disable-next-line import/no-restricted-paths
import { ITypedReduxAction } from 'shared/map-search/types/redux/actionType';

export enum ESuggestionsActionType {
  Loading = 'suggestions/getSuggestions/loading',
  Succeed = 'suggestions/getSuggestions/succeed',
  Failed = 'suggestions/getSuggestions/failed',
}

export type TGetSuggestionsLoading = ITypedReduxAction<ESuggestionsActionType.Loading>;
export type TGetSuggestionsSucceed = ITypedReduxAction<ESuggestionsActionType.Succeed>;
export type TGetSuggestionsFailed = ITypedReduxAction<ESuggestionsActionType.Failed>;

export type TSuggestionsActions = TGetSuggestionsLoading | TGetSuggestionsSucceed | TGetSuggestionsFailed;
