import { eventHandler } from './eventHandler';
import { eventSiteHandler } from './eventSiteHandler';
import { pageviewSiteHandler } from './pageviewSiteHandler';
import { teaserHandler } from './teaserHandler';
import { IAnalyticsMessage } from '../../types';

let latestPage: unknown;

export function analyticsMessageHandler(message: IAnalyticsMessage) {
  const [eventType, eventParams] = message.value;
  switch (eventType) {
    case 'eventSite':
      eventSiteHandler(message.value, latestPage);
      break;

    case 'pageviewSite':
      pageviewSiteHandler(message.value);
      latestPage = message.value[1].page || null;
      break;

    case 'teaser':
      teaserHandler(message.value, latestPage);
      break;

    case 'event':
    case 'eventEbc':
    case 'eventEnrich':
      eventHandler(eventType, eventParams, latestPage);
      break;
  }
}
