import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackFiltersClick } from './analytics';
import * as styles from './styles.css';
import { FiltersButton } from '../../../components/Navigation/buttons';
import { selectFiltersUrl } from '../../../selectors/urls';

export const FiltersButtonContainer: React.FC = () => {
  const url = useSelector(selectFiltersUrl);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const href = event.currentTarget.href;
    trackFiltersClick();

    setTimeout(() => {
      document.cookie = 'flavour=mobile;domain=.cian.ru;path=/';
      window.location.href = href;
    }, 300);
  }, []);

  return (
    <div className={styles['filters']}>
      <FiltersButton url={url} onClick={handleClick} size="L" />
    </div>
  );
};
