import { Outside } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './InfrastructureDropdown.css';

interface IInfrastructureDropdownProps {
  open: boolean;
  insideRefs: React.RefObject<Element>[];
  onClose(): void;
}

export function InfrastructureDropdown({
  open,
  onClose,
  insideRefs,
  children,
}: React.PropsWithChildren<IInfrastructureDropdownProps>) {
  const containerRef = React.useRef<HTMLDivElement>(null);

  if (!open) {
    return null;
  }

  return (
    <Outside active={open} onOutside={onClose} insideRefs={[...insideRefs, containerRef]}>
      <div ref={containerRef} className={styles['dropdown']}>
        {children}
      </div>
    </Outside>
  );
}
