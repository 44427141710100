import * as React from 'react';
import { useSelector } from 'react-redux';

import { useLoading } from './hooks/useLoading';
import { SearchResults } from '../../components/SearchResults';
import { selectUrl } from '../../selectors/searchResults';

export const SearchResultsContainer: React.FC = () => {
  const url = useSelector(selectUrl);

  const { isLoading, frameLoadStartHandler, frameLoadEndHandler } = useLoading();

  return (
    <SearchResults
      url={url}
      loading={isLoading}
      onFrameLoadStart={frameLoadStartHandler}
      onFrameLoadEnd={frameLoadEndHandler}
    />
  );
};

SearchResultsContainer.displayName = 'SearchResultsContainer';
