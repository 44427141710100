import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useGetFskBanner } from './useGetFskBanner';
import { useProPlusBanner } from './useProPlusBanner';
import { useRealtorReportBanner } from './useRealtorReportBanner';
import { EBanners } from '../../../constants/banners';
import { selectJsonQuery } from '../../../selectors/results';
import { selectIsUserAgent } from '../../../selectors/user';
import { isProPlus } from '../../../utils/bannerPackages';
import { useApplicationContext } from '../../ApplicationContext';

export function useGetCurrentBanner(): EBanners | null {
  const deviceType = useDeviceType();
  const context = useApplicationContext();
  const jsonQuery = useSelector(selectJsonQuery);

  const userIsAgent = useSelector(selectIsUserAgent);

  const proPlusBannerType = useProPlusBanner();
  const fskBannerType = useGetFskBanner(proPlusBannerType);
  const realtorReportBannerType = useRealtorReportBanner();
  const isDesktop = deviceType === 'desktop';

  const isMobileNewbuildingPromoBannerEnabled = React.useMemo(
    () => context.config.get<boolean>('mapSearchFrontend.mobileNewbuildingPromoBanner') || false,
    [],
  );

  if (fskBannerType) {
    return EBanners.Fsk;
  }

  const leadsBannerType = !userIsAgent && isDesktop && EBanners.Leads;

  if (isProPlus(jsonQuery, deviceType, isMobileNewbuildingPromoBannerEnabled)) {
    const desktopStubType = realtorReportBannerType || leadsBannerType || null;
    const mobileStubType = fskBannerType || realtorReportBannerType || EBanners.Empty;
    const stubBannerType = isDesktop ? desktopStubType : mobileStubType;

    return proPlusBannerType || stubBannerType;
  }

  return realtorReportBannerType || leadsBannerType || null;
}
