import * as React from 'react';

import { NavigationDesktopContainer } from './NavigationDesktopContainer';
import { NavigationMobileContainer } from './NavigationMobileContainer';
import { useDeviceType } from '../../../common/packages/Responsive';

export const NavigationContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const isMobile = deviceType !== 'desktop';

  if (isMobile) {
    return <NavigationMobileContainer />;
  }

  return <NavigationDesktopContainer />;
};

NavigationContainer.displayName = 'NavigationContainer';
