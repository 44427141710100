import { NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

export function getSubsidizedMortgageModifierValue(values: NonEmptyArray<string> | null): boolean | null {
  if (!values) {
    return values;
  }

  return values.some(item => item === SUBSIDIZED_MORTGAGE_OPTION_VALUE) || null;
}
