import { Tooltip } from '@cian/ui-kit';
import { IconHeartOn16, IconHeartOff16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './FavoriteButton.css';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface IFavoriteButtonProps extends Omit<HTMLButtonProps, 'type' | 'className' | 'style'> {
  isFavorite?: boolean;
  tooltip: string;
}

export const FavoriteButton: React.FunctionComponent<IFavoriteButtonProps> = ({ isFavorite, tooltip, ...props }) => {
  const Icon = isFavorite ? IconHeartOn16 : IconHeartOff16;

  return (
    <Tooltip title={tooltip}>
      <button {...props} className={styles['button']} type="button">
        <Icon />
      </button>
    </Tooltip>
  );
};
