/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IQueryToGeoTagsResponseSchema } from '../../entities/schemas/QueryToGeoTagsResponseSchema';

export type TQueryToGeoTagsModel = IModel<IQueryToGeoTagsRequest, TQueryToGeoTagsResponse>;

export interface IQueryToGeoTagsRequest {
  jsonQuery?: any | null;
  queryString?: string | null;
}

export type TQueryToGeoTagsResponse = IQueryToGeoTagsResponse200 | IQueryToGeoTagsResponse400;

export interface IQueryToGeoTagsResponse200 extends IModelResponse<IQueryToGeoTagsResponse> {
  statusCode: 200;
}

export interface IQueryToGeoTagsResponse400 extends IModelResponse<IQueryToGeoTagsResponseError> {
  statusCode: 400;
}

export interface IQueryToGeoTagsResponse {
  data: IQueryToGeoTagsResponseSchema;
  status: EStatus;
}

export interface IQueryToGeoTagsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IQueryToGeoTagsResponse | IQueryToGeoTagsResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IQueryToGeoTagsResponse): TResponse200;
  400(response: IQueryToGeoTagsResponseError): TResponse400;
}
