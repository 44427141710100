import * as React from 'react';

import { ESuburbanOfferFilter } from 'shared/common/packages/api-models/common/json_query';

import { Select, Option } from '../../Select';

interface IPosessorType {
  label: string;
  value: ESuburbanOfferFilter;
}

const POSESSOR_TYPES: IPosessorType[] = [
  { label: 'От любого владельца', value: ESuburbanOfferFilter.Any },
  { label: 'От застройщика', value: ESuburbanOfferFilter.Builder },
  { label: 'От собственников и агентов', value: ESuburbanOfferFilter.Individual },
];

interface IPosessorProps {
  value: ESuburbanOfferFilter | null;
  onChange(value: ESuburbanOfferFilter | null): void;
}

export const Posessor: React.FC<IPosessorProps> = ({ value, onChange }) => {
  return (
    <Select
      data-name="FilterPosessor"
      withoutArrow
      placeholder="Владелец"
      value={value || ESuburbanOfferFilter.Any}
      onChange={(_, value: ESuburbanOfferFilter) => onChange(value !== ESuburbanOfferFilter.Any ? value : null)}
    >
      {POSESSOR_TYPES.map(posessorType => (
        <Option key={`${posessorType.label}_${posessorType.value}`} value={posessorType.value}>
          {posessorType.label}
        </Option>
      ))}
    </Select>
  );
};
