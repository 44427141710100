import * as React from 'react';

import { Desktop } from 'shared/common/packages/Responsive';
import { MapLayer } from 'shared/map-search/components/MapLayer';

import { HelpLinkButton } from './HelpLinkButton';
import * as styles from './styles.css';
import { trackHelpClick } from './tracking';
import { useFeedbackModal } from './useFeedbackModal';

export const HelpLinkButtonContainer: React.FC = () => {
  const url = '/contacts/?from_map=1';
  const { openFeedback } = useFeedbackModal();

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      trackHelpClick();
      openFeedback();
    },
    [openFeedback],
  );

  return (
    <Desktop>
      <MapLayer className={styles['layer']}>
        <HelpLinkButton onClick={handleClick} href={url} />
      </MapLayer>
    </Desktop>
  );
};

HelpLinkButtonContainer.displayName = 'HelpLinkButtonContainer';
