import cx from 'clsx';

import * as styles from './styles.css';

interface ISpinnerProps {
  className?: string;
}

export function Spinner({ className }: ISpinnerProps) {
  return `
    <div class="${cx(styles['spinner'], className)}">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.94484 4.19312C7.58071 4.19312 7.28278 3.89519 7.28278 3.53105V0.882772C7.28278 0.518634 7.58071 0.220703 7.94484 0.220703C8.30898 0.220703 8.60691 0.518634 8.60691 0.882772V3.53105C8.60691 3.89519 8.30898 4.19312 7.94484 4.19312Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.82075 4.69579C9.50538 4.51372 9.39636 4.10677 9.57843 3.7914L10.9026 1.49799C11.0846 1.18263 11.4916 1.07361 11.807 1.25568C12.1223 1.43775 12.2313 1.8447 12.0493 2.16006L10.7251 4.45347C10.5431 4.76884 10.1361 4.87786 9.82075 4.69579Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.1939 6.06892C11.0118 5.75355 11.1209 5.3466 11.4362 5.16453L13.7296 3.84039C14.045 3.65833 14.4519 3.76735 14.634 4.08271C14.8161 4.39808 14.7071 4.80503 14.3917 4.9871L12.0983 6.31124C11.7829 6.4933 11.376 6.38428 11.1939 6.06892Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6965 7.94478C11.6965 7.58065 11.9945 7.28271 12.3586 7.28271H15.0069C15.371 7.28271 15.6689 7.58065 15.6689 7.94478C15.6689 8.30892 15.371 8.60685 15.0069 8.60685H12.3586C11.9945 8.60685 11.6965 8.30892 11.6965 7.94478Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.1939 9.82075C11.376 9.50538 11.7829 9.39636 12.0983 9.57843L14.3917 10.9026C14.7071 11.0846 14.8161 11.4916 14.634 11.807C14.4519 12.1223 14.045 12.2313 13.7296 12.0493L11.4362 10.7251C11.1209 10.5431 11.0118 10.1361 11.1939 9.82075Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.82075 11.1939C10.1361 11.0118 10.5431 11.1209 10.7251 11.4362L12.0493 13.7296C12.2313 14.045 12.1223 14.4519 11.807 14.634C11.4916 14.8161 11.0846 14.7071 10.9026 14.3917L9.57843 12.0983C9.39636 11.7829 9.50538 11.376 9.82075 11.1939Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.94484 11.6965C8.30898 11.6965 8.60691 11.9945 8.60691 12.3586V15.0069C8.60691 15.371 8.30898 15.6689 7.94484 15.6689C7.58071 15.6689 7.28278 15.371 7.28278 15.0069V12.3586C7.28278 11.9945 7.58071 11.6965 7.94484 11.6965Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.06898 11.1939C6.38435 11.376 6.49337 11.7829 6.3113 12.0983L4.98716 14.3917C4.80509 14.7071 4.39814 14.8161 4.08277 14.634C3.76741 14.4519 3.65839 14.045 3.84046 13.7296L5.16459 11.4362C5.34666 11.1209 5.75361 11.0118 6.06898 11.1939Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.69579 9.82075C4.87786 10.1361 4.76884 10.5431 4.45347 10.7251L2.16006 12.0493C1.8447 12.2313 1.43775 12.1223 1.25568 11.807C1.07361 11.4916 1.18263 11.0846 1.49799 10.9026L3.7914 9.57843C4.10677 9.39636 4.51372 9.50538 4.69579 9.82075Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.19312 7.94478C4.19312 8.30892 3.89519 8.60685 3.53105 8.60685H0.882772C0.518634 8.60685 0.220703 8.30892 0.220703 7.94478C0.220703 7.58065 0.518634 7.28271 0.882772 7.28271H3.53105C3.89519 7.28271 4.19312 7.58065 4.19312 7.94478Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.69579 6.06892C4.51372 6.38428 4.10677 6.4933 3.7914 6.31124L1.49799 4.9871C1.18263 4.80503 1.07361 4.39808 1.25568 4.08271C1.43775 3.76735 1.8447 3.65833 2.16006 3.84039L4.45347 5.16453C4.76884 5.3466 4.87786 5.75355 4.69579 6.06892Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.06898 4.69579C5.75361 4.87786 5.34666 4.76884 5.16459 4.45347L3.84046 2.16006C3.65839 1.8447 3.76741 1.43775 4.08277 1.25568C4.39814 1.07361 4.80509 1.18263 4.98716 1.49799L6.3113 3.7914C6.49337 4.10677 6.38435 4.51372 6.06898 4.69579Z"
          fill="currentColor"
        />
      </svg>
    </div>
  `;
}
