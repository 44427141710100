import { ca } from '@cian/analytics';

interface IEventData {
  action: string;
  category: string;
  page: unknown;
  products: unknown;
}

export const trackVideoPlaying = (eventData: IEventData) => {
  ca('eventSite', eventData);
};
