import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import * as styles from './LandArea.css';
import { getLandAreaLabel } from './helpers';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';

interface ILandAreaProps {
  landArea: IJsonQueryRangeValue | null;
  onLandAreaMinChange(gte: number | null): void;
  onLandAreaMaxChange(lte: number | null): void;
}

export const LandArea: React.FC<ILandAreaProps> = ({ landArea, onLandAreaMinChange, onLandAreaMaxChange }) => {
  const { gte: landAreaMin = null, lte: landAreaMax = null } = landArea || {};
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const landAreaLabel = getLandAreaLabel(landAreaMin, landAreaMax);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleLandAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onLandAreaMinChange(gte);
  }, 300);

  const handleLandAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onLandAreaMaxChange(lte);
  }, 300);

  return (
    <Outside onOutside={handleClose} insideRefs={[dropdownRef]} active={isOpen}>
      <div className={styles['container']} data-name="FilterArea">
        <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
          {landAreaLabel || 'Площадь'}
        </Button>
        {isOpen && (
          <div ref={dropdownRef} className={styles['dropdown']}>
            <RangeInput
              min={0}
              max={99999}
              joined={false}
              postfix="сот."
              valueMin={landAreaMin}
              valueMax={landAreaMax}
              onChangeMin={handleLandAreaMinChange}
              onChangeMax={handleLandAreaMaxChange}
            />
          </div>
        )}
      </div>
    </Outside>
  );
};
