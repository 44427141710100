import { getGlobalEventsLogs, IEvent, createConsumer, createSubscriber } from '@cian/events-log';
import * as React from 'react';

const URL = '/contacts/?from_map=1';

export function useFeedbackModal() {
  const timeoutRef = React.useRef<number | null>(null);

  const createRedirectFallback = React.useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      window.location.href = URL;
    }, 50);
  }, []);

  const openFeedback = React.useCallback(() => {
    const globalLog = getGlobalEventsLogs();
    globalLog.produce('feedback', { type: 'open', value: true });

    createRedirectFallback();
  }, [createRedirectFallback]);

  React.useEffect(() => {
    const feedbackConsumer = createConsumer({ topic: 'feedback' });
    const feedbackSubscriber = createSubscriber((event: IEvent<unknown>) => {
      if (event.type === 'popup_opened' && timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    });
    feedbackConsumer.subscribe(feedbackSubscriber);

    return () => {
      feedbackConsumer.unsubscribe(feedbackSubscriber);
      feedbackConsumer.destroy();
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { openFeedback };
}
