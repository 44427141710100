import { isExperimentActive, selectExperiments } from './experiments';
import { AUD_MOBILE_WEB_TO_APP_MAP_LOCK } from '../../constants/config';
import { IApplicationState } from '../../types/redux';

export const selectIsMapBlockingExperimentGroupB = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  const bGroup = isExperimentActive(experiments, AUD_MOBILE_WEB_TO_APP_MAP_LOCK, 'B');

  return bGroup;
};

export const selectIsMapBlockingExperimentGroupC = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  const cGroup = isExperimentActive(experiments, AUD_MOBILE_WEB_TO_APP_MAP_LOCK, 'C');

  return cGroup;
};
