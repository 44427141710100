import { EBannerStatus } from './banner';
import { INewbuildingForPromoInventory } from './newbuildings';

export enum ENewbuildingsForPromoInventoryStatus {
  Initial = 'initial',
  Succeed = 'succeed',
}

export interface IPromoInventory {
  newbuildings: INewbuildingForPromoInventory[];
  activeBannerIndex: number | null;
  bannerStatus: EBannerStatus;
  isForceClosedBanner: boolean;
  fetchingStatus: ENewbuildingsForPromoInventoryStatus;
}
