import { IInfrastructureType } from '../../types/infrastructure';
import { IApplicationState } from '../../types/redux';

export function selectActiveType(state: IApplicationState): IInfrastructureType | undefined {
  if (!state.infrastructure.activeType) {
    return;
  }

  return state.infrastructure.types[state.infrastructure.activeType];
}
