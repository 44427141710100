export const YMAPS_MODULES: string[] = [
  'Map',
  'ObjectManager',
  'shape.Rectangle',
  'geometry.pixel.Rectangle',
  'geolocation',
  'behavior.Ruler',
  'hotspot.Layer',
  'GeoObject',
  'Polygon',
  'templateLayoutFactory',
  'Balloon',
];

export const MODULES: string[] = [
  ...YMAPS_MODULES,
  // Наши
  'PointsLayer',
  'InfrastructurePointsLayer',
  'InfrastructureHTMLPointsLayer',
  'cian.DrawPolygon',
  'HotspotsLayer',
  'BalloonManager',
  'PopupManager',
  'NewbuildingsPolygonsManager',
  'NewbuildingsPromoPinsManager',
  'NewbuildingPromoPopupManager',
];

export const MAP_DETAILS_VISIBLE_PRECISION = 10;
export const TILE_SIZE = 256;
export const POINT_RADIUS = 7;
export const POINT_SHADOW_X_RADIUS = 8;
export const POINT_SHADOW_Y_RADIUS = 3.5;
export const POINT_SHADOW_Y_OFFSET = 4.5;
export const DOT_SIZE_SMALL = 8;
export const DOT_SIZE_BIG = 11;
