import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { POPUP_DEFAULT_OPTIONS } from './constants';
import * as styles from './styles.css';
import { getContainerPopupPlacement, EPopupPlacement } from './utils';
import { NewbuildingPromoStubPopupMarkup, NewbuildingPromoStubPopup } from '../../../components/NewbuildingPromoPopup';
import { NewbuildingPromoPopupLoadable } from '../../../containers/NewbuildingPromoPopupContainer';

export function defineNewbuildingPromoPopupManager(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('NewbuildingPromoPopupManager')) {
    return;
  }

  ymaps.modules.define<[typeof YMaps.Balloon, YMaps.ITemplateLayoutFactory]>(
    'NewbuildingPromoPopupManager',
    ['Balloon', 'templateLayoutFactory'],
    (provide, Balloon: typeof YMaps.Balloon, templateLayoutFactory) => {
      class NewbuildingPromoPopupManager {
        private static templateContainerId = 'newbuilding-promo-popup-id';

        private map: YMaps.Map;
        private balloon: YMaps.Balloon;

        public constructor({ map }: YMaps.INewbuildingPromoPopupManagerParameters) {
          this.map = map;
          this.balloon = new Balloon(this.map, {
            ...POPUP_DEFAULT_OPTIONS,
            layout: this.preparePopupLayout(),
          });
          this.balloon.options.setParent(this.map.options);
        }

        public getBalloon = () => {
          return this.balloon;
        };

        public setIndent = (indent: YMaps.TPopupIndent) => {
          this.balloon.options.set<YMaps.TPopupIndent>('indent', indent);
        };

        public setState = async (state: YMaps.TNewbuildingPromoPopupManagerState) => {
          if (state.status === 'error') {
            this.balloon.close();

            return;
          }
          this.balloon.options.set<YMaps.TNewbuildingPromoPopupManagerState>(state);

          const container = document.getElementById(NewbuildingPromoPopupManager.templateContainerId);

          if (state.status === 'pending' && container) {
            createRoot(container).render(React.createElement(NewbuildingPromoStubPopup));
          }

          if (state.status === 'completed' && container) {
            createRoot(container).render(
              React.createElement(NewbuildingPromoPopupLoadable, {
                payload: state,
              }),
            );
          }
        };

        public open = (coordinates: YMaps.TCoord) => {
          const status = this.balloon.options.get('status');

          if (status === 'error') {
            this.balloon.close();

            return;
          }

          const mapBounds = this.map.getBounds({ useMapMargin: true });
          const zoom = this.map.getZoom();
          const containerPopupPlacement = getContainerPopupPlacement(coordinates, mapBounds, zoom);

          this.balloon.options.set<EPopupPlacement>('placement', containerPopupPlacement);
          this.balloon.open(coordinates);
        };

        public close = () => {
          this.balloon.close();
        };

        private preparePopupLayout = () => {
          const content = this.preparePopupContent();

          return templateLayoutFactory.createClass(content);
        };

        private preparePopupContent() {
          return `
            <div class="${styles['wrapper']}
              {% if options.indent === 'S' %} ${styles['indent--small']}
              {% elseif options.indent === 'M' %} ${styles['indent--medium']}
              {% elseif options.indent === 'L' %} ${styles['indent--large']}
              {% elseif options.indent === 'XL' %} ${styles['indent--extra-large']}{% endif %}
              {% if options.placement === '${EPopupPlacement.Left}' %} ${styles['placement--left']}
              {% elseif options.placement === '${EPopupPlacement.Right}' %} ${styles['placement--right']}
              {% elseif options.placement === '${EPopupPlacement.Top}' %} ${styles['placement--top']}
              {% elseif options.placement === '${EPopupPlacement.Bottom}' %} ${styles['placement--bottom']}
              {% elseif options.placement === '${EPopupPlacement.TopRight}' %} ${styles['placement--top-right']}
              {% elseif options.placement === '${EPopupPlacement.TopLeft}' %} ${styles['placement--top-left']}
              {% elseif options.placement === '${EPopupPlacement.BottomRight}' %} ${styles['placement--bottom-right']}
              {% elseif options.placement === '${EPopupPlacement.BottomLeft}' %} ${styles['placement--bottom-left']}{% endif %}
            ">
              <div class="${styles['container']}">
                <div id="${NewbuildingPromoPopupManager.templateContainerId}">
                    ${NewbuildingPromoStubPopupMarkup}
                </div>
              </div>
            </div>
          `;
        }
      }

      provide(NewbuildingPromoPopupManager);
    },
  );
}
