import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Desktop } from 'shared/common/packages/Responsive';
import { disableRuler, enableRuler } from 'shared/map-search/actions/ruler';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { IApplicationState } from 'shared/map-search/types/redux';

import { RulerButton } from './RulerButton';
import { trackRulerClick } from './tracking';

export const RulerButtonContainer: React.FC = props => {
  const dispatch = useDispatch();
  const { map } = useMapContext();
  const isRulerEnabled = useSelector((state: IApplicationState) => state.ruler.isEnabled);

  const handleClick = React.useCallback(() => {
    if (!isRulerEnabled) {
      dispatch(enableRuler());
    } else {
      dispatch(disableRuler());
    }

    trackRulerClick();
  }, [dispatch, isRulerEnabled]);

  return (
    <Desktop>
      <RulerButton {...props} map={map} isRulerEnabled={isRulerEnabled} onClick={handleClick} />
    </Desktop>
  );
};
