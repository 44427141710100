import { useEventCallback } from '@cian/react-utils';
import { useMemo, useRef } from 'react';

import { TFilter } from 'shared/common/packages/Filters';

export const useAppliedFilter = () => {
  const appliedFilterKey = useRef<TFilter | null>(null);

  const setAppliedFilter = useEventCallback((filter: TFilter) => {
    appliedFilterKey.current = filter;
  });

  const resetAppliedFilter = useEventCallback(() => {
    appliedFilterKey.current = null;
  });

  const getAppliedFilterKey = useEventCallback(() => appliedFilterKey.current);

  return useMemo(
    () => ({ getAppliedFilterKey, setAppliedFilter, resetAppliedFilter }),
    [getAppliedFilterKey, resetAppliedFilter, setAppliedFilter],
  );
};
