import * as styles from './styles.css';
import { ControlButton } from '../../ControlButton';

interface ISaveSearchButtonProps {
  onClick(): void;
}

export function SaveSearchButton({ onClick }: ISaveSearchButtonProps) {
  return (
    <ControlButton title="Сохранить поиск" className={styles['navigation-btn']} onClick={onClick} size="L">
      <span className={styles['title']}>Сохранить поиск</span>
    </ControlButton>
  );
}
