require('./criticalResources');

import { initGlobalEventsLogs } from '@cian/events-log';

import { initDependencies, setupDependencies } from './app';

setupDependencies();
initDependencies();
initGlobalEventsLogs();

const { runApp } = require('./services/runApp');
runApp();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept();
}
