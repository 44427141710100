import { createContext } from './createContext';
import { ensurePageCa } from '../../utils/ensurePageCa';
import { createApplicationState } from '../applicationState';
import { messageEventManager } from '../messageEventManager';
import { queryStringUpdater } from '../queryStringUpdater';
import { renderApplication } from '../renderApplication';
import { consumeYmapsLoaderEvents } from '../ymapsLoaderEventsConsumer';

export function runApp() {
  const context = createContext();
  const store = createApplicationState(context);

  ensurePageCa(context);
  consumeYmapsLoaderEvents(context);

  store.subscribe(() => queryStringUpdater(store.getState()));
  messageEventManager.init(store);

  renderApplication({ store, context });
}
