import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNewbuildingPromoPins } from '../../../actions/newbuildingPromoFeatures';
import { fetchClusters } from '../../../actions/results';
import { TMapBounds } from '../../../types/mapBounds';
import { IApplicationState, TThunkDispatch } from '../../../types/redux';
import { useApplicationContext } from '../../ApplicationContext';

export const MapFeaturesFetcher: React.FC = () => {
  const { config } = useApplicationContext();
  const deviceType = useDeviceType();
  const dispatch = useDispatch<TThunkDispatch>();
  const mapBounds = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const fetchDelayAlgorithm = React.useRef<'throttle' | 'debounce'>('throttle');
  const fetchDelayTime = React.useRef<number>(1000);

  React.useEffect(() => {
    fetchDelayAlgorithm.current = config.get('mapSearchFrontend.fetchDelayAlgorithm') || 'throttle';
    fetchDelayTime.current = config.get('mapSearchFrontend.fetchDelayTime') || 1000;
  }, [config]);

  React.useEffect(() => {
    dispatch(fetchClusters({ deviceType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds]);

  React.useEffect(() => {
    dispatch(fetchNewbuildingPromoPins());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
