import { useCallback, useEffect, useState } from 'react';

import { useContext } from 'shared/common/packages/Filters/shared/utils/useContext';

import { useVisibility } from './useVisibility';
import { getPromoSearchTypesOnboardingCookie, setPromoSearchTypesOnboardingCookie } from '../../../utils';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
  ref: React.MutableRefObject<HTMLElement | null>;
}

export const usePromoSearchTypesOnboarding = (): IOnboardingTooltipResult => {
  const { features } = useContext();

  const { ref, isVisible } = useVisibility();

  const isOnboardingEnabled = features.newbuildingPromoSearchTypesOnboardingEnabled;
  const isOnboardingShown = getPromoSearchTypesOnboardingCookie();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOnboardingEnabled && !isOnboardingShown) {
      setOpen(isVisible);
    }
  }, [isVisible, isOnboardingEnabled, isOnboardingShown]);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setPromoSearchTypesOnboardingCookie();
  }, []);

  return {
    ref,
    open,
    closeHandler,
  };
};
