import { EGetUserStatusActionType } from './types';
import {
  fetchGetUserDataByRequest,
  TGetUserDataByRequestResponse,
} from '../../../common/repositories/monolith-python/v1/get-user-data-by-request';
import { prepareUser } from '../../mappers/user';
import { TThunkAction } from '../../types/redux';
import { TUser } from '../../types/user';
import { ResponseError } from '../../utils/errors/ResponseError';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EGetUserStatusActionType.Loading>(EGetUserStatusActionType.Loading);
const setStatusSucceed = actionGenerator<EGetUserStatusActionType.Succeed, TUser>(EGetUserStatusActionType.Succeed);
const setStatusFailed = actionGenerator<EGetUserStatusActionType.Failed>(EGetUserStatusActionType.Failed);

interface IGetUserParameters {
  timeout?: number;
}

export function getUser(parameters: IGetUserParameters = {}): TThunkAction<Promise<TUser | null>> {
  const { timeout } = parameters;

  return async (dispatch, _getState, context) => {
    const {
      httpApi,
      custom: { subdomain },
    } = context;

    dispatch(setStatusLoading());

    try {
      const userData: TGetUserDataByRequestResponse = await fetchGetUserDataByRequest({
        httpApi,
        parameters: {},
        config: {
          subdomain,
          ecnhancersConfig: {
            timeout,
          },
        },
      });

      if (userData.statusCode !== 200) {
        throw new ResponseError({
          domain: 'user#getUser',
          message: userData.response.message,
          details: {
            error: userData.response.errors,
          },
        });
      }

      const {
        response: {
          data: { user: userRaw },
        },
      } = userData;

      const user = prepareUser(userRaw);

      dispatch(setStatusSucceed(user));

      return user;
    } catch (error) {
      const { logger } = context;

      logger.error(error);

      dispatch(setStatusFailed());

      return null;
    }
  };
}
