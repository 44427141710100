import loadable from '@loadable/component';

export const Notifications = loadable(() => {
  if (process.env.IS_BROWSER_BUILD) {
    return import('./Notifications');
  }

  return Promise.resolve({
    default: () => null,
  });
});
