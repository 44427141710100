import { ca } from '@cian/analytics';

interface INewbuildingPromoHoverParams {
  label: string;
  products: unknown;
  page: unknown;
}

export function trackNewbuildingPromoHover({ label, products, page }: INewbuildingPromoHoverParams) {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Listing',
    name: 'oldevent',
    label,
    products,
    page,
  });
}
