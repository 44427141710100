import { TooltipDesktop } from '@cian/ui-kit/tooltip';
import cx from 'clsx';
import * as React from 'react';

import { SIZE_TO_CLASS } from './constants';
import * as styles from './styles.css';
import { IControlButtonProps } from './types';

export const ControlButton = React.forwardRef(
  (
    { children, size = 'M', rounded, active, className, tooltip, ...sharedProps }: IControlButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const classNames = cx(
      styles['container'],
      SIZE_TO_CLASS[size],
      {
        [styles['active']]: active,
        [styles['rounded']]: rounded,
      },
      className,
    );

    if (!tooltip) {
      return (
        <button ref={ref} type="button" {...sharedProps} className={classNames}>
          {children}
        </button>
      );
    }

    return (
      <TooltipDesktop placement="left" theme="black" title={tooltip}>
        <button ref={ref} type="button" {...sharedProps} className={classNames}>
          {children}
        </button>
      </TooltipDesktop>
    );
  },
);

ControlButton.displayName = 'ControlButton';
