import { UnknownError } from '@cian/peperrors/shared';

import { ResponseError } from 'shared/common/errors';
import { TGeoValue } from 'shared/common/packages/api-models/common/json_query';
import { EObjectType } from 'shared/common/repositories/monolith-cian-realty/entities/Models/GeoLocationCatalogLink';
import {
  IApiGeoGeocodeCachedResponse200,
  IApiGeoGeocodeCachedResponse400,
  fetchApiGeoGeocodeCached,
} from 'shared/common/repositories/monolith-cian-realty/unversioned/api-geo-geocode-cached';
import {
  IApiGeoGeocodedForSearchResponse200,
  IApiGeoGeocodedForSearchResponse400,
  fetchApiGeoGeocodedForSearch,
} from 'shared/common/repositories/monolith-cian-realty/unversioned/api-geo-geocoded-for-search';
/**
 * FIXME убрать зависимость фильтров от карты
 */
// eslint-disable-next-line import/no-restricted-paths
import { TThunkAction } from 'shared/map-search/types/redux';
// eslint-disable-next-line import/no-restricted-paths
import { actionGenerator } from 'shared/map-search/utils/redux/actionGenerator';

import { geoObjectDetailsToGeoValue } from './helpers';
import { ESuggestionsActionType } from './types';

const setStatusLoading = actionGenerator<ESuggestionsActionType.Loading>(ESuggestionsActionType.Loading);
const setStatusSucceed = actionGenerator<ESuggestionsActionType.Succeed>(ESuggestionsActionType.Succeed);
const setStatusFailed = actionGenerator<ESuggestionsActionType.Failed>(ESuggestionsActionType.Failed);

interface IGeocodeResult {
  regionId: number | null;
  geoValue: TGeoValue;
}

/**
 * Плохое, но быстрое решение.
 * TODO: Сделать для фильтров свой store/другое решение с запросами
 */
export function geocode(query: string): TThunkAction<Promise<IGeocodeResult>> {
  return async (dispatch, _getState, context) => {
    const {
      httpApi,
      logger,
      custom: { subdomain },
    } = context;

    dispatch(setStatusLoading());

    try {
      const geocodeCachedItem = await fetchApiGeoGeocodeCached<
        IApiGeoGeocodeCachedResponse200,
        IApiGeoGeocodeCachedResponse400
      >({
        httpApi,
        parameters: {
          request: query,
        },
        config: {
          subdomain,
        },
      }).then(response => {
        if (response.statusCode !== 200) {
          throw new ResponseError({
            domain: 'geocode#geocodeCached',
            message: response.response.message,
            details: {
              error: response.response.errors,
            },
          });
        }

        return response.response.items[0];
      });

      const geocodeForSearch = await fetchApiGeoGeocodedForSearch<
        IApiGeoGeocodedForSearchResponse200,
        IApiGeoGeocodedForSearchResponse400
      >({
        httpApi,
        parameters: {
          address: geocodeCachedItem.text,
          kind: geocodeCachedItem.kind,
          lat: geocodeCachedItem.coordinates[1],
          lng: geocodeCachedItem.coordinates[0],
        },
        config: {
          subdomain,
          requestConfig: {
            headers: [['Content-type', 'application/x-www-form-urlencoded']],
          },
          bodyAsEncodeString: true,
        },
      }).then(response => {
        if (response.statusCode !== 200) {
          throw new ResponseError({
            domain: 'geocode#geocodeForSearch',
            message: response.response.message,
            details: {
              error: response.response.errors,
            },
          });
        }

        return response.response;
      });

      if (geocodeForSearch.isParsed) {
        const regionId: number | null = geocodeForSearch.regionId || null;
        let geoValue: TGeoValue;

        if (geocodeForSearch.geoLocationCatalogLink) {
          const { objectType, id } = geocodeForSearch.geoLocationCatalogLink;

          switch (objectType) {
            case EObjectType.NewObject:
              geoValue = {
                type: 'newobject',
                id,
              };

              break;
            default:
              throw new Error(`Unexpected geo location catalog objectType '${objectType}'`);
          }
        } else {
          if (geocodeForSearch.microDistricts && geocodeForSearch.microDistricts.length > 0) {
            geoValue = {
              type: 'district',
              id: geocodeForSearch.microDistricts[0].id,
            };
          } else {
            const geoObjectDetails = geocodeForSearch.details[geocodeForSearch.details.length - 1];

            geoValue = geoObjectDetailsToGeoValue(geoObjectDetails);
          }
        }

        dispatch(setStatusSucceed());

        return {
          regionId,
          geoValue,
        };
      }

      if (geocodeCachedItem.boundedBy) {
        const bounds = geocodeCachedItem.boundedBy.map(b => b.map(String) as [string, string]);

        const polygon: [string, string][] = [
          [bounds[0][0], bounds[0][1]],
          [bounds[0][0], bounds[1][1]],
          [bounds[1][0], bounds[1][1]],
          [bounds[1][0], bounds[0][1]],
          [bounds[0][0], bounds[0][1]],
        ];

        const geoValue: TGeoValue = {
          type: 'polygon',
          name: geocodeCachedItem.name,
          coordinates: polygon,
        };

        dispatch(setStatusSucceed());

        return {
          regionId: geocodeForSearch.regionId || null,
          geoValue,
        };
      }

      throw new UnknownError({
        domain: 'geocode#geocode',
        message: 'Unexpected geocode result',
        details: {
          geocodeCached: JSON.stringify(geocodeCachedItem),
          geocodeForSearch: JSON.stringify(geocodeForSearch),
        },
      });
    } catch (error) {
      logger.error(error);

      dispatch(setStatusFailed());

      throw error;
    }
  };
}
