import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeSearchResultsSidebar } from '../../actions/searchResults';
import { Sidebar } from '../../components/Sidebar';
import { selectIsDailyRent } from '../../selectors/results/selectIsDailyRent';
import { selectIsOpen } from '../../selectors/searchResults';
import { IApplicationState } from '../../types/redux/store';
import { CallPopupContainer } from '../CallPopupContainer';
import { SearchResultsContainer } from '../SearchResultsContainer';
import { SidebarHeaderContainer } from '../SidebarHeaderContainer';

export const SidebarContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const deviceType = useDeviceType();

  const resultsVersion = useSelector<IApplicationState, number | undefined>(state => state.results.version);
  const isDailyrent = useSelector(selectIsDailyRent(deviceType));

  React.useEffect(() => {
    if (isDailyrent) {
      return;
    }

    dispatch(closeSearchResultsSidebar());
  }, [resultsVersion, isDailyrent]);

  return (
    <Sidebar opened={isOpen}>
      <SidebarHeaderContainer />
      <SearchResultsContainer />
      <CallPopupContainer />
    </Sidebar>
  );
};

SidebarContainer.displayName = 'SidebarContainer';
