import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as styles from './Measure.css';
import { ITagProps } from '../../../Tag';

interface IMeasureProps {
  children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
  width: number;
  onMeasurementsChange(count: number, widthLeft: number): void;
}

export const Measure: React.FC<IMeasureProps> = ({ width, children, onMeasurementsChange }) => {
  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const tagsHash = JSON.stringify(children, circularReplacer());

  const measureOneLineTags = (): [number, number] => {
    if (!componentRef.current) {
      return [0, 0];
    }

    const tagElements = Array.from(componentRef.current.children);

    let visibleTags = 0;
    let widthLeft = width;
    for (let i = 0; i < tagElements.length; i++) {
      const tagElement = tagElements[i];
      const tagElementStyles = getComputedStyle(tagElement);
      const tagElementMargin = parseInt(tagElementStyles.marginRight, 10) || 0;
      const tagWidth = tagElement.getBoundingClientRect().width + tagElementMargin;

      if (widthLeft >= tagWidth) {
        visibleTags++;
        widthLeft -= tagWidth;
      } else {
        break;
      }
    }

    return [visibleTags, widthLeft];
  };

  useIsomorphicLayoutEffect(() => {
    onMeasurementsChange(...measureOneLineTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsHash, width]);

  return ReactDOM.createPortal(
    <div ref={componentRef} className={styles['container']} style={{ width: `${width}px` }}>
      {children}
    </div>,
    document.body,
  );
};

function circularReplacer() {
  const seen = new WeakSet();

  return (key: string, value: object): object | undefined => {
    if (key.startsWith('_')) {
      return undefined;
    }

    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return undefined;
      }

      seen.add(value);
    }

    return value;
  };
}
