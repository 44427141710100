import * as React from 'react';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { ZoomOut } from 'shared/map-search/components/Icons';
import { ZoomOutNew } from 'shared/map-search/components/Icons/ZoomOutNew';
import { useMapContext } from 'shared/map-search/containers/Map/context';

import { ZoomOutButton } from './ZoomOutButton';

export const ZoomOutButtonContainer: React.FC = props => {
  const { map } = useMapContext();

  return (
    <>
      <Desktop>
        <ZoomOutButton {...props} map={map} size="M" icon={<ZoomOut />} />
      </Desktop>
      <NotDesktop>
        <ZoomOutButton {...props} map={map} size="L" icon={<ZoomOutNew />} isMobile />
      </NotDesktop>
    </>
  );
};
