import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ENewbuildingPolygonActionType {
  NewbuildingPolygonSelected = 'newbuildingPolygon/selected',
}

export interface IActiveNewbuildingPolygon {
  newbuildingId?: number;
}

export type TNewbuildingPolygonSelectedAction = ITypedReduxAction<
  ENewbuildingPolygonActionType.NewbuildingPolygonSelected,
  IActiveNewbuildingPolygon
>;

export type TNewbuildingPolygonActions = TNewbuildingPolygonSelectedAction;
