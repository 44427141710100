import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeSearchResultsSidebar } from '../../actions/searchResults';
import { SidebarCommonHeader } from '../../components/SidebarCommonHeader';
import { selectNewbuildingId } from '../../selectors/features';
import { trackCloseSidebar } from '../../utils/tracking';

export const SidebarCommonHeaderContainer: React.FC = () => {
  const dispatch = useDispatch();
  const newbuildingId = useSelector(selectNewbuildingId);

  const handleClose = React.useCallback(() => {
    dispatch(closeSearchResultsSidebar());
    trackCloseSidebar(newbuildingId);
  }, [dispatch, newbuildingId]);

  return <SidebarCommonHeader onClose={handleClose} />;
};

SidebarCommonHeaderContainer.displayName = 'SidebarCommonHeaderContainer';
