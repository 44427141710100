import { INewbuildingContacts } from 'shared/map-search/types/phone/phone';
import { ITypedReduxAction } from 'shared/map-search/types/redux/actionType';

export enum EPhoneActionType {
  Open = 'newbuildingHeader/openContacts',
}

export type TPhonePopup = ITypedReduxAction<EPhoneActionType.Open, INewbuildingContacts>;

export type TPhoneActions = TPhonePopup;
