import { pathOr } from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setOffersCount } from 'shared/map-search/actions/offersCount';
import { IFeaturesMap, IRBushItem } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { EResultsStatus } from 'shared/map-search/types/results';

import { useFeaturesContext } from '../context';

const DEFAULT_FEATURES_VALUE = {};

export const OffersCounter: React.FC = () => {
  const dispatch = useDispatch();
  const { bounds, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const { rbush } = useFeaturesContext();
  const resultsStatus = useSelector<IApplicationState, EResultsStatus>(state => state.results.status);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>(DEFAULT_FEATURES_VALUE, [precision, 'features'], state.features),
  );

  React.useEffect(() => {
    if (!bounds || resultsStatus === EResultsStatus.Loading) {
      return;
    }

    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    const totalCount: number = featuresInBbox.reduce((acc: number, rbushItem: IRBushItem) => {
      // считаем количество объявлений без учета extended объявлений
      if (!rbushItem.feature.properties.isExtended) {
        // eslint-disable-next-line no-param-reassign
        acc += rbushItem.feature.properties.count;
      }

      return acc;
    }, 0);

    dispatch(setOffersCount(totalCount));
  }, [bounds, dispatch, rbush, resultsStatus, features, precision]);

  return null;
};
