import * as React from 'react';

import { EFacilityType, JsonQuery, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { Facilities } from '../../../components/advancedFilters/Facilities';
import { useContext } from '../../../utils/useContext';

export const FacilitiesContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const facilityTypes = new JsonQuery(jsonQuery).getFacilityTypes();

  const handleFacilityTypesChange = React.useCallback(
    (facilityTypes: NonEmptyArray<EFacilityType> | null) => {
      onChange({ action: 'setFacilityTypes', arguments: [facilityTypes] });
    },
    [onChange],
  );

  return <Facilities value={facilityTypes} onChange={handleFacilityTypesChange} />;
};
