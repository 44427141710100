import { IconArrowUpRight16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './CommercialLink.css';

interface ICommercialLinkProps {
  href: string;
}

export const CommercialLink: React.FC<ICommercialLinkProps> = ({ href }) => {
  return (
    <a href={href} className={styles['container']}>
      Коммерческая <IconArrowUpRight16 color="current_color" />
    </a>
  );
};
