/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetClustersForMapModel,
  IGetClustersForMapRequest,
  IMappers,
  IGetClustersForMapResponse,
  IGetClustersForMapResponseError,
  TGetClustersForMapResponse,
} from './types';

const defaultConfig: TGetClustersForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers-map',
  pathApi: '/v1/get-clusters-for-map/',
  hostType: 'api',
} as TGetClustersForMapModel;

function createGetClustersForMapModel(parameters: IGetClustersForMapRequest): TGetClustersForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetClustersForMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetClustersForMapRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetClustersForMap<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetClustersForMapOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetClustersForMapResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetClustersForMapModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetClustersForMapResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetClustersForMapResponseError);
    }
  }

  return { response, statusCode } as TGetClustersForMapResponse;
}

export { defaultConfig, createGetClustersForMapModel, fetchGetClustersForMap };
