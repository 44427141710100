import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FavoriteButtonContainer } from './FavoriteButtonContainer';
import { toggleFeaturesActiveStatus } from '../../actions/newbuildingPolygon';
import { closeSearchResultsSidebar } from '../../actions/searchResults';
import { SidebarNewbuildingHeader } from '../../components/SidebarNewbuildingHeader';
import { selectNewbuildingId, selectNewbuildingFicheringPlus } from '../../selectors/features';
import { selectActiveNewbuildingPolygon } from '../../selectors/newbuildingPolygons';
import { TThunkDispatch } from '../../types/redux';
import { trackCloseSidebar } from '../../utils/tracking';

export const SidebarNewbuildingHeaderContainer: React.FC = () => {
  const newbuildingId = useSelector(selectNewbuildingId);
  const isFicheringPlus = useSelector(selectNewbuildingFicheringPlus);
  const { geohashIds } = useSelector(selectActiveNewbuildingPolygon);

  const dispatch = useDispatch<TThunkDispatch>();

  const handleClose = React.useCallback(() => {
    dispatch(closeSearchResultsSidebar());
    trackCloseSidebar(newbuildingId, isFicheringPlus);
    dispatch(toggleFeaturesActiveStatus({ geohashIds, isActive: false }));
  }, [dispatch, newbuildingId, geohashIds, isFicheringPlus]);

  return <SidebarNewbuildingHeader favoriteButton={<FavoriteButtonContainer />} onClose={handleClose} />;
};

SidebarNewbuildingHeaderContainer.displayName = 'SidebarNewbuildingHeaderContainer';
