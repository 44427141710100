import { isAvailableRegion } from 'shared/common/packages/JsonQuery';

import { FromMKADContainer } from '../../containers/advancedFilters/FromMKADContainer';
import { IFilter } from '../../types/advancedFilters';
import { MOSCOW_ID, MOSCOW_REGION_ID } from '../regions';

export const fromMKAD: IFilter = {
  availability: isAvailableRegion([MOSCOW_ID, MOSCOW_REGION_ID]),
  component: FromMKADContainer,
};
