import { IDrawPolygonProcessStyle } from './types';

export const DEFAULT_TOLERANCE = 24;

// zIndex пейна событий карты по умолчанию равен 500.
// Подробней в документации: https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/map.pane.Manager-docpage/
const EVENTS_PANE_ZINDEX = 500;

export const PANE_ZINDEX = EVENTS_PANE_ZINDEX + 50;

export const DEFAULT_STYLE: IDrawPolygonProcessStyle = {
  color: '#2385de',
  width: 3,
  opacity: 1,
};
