import { IFeaturesMap } from '../../types/map';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ENewbuildingPromoFeaturesActionType {
  SetNewbuildingPromoFeatures = 'newbuildingPromoFeatures/setNewbuildingPromoFeatures',
  SetNewbuildingPromoVisibility = 'newbuildingPromoFeatures/setNewbuildingPromoVisibility',
}

export type TSetNewbuildingPromoFeatures = ITypedReduxAction<
  ENewbuildingPromoFeaturesActionType.SetNewbuildingPromoFeatures,
  IFeaturesMap
>;
export type TSetNewbuildingPromoVisibility = ITypedReduxAction<
  ENewbuildingPromoFeaturesActionType.SetNewbuildingPromoVisibility,
  boolean
>;

export type TNewbuildingPromoFeaturesActions = TSetNewbuildingPromoFeatures | TSetNewbuildingPromoVisibility;
