import {
  defineInfrastructureHTMLPointsLayer,
  defineInfrastructurePointsLayer,
  defineBalloonManager,
  defineDrawPolygon,
  defineHotspotsLayer,
  defineNewbuildingPromoPopupManager,
  defineNewbuildingsPolygonsManager,
  defineNewbuildingsPromoPinsManager,
  definePointsLayer,
  definePopupManager,
} from './ymapsModules';

export async function loadYMapsApi(modules?: string[]): Promise<YMaps.IYMaps> {
  return new Promise((resolve, reject) => {
    window.ymapsLoaded(ymaps => {
      definePointsLayer(ymaps);
      defineInfrastructurePointsLayer(ymaps);
      defineInfrastructureHTMLPointsLayer(ymaps);
      defineBalloonManager(ymaps);
      defineDrawPolygon(ymaps);
      defineHotspotsLayer(ymaps);
      definePopupManager(ymaps);
      defineNewbuildingsPolygonsManager(ymaps);
      defineNewbuildingsPromoPinsManager(ymaps);
      defineNewbuildingPromoPopupManager(ymaps);

      ymaps.ready({
        require: modules,
        successCallback: ymaps => resolve(ymaps),
        errorCallback: error => reject(error),
      });
    });
  });
}
