import { DEFAULT_TOLERANCE, DEFAULT_STYLE, PANE_ZINDEX } from './constants';
import { IDrawPolygonProcessStyle } from './types';
import { toPosition, simplifyCoordinates } from './utils';

interface IDrawPolygonProcessDependencies {
  meta: YMaps.IMeta;
  EventsPane: typeof YMaps.pane.EventsPane;
}

interface IDrawPolygonProcessOptions {
  map: YMaps.Map;
}

interface IDrawPolygonProcessDrawOptions {
  tolerance?: number;
  style?: Partial<IDrawPolygonProcessStyle>;
}

export function createDrawPolygonProcess({ meta, EventsPane }: IDrawPolygonProcessDependencies) {
  return class DrawPolygonProcess {
    private map: YMaps.Map;
    private pane: YMaps.pane.EventsPane;
    private canvas: HTMLCanvasElement;
    private ctx2d: CanvasRenderingContext2D;
    private coordinates: YMaps.TCoord[] = [];
    private style: IDrawPolygonProcessStyle = DEFAULT_STYLE;
    private tolerance: number = DEFAULT_TOLERANCE;

    public constructor(options: IDrawPolygonProcessOptions) {
      this.map = options.map;

      this.init();
    }

    public start(startMouseEvent: YMaps.IEvent, options: IDrawPolygonProcessDrawOptions = {}) {
      this.style = { ...DEFAULT_STYLE, ...options.style };
      this.tolerance = typeof options.tolerance === 'number' ? options.tolerance : DEFAULT_TOLERANCE;
      this.coordinates = [toPosition(startMouseEvent, this.getYOffset())];

      this.applyStyle();

      this.canvas.addEventListener('mousemove', this.handleMouseMove);
      this.canvas.addEventListener('touchmove', this.handleMouseMove);
    }

    public finish(finishMouseEvent: YMaps.IEvent): YMaps.TCoord[] | void {
      const { tolerance } = this;

      this.coordinates.push(toPosition(finishMouseEvent, this.getYOffset()));

      if (tolerance) {
        this.coordinates = simplifyCoordinates(this.coordinates, tolerance);
      }

      const bounds = this.map.getBounds();
      const latDiff = bounds[1][0] - bounds[0][0];
      const lonDiff = bounds[1][1] - bounds[0][1];

      this.canvas.removeEventListener('mousemove', this.handleMouseMove);
      this.canvas.removeEventListener('touchmove', this.handleMouseMove);

      // Преобразовываем координаты canvas-элемента в геодезические sкоординаты.
      const result: YMaps.TCoord[] = this.coordinates.map((x: YMaps.TCoord) => {
        const lon = bounds[0][1] + (x[0] / this.canvas.width) * lonDiff;
        const lat = bounds[0][0] + (1 - x[1] / this.canvas.height) * latDiff;

        return meta.coordinatesOrder === 'latlong' ? [lat, lon] : [lon, lat];
      });

      this.clearCanvas();
      this.coordinates = [];

      if (result.length >= 3) {
        return result;
      }
    }

    public destroy() {
      this.pane.destroy();

      this.map.panes.remove(this.pane);
    }

    private createCanvas(): HTMLCanvasElement {
      const rect = this.map.container.getParentElement().getBoundingClientRect();

      const canvas = document.createElement('canvas');

      canvas.width = rect.width;
      canvas.height = rect.height;
      canvas.style.width = '100%';
      canvas.style.height = '100%';

      return canvas;
    }

    private init() {
      const canvas = this.createCanvas();
      const pane = new EventsPane(this.map, {
        css: { position: 'absolute', width: '100%', height: '100%', cursor: 'pointer' },
        zIndex: PANE_ZINDEX,
        transparent: true,
      });

      pane.getElement().appendChild(canvas);

      this.map.panes.append('cian-draw-polygon-process', pane);

      this.pane = pane;
      this.canvas = canvas;
      this.ctx2d = canvas.getContext('2d') as CanvasRenderingContext2D;
    }

    private applyStyle() {
      const { ctx2d } = this;

      ctx2d.globalAlpha = this.style.opacity;
      ctx2d.strokeStyle = this.style.color;
      ctx2d.lineWidth = this.style.width;
    }

    private handleMouseMove = (e: MouseEvent | TouchEvent) => {
      if ('touches' in e) {
        const touch = e.touches[0];

        this.coordinates.push([touch.pageX, touch.pageY - this.getYOffset()]);
      } else if ('offsetX' in e && 'offsetY' in e) {
        this.coordinates.push([e.offsetX, e.offsetY]);
      }

      this.clearCanvas();
      this.drawCanvasRect();
    };

    private getYOffset() {
      return window.innerHeight - this.canvas.height;
    }

    private clearCanvas() {
      this.ctx2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    private drawCanvasRect() {
      const { ctx2d, coordinates } = this;

      ctx2d.beginPath();

      ctx2d.moveTo(coordinates[0][0], coordinates[0][1]);

      for (let i = 1; i < coordinates.length; i++) {
        ctx2d.lineTo(coordinates[i][0], coordinates[i][1]);
      }

      ctx2d.stroke();
    }
  };
}
