export function isAvailable(): boolean {
  const value = 'meow';
  try {
    localStorage.setItem(value, value);
    localStorage.removeItem(value);

    return true;
  } catch {
    return false;
  }
}
