import { SUBEQUATOR, HALF_EQUATOR } from './contants';
import { cycleRestrict } from './math';
import { PI_UNDER_180, yToLatitude } from './mercator';

export function fromGlobalPixels(pixelCoords: YMaps.TPixelCoord, zoom: number): YMaps.TCoord {
  const [pixelCoordsX, pixelCoordsY] = pixelCoords;
  const pixelsPerMeter = Math.pow(2, zoom + 8) * SUBEQUATOR;

  return [yToLatitude(HALF_EQUATOR - pixelCoordsY / pixelsPerMeter), globalPixelXToLongitude(pixelCoordsX, zoom)];
}

/**
 * Метод для прямого перевода пиксельных координат по x в градусы.
 * Сделан в обход стандартных преобразований пиксели -> метры -> градусы
 * из-за больших потерей точности при делении и умножении на радиус сферы.
 */
function globalPixelXToLongitude(x: number, zoom: number): number {
  return cycleRestrict((Math.PI * x) / Math.pow(2, zoom + 7) - Math.PI, -Math.PI, Math.PI) * PI_UNDER_180;
}
