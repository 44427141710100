import { IConfig } from '@cian/config/shared';
import { compose, filter, keys, map, min, reduce } from 'ramda';

import { IZoomToPrecisionMap } from './types';

export function precisionToMinZoom(config: IConfig, precision: number): number {
  const zoomToPrecisionMap = config.getStrict<IZoomToPrecisionMap>('SEARCH_ENGINE_ZOOM_TO_PRECISION_MAP');

  const isPrecision = (p: number) => p === precision;

  return compose(reduce<number, number>(min, Infinity), map(Number), keys, filter(isPrecision))(zoomToPrecisionMap);
}
