import cx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useMapContext } from 'shared/map-search/containers/Map/context';
import { TFeature } from 'shared/map-search/types/map';
import { IApplicationState } from 'shared/map-search/types/redux';
import { useDailyRentAvailabilityFeatureEnabled } from 'shared/map-search/utils/dailyRentAvailability';

import * as styles from './DotActive.css';

interface IDotActiveProps {
  resultsAvailable: boolean;
  detailsVisible: boolean;
}

interface IIconLayout {
  isNewbuildingPin: boolean;
  isSmall: boolean;
  isSuburbanFromKpPin: boolean;
}

export const DotActive: React.FC<IDotActiveProps> = ({
  resultsAvailable: isResultsAvailable,
  detailsVisible: isDetailsVisible,
}) => {
  const { ymaps, map } = useMapContext();
  const selectedFeature = useSelector<IApplicationState, TFeature | null>(state => state.activeFeature);
  const isNewbuildingsListing = useSelector<IApplicationState, boolean>(state => state.isNewbuildingsListing);
  // @todo выпилить эксп CD-159979
  const hideTileOnHover = useDailyRentAvailabilityFeatureEnabled();
  const isNewbuildingPromo = !!selectedFeature?.properties.isNewbuildingPromo;
  const isNewbuildingPin = !!selectedFeature?.properties.newbuilding?.isAnyFicheringPlus || isNewbuildingsListing;
  const isSuburbanFromKpPin = !!selectedFeature?.properties.isAnyFromKp;

  const iconLayout = React.useRef(({ isNewbuildingPin = false, isSmall, isSuburbanFromKpPin }: IIconLayout) =>
    ymaps.templateLayoutFactory.createClass(
      // @todo выпилить эксп CD-159979
      `<div class="${cx(
        hideTileOnHover ? styles['dailyrent-container'] : styles['container'],
        isSmall && styles['container--small'],
        isNewbuildingPin || isSuburbanFromKpPin ? styles['container--green'] : styles['container--blue'],
      )}">{% if properties.count > 1 %}{% if properties.count <= 9 %}{{properties.count}}{% else %}9+{% endif %}{% endif %}</div>`,
    ),
  );
  const placemarkObject = React.useRef<YMaps.GeoObject | null>(null);

  React.useEffect(() => {
    if (placemarkObject.current) {
      map.geoObjects.remove(placemarkObject.current);
      placemarkObject.current = null;
    }

    if (
      isNewbuildingPromo ||
      !isResultsAvailable ||
      (isDetailsVisible &&
        (!selectedFeature || !selectedFeature.properties.isExtended || selectedFeature.properties.specialPromo))
    ) {
      return;
    }

    if (selectedFeature) {
      const isSmallPlacemark = selectedFeature.properties.count === 1;

      placemarkObject.current = new ymaps.GeoObject(
        {
          ...selectedFeature,
          type: 'Feature',
          id: 'DotActiveFeature',
        },
        {
          iconLayout: iconLayout.current({
            isNewbuildingPin,
            isSmall: isSmallPlacemark,
            isSuburbanFromKpPin,
          }),
        },
      );

      map.geoObjects.add(placemarkObject.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  React.useEffect(() => {
    if (!isResultsAvailable || isDetailsVisible) {
      if (placemarkObject.current) {
        map.geoObjects.remove(placemarkObject.current);
        placemarkObject.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultsAvailable, isDetailsVisible]);

  return null;
};
