import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { ISelectActions } from './types';
import { processKeyboardEvent } from './utils/processKeyboardEvent';
import { ISelectProps, ISelectStyles } from '../types';

export { ISelectActions };

interface ISelectContainerProps extends ISelectProps {
  opened: boolean;
  className: string;
  forwardedRef: React.RefObject<HTMLDivElement>;
  actions: ISelectActions;
  style?: ISelectStyles;
  'data-name'?: string;
}

export class SelectContainer extends React.Component<ISelectContainerProps> {
  private isMouseDown = false;

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
  }

  public render() {
    return (
      <Outside onOutside={this.handleOutside} insideRefs={[this.props.forwardedRef]} active={this.props.opened}>
        <div
          ref={this.props.forwardedRef}
          className={this.props.className}
          tabIndex={this.props.disabled ? -1 : this.props.tabIndex || 0}
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          onBlur={this.handleBlur}
          style={this.props.style}
          data-name={this.props['data-name']}
        >
          {this.props.children}
        </div>
      </Outside>
    );
  }

  private handleMouseDown = () => {
    this.isMouseDown = true;

    document.addEventListener('mouseup', this.handleDocumentMouseUp);
  };

  private handleDocumentMouseUp = () => {
    this.isMouseDown = false;

    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
  };

  private handleBlur = () => {
    if (!this.isMouseDown && this.props.opened) {
      this.props.actions.closeDropdown();
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    processKeyboardEvent(e, {
      multiple: this.props.multiple,
      opened: this.props.opened,
      actions: this.props.actions,
    });
  };

  private handleOutside = () => {
    if (this.props.opened) {
      this.props.actions.closeDropdown();
    }
  };
}
