import { IPointConfig, getPointConfig } from './pointTypes';
import { TILE_SIZE } from '../../../constants/map';
import { TFeature } from '../../../types/map';
import { getTilePixelCoords } from '../../tiles';
import { onYmapsDebug, YMAPS_DEBUG } from '../ymapsModulesDebug';

interface IDrawTileParameters extends IPointConfig {
  pointCoords: [number, number];
}

export interface ICreateTileClassOptions {
  getFeaturesForTile(tileNumber: YMaps.TTileNumber, zoom: number): TFeature[];
  isDetailsVisible(): boolean;
  isNewbuildingsListing(): boolean;
  isResultable(): boolean;
  mapProjection: YMaps.projection.wgs84Mercator;
  dpr: number;
  // @todo выпилить эксп CD-159979
  showOnlyPrice(): boolean;
}

export function createTileClass(options: ICreateTileClassOptions) {
  const {
    getFeaturesForTile,
    isDetailsVisible,
    isNewbuildingsListing,
    isResultable,
    mapProjection,
    dpr,
    // @todo выпилить эксп CD-159979
    showOnlyPrice,
  } = options;

  return class TileClass implements YMaps.IDomTile {
    private tileNumber: YMaps.TTileNumber;
    private tileZoom: number;
    private canvasElement: HTMLCanvasElement | undefined;
    private canvasContext: CanvasRenderingContext2D | undefined;
    private attached: boolean;
    private hash: string;

    public constructor(
      _url: string | null,
      _container: YMaps.layer.tileContainer.DomContainer,
      options: YMaps.IDomTileOptions,
    ) {
      this.tileNumber = options.tileNumber;
      this.tileZoom = options.tileZoom;
      this.attached = false;
      this.hash = '';
    }

    public destroy() {
      if (this.canvasElement) {
        this.canvasElement.width = 0;
        this.canvasElement.height = 0;

        if (this.canvasElement.parentElement) {
          this.canvasElement.parentElement.removeChild(this.canvasElement);
        }
        this.canvasElement = undefined;
        this.canvasContext = undefined;
      }
      this.attached = false;
    }

    public isReady() {
      return true;
    }

    public renderAt(context: HTMLElement, bounds: [[number, number], [number, number]]) {
      const timerLabel = `Генерация тайла ${this.tileNumber},${this.tileZoom}`;
      onYmapsDebug(() => console.time(timerLabel));

      if (!this.canvasElement) {
        this.initCanvas();
      }

      const tileFeatures = getFeaturesForTile(this.tileNumber, this.tileZoom);
      tileFeatures.sort((a, b) => (a.id.split('').reverse().join('') < b.id.split('').reverse().join('') ? -1 : 1));
      const featuresHash = tileFeatures
        .map(f => f.geometry.coordinates.join(','))
        .sort()
        .join(' ');

      const tileHash = `${this.tileNumber};${this.tileZoom};${featuresHash}`;
      if (this.hash !== tileHash) {
        this.drawTile(tileFeatures, this.tileNumber, this.tileZoom);
        this.hash = tileHash;
      }

      const canvas = this.canvasElement as HTMLCanvasElement;

      canvas.style.top = bounds[0][1] + 'px';
      canvas.style.left = bounds[0][0] + 'px';
      canvas.style.width = bounds[1][0] - bounds[0][0] + 'px';
      canvas.style.height = bounds[1][1] - bounds[0][1] + 'px';

      if (!this.attached) {
        context.appendChild(canvas);
        this.attached = true;
      }

      onYmapsDebug(() => console.timeEnd(timerLabel));
    }

    private drawTile(tileFeatures: TFeature[], tileNumber: YMaps.TTileNumber, tileZoom: number) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      const tilePixelCoords = getTilePixelCoords(tileNumber);

      this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

      tileFeatures.forEach(tileFeature => {
        const tileFeatureCoords = this.getFeatureTileCoords(tileFeature, tilePixelCoords, tileZoom);

        this.drawPoint({
          pointCoords: tileFeatureCoords,
          ...getPointConfig({
            feature: tileFeature,
            isResidentialComplexesListing: isNewbuildingsListing(),
            isDetailed: isDetailsVisible(),
            isResultable: isResultable(),
            // @todo выпилить эксп CD-159979
            showOnlyPrice: showOnlyPrice(),
          }),
        });
      });

      if (YMAPS_DEBUG) {
        this.canvasContext.beginPath();
        this.canvasContext.strokeStyle = '#000';
        this.canvasContext.strokeRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasContext.font = '64px serif';
        this.canvasContext.fillStyle = 'red';
        this.canvasContext.fillText(tileNumber.join(';'), 30, 64);
        this.canvasContext.closePath();
      }
    }

    private initCanvas() {
      this.canvasElement = document.createElement('canvas');
      this.canvasElement.width = TILE_SIZE * dpr;
      this.canvasElement.height = TILE_SIZE * dpr;
      this.canvasElement.style.position = 'absolute';
      this.canvasElement.style.textRendering = 'geometricPrecision';
      this.canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
    }

    private drawPoint({
      pointCoords,
      radius,
      color,
      textColor = '#fff',
      borderColor = '#fff',
      borderWidth = 0,
      count,
      hasContourShadow,
    }: IDrawTileParameters) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      // Магия Яндекс Карт. Даже знать не хочу почему так.
      const [pointXRaw, pointYRaw] = pointCoords;
      const [pointX, pointY] = [Math.round(pointXRaw), Math.floor(pointYRaw)];
      const ctx = this.canvasContext;

      if (isDetailsVisible()) {
        this.drawPinShadow(pointCoords, radius);
      }

      if (hasContourShadow) {
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        ctx.shadowBlur = 4 * dpr;
        ctx.shadowOffsetY = dpr;
      }

      if (borderWidth > 0) {
        ctx.beginPath();

        ctx.fillStyle = borderColor;
        ctx.arc(pointX * dpr, pointY * dpr, radius * dpr, 0, 2 * Math.PI);
        ctx.fill();

        ctx.closePath();

        if (hasContourShadow) {
          this.disableContextShadow();
        }
      }

      ctx.beginPath();

      ctx.fillStyle = color;
      ctx.arc(pointX * dpr, pointY * dpr, (radius - borderWidth) * dpr, 0, 2 * Math.PI);
      ctx.fill();

      if (count) {
        if (borderWidth === 0 && hasContourShadow) {
          this.disableContextShadow();
        }

        ctx.font = `${10 * dpr}px/1 Lato`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'alphabetic';
        ctx.fillStyle = textColor;
        ctx.fillText(count, pointX * dpr, (pointY + 4) * dpr);
      }

      ctx.closePath();
    }

    private disableContextShadow() {
      if (!this.canvasContext) {
        return;
      }

      this.canvasContext.shadowColor = '';
      this.canvasContext.shadowBlur = 0;
      this.canvasContext.shadowOffsetY = 0;
    }

    private drawPinShadow(point: [number, number], radius: number) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      const [pointX, pointY] = [Math.round(point[0]), Math.floor(point[1])];
      const ctx = this.canvasContext;

      const radiusX = radius * 0.6;

      ctx.beginPath();
      ctx.fillStyle = 'rgba(21, 34, 66, 0.1)';
      ctx.ellipse(pointX * dpr, (pointY + radiusX + 1) * dpr, radius * 1.3 * dpr, radiusX * dpr, 0, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }

    private getFeatureTileCoords(
      feature: TFeature,
      tilePixelCoords: YMaps.TPixelCoord,
      tileZoom: number,
    ): [number, number] {
      const [tilePixelCoordX, tilePixelCoordY] = tilePixelCoords;
      const [featureCoordX, featureCoordY] = feature.geometry.coordinates;

      const [featurePixelCoordX, featurePixelCoordY] = mapProjection.toGlobalPixels(
        [featureCoordX, featureCoordY],
        tileZoom,
      );

      return [featurePixelCoordX - tilePixelCoordX, featurePixelCoordY - tilePixelCoordY];
    }
  };
}
