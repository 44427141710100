import * as React from 'react';

import * as styles from './styles.css';
import { ControlLinkButton } from '../../ControlLinkButton';
import { TLinkButtonSize } from '../../ControlLinkButton/types';
import { Filters } from '../../Icons';

interface IFiltersButtonProps {
  url: string;
  onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  size: TLinkButtonSize;
}

export function FiltersButton({ url, onClick, size }: IFiltersButtonProps) {
  return (
    <ControlLinkButton title="Фильтры" href={url} className={styles['navigation-btn']} onClick={onClick} size={size}>
      <Filters />
    </ControlLinkButton>
  );
}
