import * as React from 'react';

/* istanbul ignore next */
export const GeoLocation: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.663 4.98046L14.2559 20.71C14.1045 21.1504 13.4863 21.1629 13.3172 20.7291L10.582 13.7103C10.5299 13.5766 10.4228 13.4717 10.288 13.4224L3.3023 10.864C2.86254 10.703 2.86568 10.0799 3.30704 9.92331L19.0229 4.34671C19.4174 4.20671 19.7991 4.58456 19.663 4.98046Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

GeoLocation.displayName = 'GeoLocation';
