import * as React from 'react';

import { InfrastructureControlContainer, InfrastructureDropdownContainer } from './Infrastructure';
import { ListingButtonContainer } from './ListingButton';
import { ProfessionalSearchButtonContainer } from './ProfessionalSearchButton';
import { Navigation, NavigationButtons } from '../../components/Navigation';

export const NavigationDesktopContainer: React.FC = () => {
  return (
    <Navigation indent="L">
      <NavigationButtons indent="L">
        <InfrastructureControlContainer />
        <ProfessionalSearchButtonContainer />
        <ListingButtonContainer />
      </NavigationButtons>
      <InfrastructureDropdownContainer />
    </Navigation>
  );
};

NavigationDesktopContainer.displayName = 'NavigationDesktopContainer';
