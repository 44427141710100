import * as React from 'react';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { ZoomIn } from 'shared/map-search/components/Icons';
import { ZoomInNew } from 'shared/map-search/components/Icons/ZoomInNew';
import { useMapContext } from 'shared/map-search/containers/Map/context';

import { ZoomInButton } from './ZoomInButton';

export const ZoomInButtonContainer: React.FC = props => {
  const { map } = useMapContext();

  return (
    <>
      <Desktop>
        <ZoomInButton {...props} map={map} size="M" icon={<ZoomIn />} />
      </Desktop>
      <NotDesktop>
        <ZoomInButton {...props} map={map} size="L" icon={<ZoomInNew />} isMobile />
      </NotDesktop>
    </>
  );
};
