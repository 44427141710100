import { IconProSearch16 } from '@cian/ui-kit/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';
import { ControlLinkButton } from '../../ControlLinkButton';

interface IListingButtonProps {
  url: string;
  onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export function ProfessionalSearchButton({ url, onClick }: IListingButtonProps) {
  return (
    <ControlLinkButton href={url} className={cx(styles['navigation-btn'], styles['listing-btn'])} onClick={onClick}>
      <IconProSearch16 color="black_100" />
      <span className={styles['listing-title']}>Профпоиск</span>
    </ControlLinkButton>
  );
}
