import { TReduxActions } from '../../actions';
import { EFeaturesActionType } from '../../actions/features';
import { ENewbuildingPolygonActionType } from '../../actions/newbuildingPolygon';
import { ESearchResultsActionType } from '../../actions/searchResults';
import { IActiveNewbuildingPolygon } from '../../types/map/newbuildingPolygon';

const defaultState: IActiveNewbuildingPolygon = {};

export function activeNewbuildingPolygonReducer(state = defaultState, action: TReduxActions) {
  switch (action.type) {
    case ENewbuildingPolygonActionType.NewbuildingPolygonSelected: {
      return action.payload;
    }

    case ESearchResultsActionType.SidebarClosed:
    case EFeaturesActionType.FeatureSelected: {
      return {};
    }

    default: {
      return state;
    }
  }
}
