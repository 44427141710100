import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { Calendar } from 'shared/common/packages/Calendar';

import * as styles from './Dates.css';

interface IDatesProps {
  buttonLabel: string;
  isOpen: boolean;
  minDate: Date;
  maxDate: Date;
  fromValue: Date | null;
  toValue: Date | null;
  onButtonClick(): void;
  onDatesChange(from: Date | undefined, to: Date | undefined): void;
  onClose(): void;
}

export const Dates = (props: IDatesProps) => {
  const { buttonLabel, isOpen, minDate, maxDate, fromValue, toValue, onButtonClick, onDatesChange, onClose } = props;

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  return (
    <Outside onOutside={onClose} insideRefs={[dropdownRef]} active={isOpen}>
      <div className={styles['container']} data-name="FilterDates">
        <Button theme="stroke_secondary" size="XS" onClick={onButtonClick}>
          {buttonLabel}
        </Button>

        {isOpen && (
          <div className={styles['dropdown']} ref={dropdownRef} data-name="FilterDatesPopover">
            <Calendar
              minDate={minDate}
              maxDate={maxDate}
              fromValue={fromValue}
              toValue={toValue}
              onChange={onDatesChange}
            />
          </div>
        )}
      </div>
    </Outside>
  );
};
