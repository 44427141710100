import { IGetNewbuildingsForPromoSliderOnMapResponse } from '../../../common/repositories/newbuilding-search/v1/get-newbuildings-for-promo-slider-on-map';
import { INewbuildingForPromoInventory } from '../../types/promoInventory';

export function newbuildingsForPromoInventoryMapper(
  response: IGetNewbuildingsForPromoSliderOnMapResponse,
): INewbuildingForPromoInventory[] {
  return response.newbuildings.map(item => {
    if (item.undergroundInfo) {
      return { ...item, undergroundInfo: { ...item.undergroundInfo, lineColor: `#${item.undergroundInfo.lineColor}` } };
    }

    return item;
  });
}
