import {
  IGetNewbuildingPromoPinsForMapResponse,
  IGetNewbuildingPromoPinsForMapResponse200,
  IGetNewbuildingPromoPinsForMapResponse400,
  IGetNewbuildingPromoPinsForMapResponseError,
} from 'shared/common/repositories/search-offers-index/v1/get-newbuilding-promo-pins-for-map';

export const mapGetNewbuildingPromoPinsForMapResponse = (
  response: IGetNewbuildingPromoPinsForMapResponse,
): IGetNewbuildingPromoPinsForMapResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};

export const mapGetNewbuildingPromoPinsForMapResponseError = (
  response: IGetNewbuildingPromoPinsForMapResponseError,
): IGetNewbuildingPromoPinsForMapResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
