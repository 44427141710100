export function getNurseryIcon(): string {
  return `
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_432_2720)">
    <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M12.0506 8.12468C12.9294 8.11205 13.8043 7.77046 14.4749 7.09991C15.8417 5.73308 15.8417 3.517 14.4749 2.15017C13.108 0.783332 10.892 0.783332 9.52513 2.15017C8.71093 2.96436 8.38174 4.07989 8.53754 5.13776L7 6.67529L4 3.67529L2.375 5.30029L4.66589 7.59118C3.60334 7.66815 2.56244 8.11286 1.75001 8.92528C-0.0449117 10.7202 -0.0449122 13.6303 1.75001 15.4253C3.54493 17.2202 6.45507 17.2202 8.24999 15.4253C9.06242 14.6128 9.50712 13.5719 9.5841 12.5094L12.125 15.0503L13.75 13.4253L10.25 9.92529L12.0506 8.12468ZM6.83578 14.0111C5.82191 15.0249 4.17809 15.0249 3.16422 14.0111C2.15035 12.9972 2.15035 11.3534 3.16422 10.3395C4.17809 9.32563 5.82191 9.32563 6.83578 10.3395C7.84965 11.3534 7.84965 12.9972 6.83578 14.0111Z" />
  </g>
  <defs>
    <clipPath id="clip0_432_2720">
      <rect width="16" height="16" fill="currentColor" transform="translate(0 0.775391)" />
    </clipPath>
  </defs>
</svg>
  `;
}
