import { plural } from '@cian/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  InfrastructureLegendSection,
  InfrastructureLegend,
  InfrastructureLegendInformer,
} from '../../../components/Navigation/Infrastructure';
import { selectActiveType, selectMinZoom, selectVisibleItemsCount } from '../../../selectors/infrastructure';
import { selectMapBounds } from '../../../selectors/mapBounds';
import { EInfrastructureIconType } from '../../../types/infrastructure';

const PLURALS_MAP: { [key: string]: [string, string, string] | null } = {
  [EInfrastructureIconType.School]: ['школа', 'школы', 'школ'],
  [EInfrastructureIconType.Nursery]: ['детский сад', 'детских сада', 'детских садов'],
  [EInfrastructureIconType.Clinic]: ['поликлиника', 'поликлиники', 'поликлиник'],
  [EInfrastructureIconType.Grocery]: ['магазин', 'магазина', 'магазинов'],
  [EInfrastructureIconType.Empty]: null,
};

export function InfrastructureLegendContainer() {
  const activeLayer = useSelector(selectActiveType);
  const visibleCount = useSelector(selectVisibleItemsCount);
  const minZoom = useSelector(selectMinZoom);
  const mapBounds = useSelector(selectMapBounds);

  const legendText = React.useMemo<string>(() => {
    const pluralVariants = PLURALS_MAP[activeLayer?.icon || EInfrastructureIconType.Empty];

    if (!activeLayer || visibleCount === undefined) {
      return '';
    }

    if (pluralVariants) {
      return visibleCount === 0
        ? `В этом районе нет ${plural(visibleCount, pluralVariants)}`
        : `${visibleCount} ${plural(visibleCount, pluralVariants)} в этом районе`;
    }

    return `${activeLayer.name} в этом районе: ${visibleCount}`;
  }, [activeLayer, visibleCount]);

  if (!activeLayer) {
    return null;
  }

  if (mapBounds.zoom <= minZoom) {
    return (
      <InfrastructureLegendSection>
        <InfrastructureLegendInformer text="Приблизьте карту, чтобы увидеть объекты инфраструктуры" />
      </InfrastructureLegendSection>
    );
  }

  if (!legendText) {
    return null;
  }

  return (
    <InfrastructureLegendSection>
      <InfrastructureLegend text={legendText} color={activeLayer.pinColor} />
    </InfrastructureLegendSection>
  );
}
