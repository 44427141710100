import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { selectNewbuildingPolygons } from 'shared/map-search/selectors/newbuildingPolygons';
import { INewbuildingsPolygons } from 'shared/map-search/types/map/newbuildingPolygon';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';

import { useNewbuildingPolygonsContext } from '../context';

export const PolygonsManager: React.FC = () => {
  const { config } = useApplicationContext();

  const { newbuildingsPolygonsManager } = useNewbuildingPolygonsContext();
  const polygons = useSelector<IApplicationState, INewbuildingsPolygons | null>(selectNewbuildingPolygons);
  const { zoom: currentZoom } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const deviceType = useDeviceType();
  const isDesktop = deviceType === 'desktop';

  const polygonsVisibleZoom = config.get<number>('mapSearchFrontend.polygonsVisibleZoom') || 15;

  const isVisible = React.useMemo(
    () => currentZoom >= polygonsVisibleZoom && isDesktop,
    [currentZoom, isDesktop, polygonsVisibleZoom],
  );

  React.useEffect(() => {
    if (isVisible && polygons !== null) {
      newbuildingsPolygonsManager.createPolygons(polygons);
    } else {
      newbuildingsPolygonsManager.clear();
    }
  }, [isVisible, newbuildingsPolygonsManager, polygons]);

  return null;
};
