import * as React from 'react';

import { getRangeValue } from 'shared/common/packages/JsonQuery';

import { FromMKAD } from '../../../components/advancedFilters/FromMKAD';
import { useContext } from '../../../utils/useContext';

export const FromMKADContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const fromMKAD = getRangeValue('from_mcad_km')(jsonQuery);

  const handleFromMKADMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFromMKADMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFromMKADMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFromMKADMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <FromMKAD
      distance={fromMKAD}
      onFromMKADMinChange={handleFromMKADMinChange}
      onFromMKADMaxChange={handleFromMKADMaxChange}
    />
  );
};
