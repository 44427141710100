import { INewbuilding } from '../../types/map/newbuilding';

interface IGetNewbuildingQuery {
  qs: string;
  newbuilding: INewbuilding;
  houseIds?: number[];
  selectAllHouseIds?: boolean;
  preselectedFromDeveloper?: boolean;
  isNewbuildingPromo?: boolean;
}

export function getNewbuildingQuery({
  qs,
  newbuilding,
  houseIds,
  selectAllHouseIds,
  preselectedFromDeveloper,
  isNewbuildingPromo,
}: IGetNewbuildingQuery): string {
  const newbuildingSearch = new URLSearchParams(qs);

  newbuildingSearch.append('newobject[0]', String(newbuilding.id));

  if (isNewbuildingPromo) {
    newbuildingSearch.append('is_fichering_plus', '1');
    newbuildingSearch.append('from_developer', 'true');
  }

  if (houseIds && houseIds.length > 0) {
    houseIds.map((id, index) => {
      newbuildingSearch.append(`nb_house_key[${index}]`, `${newbuilding.id}_${id}`);
    });
  }

  if (selectAllHouseIds) {
    newbuildingSearch.append('selectAllHouseIds', '1');
  }

  if (preselectedFromDeveloper) {
    newbuildingSearch.append('preselected_from_developer', '1');
  }

  return newbuildingSearch.toString();
}
