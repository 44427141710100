import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import * as styles from './Area.css';
import { getAreaLabel } from './helpers';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';

interface IAreaProps {
  area: IJsonQueryRangeValue | null;
  onAreaMinChange(gte: number | null): void;
  onAreaMaxChange(lte: number | null): void;
}

export const Area: React.FC<IAreaProps> = ({ area, onAreaMinChange, onAreaMaxChange }) => {
  const { gte: areaMin = null, lte: areaMax = null } = area || {};
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const areaLabel = getAreaLabel(areaMin, areaMax);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onAreaMinChange(gte);
  }, 300);

  const handleAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onAreaMaxChange(lte);
  }, 300);

  return (
    <Outside onOutside={handleClose} insideRefs={[dropdownRef]} active={isOpen}>
      <div className={styles['container']} data-name="FilterArea">
        <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
          {areaLabel || 'Площадь'}
        </Button>
        {isOpen && (
          <div className={styles['dropdown']} ref={dropdownRef}>
            <RangeInput
              min={0}
              max={99999}
              joined={false}
              postfix={'м\u00b2'}
              valueMin={areaMin}
              valueMax={areaMax}
              onChangeMin={handleAreaMinChange}
              onChangeMax={handleAreaMaxChange}
            />
          </div>
        )}
      </div>
    </Outside>
  );
};
