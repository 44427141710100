/* eslint-disable no-irregular-whitespace */
import { ArticleParagraph2, useDeviceType } from '@cian/ui-kit';
import classNames from 'clsx';
import * as React from 'react';

import icon from './ocenka.svg';
import * as styles from './styles.css';
import { BannerWrapper } from '../BannerWrapper';

interface IRealtorReportBannerProps {
  onClose(): void;
  render(c: React.ReactElement): React.ReactElement | React.ReactPortal | null;
  redirectUrl: string;
}

export const RealtorReportBanner: React.FC<IRealtorReportBannerProps> = props => {
  const { onClose, redirectUrl, render } = props;
  const deviceType = useDeviceType();

  return render(
    <BannerWrapper
      moveRight={false}
      attributes={{
        className: classNames(styles['container'], { [styles['mobile']]: deviceType !== 'desktop' }),
        'data-testid': 'realtor_report_banner',
      }}
      onClose={onClose}
    >
      <img src={icon} alt="icon" />
      <div className={styles['content']}>
        <ArticleParagraph2>Создайте отчёт об оценке и отправьте его клиенту</ArticleParagraph2>
        <a href={redirectUrl} className={styles['link']} target="_blank" rel="noreferrer">
          <ArticleParagraph2 color="primary_100">Перейти к оценке</ArticleParagraph2>
        </a>
      </div>
    </BannerWrapper>,
  );
};
