import { CancellationToken } from '@cian/http-api/shared';

import {
  fetchGetNewbuildingPromoSnippetForMap,
  IGetNewbuildingPromoSnippetForMapRequest,
  IGetNewbuildingPromoSnippetForMapResponse,
  IGetNewbuildingPromoSnippetForMapResponse200,
} from 'shared/common/repositories/newbuilding-brand-promotion/v1/get-newbuilding-promo-snippet-for-map';

import { IApplicationContext } from '../../../types/applicationContext';

export async function fetchNewbuildingPromoPopup(
  context: IApplicationContext,
  parameters: IGetNewbuildingPromoSnippetForMapRequest,
): Promise<IGetNewbuildingPromoSnippetForMapResponse | null | undefined> {
  const { httpApi, cancellationTokenManager } = context;

  let cancellationToken: CancellationToken | undefined;
  if (cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious('fetchGetNewbuildingPromoPopup');
  }

  const response = await fetchGetNewbuildingPromoSnippetForMap<IGetNewbuildingPromoSnippetForMapResponse200>({
    httpApi,
    parameters: { ...parameters },
    config: {
      cancellationToken,
    },
  });

  return response.response;
}
