import { term } from '../../../common/packages/JsonQuery/constructors';
import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';
import { TReduxActions } from '../../actions';
import { EFiltersActionType } from '../../actions/filters';

const defaultState: IJsonQuery = {
  _type: 'flatrent',
  engine_version: term(2),
};

export function jsonQueryReducer(state: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case EFiltersActionType.JsonQueryChanged:
      return action.payload.nextJsonQuery;
  }

  return state;
}
