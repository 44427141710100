export interface IUndergroundInfo {
  /** Цвет линии **/
  lineColor: string;
  /** Название станции **/
  name: string;
  /** Время до метро строкой **/
  timeDisplay: string;
  /** Способ передвижения **/
  transportType: ETransportType;
  /** Тип метро для отрисовки правильной иконки **/
  undergroundType: EUndergroundType;
}

export enum ETransportType {
  /** Транспортом **/
  Transport = 'transport',
  /** Пешком **/
  Walk = 'walk',
}
export enum EUndergroundType {
  /** Екатеринбург **/
  Ekb = 'ekb',
  /** Казань **/
  Kzn = 'kzn',
  /** Москва **/
  Mow = 'mow',
  /** Нижний новгород **/
  Nn = 'nn',
  /** Новосибирск **/
  Nsk = 'nsk',
  /** Самара **/
  Smr = 'smr',
  /** Санкт-Петербург **/
  Spb = 'spb',
}
