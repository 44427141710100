import { useSelector } from 'react-redux';

import { isClosed } from 'browser/map-search/utils/banner';

import { offerTypeFromJsonQuery } from 'shared/common/packages/Filters/shared/actions/suggestions/helpers';
import { dealTypeFromJsonQuery, FDealType } from 'shared/common/packages/JsonQuery';
import { EOfferType } from 'shared/common/repositories/geo-suggest/v2/suggest';
import { EBanners, REALTOR_REPORT_CLOSED_COOKIE_KEY } from 'shared/map-search/constants/banners';
import { selectJsonQuery } from 'shared/map-search/selectors/results';
import { selectIsUserAgent } from 'shared/map-search/selectors/user';

/** Проверяет условия показа баннера RealtorReport. Вернет enum, если условия выполняются. */
export const useRealtorReportBanner = (): EBanners | null => {
  const userIsAgent = useSelector(selectIsUserAgent);

  const jsonQuery = useSelector(selectJsonQuery);

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const isRent = dealType === FDealType.RentLongterm;
  const isSale = dealType === FDealType.Sale;
  const isValidDealType = isRent || isSale;

  const isFlat = offerType === EOfferType.Flat;

  if (typeof window === 'undefined' || !userIsAgent || !isValidDealType || !isFlat) {
    return null;
  }

  if (!isClosed(REALTOR_REPORT_CLOSED_COOKIE_KEY, EBanners.RealtorReport)) {
    return EBanners.RealtorReport;
  }

  return null;
};
