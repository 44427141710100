import RBush from 'rbush';
import * as React from 'react';

import { Desktop } from 'shared/common/packages/Responsive';
import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { IRBushItem } from 'shared/map-search/types/map';
import { useDailyRentAvailabilityFeatureEnabled } from 'shared/map-search/utils/dailyRentAvailability';

import { ClickHandlers } from './ClickHandlers';
import { DetailsVisibilityManager } from './DetailsVisibilityManager';
import { DotActive } from './DotActive';
import { DotHover } from './DotHover';
import { FavoritesUpdater } from './FavoritesUpdater';
import { FeaturesPropertiesUpdater } from './FeaturesPropertiesUpdater';
import { FeaturesUpdater } from './FeaturesUpdater';
import { NewbuildingPromoPinsManager } from './NewbuildingPromoPinsManager';
import { NewbuildingPromoPopupManager } from './NewbuildingPromoPopupManager';
import { NotFoundContainer } from './NotFoundContainer';
import { OffersCounter } from './OffersCounter';
import { PopupManager } from './PopupManager';
import { ResultabilityManager } from './ResultabilityManager';
import { SubdomainUpdater } from './SubdomainUpdater';
import { TestMode } from './TestMode';
import { FeaturesContext, IFeaturesContext } from './context';
import { useMapContext } from '../../context';

export interface IFeaturesApi {
  init(): void;

  destroy(): void;
}

export const Features = React.forwardRef((_, ref) => {
  const { config } = useApplicationContext();
  const { ymaps, map } = useMapContext();

  // @todo выпилить эксп CD-159979
  const showOnlyPrice = useDailyRentAvailabilityFeatureEnabled();

  const rbushRef = React.useRef(new RBush<IRBushItem>());
  const pointsLayerRef = React.useRef(
    new ymaps.PointsLayer({
      map,
      rbush: rbushRef.current,
    }),
  );

  const hotspotsLayerRef = React.useRef(new ymaps.HotspotsLayer({ map, rbush: rbushRef.current }));
  const balloonManagerRef = React.useRef(new ymaps.BalloonManager({ map, rbush: rbushRef.current, showOnlyPrice }));
  const popupManagerRef = React.useRef(new ymaps.PopupManager({ map }));
  const newbuildingPopupManagerRef = React.useRef(new ymaps.NewbuildingPromoPopupManager({ map }));
  const [isDetailsVisible, changeIsDetailsVisible] = React.useState(false);
  const [isResultsAvailable, changeIsResultsAvailable] = React.useState(false);
  const isPopupEnabled = config.get<boolean>('pinPopup.enabled');
  const isNewbuildingPopupEnabled = config.get<boolean>('Map.NewbuildingProPlusFeatures.Enabled');

  const handleVisibilityChange = React.useCallback((isDetailsVisible: boolean) => {
    changeIsDetailsVisible(isDetailsVisible);
  }, []);

  const handleResultabilityChange = React.useCallback((isResultable: boolean) => {
    changeIsResultsAvailable(isResultable);
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      init: () => {
        map.layers.add(pointsLayerRef.current.layer);
        map.layers.add(hotspotsLayerRef.current.layer);
        map.geoObjects.add(balloonManagerRef.current.objectManager);
      },
      destroy: () => {
        map.layers.remove(pointsLayerRef.current.layer);
        map.layers.remove(hotspotsLayerRef.current.layer);
        map.geoObjects.remove(balloonManagerRef.current.objectManager);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const context = React.useMemo<IFeaturesContext>(
    () => ({
      rbush: rbushRef.current,
      pointsLayer: pointsLayerRef.current,
      hotspotsLayer: hotspotsLayerRef.current,
      balloonManager: balloonManagerRef.current,
      popupManager: popupManagerRef.current,
      newbuildingPopupManager: newbuildingPopupManagerRef.current,
    }),
    [],
  );

  return (
    <FeaturesContext.Provider value={context}>
      <ClickHandlers resultsAvailable={isResultsAvailable} detailsVisible={isDetailsVisible} />
      <DetailsVisibilityManager onVisibilityChange={handleVisibilityChange} />
      <Desktop>
        {isPopupEnabled && <PopupManager resultsAvailable={isResultsAvailable} detailsVisible={isDetailsVisible} />}
        {isNewbuildingPopupEnabled && (
          <NewbuildingPromoPopupManager resultsAvailable={isResultsAvailable} detailsVisible={isDetailsVisible} />
        )}
      </Desktop>
      <DotActive resultsAvailable={isResultsAvailable} detailsVisible={isDetailsVisible} />
      <DotHover resultsAvailable={isResultsAvailable} detailsVisible={isDetailsVisible} />
      <FavoritesUpdater />
      <FeaturesPropertiesUpdater />
      <FeaturesUpdater detailsVisible={isDetailsVisible || showOnlyPrice} />
      <NewbuildingPromoPinsManager detailsVisible={isDetailsVisible} />
      <NotFoundContainer />
      <ResultabilityManager onResultabilityChange={handleResultabilityChange} />
      <SubdomainUpdater />
      <TestMode map={map} />
      <OffersCounter />
    </FeaturesContext.Provider>
  );
});

Features.displayName = 'Features';
