import cx from 'clsx';
import * as React from 'react';

import * as styles from './NavigationButtons.css';

type TNavigationIndent = 'M' | 'L';

const INDENT_TO_CLASS: { [key in TNavigationIndent]: string } = {
  M: styles['middle-navigation-btn'],
  L: styles['large-navigation-btn'],
};

export interface INavigationButtonsProps {
  children: React.ReactNode;
  indent?: TNavigationIndent;
}

export function NavigationButtons({ children, indent = 'M' }: INavigationButtonsProps) {
  const classNames = cx(INDENT_TO_CLASS[indent]);

  return <div className={classNames}>{children}</div>;
}
