import { changeActiveBannerIndex } from './changeActiveBannerIndex';
import { setStatusSucceed } from './newbuildingForPromoInventoryStatus';
import { resolveNewbuildingsForPromoInventory } from '../../services/resolveNewbuildingsForPromoInventory';
import { TThunkAction } from '../../types/redux';
import { mapBBoxBoundsToBBox } from '../../utils/mapBounds';

export function fetchNewbuildingsForPromoInventory(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { mapBounds, promoInventory } = getState();

    if (!mapBounds.bounds || !mapBounds.zoom) {
      return;
    }

    const bbox = mapBBoxBoundsToBBox({ bounds: mapBounds.bounds });

    const response = await resolveNewbuildingsForPromoInventory({ context, bbox, zoom: mapBounds.zoom });

    if (promoInventory.activeBannerIndex === null && response.length !== 0) {
      dispatch(changeActiveBannerIndex(0));
    }

    dispatch(setStatusSucceed(response));
  };
}
