import { TTag } from '../tags';

export enum ETagsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface ITagsState {
  status: ETagsStatus;
  items: TTag[] | null;
}
