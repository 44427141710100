import { ResponseError } from 'shared/common/errors';
import {
  IGetInfrastructureGeoObjectsResponse200,
  TGetInfrastructureGeoObjectsResponse,
} from 'shared/common/repositories/infrastructure-caching/v1/get-infrastructure-geo-objects';

export function assertFetchGetInfrastructureGeoObjectsResponse(
  response: TGetInfrastructureGeoObjectsResponse,
): asserts response is IGetInfrastructureGeoObjectsResponse200 {
  if (response.statusCode !== 200) {
    throw new ResponseError({
      message: response.response.message || 'Unknown error',
      domain: 'assertFetchGetInfrastructureGeoObjectsResponse',
    });
  }
}
