import { ca } from '@cian/analytics';
import { TDevice } from '@cian/utils';

import { getSiteType } from '../../utils/analytics';

interface ITrackBannerArguments {
  queryString: string;
  deviceType: TDevice;
  id: number;
}

export function trackShowBanner({ queryString, deviceType, id }: ITrackBannerArguments) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'show_sopr',
    label: `newbuilding_banner_${id}`,
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}

export function trackClickBanner({ queryString, deviceType, id }: ITrackBannerArguments) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click',
    label: `newbuilding_banner_${id}`,
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}

export function trackCloseBanner({ queryString, deviceType, id }: ITrackBannerArguments) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Close',
    label: `newbuilding_banner_${id}`,
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}
