import { colors } from '@cian/ui-kit/colors';
import { pathOr } from 'ramda';

import { setFeaturesStatus } from './setFeaturesStatus';
import { EInfrastructureActionType, ISetFeaturesPayload } from './types';
import { prepareInfrastructureFeaturesMap } from '../../mappers/infrastructure';
import { selectActiveType } from '../../selectors/infrastructure';
import { fetchInfrastructureFeatures as fetchInfrastructureFeaturesService } from '../../services/infrastructure';
import { EAsyncStatus } from '../../types/asyncStatus';
import { ITilesMap } from '../../types/map';
import { TThunkAction } from '../../types/redux';
import { combineBboxes } from '../../utils/geometry';
import { mapBBoxBoundsToInfrastructureCachingBBox } from '../../utils/infrastructureCaching';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { getRequiredTiles, getVisibleTiles, tileToBbox } from '../../utils/tiles';

const setInfrastructureFeatures = actionGenerator<EInfrastructureActionType.SetFeatures, ISetFeaturesPayload>(
  EInfrastructureActionType.SetFeatures,
);

export function fetchInfrastructureFeatures(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const state = getState();
    const { logger } = context;
    const {
      mapBounds,
      infrastructure: { data },
    } = state;
    const activeType = selectActiveType(state);

    const { bounds, zoom: currentZoom } = mapBounds;

    if (!activeType) {
      return;
    }

    if (!bounds || !currentZoom) {
      logger.error('координаты или зум не заданы', { domain: 'actions/infrastructure/fetchInfrastructureFeatures' });

      return;
    }

    dispatch(setFeaturesStatus({ id: activeType.id, status: EAsyncStatus.Loading }));

    const visibleTiles = getVisibleTiles(bounds, currentZoom);
    const loadedTiles = pathOr<ITilesMap>({}, [activeType.id, 'tiles'], data);
    const requiredTiles = getRequiredTiles(loadedTiles, visibleTiles);

    if (requiredTiles.length === 0) {
      logger.debug('попытка загрузить уже загруженные тайлы', {
        domain: 'actions/infrastructure/fetchInfrastructureFeatures',
      });

      dispatch(setFeaturesStatus({ id: activeType.id, status: EAsyncStatus.Succeed }));

      return;
    }

    const requiredBboxes = requiredTiles.map(t => tileToBbox(t, currentZoom));
    const combinedBbox = combineBboxes(requiredBboxes);

    const features = await fetchInfrastructureFeaturesService(
      {
        bbox: mapBBoxBoundsToInfrastructureCachingBBox({ bounds: combinedBbox }),
        types: activeType.id.toString(),
      },
      context,
    );

    const icon = activeType.icon;
    const pinColor = activeType.pinColor || colors.black_100;
    const iconColor = activeType.iconColor || colors.white_100;
    const featuresMap = prepareInfrastructureFeaturesMap(features, { icon, pinColor, iconColor });

    dispatch(setFeaturesStatus({ id: activeType.id, status: EAsyncStatus.Succeed }));

    dispatch(
      setInfrastructureFeatures({
        features: featuresMap,
        id: activeType.id,
        tiles: requiredTiles,
      }),
    );
  };
}
