import classNames from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';
import { BannerWrapper } from '../../BannerWrapper';

export interface IDesktopBannerTemplateProps {
  title: React.ReactNode;
  description: React.ReactNode;
  copyright: React.ReactNode;
  onClick?(): void;
  onClose?(event: React.MouseEvent<HTMLElement>): void;
  href: string;
  developer?: React.ReactNode;
  isSearchResultsOpen?: boolean;
  rootClassName?: string;
  background?: string;
  logo: string;
}

export function DesktopBannerTemplate({
  title,
  description,
  copyright,
  developer,
  onClick,
  onClose,
  href,
  rootClassName,
  isSearchResultsOpen,
  background,
  logo,
}: IDesktopBannerTemplateProps) {
  return (
    <BannerWrapper
      moveRight={isSearchResultsOpen}
      attributes={{
        className: classNames(rootClassName),
      }}
    >
      <button className={styles['close']} onClick={onClose} />

      <div data-testid="fsk-banner-background" className={styles['image-container']}>
        {background && <div className={styles['image']} style={{ backgroundImage: `url(${background})` }} />}
      </div>

      <a href={href} target="_blank" rel="noopener noreferrer" className={styles['content']} onClick={onClick}>
        <div data-testid="fsk-banner-text" className={styles['info']}>
          {developer}
          {title}
          {description}
        </div>

        <div data-testid="fsk-banner-logo" className={styles['logo']}>
          <img src={logo} alt="Застройщик ФСК" />
        </div>
      </a>

      <div data-testid="fsk-banner-copyright" className={styles['copyright']}>
        {copyright}
      </div>
    </BannerWrapper>
  );
}
