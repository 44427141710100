import { JsonQuery } from '../../../common/packages/JsonQuery';
import { TGeoValue } from '../../../common/packages/api-models/common/json_query';
import { TThunkAction } from '../../types/redux';
import { changeJsonQuery, fetchTags } from '../filters';
import { fetchClusters } from '../results';

/** Deprecated. Use changeJsonQuery */
export function clearGeoType(type: TGeoValue['type']): TThunkAction {
  return async (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();

    const jq = new JsonQuery(jsonQuery);
    jq.clearGeo();

    if (jsonQuery.geo && jsonQuery.geo.value.some(geo => geo.type !== type)) {
      jsonQuery.geo.value
        .filter(geo => geo.type !== type)
        .forEach(geo => {
          jq.selectGeo(geo);
        });
    }

    // TODO: appliedModifiers
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(changeJsonQuery({ appliedModifiers: [] as any, prevJsonQuery: jsonQuery, nextJsonQuery: jq.toJSON() }));
    dispatch(fetchClusters());
    dispatch(fetchTags());
  };
}
