import RBush from 'rbush';

import { getPinMarkup } from './utils/markup';
import { IInfrastructureRBushItem, TInfrastructureFeature } from '../../../types/infrastructure';

interface IInfrastructureHTMLPointsLayer {
  map: YMaps.Map;
  rbush: RBush<IInfrastructureRBushItem>;
  minZoom: number;
  largePinsZoom: number;
}

export function defineInfrastructureHTMLPointsLayer(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('InfrastructureHTMLPointsLayer')) {
    return;
  }

  ymaps.modules.define<
    [YMaps.ITemplateLayoutFactory, typeof YMaps.shape.Rectangle, typeof YMaps.geometry.pixel.Rectangle]
  >(
    'InfrastructureHTMLPointsLayer',
    ['templateLayoutFactory', 'shape.Rectangle', 'geometry.pixel.Rectangle'],
    (
      provide,
      templateLayoutFactory: YMaps.ITemplateLayoutFactory,
      shapeRectangle: typeof YMaps.shape.Rectangle,
      geometryPixelRectangle: typeof YMaps.geometry.pixel.Rectangle,
    ) => {
      class InfrastructureHTMLPointsLayer {
        public readonly objectManager: YMaps.ObjectManager;

        private map: YMaps.Map;
        private rbush: RBush<IInfrastructureRBushItem>;
        private largePinsZoom: number;
        private minZoom: number;

        public constructor(options: IInfrastructureHTMLPointsLayer) {
          this.largePinsZoom = options.largePinsZoom;
          this.minZoom = options.minZoom;
          this.map = options.map;
          this.rbush = options.rbush;
          this.objectManager = new ymaps.ObjectManager();
        }

        public clear = () => {
          this.objectManager.removeAll();
        };

        public updatePins = () => {
          const features: TInfrastructureFeature[] = this.rbush
            .all()
            .map(feature => feature.feature as TInfrastructureFeature);

          for (const feature of features) {
            this.objectManager.objects.setObjectOptions(feature.id, {
              iconLayout: this.preparePinLayout(feature),
            });
          }
        };

        public drawPins = () => {
          this.clear();

          if (this.isShown) {
            return;
          }

          const bounds = this.map.getBounds();
          const rBushItems = this.rbush.search({
            minX: bounds[0][0],
            minY: bounds[0][1],
            maxX: bounds[1][0],
            maxY: bounds[1][1],
          });

          this.objectManager.add({
            type: 'FeatureCollection',
            features: [...(rBushItems.map(this.preparePin).filter(feature => !!feature) as TInfrastructureFeature[])],
          });
        };

        private get isShown() {
          return this.map.getZoom() <= this.minZoom;
        }

        private get isLargePin() {
          return this.map.getZoom() >= this.largePinsZoom;
        }

        private preparePin = ({ feature }: IInfrastructureRBushItem) => {
          // id: `infra-pin_${feature.id}`,
          return {
            ...feature,
            options: {
              iconLayout: this.preparePinLayout(feature),
            },
          };
        };

        private preparePinLayout = (feature: TInfrastructureFeature) => {
          const content = getPinMarkup(feature.properties, this.isLargePin);
          const pinSize: YMaps.TCoord = this.isLargePin ? [32, 32] : [12, 12];

          return templateLayoutFactory.createClass(content, {
            getShape() {
              return new shapeRectangle(new geometryPixelRectangle([[0, 0], pinSize]));
            },
          });
        };
      }

      provide(InfrastructureHTMLPointsLayer);
    },
  );
}
