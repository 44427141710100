import { IconKid16, IconSchool16, IconHospital16, IconStore16 } from '@cian/ui-kit';
import classNames from 'clsx';
import * as React from 'react';

import { EInfrastructureIconType, IInfrastructureType } from 'shared/map-search/types/infrastructure';

import * as styles from './InfrastructureControl.css';

interface IInfrastructureControlProps {
  activeType?: number;
  types: IInfrastructureType[];
  legend?: React.ReactNode;
  onChange(infrastructureType: IInfrastructureType | null): void;
}

const ICONS_MAP: { [key: string]: React.ReactElement | null } = {
  [EInfrastructureIconType.School]: <IconSchool16 />,
  [EInfrastructureIconType.Nursery]: <IconKid16 />,
  [EInfrastructureIconType.Clinic]: <IconHospital16 />,
  [EInfrastructureIconType.Grocery]: <IconStore16 />,
  [EInfrastructureIconType.Empty]: null,
};

export const InfrastructureControl = React.forwardRef<HTMLDivElement, IInfrastructureControlProps>(
  ({ types, onChange, activeType, legend }, ref) => {
    return (
      <div className={styles['container']} ref={ref} data-name="InfrastructureControl">
        <div className={styles['buttons']}>
          {types.map(t => {
            const icon = ICONS_MAP[t.icon];

            return (
              <div
                key={t.id}
                className={classNames(styles['type'], activeType === t.id && styles['type_active'])}
                onClick={() => onChange(t)}
              >
                {icon && <div className={styles['icon']}>{icon}</div>}
                <div>{t.name}</div>
              </div>
            );
          })}
        </div>
        {legend}
        {activeType && (
          <div className={styles['reset']}>
            <button className={styles['reset-btn']} onClick={() => onChange(null)}>
              Сбросить
            </button>
          </div>
        )}
      </div>
    );
  },
);

InfrastructureControl.displayName = 'InfrastructureControl';
