import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';

import { useFeaturesContext } from '../context';

interface IResultabilityManagerProps {
  onResultabilityChange(isResultable: boolean): void;
}

export const ResultabilityManager: React.FC<IResultabilityManagerProps> = ({ onResultabilityChange }) => {
  const { config } = useApplicationContext();
  const { pointsLayer, hotspotsLayer } = useFeaturesContext();
  const resultablePrecision = config.get<number>('mapSearchFrontend.resultablePrecision') || 7;
  const { precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);

  const isResultableByPrecision = precision >= resultablePrecision;

  React.useEffect(() => {
    const isResultable = isResultableByPrecision;

    pointsLayer.isResultable = isResultable;
    pointsLayer.layer.update();
    hotspotsLayer.isResultable = isResultable;
    hotspotsLayer.layer.update();

    onResultabilityChange(isResultable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultableByPrecision]);

  return null;
};
