import * as React from 'react';

/* istanbul ignore next */
export const ZoomInNew: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 5V0H5V5H0V7H5V12H7V7H12V5H7Z" fill="#152242" />
    </svg>
  );
};

ZoomInNew.displayName = 'ZoomInNew';
