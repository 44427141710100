import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

export function trackListingClick(jsonQuery: IJsonQuery) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'Map',
    label: 'ListView',
    sc: jsonQuery,
  });
}
