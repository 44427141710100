import * as React from 'react';

import { FOfferType, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { EParkingType } from 'shared/common/packages/api-models/common/json_query';

import { getParkingTypes } from './helpers';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IParkingProps {
  offerType: FOfferType;
  value: NonEmptyArray<EParkingType> | null;
  onChange(value: NonEmptyArray<EParkingType> | null): void;
}

export const Parking: React.FC<IParkingProps> = ({ offerType, value, onChange }) => {
  const parkingTypes = getParkingTypes(offerType);

  return (
    <Filter id="advancedFilter_parking" label="Парковка">
      <CheckboxButtonGroupUnselectable options={parkingTypes} value={value} onChange={onChange} />
    </Filter>
  );
};
