import cx from 'clsx';
import * as React from 'react';

import * as styles from './Navigation.css';

type TNavigationIndent = 'M' | 'L' | 'redesign';

const INDENT_TO_CLASS: { [key in TNavigationIndent]: string } = {
  M: styles['middle-indent'],
  L: styles['large-indent'],
  redesign: styles['large-indent--redesign'],
};

export interface INavigationProps {
  children: React.ReactNode;
  indent?: TNavigationIndent;
}

export function Navigation({ children, indent = 'M' }: INavigationProps) {
  const classNames = cx(styles['container'], INDENT_TO_CLASS[indent]);

  return <div className={classNames}>{children}</div>;
}
