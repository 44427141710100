import { useDeviceType } from '@cian/ui-kit/responsive';
import { numberToPrettyString, plural } from '@cian/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { trackListingClick } from './analytics';
import { ListingButton } from '../../../components/Navigation/buttons';
import { selectOffersCount } from '../../../selectors/offersCount';
import { selectListingUrl } from '../../../selectors/urls';
import { IApplicationState } from '../../../types/redux';
import { EResultsStatus } from '../../../types/results';
import { TUser } from '../../../types/user';
import { useApplicationContext } from '../../ApplicationContext';

export const ListingButtonContainer: React.FC = () => {
  const url = useSelector(selectListingUrl);
  const offersCount = useSelector(selectOffersCount);
  const {
    custom: { subdomain },
  } = useApplicationContext();
  const targetSubdomain = useSelector<IApplicationState, string>(state => state.results.subdomain);
  const jsonQuery = useSelector<IApplicationState, IJsonQuery>(state => state.results.jsonQuery);
  const deviceType = useDeviceType();
  const [targetDomain, setTargetDomain] = React.useState(`${targetSubdomain}.cian.ru`);
  const user = useSelector<IApplicationState, TUser>(state => state.user);
  const resultsStatus = useSelector<IApplicationState, EResultsStatus>(state => state.results.status);
  const isMobile = deviceType !== 'desktop';
  const withOffersCounter = !user.isAuthenticated || user.userType === 'searcher' || user.userType === 'homeowner';
  const isLoading = resultsStatus === EResultsStatus.Loading && withOffersCounter;

  const text = React.useMemo(
    () =>
      withOffersCounter
        ? `${numberToPrettyString(offersCount)} ${plural(offersCount, ['объект', 'объекта', 'объектов'])} списком`
        : 'Списком',
    [offersCount, withOffersCounter],
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      const href = event.currentTarget.href;
      trackListingClick(jsonQuery);

      setTimeout(() => {
        if (deviceType === 'desktop') {
          window.open(href, '_blank');
        } else {
          document.cookie = 'flavour=mobile;domain=.cian.ru;path=/';
          window.location.href = href;
        }
      }, 300);
    },
    [deviceType, isLoading, jsonQuery],
  );

  React.useEffect(() => {
    setTargetDomain(window.location.hostname.replace(`${subdomain}.`, `${targetSubdomain}.`));
  }, [subdomain, targetSubdomain]);

  return (
    <ListingButton
      url={`//${targetDomain}${url}`}
      onClick={handleClick}
      text={isMobile ? 'Список' : text}
      loading={isLoading}
      isMobile={isMobile}
    />
  );
};
