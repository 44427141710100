import { INewbuildingContacts } from '../../types/phone/phone';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ECallPopupActionType {
  Open = 'newbuildingHeader/openContacts',
  Close = 'newbuildingHeader/closeContacts',
}

export type TOpenCallPopup = ITypedReduxAction<ECallPopupActionType.Open, INewbuildingContacts>;
export type TCloseCallPopup = ITypedReduxAction<ECallPopupActionType.Close>;

export type TCallPopupActions = TOpenCallPopup | TCloseCallPopup;
