import { TMessage } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidMessage(message: any): message is TMessage {
  if (!message || typeof message !== 'object') {
    return false;
  }

  return 'value' in message && 'type' in message && ['reduxAction', 'analytics'].includes(message.type);
}
