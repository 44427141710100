/* eslint-disable @typescript-eslint/no-explicit-any */
import { ca } from '@cian/analytics';

interface ITackNewbuildingMyHomeTrap {
  products: any;
  page: any;
}

export function trackNewbuildingMyHomeTrapShow({ products, page }: ITackNewbuildingMyHomeTrap) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'show_sopr',
    label: `add_address_nb`,
    products,
    // Вынужденый костыль для прокидывания дополнительного extra
    page: {
      ...page,
      extra: {
        ...page.extra,
        trap_type: 'MyHomeNewBuildingListing',
      },
    },
  });
}

export function trackNewbuildingMyHomeTrapClick({ products, page }: ITackNewbuildingMyHomeTrap) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'click',
    label: `add_address_nb`,
    products,
    // Вынужденый костыль для прокидывания дополнительного extra
    page: {
      ...page,
      extra: {
        ...page.extra,
        trap_type: 'MyHomeNewBuildingListing',
      },
    },
  });
}
