import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { EGarageStatus } from 'shared/common/packages/api-models/common/json_query';

import { GarageStatus } from '../../../components/advancedFilters/GarageStatus';
import { useContext } from '../../../utils/useContext';

export const GarageStatusContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const garageStatus = getTermsValue('garage_status')(jsonQuery);

  const handleChange = React.useCallback(
    (garageStatus: NonEmptyArray<EGarageStatus> | null) => {
      onChange({ action: 'setGarageStatus', arguments: [garageStatus] });
    },
    [onChange],
  );

  return <GarageStatus value={garageStatus as NonEmptyArray<EGarageStatus> | null} onChange={handleChange} />;
};
