export const MAP_DEFAULT_STATE: YMaps.IMapState = {
  controls: [],
};

export const MAP_DEFAULT_OPTIONS: YMaps.IMapOptions = {
  avoidFractionalZoom: true,
  // scrollZoomSmooth: true,
  layerLoadTilesInAction: true,
  autoFitToViewport: 'always',
  restrictMapArea: [
    [41.1859, 19.6389],
    [81.8569, -168.9978],
  ],
  suppressMapOpenBlock: true,
  yandexMapAutoSwitch: false,
  yandexMapDisablePoiInteractivity: true,
};

export const MAP_SET_CENTER_OPTIONS = {
  duration: 500,
  timingFunction: 'ease-in-out',
};
