import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';

import { Controls } from './Controls';
import { DrawButtonContainer as DrawButton } from './DrawButton';
import { GeoLocationButtonContainer as GeoLocationButton } from './GeoLocationButton';
import { HelpLinkButtonContainer as HelpLinkButton } from './HelpLinkButton';
import { RulerButtonContainer as RulerButton } from './RulerButton';
import { ZoomInButtonContainer as ZoomInButton } from './ZoomInButton';
import { ZoomOutButtonContainer as ZoomOutButton } from './ZoomOutButton';

export const ControlsContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const controlsIndentType = deviceType === 'desktop' ? 'L' : 'M';

  return (
    <Controls
      ruler={<RulerButton />}
      help={<HelpLinkButton />}
      drawPolygon={<DrawButton />}
      zoomIn={<ZoomInButton />}
      zoomOut={<ZoomOutButton />}
      geoLocation={<GeoLocationButton />}
      indent={controlsIndentType}
    />
  );
};

ControlsContainer.displayName = 'ControlsContainer';
