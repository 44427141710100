import { addDays, startOfToday, parseISO, formatISO } from 'date-fns';
import * as React from 'react';

import { getDateRangeValue } from 'shared/common/packages/JsonQuery';

import { getButtonLabel } from './utils';
import { Dates } from '../../../components/filters/Dates';
import { useContext } from '../../../utils/useContext';

export const DatesContainer: React.FC = () => {
  const { jsonQuery, onChange, onApply } = useContext();

  const range = getDateRangeValue('dates')(jsonQuery);

  const [isOpen, setIsOpen] = React.useState(false);

  const today = startOfToday();
  const minDate = today;
  const maxDate = addDays(today, 365);
  const fromValue = range?.gte ? parseISO(range.gte) : null;
  const toValue = range?.lt ? parseISO(range.lt) : null;

  const buttonLabel = getButtonLabel(fromValue, toValue);

  const handleButtonClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleDatesChange = React.useCallback(
    (from: Date | undefined, to: Date | undefined) => {
      let fromValue = from;
      let toValue = to;

      if (fromValue && !toValue) {
        toValue = addDays(fromValue, 1);
      }
      if (toValue && !fromValue) {
        fromValue = addDays(toValue, -1);
      }
      if (fromValue && toValue && fromValue >= toValue) {
        toValue = addDays(fromValue, 1);
      }

      if (fromValue && toValue) {
        const gte = formatISO(fromValue, { representation: 'date' });
        const lte = formatISO(toValue, { representation: 'date' });

        onChange({ action: 'setDates', arguments: [gte, lte] });
        onApply();
      }
    },
    [onApply, onChange],
  );

  return (
    <Dates
      isOpen={isOpen}
      buttonLabel={buttonLabel}
      minDate={minDate}
      maxDate={maxDate}
      fromValue={fromValue}
      toValue={toValue}
      onButtonClick={handleButtonClick}
      onDatesChange={handleDatesChange}
      onClose={handleClose}
    />
  );
};
