import classNames from 'clsx';

import * as styles from './styles.css';
import { TIconSizeModifier } from './types';

export const ICON_SIZE_MODIFIER_TO_CLASS: { [key in TIconSizeModifier]: string | undefined } = {
  M: undefined,
  L: classNames(styles['icon'], styles['large']),
  XL: classNames(styles['icon'], styles['extra-large']),
};

export const COOKIE_NAME = 'map_ob_polygons';
export const COOKIE_EXPIRES = 1000 * 60 * 60 * 24 * 30;
