import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

export interface IBedroomsProps {
  bedrooms: IJsonQueryRangeValue | null;
  onBedroomsMinChange(gte: number | null): void;
  onBedroomsMaxChange(lte: number | null): void;
}

export const Bedrooms: React.FC<IBedroomsProps> = ({ bedrooms, onBedroomsMinChange, onBedroomsMaxChange }) => {
  const { gte: bedroomsMin = null, lte: bedroomsMax = null } = bedrooms || {};

  const handleBedroomsMinChange = useDebouncedCallback((gte: number | null) => {
    onBedroomsMinChange(gte);
  }, 300);

  const handleBedroomsMaxChange = useDebouncedCallback((lte: number | null) => {
    onBedroomsMaxChange(lte);
  }, 300);

  return (
    <Filter id="advancedFilter_bedrooms" label="Спален в доме">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            min={1}
            max={200}
            valueMin={bedroomsMin}
            valueMax={bedroomsMax}
            onChangeMin={handleBedroomsMinChange}
            onChangeMax={handleBedroomsMaxChange}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
