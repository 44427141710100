import * as React from 'react';

import { NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { TagsInput } from '../../TagsInput';
import { Filter } from '../common/Filter';

interface IContainWordsProps {
  value: string[] | null;
  onChange(value: string[] | null): void;
}

export const ContainWords: React.FC<IContainWordsProps> = ({ value: values, onChange }) => {
  const handleAdd = React.useCallback(
    (value: string) => {
      const newValues = [value];

      switch (value.toLowerCase()) {
        case 'кот':
        case 'кошка':
        case 'котик':
        case 'коты':
        case 'котики':
          if (!values || !values.includes('🐈')) {
            newValues.push('🐈');
          }

          break;
      }

      onChange(values ? [...values, ...newValues] : newValues);
    },
    [values, onChange],
  );

  const handleRemove = React.useCallback(
    (value: string) => {
      let nextValue = values ? values.filter(v => v !== value) : null;
      if (nextValue && nextValue.length === 0) {
        nextValue = null;
      }

      onChange(nextValue);
    },
    [values, onChange],
  );

  return (
    <Filter id="advancedFilter_containWords" label="Содержит слова в объявлении">
      <TagsInput
        id="advancedFilter_containWords_tagsInput"
        placeholder="Например: срочно"
        value={values && values.length > 0 ? (values as NonEmptyArray<string>) : null}
        onAdd={handleAdd}
        onRemove={handleRemove}
      />
    </Filter>
  );
};
