import {
  all,
  append,
  apply,
  both,
  complement,
  compose,
  cond,
  equals,
  has,
  ifElse,
  map,
  merge,
  mergeWith,
  objOf,
  prepend,
  reject,
  type,
  useWith,
  values,
} from 'ramda';

const isObject = compose(equals('Object'), type);
const allAreObjects = compose(all(isObject), values);
const hasLeft = has('left');
const hasRight = has('right');
const hasBoth = both(hasLeft, hasRight);
const isEqual = both(hasBoth, compose(apply<[unknown, unknown], boolean>(equals), values));

const isAddition = both(hasLeft, complement(hasRight));
const isRemoval = both(complement(hasLeft), hasRight);
const markAdded = compose(append(undefined), values);
const markRemoved = compose(prepend(undefined), values);

type TObjectDiff<T extends object> = {
  [key in keyof Partial<T>]: T[key] extends object ? TObjectDiff<T[key]> : [T[key] | undefined, T[key] | undefined];
};

export function objectDiff<L extends object, R extends object>(left: L, right: R): TObjectDiff<L & R> {
  return (
    compose(
      map(
        cond([
          [isAddition, markAdded],
          [isRemoval, markRemoved],
          [hasBoth, ifElse(allAreObjects, compose(apply(objectDiff), values), values)],
        ]),
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reject(isEqual) as any,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useWith(mergeWith(merge), [map(objOf('left')), map(objOf('right'))]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any
  )(left, right);
}
