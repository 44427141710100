import * as React from 'react';

export const Help: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.994 3.59C7.31133 3.31 7.65667 3.044 8.03 2.792C8.41267 2.54 8.82333 2.32067 9.262 2.134C9.70067 1.94733 10.1767 1.80267 10.69 1.7C11.2033 1.588 11.7587 1.532 12.356 1.532C13.168 1.532 13.9053 1.644 14.568 1.868C15.24 2.092 15.814 2.414 16.29 2.834C16.766 3.24467 17.1347 3.744 17.396 4.332C17.6573 4.92 17.788 5.578 17.788 6.306C17.788 7.01533 17.6853 7.63133 17.48 8.154C17.2747 8.66733 17.018 9.11533 16.71 9.498C16.402 9.88067 16.0613 10.212 15.688 10.492C15.324 10.772 14.9787 11.038 14.652 11.29C14.3253 11.5327 14.0407 11.7753 13.798 12.018C13.5647 12.2513 13.4247 12.5173 13.378 12.816L13.056 14.86H10.69L10.452 12.578C10.396 12.1393 10.4567 11.7567 10.634 11.43C10.8207 11.094 11.0633 10.7907 11.362 10.52C11.67 10.24 12.0107 9.974 12.384 9.722C12.7573 9.46067 13.1073 9.18067 13.434 8.882C13.7607 8.574 14.0313 8.23333 14.246 7.86C14.47 7.47733 14.582 7.02467 14.582 6.502C14.582 6.166 14.5167 5.86733 14.386 5.606C14.2647 5.33533 14.092 5.10667 13.868 4.92C13.644 4.724 13.3733 4.57467 13.056 4.472C12.748 4.36933 12.412 4.318 12.048 4.318C11.516 4.318 11.0633 4.37867 10.69 4.5C10.326 4.612 10.0133 4.74267 9.752 4.892C9.5 5.032 9.28533 5.16267 9.108 5.284C8.93067 5.396 8.772 5.452 8.632 5.452C8.296 5.452 8.05333 5.312 7.904 5.032L6.994 3.59ZM9.64 20.096C9.64 19.8067 9.69133 19.5313 9.794 19.27C9.906 19.0087 10.0553 18.7847 10.242 18.598C10.4287 18.4113 10.6527 18.262 10.914 18.15C11.1753 18.038 11.4553 17.982 11.754 17.982C12.0527 17.982 12.328 18.038 12.58 18.15C12.8413 18.262 13.0653 18.4113 13.252 18.598C13.448 18.7847 13.602 19.0087 13.714 19.27C13.826 19.5313 13.882 19.8067 13.882 20.096C13.882 20.3947 13.826 20.6747 13.714 20.936C13.602 21.188 13.448 21.4073 13.252 21.594C13.0653 21.7807 12.8413 21.9253 12.58 22.028C12.328 22.14 12.0527 22.196 11.754 22.196C11.4553 22.196 11.1753 22.14 10.914 22.028C10.6527 21.9253 10.4287 21.7807 10.242 21.594C10.0553 21.4073 9.906 21.188 9.794 20.936C9.69133 20.6747 9.64 20.3947 9.64 20.096Z"
        fill="#0468FF"
      />
    </svg>
  );
};

Help.displayName = 'Help';
