import { IConfig } from '@cian/config/shared';
import { TDevice } from '@cian/utils';

import { ERentTime, getTermValue } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { RS_DESKTOP_FEATURE_TOGGLE_NAME } from 'shared/map-search/constants/config/rs';

interface IParams {
  config: IConfig;
  jsonQuery: IJsonQuery;
  deviceType?: TDevice;
}

/**
 * Подробности в README
 * @see src/shared/map-search/utils/dailyRentAvailability/README.md
 */
export function checkDailyRentAvailabilityRSEnabled({ config, jsonQuery, deviceType }: IParams) {
  const rentalPeriod = getTermValue('for_day')(jsonQuery);
  const isDailyRent = rentalPeriod === ERentTime.Daily;
  const isCorrectDeviceType = deviceType ? ['desktop', 'tablet'].includes(deviceType) : false;

  if (!isDailyRent || !isCorrectDeviceType) {
    return false;
  }

  return Boolean(config.get<boolean>(RS_DESKTOP_FEATURE_TOGGLE_NAME));
}
