import { makeContextModule } from '@cian/react-utils';
import RBush from 'rbush';

import { IRBushItem } from 'shared/map-search/types/map';

export interface IFeaturesContext {
  rbush: RBush<IRBushItem>;
  pointsLayer: YMaps.PointsLayer;
  hotspotsLayer: YMaps.HotspotsLayer;
  balloonManager: YMaps.BalloonManager;
  popupManager: YMaps.PopupManager;
  newbuildingPopupManager: YMaps.NewbuildingPromoPopupManager;
}

export const {
  Context: FeaturesContext,
  useContext: useFeaturesContext,
  withContext: withFeaturesContext,
} = makeContextModule<IFeaturesContext>({
  contextName: 'FeaturesContext',
});
