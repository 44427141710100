import { EBannerStatus, INewbuildingForPromoInventory } from '../../types/promoInventory';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EFetchNewbuildingsForPromoInventoryActionType {
  Initial = 'promoInventory/fetchNewbuildingsForPromoInventory/initial',
  Succeed = 'promoInventory/fetchNewbuildingsForPromoInventory/succeed',
}

export enum EPromoInventoryActionType {
  ActiveBannerIndexChanged = 'promoInventory/activeBannerIndex/changed',
  BannerStatusChanged = 'promoInventory/bannerStatus/changed',
  ForceClosedBannerChanged = 'promoInventory/forceClosedBanner/changed',
}

export type TFetchNewbuildingsForPromoInventorySucceed = ITypedReduxAction<
  EFetchNewbuildingsForPromoInventoryActionType.Succeed,
  INewbuildingForPromoInventory[]
>;

export type TPromoInventoryActiveBannerChanged = ITypedReduxAction<
  EPromoInventoryActionType.ActiveBannerIndexChanged,
  number | null
>;

export type TPromoInventoryBannerStatusChanged = ITypedReduxAction<
  EPromoInventoryActionType.BannerStatusChanged,
  EBannerStatus
>;

export type TPromoInventoryForceClosedBannerChanged = ITypedReduxAction<
  EPromoInventoryActionType.ForceClosedBannerChanged,
  boolean
>;

export type TPromoInventoryActions =
  | TFetchNewbuildingsForPromoInventorySucceed
  | TPromoInventoryActiveBannerChanged
  | TPromoInventoryForceClosedBannerChanged
  | TPromoInventoryBannerStatusChanged;
