import * as React from 'react';

import { FDealType } from 'shared/common/packages/JsonQuery';

import { Select, Option } from '../../Select';

interface IDealType {
  label: string;
  value: FDealType;
}

const DEAL_TYPES: IDealType[] = [
  { label: 'Купить', value: FDealType.Sale },
  { label: 'Снять', value: FDealType.RentLongterm },
  { label: 'Посуточно', value: FDealType.RentDaily },
];

interface IDealTypeProps {
  value: FDealType;
  onChange(value: FDealType): void;
}

export const DealType: React.FC<IDealTypeProps> = ({ value, onChange }) => {
  return (
    <Select
      data-name="FilterBedrooms"
      withoutArrow
      placeholder="Тип сделки"
      value={value}
      onChange={(_, value: FDealType) => onChange(value)}
    >
      {DEAL_TYPES.map(dealType => (
        <Option key={`${dealType.label}_${dealType.value}`} value={dealType.value}>
          {dealType.label}
        </Option>
      ))}
    </Select>
  );
};
