import classNames from 'clsx';

import * as styles from './styles.css';
import { IUndergroundInfo } from '../../../types/newbuilding';

interface INewbuildingLocationProps {
  address: string;
  undergrounds: IUndergroundInfo[];
}

export enum EUndergroundType {
  Ekaterinburg = 'Ekb',
  Kazan = 'Kazan',
  Moscow = 'Msk',
  Novgorod = 'Nizhniy',
  Novosibirsk = 'Novosib',
  Samara = 'Samara',
  SaintPetersburg = 'Spb',
}

export enum ETransportType {
  Transport = 'transport',
  Walk = 'walk',
}

export function NewbuildingLocation({ undergrounds, address }: INewbuildingLocationProps) {
  const getUndergroundIconStyle = (type: string) => {
    switch (type) {
      case EUndergroundType.Ekaterinburg:
        return styles['underground-icon--ekb'];
      case EUndergroundType.Kazan:
        return styles['underground-icon--kazan'];
      case EUndergroundType.Moscow:
        return styles['underground-icon--www'];
      case EUndergroundType.Novgorod:
        return styles['underground-icon--nn'];
      case EUndergroundType.Novosibirsk:
        return styles['underground-icon--novosibirsk'];
      case EUndergroundType.Samara:
        return styles['underground-icon--samara'];
      case EUndergroundType.SaintPetersburg:
        return styles['underground-icon--spb'];
      default:
        return styles['underground-icon--www'];
    }
  };

  return (
    <>
      {undergrounds.length === 0 && (
        <div data-testid="newbuilding-address" className={styles['address']}>
          {address}
        </div>
      )}
      {undergrounds.map((item, index) => (
        <div className={styles['underground']} key={index}>
          <div className={styles['underground-group']}>
            <div
              className={classNames(styles['underground-icon'], getUndergroundIconStyle(item.undergroundType))}
              style={{ backgroundColor: `#${item.lineColor}` }}
            ></div>
            <span data-testid="underground-name" className={styles['underground-name']}>
              {item.name}
            </span>
          </div>
          <div className={styles['distance-group']}>
            <span
              className={classNames(
                styles['distance-icon'],
                item.transportType === ETransportType.Walk
                  ? styles['distance-icon--walk']
                  : styles['distance-icon--transport'],
              )}
            ></span>
            <span data-testid="distance-time" className={styles['distance-time']}>
              {item.timeDisplay}
            </span>
          </div>
        </div>
      ))}
    </>
  );
}
