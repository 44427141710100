import { HttpCancelError } from '@cian/peperrors/shared';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { TFeature } from 'shared/map-search/types/map';
import { IBBox } from 'shared/map-search/types/map/bbox';
import { IApplicationState } from 'shared/map-search/types/redux';

import { fetchGetClusterPopup, IFetchGetClusterPopupParameters } from '../services/getClusterPopup';

// TODO разрешать перезапрашивать одну и ту же фичу, если запрос зафейлился
export function usePopupState(
  initialFeature: TFeature | null = null,
): [YMaps.TPopupManagerState, React.Dispatch<React.SetStateAction<TFeature | null>>] {
  const context = useApplicationContext();
  const queryString = useSelector<IApplicationState, string>(state => state.queryString);
  const extendedQueryString = useSelector<IApplicationState, string>(state => state.extendedQueryString || '');
  const previousFeatureRef = React.useRef<TFeature | null>(null);
  const [feature, setFeature] = React.useState<TFeature | null>(initialFeature);
  const [state, setState] = React.useState<YMaps.TPopupManagerState>({ status: 'pending' });

  React.useEffect(() => {
    if (!feature) {
      return;
    }

    const previousFeature = previousFeatureRef.current;
    previousFeatureRef.current = feature;

    // не запрашиваем два раза одну и ту же фичу
    if (previousFeature && feature.id === previousFeature.id) {
      return;
    }

    const parameters: IFetchGetClusterPopupParameters = {
      bbox: feature.properties.bbox as IBBox,
      queryString: feature.properties.isExtended ? extendedQueryString : queryString,
    };

    setState({ status: 'pending' });

    fetchGetClusterPopup(context, parameters)
      .then(response => {
        setState({ ...response, status: 'completed' });
      })
      .catch(error => {
        if (error instanceof HttpCancelError) {
          return;
        }

        context.logger.error(error, {
          domain: 'PopupManager/usePopupState/fetchGetClusterPopup',
          parameters: JSON.stringify(parameters),
          feature: JSON.stringify(feature),
          queryString,
          extendedQueryString,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  return [state, setFeature];
}
