import { ca } from '@cian/analytics';

interface ITrackClickCardParams {
  sc: object;
  products: unknown;
  page: unknown;
}

export function trackClickCard({ sc, products, page }: ITrackClickCardParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_card',
    label: 'from_listing_in_map',
    sc,
    products,
    page,
  });
}
