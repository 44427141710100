import { isAvailableRegion } from 'shared/common/packages/JsonQuery';

import { UndergroundTimeContainer } from '../../containers/advancedFilters/UndergroundTimeContainer';
import { IFilter } from '../../types/advancedFilters';
import { REGIONS_WITH_UNDERGROUND } from '../regions';

export const undergroundTime: IFilter = {
  availability: isAvailableRegion(REGIONS_WITH_UNDERGROUND),
  component: UndergroundTimeContainer,
};
