import { validateCianDomain } from 'shared/map-search/utils/validateCianDomain';

import { analyticsMessageHandler, reduxActionMessageHandler } from './messageHandlers';
import { TReduxStore } from './types';
import { isValidMessage, assertStore } from './utils';

export class MessageEventManager {
  private store: TReduxStore;

  public init(store: TReduxStore) {
    this.store = store;

    window.addEventListener('message', this.processMessage);
  }

  public processMessage = (event: MessageEvent) => {
    assertStore(this.store);

    if (!validateCianDomain(event.origin)) {
      return;
    }

    const message = event.data;
    if (!isValidMessage(message)) {
      return;
    }

    switch (message.type) {
      case 'reduxAction':
        reduxActionMessageHandler(message, this.store.dispatch);
        break;
      case 'analytics':
        analyticsMessageHandler(message);
        break;
    }
  };
}

export const messageEventManager = new MessageEventManager();
