import { getTilePixelCoords } from './getTilePixelCoords';
import { TILE_SIZE } from '../../constants/map';
import { fromGlobalPixels } from '../projection';

export function tileToBbox(tileNumber: YMaps.TTileNumber, zoomRaw: number): [YMaps.TCoord, YMaps.TCoord] {
  const zoom = Math.round(zoomRaw);
  const [tilePixelCoordX, tilePixelCoordY] = getTilePixelCoords(tileNumber);

  const [coordMaxX, coordMinY] = fromGlobalPixels([tilePixelCoordX, tilePixelCoordY], zoom);
  const [coordMinX, coordMaxY] = fromGlobalPixels([tilePixelCoordX + TILE_SIZE, tilePixelCoordY + TILE_SIZE], zoom);

  return [
    [coordMinX, coordMinY],
    [coordMaxX, coordMaxY],
  ];
}
