import { EDrawingActionType } from '../../actions/drawing';
import { ERulerActionType } from '../../actions/ruler';
import { IDrawingState } from '../../types/drawing';
import { TReduxActions } from '../../types/redux';

const defaultState: IDrawingState = {
  isDrawingMode: false,
};

export function drawingReducer(state: IDrawingState = defaultState, action: TReduxActions): IDrawingState {
  switch (action.type) {
    case EDrawingActionType.DrawingStarted:
      return {
        ...state,
        isDrawingMode: true,
      };
    case EDrawingActionType.DrawingFinished:
    case ERulerActionType.RulerEnabled:
      return {
        ...state,
        isDrawingMode: false,
      };
    default:
      return state;
  }
}
