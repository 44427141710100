import { ca } from '@cian/analytics';
import { TDevice } from '@cian/utils';

import { getSiteType } from '../../utils/analytics';

export function trackShowBanner(queryString: string, deviceType: TDevice) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'show_sopr',
    label: 'newbuilding_banner_fsk',
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}

export function trackClickBanner(queryString: string, deviceType: TDevice) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click',
    label: 'newbuilding_banner_fsk',
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}

export function trackCloseBanner(queryString: string, deviceType: TDevice) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Close',
    label: 'newbuilding_banner_fsk',
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
      queryString,
    },
  });
}
