import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackProfessionalSearchClick } from './tracking';
import { ProfessionalSearchButton } from '../../../components/Navigation';
import { selectQueryString } from '../../../selectors/queryString';
import { selectIsUserAgent } from '../../../selectors/user';

export function ProfessionalSearchButtonContainer() {
  const deviceType = useDeviceType();
  const isUserAgent = useSelector(selectIsUserAgent);
  const queryString = useSelector(selectQueryString);
  const encodeQueryString = queryString ? encodeURIComponent(queryString) : '';
  const url = queryString
    ? `https://my.cian.ru/offers?from_full_url=${encodeQueryString}`
    : 'https://my.cian.ru/offers';

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      const href = event.currentTarget.href;
      trackProfessionalSearchClick(queryString, deviceType);

      setTimeout(() => {
        if (deviceType === 'desktop') {
          window.open(href, '_blank');
        } else {
          window.location.href = href;
        }
      }, 300);
    },
    [deviceType],
  );

  return isUserAgent ? <ProfessionalSearchButton url={url} onClick={handleClick} /> : null;
}
