import { intersection } from 'ramda';

import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';
import { PIK_PROMO_REGIONS, PIK_PROMO } from '../../constants/pik';
import { IRegionSpecialPromo } from '../../types/specialPromos';

export function getAvailableSpecialPromo(
  specialPromos: IRegionSpecialPromo[],
  jsonQuery: IJsonQuery,
): IRegionSpecialPromo | undefined {
  const currentRegion = jsonQuery.region?.value;
  let specialPromo: IRegionSpecialPromo | undefined;
  if (currentRegion) {
    if (intersection(PIK_PROMO_REGIONS, currentRegion).length > 0) {
      specialPromo = PIK_PROMO;
    } else {
      specialPromo = currentRegion
        ? specialPromos.filter(specialPromo =>
            currentRegion.some(cRegion => specialPromo.regions.some(pRegion => pRegion === cRegion)),
          )[0]
        : undefined;
    }
  }

  return specialPromo;
}
