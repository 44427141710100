import classNames from 'clsx';
import * as React from 'react';

import * as styles from './Group.css';

type TFilterProps = Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'>;

export const Group: React.FC<React.PropsWithChildren<TFilterProps>> = props => {
  const className = classNames(styles['container'], props.className);

  return <div className={className}>{props.children}</div>;
};
