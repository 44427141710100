import { beautify } from '../../../utils/number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getAreaLabel(min: number | null, max: number | null): string | null {
  if (min === 0 && max === 0) {
    return SHRUG;
  }

  if (min !== null && max === null) {
    return `от ${beautify(min)} м\u00b2`;
  } else if (min === null && max !== null) {
    return `до ${beautify(max)} м\u00b2`;
  } else if (min !== null && max !== null) {
    return `${beautify(min)} - ${beautify(max)} м\u00b2`;
  }

  return null;
}
