import { TInputSize } from '@cian/ui-kit';
import { IconPlus16, IconMinus12 } from '@cian/ui-kit/icons';
import { NumberInput } from '@cian/ui-kit/input';
import { InputAdornmentButton } from '@cian/ui-kit/inputAdornment';
import { useDeviceType } from '@cian/ui-kit/responsive';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './NumberInputWithButtons.css';

export interface INumberInputWithButtonsProps {
  value: number;
  onChange(value: number): void;
  size?: TInputSize;
  min?: number;
  max?: number;
}

const DEFAULT_MIN = Number.MIN_SAFE_INTEGER;
const DEFAULT_MAX = Number.MAX_SAFE_INTEGER;

export const NumberInputWithButtons: React.FC<INumberInputWithButtonsProps> = ({
  size,
  value,
  onChange,
  min: passedMin,
  max: passedMax,
}) => {
  const min = passedMin !== undefined ? passedMin : DEFAULT_MIN;
  const max = passedMax !== undefined ? passedMax : DEFAULT_MAX;

  const decrement = React.useCallback(() => onChange(Math.max(min, value - 1)), [min, value, onChange]);
  const increment = React.useCallback(() => onChange(Math.min(max, value + 1)), [max, value, onChange]);

  const deviceType = useDeviceType();

  return (
    <div className={cx(styles['container'], deviceType === 'phone' && styles['container-phone'])}>
      <NumberInput
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        size={size}
        textAlign="center"
        leftAdornment={
          <InputAdornmentButton onClick={decrement} disabled={value <= min}>
            <IconMinus12 color={value <= min ? 'gray_icons_100' : 'primary_100'} />
          </InputAdornmentButton>
        }
        rightAdornment={
          <InputAdornmentButton onClick={increment} disabled={value >= max}>
            <IconPlus16 color={value >= max ? 'gray_icons_100' : 'primary_100'} />
          </InputAdornmentButton>
        }
      />
    </div>
  );
};
