import { ca } from '@cian/analytics';

import { INewbuilding } from 'shared/map-search/types/map/newbuilding';

interface ITrackPinClickParameters {
  zoom: number;
  newbuilding: INewbuilding;
}

export function trackPinClick({ zoom, newbuilding }: ITrackPinClickParameters) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click_pin',
    category: 'Map',
    label: `green/${zoom}`,
    products: [
      {
        extra: {
          newbuilding_id: newbuilding.id,
          is_promo: true,
        },
      },
    ],
  });
}
