import * as React from 'react';

import { EHeatingType, getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { HeatingType } from '../../../components/advancedFilters/HeatingType';
import { useContext } from '../../../utils/useContext';

export const HeatingTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const heatingTypes = getTermsValue('heating_source_type')(jsonQuery);

  const handleChange = React.useCallback(
    (heatingTypes: NonEmptyArray<EHeatingType> | null) => {
      onChange({ action: 'setHeatingTypes', arguments: [heatingTypes] });
    },
    [onChange],
  );

  return <HeatingType value={heatingTypes as NonEmptyArray<EHeatingType> | null} onChange={handleChange} />;
};
