import * as ReactDOMServer from 'react-dom/server';

import * as styles from './styles.css';

export function NewbuildingPromoStubPopup() {
  return (
    <div className={styles['loading']}>
      <div className={styles['image-block']}></div>

      <div className={styles['content-block']}>
        <div className={styles['title']}></div>

        <div className={styles['undergrounds']}></div>

        <div className={styles['footer']}></div>
      </div>
    </div>
  );
}

export const NewbuildingPromoStubPopupMarkup = ReactDOMServer.renderToStaticMarkup(<NewbuildingPromoStubPopup />);
