import * as React from 'react';

import { EFacilityType, JsonQuery, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { SuburbanFacilities } from '../../../components/advancedFilters/SuburbanFacilities';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const SuburbanFacilitiesContainer: React.FC = () => {
  const dealType = useDealType();
  const { jsonQuery, onChange } = useContext();
  const suburbanFacilities = new JsonQuery(jsonQuery).getSuburbanFacilityTypes();

  const handleChange = React.useCallback(
    (suburbanFacilities: NonEmptyArray<EFacilityType> | null) => {
      onChange({ action: 'setSuburbanFacilityTypes', arguments: [suburbanFacilities] });
    },
    [onChange],
  );

  return <SuburbanFacilities dealType={dealType} value={suburbanFacilities} onChange={handleChange} />;
};
