import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import { NumberInputWithButtons } from 'shared/common/components/NumberInputWithButtons';

import * as styles from './Beds.css';
import { getBedsLabel } from './helpers';

interface IAreaProps {
  value: number;
  min: number;
  max: number;

  onChange(value: number): void;
}

export const Beds: React.FC<IAreaProps> = ({ value, onChange, min, max }) => {
  const insideRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const areaLabel = getBedsLabel(value);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Outside insideRefs={[insideRef]} onOutside={handleClose} active={isOpen}>
      <div className={styles['container']} data-name="FilterBeds">
        <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
          {areaLabel}
        </Button>
        {isOpen && (
          <div ref={insideRef} className={styles['dropdown']} data-name="FilterBedsPopover">
            <div className={styles['heading']}>Количество гостей</div>
            <NumberInputWithButtons size="XS" value={value} onChange={onChange} min={min} max={max} />
          </div>
        )}
      </div>
    </Outside>
  );
};
