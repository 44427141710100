import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackClick } from './tracking';
import { setIsDropdownOpen } from '../../../actions/infrastructure/setIsDropdownOpen';
import { InfrastructureButton } from '../../../components/Navigation/Infrastructure';
import {
  selectActiveType,
  selectIsDropdownOpen,
  selectIsInfrastructureOnMapEnabled,
  selectTypes,
} from '../../../selectors/infrastructure';

export function InfrastructureControlContainer() {
  const isOpen = useSelector(selectIsDropdownOpen);

  const dispatch = useDispatch();
  const activeLayer = useSelector(selectActiveType);
  const layers = useSelector(selectTypes);
  const isInfrastructureOnMapEnabled = useSelector(selectIsInfrastructureOnMapEnabled);

  const handleClick = React.useCallback(() => {
    if (!isOpen) {
      trackClick();
    }
    dispatch(setIsDropdownOpen(!isOpen));
  }, [dispatch, isOpen]);

  if (!isInfrastructureOnMapEnabled || layers.length === 0) {
    return null;
  }

  return <InfrastructureButton title={activeLayer?.name} expanded={isOpen} onClick={handleClick} />;
}
