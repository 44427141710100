import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveType } from 'shared/map-search/selectors/infrastructure';
import { IInfrastructureFeaturesMap } from 'shared/map-search/types/infrastructure';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { geoJsonFeatureToInfrastructureRBushItem } from 'shared/map-search/utils/features';
import { getInfrastructurePointsTilesToUpdate } from 'shared/map-search/utils/tiles';
import { onYmapsDebug } from 'shared/map-search/utils/ymapsModules/ymapsModulesDebug';

import { useInfrastructureFeaturesContext } from '../context';

export const InfrastructureFeaturesUpdater: React.FunctionComponent = () => {
  const { rbush, infrastructurePointsLayer } = useInfrastructureFeaturesContext();
  const { bounds, zoom } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const activeType = useSelector(selectActiveType);
  const infrastructureFeatures = useSelector<IApplicationState, IInfrastructureFeaturesMap>(state => {
    if (activeType === undefined) {
      return {};
    }

    return pathOr<IInfrastructureFeaturesMap>({}, [activeType.id, 'features'], state.infrastructure.data);
  });

  const loadedFeatures = React.useRef<IInfrastructureFeaturesMap>({});

  React.useEffect(() => {
    rbush.clear();
    loadedFeatures.current = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeType]);

  React.useEffect(() => {
    const rbushItems = Object.values(infrastructureFeatures)
      .filter(({ id }) => !loadedFeatures.current[id])
      .map(geoJsonFeatureToInfrastructureRBushItem);

    if (rbushItems.length === 0) {
      if (Object.keys(loadedFeatures.current).length === 0) {
        infrastructurePointsLayer.layer.update();
      }

      return;
    }

    rbush.load(rbushItems);

    const featuresWereLoaded = Object.keys(loadedFeatures.current).length > 0;
    loadedFeatures.current = infrastructureFeatures;

    if (!featuresWereLoaded) {
      infrastructurePointsLayer.layer.update();

      return;
    }

    onYmapsDebug(() => console.time('getInfrastructurePointsTilesToUpdate'));
    const tilesToUpdate = getInfrastructurePointsTilesToUpdate({ rbushItems, bounds, zoom });
    onYmapsDebug(() => console.timeEnd('getInfrastructurePointsTilesToUpdate'));

    onYmapsDebug(() => console.time('infrastructurePointsLayer update'));
    if (tilesToUpdate.length > 0) {
      infrastructurePointsLayer.layer.update(tilesToUpdate);
    }
    onYmapsDebug(() => console.timeEnd('infrastructurePointsLayer update'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrastructureFeatures, zoom, bounds]);

  return null;
};
