import { EDrawingActionType } from '../../actions/drawing';
import { ERulerActionType } from '../../actions/ruler';
import { TReduxActions } from '../../types/redux';
import { IRulerState } from '../../types/ruler';

const defaultState: IRulerState = {
  isEnabled: false,
};

export function rulerReducer(state: IRulerState = defaultState, action: TReduxActions): IRulerState {
  switch (action.type) {
    case ERulerActionType.RulerEnabled:
      return {
        ...state,
        isEnabled: true,
      };
    case ERulerActionType.RulerDisabled:
    case EDrawingActionType.DrawingStarted:
      return {
        ...state,
        isEnabled: false,
      };
    default:
      return state;
  }
}
