import { IApplicationState } from '../../types/redux/store';
import { TTrackingUser, TUserState } from '../../types/user';

export const selectUser = (state: IApplicationState): TUserState => {
  return state.user;
};

export const selectUserId = ({ user }: IApplicationState): number | null =>
  (user.isAuthenticated && user.userId) || null;

export const selectIsUserAgent = (state: IApplicationState) =>
  (state.user.isAuthenticated && state.user.isAgent) || false;

export const getTrackingUser = (state: IApplicationState): TTrackingUser => {
  const { user } = state;

  if (user.isAuthenticated) {
    return {
      userId: user.userId,
    };
  }

  return {};
};
