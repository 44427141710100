import { selectIsNewbuildingClusterActive } from './selectIsNewbuildingClusterActive';
import { IApplicationState } from '../../types/redux';
import { selectActiveNewbuildingPolygon } from '../newbuildingPolygons';

export const selectIsNewbuildingActive = (state: IApplicationState) => {
  const isNewbuildingClusterActive = selectIsNewbuildingClusterActive(state);
  const newbuildingActivePolygon = selectActiveNewbuildingPolygon(state);

  return Boolean(newbuildingActivePolygon.newbuildingId) || isNewbuildingClusterActive;
};
