import * as React from 'react';

import * as styles from './SubwayInfo.css';

interface ISubwayInfoProps {
  subwayIcon: React.ReactNode;
  title: React.ReactNode;
  typeIcon: React.ReactNode;
  subwayColor: string;
  time: React.ReactNode;
}

export function SubwayInfo({ subwayIcon, subwayColor, title, time, typeIcon }: ISubwayInfoProps) {
  return (
    <div className={styles['info']}>
      <div className={styles['info__group']}>
        <span style={{ color: subwayColor }}>{subwayIcon}</span>
        {title}
      </div>
      <div className={styles['info__group']}>
        <span className={styles['type']}>{typeIcon}</span>
        {time}
      </div>
    </div>
  );
}
