import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { MotivationBanner } from '@cian/mobile-banner-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { MOBILE_BANNER_TITLE } from './constants';
import { trackClickOnBanner, trackShowBanner, trackOnCloseBanner } from './tracking';
import { selectIsMapBlockingExperimentGroupB } from '../../selectors/experiments/selectIsAudMobileBannerToAppExperimentActive';
import { selectPlatform } from '../../selectors/platform';
import { useApplicationContext } from '../ApplicationContext';

export function MotivationBannerContainer() {
  const { config } = useApplicationContext();

  const defaultBrowserUrl = config.getStrict<string>('mobileBanner.browserUrl');
  const isMotivationBannerEnabled = config.getStrict<boolean>('mapSearchFrontend.mobileMotivationBanner') || false;

  const [url, setUrl] = React.useState<string | null>(defaultBrowserUrl);

  const isMapBlockingExperimentGroupB = useSelector(selectIsMapBlockingExperimentGroupB);
  const platform = useSelector(selectPlatform);

  React.useEffect(() => {
    setUrl(generateOneLinkUrl(url, 'mobile_site', MOBILE_BANNER_TITLE));
  }, []);

  const handleBannerClick = React.useCallback(() => {
    trackClickOnBanner(platform);
  }, [platform]);

  const handleCloseBanner = React.useCallback(() => {
    trackOnCloseBanner(platform);
  }, [platform]);

  const handleShowBanner = React.useCallback(() => {
    trackShowBanner(platform);
  }, [platform]);

  if (!(isMotivationBannerEnabled && isMapBlockingExperimentGroupB)) {
    return null;
  }

  return (
    <MotivationBanner
      url={url || defaultBrowserUrl}
      caShowBanner={handleShowBanner}
      caClickOnBanner={handleBannerClick}
      caCloseBanner={handleCloseBanner}
      isExperiment={isMapBlockingExperimentGroupB}
    />
  );
}
