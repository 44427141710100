import {
  INewbuildingPolygon,
  INewbuildingsPolygons,
  TNewbuildingPolygonFeature,
} from '../../../types/map/newbuildingPolygon';

export function defineNewbuildingsPolygonsManager(ymaps: YMaps.IYMaps) {
  if (ymaps.modules.isDefined('defineNewbuildingsPolygonsManager')) {
    return;
  }

  ymaps.modules.define('NewbuildingsPolygonsManager', [], provide => {
    class NewbuildingsPolygonsManager {
      public readonly objectManager: YMaps.ObjectManager;

      public constructor() {
        this.objectManager = new ymaps.ObjectManager();
      }

      public createPolygons = (polygons: INewbuildingsPolygons) => {
        const features = this.prepareFeatures(polygons);

        this.objectManager.add({
          type: 'FeatureCollection',
          features,
        });
      };

      public clear = () => {
        this.objectManager.removeAll();
      };

      private prepareFeatures = (polygons: INewbuildingsPolygons) => {
        const polygonsIds = Object.keys(polygons);

        return polygonsIds.map(id => this.transformPolygonToFeature(polygons[id]));
      };

      private transformPolygonToFeature(polygon: INewbuildingPolygon): TNewbuildingPolygonFeature {
        const { coordinates, newbuildingId } = polygon;

        return {
          type: 'Feature',
          id: String(newbuildingId),
          properties: {
            newbuildingId,
          },
          options: {
            fillColor: 'rgba(4, 104, 255, 0.1)',
            interactivityModel: 'default#transparent',
            strokeWidth: 0,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates] as GeoJSON.Position[][],
          },
        };
      }
    }

    provide(NewbuildingsPolygonsManager);
  });
}
