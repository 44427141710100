import { ca } from '@cian/analytics';

export function trackRulerClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'use_ruler',
    category: 'Map',
    label: 'success',
  });
}
