import { toggleFeaturesActiveStatus } from './toggleFeaturesActiveStatus';
import { TThunkAction } from '../../types/redux';
import { updateFeatureProperties } from '../features';

export function selectNewbuildingFeatures(): TThunkAction {
  return (dispatch, getState) => {
    const {
      mapBounds: { precision },
      activeFeature,
      activeNewbuildingPolygon: { geohashIds, prevActiveGeoHashIds },
    } = getState();

    if (activeFeature?.id) {
      dispatch(
        updateFeatureProperties({
          featureId: activeFeature.id,
          precision,
          properties: { isActive: false },
        }),
      );
    }

    dispatch(toggleFeaturesActiveStatus({ geohashIds: prevActiveGeoHashIds, isActive: false }));
    dispatch(toggleFeaturesActiveStatus({ geohashIds, isActive: true }));
  };
}
