import { setTerms } from './helpers';
import { NonEmptyArray, TJsonQuery } from './types';
import { nonEmptyArrayOr } from './utils';

export interface ISetPromoSearchTypesParams {
  allValues: NonEmptyArray<string> | null;
  newValues: NonEmptyArray<string> | null;
  availableFilterValues: string[];
}

export function setPromoSearchTypes(jsonQuery: TJsonQuery) {
  return ({ allValues, newValues, availableFilterValues }: ISetPromoSearchTypesParams): TJsonQuery => {
    if (!allValues) {
      return setTerms('promo_search_types')(jsonQuery)(newValues);
    }

    const cleanedValues = allValues.filter(
      value => !availableFilterValues.includes(value) || newValues?.includes(value),
    );

    const uniqueArray = Array.from(new Set([...cleanedValues, ...(newValues ?? [])]));

    const result = nonEmptyArrayOr(uniqueArray, null);

    return setTerms('promo_search_types')(jsonQuery)(result);
  };
}
