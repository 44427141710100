/**
 * Файл генерируется автоматически командой `npm run generate:advanced-filter`.
 * Не стоит его изменять руками, если вы не уверены в том, что делаете :)
 */
import { apartments } from './apartments';
import { balcony } from './balcony';
import { bathType } from './bathType';
import { bedrooms } from './bedrooms';
import { ceilingHeight } from './ceilingHeight';
import { completionYear } from './completionYear';
import { constructionType } from './constructionType';
import { constructionYear } from './constructionYear';
import { containWords } from './containWords';
import { dealType } from './dealType';
import { demolition } from './demolition';
import { discountPromoSearchType } from './discountPromoSearchType';
import { elevators } from './elevators';
import { excludeWords } from './excludeWords';
import { facilities } from './facilities';
import { finishing } from './finishing';
import { flatRooms } from './flatRooms';
import { flatShare } from './flatShare';
import { floor } from './floor';
import { floors } from './floors';
import { fromMKAD } from './fromMKAD';
import { garageStatus } from './garageStatus';
import { garageType } from './garageType';
import { garageUtilities } from './garageUtilities';
import { heatingType } from './heatingType';
import { kitchenStove } from './kitchenStove';
import { landStatus } from './landStatus';
import { layout } from './layout';
import { livingConditions } from './livingConditions';
import { mortgagePromoSearchType } from './mortgagePromoSearchType';
import { offerType } from './offerType';
import { onlineBooking } from './onlineBooking';
import { parking } from './parking';
import { publicationDate } from './publicationDate';
import { rentTerms } from './rentTerms';
import { rentalPeriod } from './rentalPeriod';
import { repair } from './repair';
import { roomsInDeal } from './roomsInDeal';
import { saleType } from './saleType';
import { squareMeterArea } from './squareMeterArea';
import { subsidisedMortgage } from './subsidisedMortgage';
import { suburbanArea } from './suburbanArea';
import { suburbanBathroom } from './suburbanBathroom';
import { suburbanFacilities } from './suburbanFacilities';
import { suburbanUtilities } from './suburbanUtilities';
import { undergroundTime } from './undergroundTime';
import { urbanBathroom } from './urbanBathroom';
import { windows } from './windows';
import { IFilter, TFilter } from '../../types/advancedFilters';
/* marker: prepend import */

export const ADVANCED_FILTERS: { [key in TFilter]: IFilter } = {
  apartments,
  flatShare,
  balcony,
  bathType,
  ceilingHeight,
  completionYear,
  constructionType,
  constructionYear,
  containWords,
  dealType,
  demolition,
  discountPromoSearchType,
  elevators,
  excludeWords,
  facilities,
  finishing,
  flatRooms,
  floor,
  floors,
  fromMKAD,
  garageStatus,
  garageType,
  garageUtilities,
  heatingType,
  kitchenStove,
  landStatus,
  layout,
  livingConditions,
  mortgagePromoSearchType,
  offerType,
  onlineBooking,
  parking,
  publicationDate,
  rentTerms,
  rentalPeriod,
  repair,
  roomsInDeal,
  saleType,
  squareMeterArea,
  suburbanArea,
  suburbanBathroom,
  suburbanFacilities,
  suburbanUtilities,
  subsidisedMortgage,
  undergroundTime,
  urbanBathroom,
  windows,
  bedrooms,
  /* marker: prepend key */
};
