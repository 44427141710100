import { useDeviceType } from '@cian/ui-kit/responsive';
import cx from 'clsx';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TModifier, modifyJsonQuery } from 'shared/common/packages/Filters';
import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { changeJsonQuery, IJsonQueryChanged, removeTag, fetchTags } from 'shared/map-search/actions/filters';
import { fetchClusters } from 'shared/map-search/actions/results';
import { Tag } from 'shared/map-search/components/Tag';
import { TagsCollapsible } from 'shared/map-search/components/TagsCollapsible';
import { PIK_PROMO } from 'shared/map-search/constants/pik';
import { ITagsState } from 'shared/map-search/types/filters';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';
import { TTag } from 'shared/map-search/types/tags';

import * as styles from './Tags.css';

export const Tags: React.FC = () => {
  const deviceType = useDeviceType();
  const dispatch = useDispatch<TThunkDispatch>();
  const jsonQuery = useSelector<IApplicationState, IJsonQuery>(state => state.filters.jsonQuery);
  const tags = useSelector<IApplicationState, ITagsState>(state => state.filters.tags);
  const isSearchResultsOpen = useSelector<IApplicationState, boolean>(state => state.searchResults.isOpen);

  const handleRemove = React.useCallback(
    (tag: TTag) => {
      switch (tag.type) {
        case 'address':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, { action: 'removeGeo', arguments: [{ type: tag.geoType, id: tag.id }] }),
            ),
          );
          break;
        case 'developer': {
          const modifiers: [TModifier, ...TModifier[]] = [
            { action: 'removeGeo', arguments: [{ type: 'builder', id: tag.id }] },
          ];

          if (tag.id === PIK_PROMO.developerId) {
            modifiers.push({ action: 'setPikPromo', arguments: [null] });
          }

          dispatch(changeJsonQuery(applyModifiers(jsonQuery, ...modifiers)));
          break;
        }
        case 'distance':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'distance', center: tag.center, radius: tag.radius, name: tag.name }],
              }),
            ),
          );
          break;
        case 'district':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'district', id: tag.id }],
              }),
            ),
          );
          break;
        case 'regional_district_id':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'regional_district_id', id: tag.id }],
              }),
            ),
          );
          break;
        case 'polygon':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'polygon', coordinates: tag.coordinates, name: tag.name }],
              }),
            ),
          );
          break;
        case 'railway':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'railway', id: tag.id }],
              }),
            ),
          );
          break;
        case 'region': {
          const currentRegions = getTermsValue('region')(jsonQuery);

          let nextRegions = currentRegions && currentRegions.filter(region => region !== tag.id);
          if (nextRegions && nextRegions.length === 0) {
            nextRegions = null;
          }

          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'setRegion',
                arguments: [nextRegions as NonEmptyArray<number>],
              }),
            ),
          );
          break;
        }

        case 'residentialComplex':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'newobject', id: tag.id }],
              }),
            ),
          );
          break;
        case 'underground':
          dispatch(
            changeJsonQuery(
              applyModifiers(jsonQuery, {
                action: 'removeGeo',
                arguments: [{ type: 'underground', id: tag.id }],
              }),
            ),
          );
          break;
      }

      dispatch(removeTag(tag));
      dispatch(fetchTags());
      dispatch(fetchClusters({ deviceType }));
    },
    [dispatch, jsonQuery],
  );

  if (deviceType !== 'desktop' || !tags.items) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={cx(styles['content'], { [styles['content--ectopic']]: isSearchResultsOpen })}>
          <TagsCollapsible>
            {tags.items.map(tag => (
              <Tag key={`tag_${tag.type}_${tag.name}`} onRemove={() => handleRemove(tag)}>
                {tag.name}
              </Tag>
            ))}
          </TagsCollapsible>
        </div>
      </div>
    </div>
  );
};

function applyModifiers(jsonQuery: IJsonQuery, ...modifiers: [TModifier, ...TModifier[]]): IJsonQueryChanged {
  return {
    appliedModifiers: modifiers,
    prevJsonQuery: jsonQuery,
    nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
  };
}
