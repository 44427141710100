import { ESuggestType } from '@cian/geosuggest-widget';

import { NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { TGeoValue } from 'shared/common/packages/api-models/common/json_query';

import { ISuggestGeoItem } from './types';

const GEO_OBJECT_TYPES_MAP = {
  [ESuggestType.city]: 'location',
  [ESuggestType.district]: 'district',
  [ESuggestType.newbuilding]: 'newobject',
  [ESuggestType.railway]: 'railway',
  [ESuggestType.road]: 'highway',
  [ESuggestType.underground]: 'underground',
  [ESuggestType.village]: 'village',
  [ESuggestType.regionalDistrict]: 'regional_district_id',
};

export function normalizeGeoObject(geoObject: ISuggestGeoItem): TGeoValue {
  return {
    type: GEO_OBJECT_TYPES_MAP[geoObject.group],
    id: geoObject.id,
  } as TGeoValue;
}

export function getExtendedRegion(regionId: number): NonEmptyArray<number> {
  switch (regionId) {
    case 1:
    case 4593:
      return [1, 4593];

    case 2:
    case 4588:
      return [2, 4588];
  }

  return [regionId];
}
