import { FDealType, FOfferType } from 'shared/common/packages/JsonQuery';

import {
  OFFER_TYPES_RENT_RESIDENTIAL,
  OFFER_TYPES_RENT_COMMERCIAL,
  OFFER_TYPES_SALE_COMMERCIAL,
  OFFER_TYPES_DAILY_RESIDENTIAL,
} from './constants/offerTypes';
import { IOfferType } from './types';
import { getSaleResidentialOfferTypes } from '../../../utils/getSaleResidentialOfferTypes';

export function normalizeOfferType(offerType: FOfferType): FOfferType {
  return (offerType & FOfferType.Residential) !== 0 ? FOfferType.Residential : FOfferType.Commercial;
}

const getOfferTypes = (
  isSearchFlatShareEnabled: boolean,
): { dealType: FDealType; offerType: FOfferType; list: IOfferType[][] }[] => [
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Residential,
    list: getSaleResidentialOfferTypes(isSearchFlatShareEnabled),
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_SALE_COMMERCIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_RENT_RESIDENTIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_RENT_COMMERCIAL,
  },
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_DAILY_RESIDENTIAL,
  },
];

export function getOfferTypeList({
  dealType,
  offerType,
  isSearchFlatShareEnabled,
}: {
  dealType: FDealType;
  offerType: FOfferType;
  isSearchFlatShareEnabled: boolean;
}): IOfferType[][] {
  const type = getOfferTypes(isSearchFlatShareEnabled).find(
    t => t.dealType === dealType && t.offerType === normalizeOfferType(offerType),
  );

  return (type && type.list) || [];
}
