import { EAsyncStatus } from '../../types/asyncStatus';
import { IInfrastructureFeaturesMap, IInfrastructureType } from '../../types/infrastructure';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EInfrastructureActionType {
  SetActiveType = 'infrastructure/setActiveType',
  SetFeatures = 'infrastructure/setFeatures',
  SetFeaturesStatus = 'infrastructure/setFeaturesStatus',
  SetTypes = 'infrastructure/setTypes',
  SetTypesByBbox = 'infrastructure/setTypesByBbox',
  SetVisibleFeaturesCount = 'infrastructure/setVisibleFeaturesCount',
  SetIsDropdownOpen = 'infrastructure/setIsDropdownOpen',
  ResetActiveType = 'infrastructure/resetActiveType',
}

export interface ISetFeaturesPayload {
  id: number;
  features: IInfrastructureFeaturesMap;
  tiles: YMaps.TTileNumber[];
}

export interface ISetFeaturesStatusPayload {
  id: number;
  status: EAsyncStatus;
}

export interface ISetFeaturesCountPayload {
  id: number;
  count: number;
}

export interface ISetTypesPayload {
  types: IInfrastructureType[];
}

export interface ISetTypesByBboxPayload {
  types: IInfrastructureType[];
  tiles: YMaps.TTileNumber[];
}

export type TSetActiveTypeAction = ITypedReduxAction<EInfrastructureActionType.SetActiveType, number | undefined>;

export type TResetTypeAction = ITypedReduxAction<EInfrastructureActionType.ResetActiveType>;

export type TSetFeaturesAction = ITypedReduxAction<EInfrastructureActionType.SetFeatures, ISetFeaturesPayload>;

export type TSetTypesAction = ITypedReduxAction<EInfrastructureActionType.SetTypes, ISetTypesPayload>;

export type TSetTypesByBboxAction = ITypedReduxAction<EInfrastructureActionType.SetTypesByBbox, ISetTypesByBboxPayload>;

export type TSetVisibleItemsCountAction = ITypedReduxAction<
  EInfrastructureActionType.SetVisibleFeaturesCount,
  ISetFeaturesCountPayload
>;

export type TSetFeaturesStatus = ITypedReduxAction<
  EInfrastructureActionType.SetFeaturesStatus,
  ISetFeaturesStatusPayload
>;

export type TSetIsDropdownOpen = ITypedReduxAction<EInfrastructureActionType.SetIsDropdownOpen, boolean>;

export type TInfrastructureActions =
  | TSetActiveTypeAction
  | TSetFeaturesAction
  | TSetTypesAction
  | TSetTypesByBboxAction
  | TResetTypeAction
  | TSetVisibleItemsCountAction
  | TSetFeaturesStatus
  | TSetIsDropdownOpen;
