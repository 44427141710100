import { ca } from '@cian/analytics';

interface ITrackGoToApplicationFormParams {
  sc: object;
  products: unknown;
  page: unknown;
}

export function trackGoToApplicationForm({ page, sc, products }: ITrackGoToApplicationFormParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click',
    label: 'GoToApplicationForm/pin',
    page,
    sc,
    products,
  });
}
