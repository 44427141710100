import { IConfig } from '@cian/config/shared';
import { IModel } from '@cian/http-api/shared/model';
import { IModelResponse } from '@cian/http-api/shared/model/IModel';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withJobName<TRequest, TResponse extends IModelResponse<any>>(
  config: IConfig,
  model: IModel<TRequest, TResponse>,
) {
  const jobName = config.get<string>(`${model.microserviceName}.jobName`);
  if (!jobName) {
    return model;
  }

  return {
    ...model,
    microserviceName: `${model.microserviceName}-${jobName}`,
  };
}
