import * as React from 'react';

import { ECompletionYearType, JsonQuery, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { CompletionYear } from '../../../components/advancedFilters/CompletionYear';
import { AVAILABLE_COMPLETION_YEAR_TYPES } from '../../../constants/availableCompletionYearTypes';
import { useContext } from '../../../utils/useContext';

const CURRENT_YEAR = new Date().getFullYear();

export const CompletionYearContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const completionYearTypes = new JsonQuery(jsonQuery).getCompletionYearTypes({
    yearOffset: CURRENT_YEAR,
    availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
  });

  const handleChange = React.useCallback(
    (completionYearTypes: NonEmptyArray<ECompletionYearType> | null) => {
      onChange({
        action: 'setCompletionYearTypes',
        arguments: [
          {
            completionYearTypes,
            yearOffset: CURRENT_YEAR,
            availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
          },
        ],
      });
    },
    [onChange],
  );

  return <CompletionYear yearOffset={CURRENT_YEAR} value={completionYearTypes} onChange={handleChange} />;
};
