import cx from 'clsx';

import { PIK_PROMO } from 'shared/map-search/constants/pik';
import { TFeaturePropertiesRequiredPrice } from 'shared/map-search/types/map';

import * as styles from './specialPromo.css';

export const getSpecialPromoMarkup = ({ specialPromo, isActive }: TFeaturePropertiesRequiredPrice): string => {
  return `<div class="${cx(styles['container'], {
    [styles['container--active']]: isActive,
    [styles['container--pik']]: specialPromo && specialPromo.builderId === PIK_PROMO.developerId,
  })}"${
    specialPromo && specialPromo.builderId !== PIK_PROMO.developerId
      ? ` style="background-image: url(${specialPromo.pinUrl}); background-color: ${specialPromo.lightColor}"`
      : ''
  }>{% if properties.favoriteIds && properties.favoriteIds.length > 0 %}<span class="${
    styles['like']
  }" />{% endif %}</div>`;
};
