import classNames from 'clsx';

import { IInfrastructureFeatureProperties, EInfrastructureIconType } from 'shared/map-search/types/infrastructure';

import { getClinicIcon, getGroceryIcon, getNurseryIcon, getSchoolIcon } from './iconsMarkup';
import * as styles from './styles.css';

const ICONS: { [key: string]: string } = {
  [EInfrastructureIconType.Nursery]: getNurseryIcon(),
  [EInfrastructureIconType.School]: getSchoolIcon(),
  [EInfrastructureIconType.Clinic]: getClinicIcon(),
  [EInfrastructureIconType.Grocery]: getGroceryIcon(),
  [EInfrastructureIconType.Empty]: '',
};

export function getPinMarkup(props: IInfrastructureFeatureProperties, isLarge: boolean): string {
  return `
  <div
    class="${isLarge ? styles['large-pin'] : styles['small-pin']}"
    style="background-color: ${props.pinColor}; color: ${props.iconColor}">
      <span class="${classNames(styles['icon'])}">
        ${ICONS[props.icon]}
      </span>
  </div>
  `;
}
