import { FSK_REGIONS } from '../../constants/fsk';

export function isFskRegions(regions: number[] | null, subdomain: string) {
  if (!regions) {
    return subdomain === 'www';
  }

  const regionsSet = new Set(regions);

  return FSK_REGIONS.some(id => regionsSet.has(id));
}
