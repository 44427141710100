import classNames from 'clsx';
import * as React from 'react';

import * as styles from './Sidebar.css';

interface ISidebarProps {
  children: React.ReactNode;
  opened: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = ({ children, opened }) => {
  return <div className={classNames(styles['container'], opened && styles['opened'])}>{children}</div>;
};

Sidebar.displayName = 'Sidebar';
