import { getIsAgent } from './getIsAgent';
import { IFastFiltersUserSchema } from '../../../common/repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { TUser } from '../../types/user';

export function prepareUser(userResponse?: IFastFiltersUserSchema | null): TUser {
  if (!userResponse || !userResponse.isAuthenticated) {
    return {
      isAuthenticated: false,
    };
  }

  return {
    isAuthenticated: true,
    isAgent: getIsAgent(userResponse),
    userId: userResponse.userId as number,
    email: userResponse.email || null,
    userType: userResponse.userType || null,
  };
}
