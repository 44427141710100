import { toGlobalPixels } from '../../../projection';
import { POPUP_WIDTH, POPUP_HEIGHT, POPUP_HEIGHT_HALF, POPUP_WIDTH_HALF } from '../constants';

export enum EPopupPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
}

const SORT_ORDER: EPopupPlacement[] = [
  EPopupPlacement.Left,
  EPopupPlacement.Right,
  EPopupPlacement.Top,
  EPopupPlacement.Bottom,
  EPopupPlacement.TopLeft,
  EPopupPlacement.TopRight,
  EPopupPlacement.BottomLeft,
  EPopupPlacement.BottomRight,
];

export function getContainerPopupPlacement(
  coordinate: [number, number],
  mapBounds: [YMaps.TCoord, YMaps.TCoord],
  zoom: number,
): EPopupPlacement {
  const [pixelCoordX, pixelCoordY] = toGlobalPixels(coordinate, zoom);
  const [mapPixelCoordXMin, mapPixelCoordYMax] = toGlobalPixels(mapBounds[0], zoom);
  const [mapPixelCoordXMax, mapPixelCoordYMin] = toGlobalPixels(mapBounds[1], zoom);

  const containerWidth = mapPixelCoordXMax - mapPixelCoordXMin;
  const containerHeight = mapPixelCoordYMax - mapPixelCoordYMin;
  const [containerCoordX, containerCoordY] = [pixelCoordX - mapPixelCoordXMin, pixelCoordY - mapPixelCoordYMin];

  const containerBottomRemainingSpace = containerHeight - containerCoordY;

  const availableSpace: { [key in EPopupPlacement]: number } = {
    [EPopupPlacement.Left]:
      Math.min(
        POPUP_HEIGHT,
        Math.min(POPUP_HEIGHT_HALF, containerCoordY) + Math.min(POPUP_HEIGHT_HALF, containerBottomRemainingSpace),
      ) * Math.min(POPUP_WIDTH, containerCoordX),
    [EPopupPlacement.Right]:
      Math.min(
        POPUP_HEIGHT,
        Math.min(POPUP_HEIGHT_HALF, containerCoordY) + Math.min(POPUP_HEIGHT_HALF, containerBottomRemainingSpace),
      ) * Math.min(POPUP_WIDTH, containerWidth - containerCoordX),
    [EPopupPlacement.Top]:
      Math.min(POPUP_HEIGHT, containerCoordY) *
      Math.min(
        POPUP_WIDTH,
        Math.min(POPUP_WIDTH_HALF, containerCoordX) + Math.min(POPUP_WIDTH_HALF, containerWidth - containerCoordX),
      ),
    [EPopupPlacement.Bottom]:
      Math.min(POPUP_HEIGHT, containerHeight - containerCoordY) *
      Math.min(
        POPUP_WIDTH,
        Math.min(POPUP_WIDTH_HALF, containerCoordX) + Math.min(POPUP_WIDTH_HALF, containerWidth - containerCoordX),
      ),
    [EPopupPlacement.TopRight]:
      Math.min(POPUP_HEIGHT, containerCoordY) * Math.min(POPUP_WIDTH, containerWidth - containerCoordX),
    [EPopupPlacement.TopLeft]: Math.min(POPUP_HEIGHT, containerCoordY) * Math.min(POPUP_WIDTH, containerCoordX),
    [EPopupPlacement.BottomRight]:
      Math.min(POPUP_HEIGHT, containerHeight - containerCoordY) *
      Math.min(POPUP_WIDTH, containerWidth - containerCoordX),
    [EPopupPlacement.BottomLeft]:
      Math.min(POPUP_HEIGHT, containerHeight - containerCoordY) * Math.min(POPUP_WIDTH, containerCoordX),
  };

  return SORT_ORDER.reduce((previousValue, currentValue) =>
    availableSpace[previousValue] >= availableSpace[currentValue] ? previousValue : currentValue,
  );
}
