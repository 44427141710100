import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';

import { AddressInfo } from './AddressInfo';
import { trackClickBanner, trackCloseBanner, trackShowBanner } from './tracking';
import { changeBannerStatus } from '../../actions/promoInventory';
import { DesktopBanner, MobileBanner } from '../../components/ProPlusBanner';
import { STATUS_CHANGING_DEBOUNCE_TIME } from '../../constants/banners';
import { selectQueryString } from '../../selectors/queryString';
import { selectIsOpen } from '../../selectors/searchResults';
import { EBannerStatus, INewbuildingForPromoInventory } from '../../types/promoInventory';
import { usePrevious } from '../../utils/usePrevious';

interface IPropPlusBannerProps {
  currentNewbuilding: INewbuildingForPromoInventory;
  onClose(): void;
}

export function PropPlusBanner({ currentNewbuilding, onClose }: IPropPlusBannerProps) {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const queryString = useSelector(selectQueryString);
  const isSearchResultsOpen = useSelector(selectIsOpen);
  const prevCurrentNewbuilding = usePrevious(currentNewbuilding);
  const Component = React.useMemo(() => (deviceType === 'desktop' ? DesktopBanner : MobileBanner), [deviceType]);

  const handleBannerClose = React.useCallback(() => {
    onClose();
    trackCloseBanner({ queryString, deviceType, id: currentNewbuilding.id });
  }, [currentNewbuilding, deviceType, onClose, queryString]);

  const handleBannerClick = React.useCallback(() => {
    trackClickBanner({ queryString, deviceType, id: currentNewbuilding.id });
  }, [currentNewbuilding, deviceType, queryString]);

  const handleMouseOver = React.useCallback(
    debounce(STATUS_CHANGING_DEBOUNCE_TIME, () => {
      dispatch(changeBannerStatus(EBannerStatus.Paused));
    }),
    [dispatch],
  );

  const handleMouseOut = React.useCallback(
    debounce(STATUS_CHANGING_DEBOUNCE_TIME, () => {
      dispatch(changeBannerStatus(EBannerStatus.Active));
    }),
    [dispatch],
  );

  React.useEffect(() => {
    if (prevCurrentNewbuilding?.id !== currentNewbuilding.id) {
      trackShowBanner({ queryString, deviceType, id: currentNewbuilding.id });
    }
  }, [prevCurrentNewbuilding, currentNewbuilding, deviceType, queryString]);

  return (
    <Component
      isSearchResultsOpen={isSearchResultsOpen}
      title={currentNewbuilding.displayName}
      href={currentNewbuilding.url}
      description={currentNewbuilding.statusDisplay}
      newbuildingImage={currentNewbuilding.imageUrl}
      developerLogo={currentNewbuilding.developerLogoUrl}
      addressInfo={<AddressInfo subwayInfo={currentNewbuilding.undergroundInfo} address={currentNewbuilding.address} />}
      onClose={handleBannerClose}
      onClick={handleBannerClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
}
