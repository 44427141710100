import { JsonQuery } from '../../../JsonQuery';
import { IJsonQuery } from '../../../api-models/common/json_query';
import { TModifier } from '../types/modifier';

export function modifyJsonQuery(jsonQuery: IJsonQuery, modifiers: [TModifier, ...TModifier[]]): IJsonQuery {
  const jq = new JsonQuery(jsonQuery);

  modifiers.forEach(modifier => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (jq[modifier.action] as any)(...modifier.arguments);
  });

  return jq.toJSON();
}
