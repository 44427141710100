import { GeoSuggest, EInputType, IMultiSuggestResult, IItemClickParams, ETruncation } from '@cian/geosuggest-widget';
import * as React from 'react';

import * as styles from './Geo.css';

interface IGeoProps {
  value: string | null;
  suggestions: IMultiSuggestResult | null;
  onValueChange(value: string | null): void;
  onItemSelect(params: IItemClickParams): void;
  onFocus?(): void;
  onEmptyResults?(value: string): void;
}

export const Geo: React.FC<IGeoProps> = ({
  value,
  suggestions,
  onValueChange,
  onItemSelect,
  onFocus,
  onEmptyResults,
}) => {
  const isEmptyResults = suggestions && Object.keys(suggestions.suggestions).length === 0;

  React.useEffect(() => {
    if (isEmptyResults && value && onEmptyResults) {
      onEmptyResults(value);
    }
  }, [isEmptyResults, onEmptyResults, value]);

  return (
    <GeoSuggest
      suggestData={suggestions || undefined}
      value={value || ''}
      inputType={EInputType.map}
      error={isEmptyResults ? { title: 'Ничего не найдено', text: 'Укажите другой адрес' } : undefined}
      onValueChange={onValueChange}
      onItemClick={onItemSelect}
      onFocus={onFocus}
      inputStyle={styles['input']}
      itemsTruncation={ETruncation.Two}
    />
  );
};
