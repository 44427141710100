import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IFeaturesMap } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { useDailyRentAvailabilityFeatureEnabled } from 'shared/map-search/utils/dailyRentAvailability';
import { geoJsonFeatureToRBushItem } from 'shared/map-search/utils/features';
import { getTilesToUpdate } from 'shared/map-search/utils/tiles';
import { onYmapsDebug } from 'shared/map-search/utils/ymapsModules/ymapsModulesDebug';

import { useFeaturesContext } from '../context';

interface IFeaturesUpdaterProps {
  detailsVisible?: boolean;
}

export const FeaturesUpdater: React.FC<IFeaturesUpdaterProps> = ({ detailsVisible: isDetailsVisible }) => {
  const { rbush, pointsLayer, hotspotsLayer, balloonManager } = useFeaturesContext();
  const { bounds, zoom, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>({}, [precision, 'features'], state.features),
  );
  const loadedFeatures = React.useRef<IFeaturesMap>({});
  const resultsVersion = useSelector<IApplicationState, number | undefined>(state => state.results.version);
  const isNewbuildingsListing = useSelector<IApplicationState, boolean>(state => state.isNewbuildingsListing);
  const isDailyRentAvailabilityExpEnabled = useDailyRentAvailabilityFeatureEnabled();

  pointsLayer.isNewbuildingsListing = isNewbuildingsListing;
  pointsLayer.showOnlyPrice = isDailyRentAvailabilityExpEnabled;

  React.useEffect(() => {
    rbush.clear();
    loadedFeatures.current = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsVersion, precision]);

  React.useEffect(() => {
    balloonManager.objectManager.removeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsVersion]);

  React.useEffect(() => {
    const rbushItems = isDailyRentAvailabilityExpEnabled
      ? Object.values(features).map(geoJsonFeatureToRBushItem)
      : Object.values(features)
          .filter(({ id }) => !loadedFeatures.current[id])
          .map(geoJsonFeatureToRBushItem);

    if (isDailyRentAvailabilityExpEnabled) {
      const rbushToRemove = Object.values(features)
        .filter(({ id }) => loadedFeatures.current[id])
        .map(geoJsonFeatureToRBushItem);

      rbushToRemove.forEach(item => rbush.remove(item, (a, b) => a.feature.id === b.feature.id));
    }

    if (rbushItems.length === 0) {
      if (Object.keys(loadedFeatures.current).length === 0) {
        pointsLayer.layer.update();
      }

      return;
    }

    rbush.load(rbushItems);

    const featuresWereLoaded = Object.keys(loadedFeatures.current).length > 0;
    loadedFeatures.current = features;

    hotspotsLayer.layer.update();

    if (isDetailsVisible || isDailyRentAvailabilityExpEnabled) {
      balloonManager.updateBalloons();
    }

    if (!featuresWereLoaded) {
      pointsLayer.layer.update();

      return;
    }

    onYmapsDebug(() => console.time('getTilesToUpdate'));
    const tilesToUpdate = getTilesToUpdate({ rbushItems, bounds, zoom, includeShadow: isDetailsVisible });
    onYmapsDebug(() => console.timeEnd('getTilesToUpdate'));

    onYmapsDebug(() => console.time('pointsLayer update'));
    if (tilesToUpdate.length > 0) {
      pointsLayer.layer.update(tilesToUpdate);
    }
    onYmapsDebug(() => console.timeEnd('pointsLayer update'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  React.useEffect(() => {
    if (isDetailsVisible || isDailyRentAvailabilityExpEnabled) {
      balloonManager.updateBalloons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  React.useEffect(() => {
    if (isDetailsVisible || isDailyRentAvailabilityExpEnabled) {
      balloonManager.updateBalloons();
    } else {
      balloonManager.objectManager.removeAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailsVisible]);

  return null;
};
