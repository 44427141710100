import { IconClose12 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './Tag.css';

export interface ITagProps {
  onRemove(): void;
}

export const Tag: React.FC<React.PropsWithChildren<ITagProps>> = ({ children, onRemove }) => {
  return (
    <div className={styles['container']}>
      {children}
      <button className={styles['button']} onClick={onRemove}>
        <IconClose12 color="current_color" />
      </button>
    </div>
  );
};
