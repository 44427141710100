import { makeContextModule } from '@cian/react-utils';

export interface INewbuildingPolygonsContext {
  newbuildingsPolygonsManager: YMaps.NewbuildingsPolygonsManager;
}

export const {
  Context: NewbuildingPolygonsContext,
  useContext: useNewbuildingPolygonsContext,
  withContext: withNewbuildingPolygonsContext,
} = makeContextModule<INewbuildingPolygonsContext>({
  contextName: 'NewbuildingPolygonsContext',
});
