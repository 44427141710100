import * as React from 'react';

import { useDeviceType } from '../../utils/responsive';

export const Phone: React.FC<React.PropsWithChildren> = ({ children }) => {
  const deviceType = useDeviceType();

  return deviceType === 'phone' ? (children as React.ReactElement) : null;
};

Phone.displayName = 'ResponsivePhone';
