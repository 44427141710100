import {
  markAsViewed as browserMarkAsViewed,
  isAvailable as isViewedClustersAvailable,
} from 'browser/map-search/utils/viewedClusters';

import {
  fetchMarkOffersAsViewed,
  TMarkOffersAsViewedResponse,
} from 'shared/common/repositories/viewed-offers/v1/mark-offers-as-viewed';

import { EMarkAsViewedActionType, IMarkAsViewedSucceedPayload } from './types';
import { TFeature } from '../../types/map';
import { TThunkAction } from '../../types/redux';
import { ResponseError } from '../../utils/errors/ResponseError';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EMarkAsViewedActionType.Loading>(EMarkAsViewedActionType.Loading);
const setStatusSucceed = actionGenerator<EMarkAsViewedActionType.Succeed, IMarkAsViewedSucceedPayload>(
  EMarkAsViewedActionType.Succeed,
);
const setStatusFailed = actionGenerator<EMarkAsViewedActionType.Failed>(EMarkAsViewedActionType.Failed);

interface IMarkAsViewedParameters {
  feature: TFeature;
  precision: number;
  isDetailed: boolean;
}

export function markAsViewed({ feature, precision, isDetailed }: IMarkAsViewedParameters): TThunkAction<Promise<void>> {
  return async (dispatch, _getState, context) => {
    const { httpApi } = context;
    const {
      id: featureId,
      properties: { clusterOfferIds },
    } = feature;

    dispatch(setStatusLoading());

    if (!isDetailed) {
      if (isViewedClustersAvailable()) {
        browserMarkAsViewed(featureId);
        dispatch(setStatusSucceed({ featureId, precision }));
      }

      return;
    }

    try {
      const markAsViewedResponse: TMarkOffersAsViewedResponse = await fetchMarkOffersAsViewed({
        httpApi,
        parameters: {
          fromPin: true,
          offersId: clusterOfferIds || [],
        },
      });

      // TODO Обновлял swagger, 204й статус по-идее убрали.
      //      Нужно убедиться что никогда не возвращает 204й код ответа и убрать проверку.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (markAsViewedResponse.statusCode !== 200 && (markAsViewedResponse as any).statusCode !== 204) {
        throw new ResponseError({
          domain: 'viewed#markAsViewed',
          message: markAsViewedResponse.response.message || '',
          details: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: markAsViewedResponse.response.errors as any,
          },
        });
      }

      dispatch(setStatusSucceed({ featureId, precision }));
    } catch (error) {
      const { logger } = context;

      logger.error(error);

      dispatch(setStatusFailed());
    }
  };
}
