import { FOfferType } from 'shared/common/packages/JsonQuery';
import { EParkingType } from 'shared/common/packages/api-models/common/json_query';

import { IParking } from './types';

const PARKING_TYPES: IParking[] = [
  { label: 'Неважно', value: null },
  { label: 'Наземная', value: EParkingType.Ground },
  { label: 'Многоуровневая', value: EParkingType.Multilevel },
  { label: 'Подземная', value: EParkingType.Underground },
  { label: 'На крыше', value: EParkingType.Rooftop },
];

export function getParkingTypes(offerType: FOfferType) {
  if (offerType === FOfferType.Garage) {
    return PARKING_TYPES.filter(({ value }) => value !== EParkingType.Rooftop);
  }

  return PARKING_TYPES;
}
