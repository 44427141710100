import {
  fetchGetActiveTypesByBbox,
  IGetActiveTypesByBboxRequest,
  TGetActiveTypesByBboxResponse,
} from 'shared/common/repositories/infrastructure-caching/v1/get-active-types-by-bbox';
import { prepareInfrastructureTypes } from 'shared/map-search/mappers/infrastructure';

import { assertFetchGetActiveTypesByBboxResponse } from './asserts';
import { IApplicationContext } from '../../types/applicationContext';
import { IInfrastructureType } from '../../types/infrastructure';

export async function fetchInfrastructureTypesByBbox(
  parameters: IGetActiveTypesByBboxRequest,
  context: IApplicationContext,
): Promise<IInfrastructureType[]> {
  try {
    const res: TGetActiveTypesByBboxResponse = await fetchGetActiveTypesByBbox({
      httpApi: context.httpApi,
      parameters,
    });

    assertFetchGetActiveTypesByBboxResponse(res);

    return prepareInfrastructureTypes(res);
  } catch (e) {
    context.logger.warning(e, {
      domain: 'fetchInfrastructureTypesByBbox',
      degradation: 'Типы дополнительных слоев',
    });

    return [];
  }
}
