import * as React from 'react';

/* istanbul ignore next */
export const ZoomIn: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="8" x2="8" y2="16" stroke="currentColor" strokeWidth="2" />
      <line x1="16" y1="8" y2="8" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

ZoomIn.displayName = 'ZoomIn';
