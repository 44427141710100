import { TModifier } from '../../../common/packages/Filters';
import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { TTag } from '../../types/tags';

export enum ETagsActionType {
  Loading = 'filters/tags/loading',
  Succeed = 'filters/tags/succeed',
  Failed = 'filters/tags/failed',
  Removed = 'filters/tags/removed',
}

export enum EFiltersActionType {
  JsonQueryChanged = 'filters/jsonQuery/changed',
}

export interface IJsonQueryChanged {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appliedModifiers: [TModifier, ...TModifier[]];
  prevJsonQuery: IJsonQuery;
  nextJsonQuery: IJsonQuery;
}

export type TJsonQueryChangedAction = ITypedReduxAction<EFiltersActionType.JsonQueryChanged, IJsonQueryChanged>;
export type TFetchTagsLoading = ITypedReduxAction<ETagsActionType.Loading>;
export type TFetchTagsSucceed = ITypedReduxAction<ETagsActionType.Succeed, TTag[] | null>;
export type TFetchTagsFailed = ITypedReduxAction<ETagsActionType.Failed>;
export type TTagRemoved = ITypedReduxAction<ETagsActionType.Removed, TTag>;

export type TFiltersActions =
  | TJsonQueryChangedAction
  | TFetchTagsLoading
  | TFetchTagsSucceed
  | TFetchTagsFailed
  | TTagRemoved;
