import * as React from 'react';

import { NonEmptyArray, EBalconyType, JsonQuery } from 'shared/common/packages/JsonQuery';

import { Balcony } from '../../../components/advancedFilters/Balcony';
import { useContext } from '../../../utils/useContext';

export const BalconyContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const balconyTypes = new JsonQuery(jsonQuery).getBalconyTypes();

  const handleChange = React.useCallback(
    (balconyTypes: NonEmptyArray<EBalconyType>) => {
      onChange({ action: 'setBalconyTypes', arguments: [balconyTypes] });
    },
    [onChange],
  );

  return <Balcony value={balconyTypes} onChange={handleChange} />;
};
