import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectJsonQuery } from 'shared/map-search/selectors/results';
import { IFeaturesMap } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState } from 'shared/map-search/types/redux';
import { EResultsStatus } from 'shared/map-search/types/results';
import { trackPromoPinsLoad } from 'shared/map-search/utils/tracking';

import { useFeaturesContext } from '../context';

interface INewbuildingPromoPinsManagerProps {
  detailsVisible: boolean;
}

export const NewbuildingPromoPinsManager: React.FC<INewbuildingPromoPinsManagerProps> = ({
  detailsVisible: isDetailsVisible,
}: INewbuildingPromoPinsManagerProps) => {
  const { rbush } = useFeaturesContext();
  const { bounds, precision, zoom } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>({}, [precision, 'features'], state.features),
  );
  const resultsVersion = useSelector<IApplicationState, number | undefined>(state => state.results.version);
  const resultsStatus = useSelector<IApplicationState, EResultsStatus>(state => state.results.status);
  const sendNewbuildingPromoShow = React.useRef<boolean>(isDetailsVisible);
  const jsonQuery = useSelector(selectJsonQuery);

  React.useEffect(() => {
    if (!bounds || !sendNewbuildingPromoShow.current || resultsStatus !== EResultsStatus.Succeed) {
      return;
    }

    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    const promoItems = featuresInBbox.filter(el => el.feature.properties.newbuilding?.isAnyFicheringPlus);

    trackPromoPinsLoad({
      pins: promoItems,
      zoom,
      jsonQuery,
    });

    sendNewbuildingPromoShow.current = false;
  }, [bounds, features, resultsStatus, jsonQuery]);

  React.useEffect(() => {
    sendNewbuildingPromoShow.current = isDetailsVisible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsVersion, isDetailsVisible, bounds]);

  return null;
};
