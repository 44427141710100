import { ca } from '@cian/analytics';

export const trackAddNewbuildingToFavorite = (newbuildingId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'add_from_listing_in_map_JK',
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        position: 1,
      },
    ],
  });
};

export const trackDeleteNewbuildingFromFavorite = (newbuildingId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'remove_from_listing_in_map_JK',
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        position: 1,
      },
    ],
  });
};
