import { IconChevronDown16, IconChevronUp16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './InfrastructureButton.css';
import { ControlButton } from '../../../ControlButton';

interface IInfrastructureButtonProps {
  expanded: boolean;
  title?: string;
  onClick(): void;
}

export const InfrastructureButton = React.forwardRef(
  ({ title, expanded, onClick }: IInfrastructureButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <ControlButton data-name="InfrastructureButton" className={styles['btn']} onClick={onClick} ref={ref}>
        <span className={styles['title']}>{title || 'Инфраструктура'}</span>
        {expanded && <IconChevronUp16 color="primary_100" />}
        {!expanded && <IconChevronDown16 color="primary_100" />}
      </ControlButton>
    );
  },
);

InfrastructureButton.displayName = 'InfrastructureButton';
