/* eslint-disable max-lines */
import { clone, isNil, pipe, reject } from 'ramda';

import { clearGeo } from './clearGeo';
import { convertProperties } from './convertProperties';
import { getApartments } from './getApartments';
import { getBalconyTypes } from './getBalconyType';
import { getBathTypes } from './getBathTypes';
import { getCompletionYearTypes, IGetCompletionYearTypesParams } from './getCompletionYearTypes';
import { getElevatorType } from './getElevatorType';
import { getFacilityTypes } from './getFacilityTypes';
import { getGarageUtilities } from './getGarageUtilities';
import { getIsSubsidisedMortgage } from './getIsSubsidisedMortgage';
import { getLivingConditions } from './getLivingConditions';
import { getSuburbanFacilityTypes } from './getSuburbanFacilityTypes';
import { getSuburbanUtilities } from './getSuburbanUtilities';
import { removeGeo } from './removeGeo';
import { resetTerms } from './resetTerms';
import { selectGeo } from './selectGeo';
import { setApartments } from './setApartments';
import { setArea } from './setArea';
import { setAreaMax } from './setAreaMax';
import { setAreaMin } from './setAreaMin';
import { setBSCenters } from './setBSCenters';
import { setBalconyTypes } from './setBalconyTypes';
import { setBathTypes } from './setBathTypes';
import { setBathroomCountMin } from './setBathroomCountMin';
import { setBedroomsCountMax } from './setBedroomsCountMax';
import { setBedroomsCountMin } from './setBedroomsCountMin';
import { setBeds } from './setBeds';
import { setCommissionType } from './setCommissionType';
import { ISetCompletionYearTypesParams, setCompletionYearTypes } from './setCompletionYearTypes';
import { setConstructionTypes } from './setConstructionTypes';
import { setConstructionYearMax } from './setConstructionYearMax';
import { setConstructionYearMin } from './setConstructionYearMin';
import { setContainWords } from './setContainWords';
import { setDates } from './setDates';
import { setDealRent } from './setDealRent';
import { setDealType } from './setDealType';
import { setDemolition } from './setDemolition';
import { setDistricts } from './setDistricts';
import { setElevatorType } from './setElevatorType';
import { setExcludeWords } from './setExcludeWords';
import { setFacilityTypes } from './setFacilityTypes';
import { setFlatShare } from './setFlatShare';
import { setFloorMax } from './setFloorMax';
import { setFloorMin } from './setFloorMin';
import { setFloorsMax } from './setFloorsMax';
import { setFloorsMin } from './setFloorsMin';
import { setFromDeveloper } from './setFromDeveloper';
import { setFromMKADMax } from './setFromMKADMax';
import { setFromMKADMin } from './setFromMKADMin';
import { setGarageStatus } from './setGarageStatus';
import { setGarageTypes } from './setGarageTypes';
import { setGarageUtilities } from './setGarageUtilities';
import { setHasFinishing } from './setHasFinishing';
import { setHasPhoto } from './setHasPhoto';
import { setHasVideo } from './setHasVideo';
import { setHeatingTypes } from './setHeatingTypes';
import { setHighways } from './setHighways';
import { setIsByHomeowner } from './setIsByHomeowner';
import { setIsFirstFloor } from './setIsFirstFloor';
import { setIsFromOffrep } from './setIsFromOffrep';
import { setIsSubsidisedMortgage } from './setIsSubsidisedMortgage';
import { setKitchenAreaMax } from './setKitchenAreaMax';
import { setKitchenAreaMin } from './setKitchenAreaMin';
import { setKitchenStove } from './setKitchenStove';
import { setLandArea } from './setLandArea';
import { setLandAreaMax } from './setLandAreaMax';
import { setLandAreaMin } from './setLandAreaMin';
import { setLandStatusTypes } from './setLandStatusTypes';
import { setLayoutType } from './setLayoutType';
import { setLivingAreaMax } from './setLivingAreaMax';
import { setLivingAreaMin } from './setLivingAreaMin';
import { setLivingConditions } from './setLivingConditions';
import { setMinCeilingHeight } from './setMinCeilingHeight';
import { setMortgageAvailable } from './setMortgageAvailable';
import { setNotLastFloor } from './setNotLastFloor';
import { setOfferType } from './setOfferType';
import { setOnlineBooking } from './setOnlineBooking';
import { setParkingType } from './setParkingType';
import { setPenthouse } from './setPenthouse';
import { setPikPromo } from './setPikPromo';
import { setPosessor } from './setPosessor';
import { setPrepay } from './setPrepay';
import { setPrice } from './setPrice';
import { setPriceMax } from './setPriceMax';
import { setPriceMin } from './setPriceMin';
import { setPriceType } from './setPriceType';
import { ISetPromoSearchTypesParams, setPromoSearchTypes } from './setPromoSearchTypes';
import { setPublishPeriod } from './setPublishPeriod';
import { setRegionId } from './setRegionId';
import { setRentalPeriod } from './setRentalPeriod';
import { setRepair } from './setRepair';
import { setRoomAreaMax } from './setRoomAreaMax';
import { setRoomAreaMin } from './setRoomAreaMin';
import { setRoomTypes } from './setRoomTypes';
import { setRoomsCount } from './setRoomsCount';
import { setRoomsForSaleMax } from './setRoomsForSaleMax';
import { setRoomsForSaleMin } from './setRoomsForSaleMin';
import { setSaleTypes } from './setSaleTypes';
import { setSuburbanBathroomType } from './setSuburbanBathroomType';
import { setSuburbanFacilityTypes } from './setSuburbanFacilityTypes';
import { setSuburbanUtilities } from './setSuburbanUtilities';
import { setTotalAreaMax } from './setTotalAreaMax';
import { setTotalAreaMin } from './setTotalAreaMin';
import { setUndergroundTravelTime } from './setUndergroundTravelTime';
import { setUndergroundTravelType } from './setUndergroundTravelType';
import { setUndergrounds } from './setUndergrounds';
import { setUrbanBathroomType } from './setUrbanBathroomType';
import { setVillage } from './setVillage';
import { setWindowsType } from './setWindowsType';
import { setWithDeposit } from './setWithDeposit';
import { setWithLayout } from './setWithLayout';
import {
  EBalconyType,
  ECompletionYearType,
  EElevatorType,
  EFacilityType,
  EGarageUtility,
  EHeatingType,
  EKitchenStove,
  ELandStatusType,
  ERentTime,
  ESuburbanUtility,
  FDealType,
  FOfferType,
  NonEmptyArray,
  TJsonQuery,
  TJsonQueryOptionalKeys,
} from './types';
import { unsetUnavailableProperties } from './unsetUnavailableProperties';
import {
  EGarageStatus,
  EGarageType,
  EHouseMaterial,
  ELayoutType,
  EMaxPrepay,
  EMetroTravelType,
  EParkingType,
  EPublishPeriod,
  ERepair,
  ERoomsTotal,
  ERoomType,
  ESaleType,
  ESuburbanOfferFilter,
  ESuburbanWCType,
  EWCType,
  EWindowsType,
  IJsonQueryDistrict,
  IJsonQueryHighway,
  IJsonQueryUnderground,
  TGeoValue,
} from '../api-models/common/json_query';

const postprocessJsonQuery = pipe(unsetUnavailableProperties, convertProperties, reject(isNil)) as (
  x: TJsonQuery,
) => TJsonQuery;

export class JsonQuery {
  private jsonQuery: TJsonQuery;

  public constructor(jsonQuery: TJsonQuery) {
    this.jsonQuery = clone(jsonQuery);
  }

  public toJSON = () => clone(this.jsonQuery);

  public getBalconyTypes = (): NonEmptyArray<EBalconyType> | null => getBalconyTypes(this.jsonQuery);
  public getElevatorType = (): EElevatorType | null => getElevatorType(this.jsonQuery);
  public getGarageUtilities = (): NonEmptyArray<EGarageUtility> | null => getGarageUtilities(this.jsonQuery);
  public getSuburbanUtilities = (): NonEmptyArray<ESuburbanUtility> | null => getSuburbanUtilities(this.jsonQuery);
  public getCompletionYearTypes = (params: IGetCompletionYearTypesParams): NonEmptyArray<ECompletionYearType> | null =>
    getCompletionYearTypes(this.jsonQuery, params);
  public getFacilityTypes = (): NonEmptyArray<EFacilityType> | null => getFacilityTypes(this.jsonQuery);
  public getBathTypes = (): NonEmptyArray<EFacilityType> | null => getBathTypes(this.jsonQuery);
  public getSuburbanFacilityTypes = (): NonEmptyArray<EFacilityType> | null => getSuburbanFacilityTypes(this.jsonQuery);
  public getLivingConditions = (): NonEmptyArray<EFacilityType> | null => getLivingConditions(this.jsonQuery);
  public getApartments = (): boolean | null => getApartments(this.jsonQuery);
  public getIsSubsidisedMortgage = (): boolean | null => getIsSubsidisedMortgage(this.jsonQuery);

  public setJsonQuery = (jsonQuery: TJsonQuery): this => this.wrap(jsonQuery);
  public setRegion = (region: NonEmptyArray<number> | null): this => this.wrap(setRegionId(this.jsonQuery)(region));
  public setDealType = (dealType: FDealType): this => this.wrap(setDealType(this.jsonQuery)(dealType));
  public setOfferType = (offerType: FOfferType): this => this.wrap(setOfferType(this.jsonQuery)(offerType));
  public setRoomTypes = (roomTypes: NonEmptyArray<ERoomType> | null): this =>
    this.wrap(setRoomTypes(this.jsonQuery)(roomTypes));
  public setBedroomsCountMin = (gte: number | null): this => this.wrap(setBedroomsCountMin(this.jsonQuery)(gte));
  public setBedroomsCountMax = (lte: number | null): this => this.wrap(setBedroomsCountMax(this.jsonQuery)(lte));
  public setBeds = (gte: number | null, lte: number | null): this => this.wrap(setBeds(this.jsonQuery)(gte, lte));
  public setDates = (gte: string, lt: string): this => this.wrap(setDates(this.jsonQuery)(gte, lt));
  public setPrice = (gte: number | null, lte: number | null): this => this.wrap(setPrice(this.jsonQuery)(gte, lte));
  public setPriceMin = (minPrice: number | null): this => this.wrap(setPriceMin(this.jsonQuery)(minPrice));
  public setPriceMax = (maxPrice: number | null): this => this.wrap(setPriceMax(this.jsonQuery)(maxPrice));
  public setPriceType = (isSquareMeterPrice: boolean | null): this =>
    this.wrap(setPriceType(this.jsonQuery)(isSquareMeterPrice));
  public setArea = (gte: number | null, lte: number | null): this => this.wrap(setArea(this.jsonQuery)(gte, lte));
  public setAreaMin = (minArea: number | null): this => this.wrap(setAreaMin(this.jsonQuery)(minArea));
  public setAreaMax = (maxArea: number | null): this => this.wrap(setAreaMax(this.jsonQuery)(maxArea));
  public setLandArea = (gte: number | null, lte: number | null): this =>
    this.wrap(setLandArea(this.jsonQuery)(gte, lte));
  public setLandAreaMin = (minLandArea: number | null): this => this.wrap(setLandAreaMin(this.jsonQuery)(minLandArea));
  public setLandAreaMax = (maxLandArea: number | null): this => this.wrap(setLandAreaMax(this.jsonQuery)(maxLandArea));
  public setMinCeilingHeight = (minCeilingHeight: number | null): this =>
    this.wrap(setMinCeilingHeight(this.jsonQuery)(minCeilingHeight));
  public setPosessor = (possesor: ESuburbanOfferFilter | null): this =>
    this.wrap(setPosessor(this.jsonQuery)(possesor));
  public setVillage = (villageId: number | null): this => this.wrap(setVillage(this.jsonQuery)(villageId));
  public setBSCenter = (bsCenterId: number): this => this.wrap(setBSCenters(this.jsonQuery)([bsCenterId]));
  public setRepair = (repair: ERepair[] | null): this => this.wrap(setRepair(this.jsonQuery)(repair));
  public setFloorMin = (gte: number | null): this => this.wrap(setFloorMin(this.jsonQuery)(gte));
  public setFloorMax = (lte: number | null): this => this.wrap(setFloorMax(this.jsonQuery)(lte));
  public setFloorsMin = (gte: number | null): this => this.wrap(setFloorsMin(this.jsonQuery)(gte));
  public setFloorsMax = (lte: number | null): this => this.wrap(setFloorsMax(this.jsonQuery)(lte));
  public setTotalAreaMin = (gte: number | null): this => this.wrap(setTotalAreaMin(this.jsonQuery)(gte));
  public setTotalAreaMax = (lte: number | null): this => this.wrap(setTotalAreaMax(this.jsonQuery)(lte));
  public setRoomAreaMin = (gte: number | null): this => this.wrap(setRoomAreaMin(this.jsonQuery)(gte));
  public setRoomAreaMax = (lte: number | null): this => this.wrap(setRoomAreaMax(this.jsonQuery)(lte));
  public setKitchenAreaMin = (gte: number | null): this => this.wrap(setKitchenAreaMin(this.jsonQuery)(gte));
  public setKitchenAreaMax = (lte: number | null): this => this.wrap(setKitchenAreaMax(this.jsonQuery)(lte));
  public setKitchenStove = (kitchenStove: EKitchenStove | null): this =>
    this.wrap(setKitchenStove(this.jsonQuery)(kitchenStove));
  public setLivingAreaMin = (gte: number | null): this => this.wrap(setLivingAreaMin(this.jsonQuery)(gte));
  public setLivingAreaMax = (lte: number | null): this => this.wrap(setLivingAreaMax(this.jsonQuery)(lte));
  public setConstructionYearMin = (gte: number | null): this => this.wrap(setConstructionYearMin(this.jsonQuery)(gte));
  public setConstructionYearMax = (lte: number | null): this => this.wrap(setConstructionYearMax(this.jsonQuery)(lte));
  public setPenthouse = (isPenthouse: boolean | null): this => this.wrap(setPenthouse(this.jsonQuery)(isPenthouse));
  public setIsFirstFloor = (isFirstFloor: boolean | null): this =>
    this.wrap(setIsFirstFloor(this.jsonQuery)(isFirstFloor));
  public setIsSubsidisedMortgage = (isSubsidisedMortgage: boolean | null): this =>
    this.wrap(setIsSubsidisedMortgage(this.jsonQuery)(isSubsidisedMortgage));
  public setNotLastFloor = (notLastFloor: boolean | null): this =>
    this.wrap(setNotLastFloor(this.jsonQuery)(notLastFloor));
  public setBalconyTypes = (balconyTypes: NonEmptyArray<EBalconyType> | null): this =>
    this.wrap(setBalconyTypes(this.jsonQuery)(balconyTypes));
  public setElevatorType = (elevatorType: EElevatorType | null): this =>
    this.wrap(setElevatorType(this.jsonQuery)(elevatorType));
  public setApartments = (apartments: boolean | null): this => this.wrap(setApartments(this.jsonQuery)(apartments));
  public setFlatShare = (slatShare: number | null): this => this.wrap(setFlatShare(this.jsonQuery)(slatShare));
  public setParkingType = (parkingType: EParkingType[] | null): this =>
    this.wrap(setParkingType(this.jsonQuery)(parkingType));
  public setPublishPeriod = (period: EPublishPeriod | null): this =>
    this.wrap(setPublishPeriod(this.jsonQuery)(period));
  public setHasPhoto = (hasPhoto: boolean | null): this => this.wrap(setHasPhoto(this.jsonQuery)(hasPhoto));
  public setHasVideo = (hasVideo: boolean | null): this => this.wrap(setHasVideo(this.jsonQuery)(hasVideo));
  public setExcludeWords = (excludeWords: string[] | null): this =>
    this.wrap(setExcludeWords(this.jsonQuery)(excludeWords));
  public setContainWords = (containWords: string[] | null): this =>
    this.wrap(setContainWords(this.jsonQuery)(containWords));
  public setLayoutType = (layoutType: ELayoutType | null): this => this.wrap(setLayoutType(this.jsonQuery)(layoutType));
  public setWithLayout = (withLayout: boolean | null): this => this.wrap(setWithLayout(this.jsonQuery)(withLayout));
  public setWindowsType = (windowsType: EWindowsType | null): this =>
    this.wrap(setWindowsType(this.jsonQuery)(windowsType));
  public setConstructionTypes = (constructionTypes: NonEmptyArray<EHouseMaterial> | null): this =>
    this.wrap(setConstructionTypes(this.jsonQuery)(constructionTypes));
  public setSaleTypes = (saleTypes: NonEmptyArray<ESaleType> | null): this =>
    this.wrap(setSaleTypes(this.jsonQuery)(saleTypes));
  public setMortgageAvailable = (mortgageAvailable: boolean | null): this =>
    this.wrap(setMortgageAvailable(this.jsonQuery)(mortgageAvailable));
  public setUrbanBathroomType = (bathroomType: EWCType | null): this =>
    this.wrap(setUrbanBathroomType(this.jsonQuery)(bathroomType));
  public setSuburbanBathroomType = (bathroomSite: ESuburbanWCType | null): this =>
    this.wrap(setSuburbanBathroomType(this.jsonQuery)(bathroomSite));
  public setBathroomCountMin = (bathroomCountMin: number | null): this =>
    this.wrap(setBathroomCountMin(this.jsonQuery)(bathroomCountMin));
  public selectGeo = (...geoValues: NonEmptyArray<TGeoValue>): this =>
    this.wrap(selectGeo(this.jsonQuery)(...geoValues));
  public setUndergrounds = (...geoUndergrounds: NonEmptyArray<IJsonQueryUnderground>): this =>
    this.wrap(setUndergrounds(this.jsonQuery)(...geoUndergrounds));
  public setDistricts = (...geoDistricts: NonEmptyArray<IJsonQueryDistrict>): this =>
    this.wrap(setDistricts(this.jsonQuery)(...geoDistricts));
  public setHighways = (...geoHighways: NonEmptyArray<IJsonQueryHighway>): this =>
    this.wrap(setHighways(this.jsonQuery)(...geoHighways));
  public removeGeo = (geoValue: TGeoValue): this => this.wrap(removeGeo(this.jsonQuery)(geoValue));
  public setUndergroundTravelType = (type: EMetroTravelType | null): this =>
    this.wrap(setUndergroundTravelType(this.jsonQuery)(type));
  public setUndergroundTravelTime = (time: number | null): this =>
    this.wrap(setUndergroundTravelTime(this.jsonQuery)(time));
  public clearGeo = (): this => this.wrap(clearGeo(this.jsonQuery)());
  public setIsByHomeowner = (isByHomeowner: boolean | null): this =>
    this.wrap(setIsByHomeowner(this.jsonQuery)(isByHomeowner));
  public setFromOffrep = (isFromOffrep: boolean | null): this =>
    this.wrap(setIsFromOffrep(this.jsonQuery)(isFromOffrep));
  public setRoomsForSaleMin = (gte: number | null): this => this.wrap(setRoomsForSaleMin(this.jsonQuery)(gte));
  public setRoomsForSaleMax = (lte: number | null): this => this.wrap(setRoomsForSaleMax(this.jsonQuery)(lte));
  public setRoomsCount = (value: ERoomsTotal[] | null): this => this.wrap(setRoomsCount(this.jsonQuery)(value));
  public setHasFinishing = (hasFinishing: boolean | null): this =>
    this.wrap(setHasFinishing(this.jsonQuery)(hasFinishing));
  public setSuburbanUtilities = (utilities: NonEmptyArray<ESuburbanUtility> | null): this =>
    this.wrap(setSuburbanUtilities(this.jsonQuery)(utilities));
  public setGarageUtilities = (utilities: NonEmptyArray<EGarageUtility> | null): this =>
    this.wrap(setGarageUtilities(this.jsonQuery)(utilities));
  public setCompletionYearTypes = (params: ISetCompletionYearTypesParams): this =>
    this.wrap(setCompletionYearTypes(this.jsonQuery)(params));
  public setFromMKADMin = (gte: number | null): this => this.wrap(setFromMKADMin(this.jsonQuery)(gte));
  public setFromMKADMax = (lte: number | null): this => this.wrap(setFromMKADMax(this.jsonQuery)(lte));
  public setFromDeveloper = (fromDeveloper: boolean | null): this =>
    this.wrap(setFromDeveloper(this.jsonQuery)(fromDeveloper));
  public setPikPromo = (pikPromo: boolean | null): this => this.wrap(setPikPromo(this.jsonQuery)(pikPromo));
  public setWithDeposit = (value: boolean | null): this => this.wrap(setWithDeposit(this.jsonQuery)(value));
  public setCommissionType = (value: number | null): this => this.wrap(setCommissionType(this.jsonQuery)(value));
  public setPrepay = (prepay: EMaxPrepay | null): this => this.wrap(setPrepay(this.jsonQuery)(prepay));
  public setDealRent = (value: boolean | null): this => this.wrap(setDealRent(this.jsonQuery)(value));
  public setFacilityTypes = (facilities: NonEmptyArray<EFacilityType> | null): this =>
    this.wrap(setFacilityTypes(this.jsonQuery)(facilities));
  public setSuburbanFacilityTypes = (suburbanFacilities: NonEmptyArray<EFacilityType> | null): this =>
    this.wrap(setSuburbanFacilityTypes(this.jsonQuery)(suburbanFacilities));
  public setBathTypes = (bathTypes: NonEmptyArray<EFacilityType> | null): this =>
    this.wrap(setBathTypes(this.jsonQuery)(bathTypes));
  public setDemolition = (value: boolean | null): this => this.wrap(setDemolition(this.jsonQuery)(value));
  public setHeatingTypes = (heatingTypes: NonEmptyArray<EHeatingType> | null): this =>
    this.wrap(setHeatingTypes(this.jsonQuery)(heatingTypes));
  public setGarageStatus = (garageStatus: NonEmptyArray<EGarageStatus> | null): this =>
    this.wrap(setGarageStatus(this.jsonQuery)(garageStatus));
  public setGarageTypes = (garageTypes: NonEmptyArray<EGarageType> | null): this =>
    this.wrap(setGarageTypes(this.jsonQuery)(garageTypes));
  public setLivingConditions = (livingConditions: NonEmptyArray<EFacilityType> | null): this =>
    this.wrap(setLivingConditions(this.jsonQuery)(livingConditions));
  public setRentalPeriod = (rentalPeriod: ERentTime | null): this =>
    this.wrap(setRentalPeriod(this.jsonQuery)(rentalPeriod));
  public setLandStatusTypes = (landStatusTypes: NonEmptyArray<ELandStatusType> | null): this =>
    this.wrap(setLandStatusTypes(this.jsonQuery)(landStatusTypes));
  public setOnlineBooking = (onlineBooking: boolean | null): this =>
    this.wrap(setOnlineBooking(this.jsonQuery)(onlineBooking));
  public setPromoSearchTypes = (params: ISetPromoSearchTypesParams): this =>
    this.wrap(setPromoSearchTypes(this.jsonQuery)(params));

  public resetTerms = (terms: TJsonQueryOptionalKeys[]): this => this.wrap(resetTerms(this.jsonQuery)(terms));

  private wrap = (nextJsonQuery: TJsonQuery) => {
    this.jsonQuery = postprocessJsonQuery(nextJsonQuery);

    return this;
  };
}
