import { ca } from '@cian/analytics';

interface INewbuildingFiltersSelectParams {
  label: string;
  products: unknown;
  page: unknown;
}

export function trackNewbuildingFiltersSelect({ label, products, page }: INewbuildingFiltersSelectParams) {
  ca('eventSite', {
    action: 'Click',
    category: 'Listing',
    name: 'oldevent',
    label,
    products,
    page,
  });
}
