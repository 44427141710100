import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { FOfferType, isUrban } from 'shared/common/packages/JsonQuery';
import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface IFloorsProps {
  offerType: FOfferType;
  floors: IJsonQueryRangeValue;
  isPenthouse: boolean | null;
  onFloorsMinChange(gte: number | null): void;
  onFloorsMaxChange(lte: number | null): void;
  onIsPenthouseChange(isPenthouse: boolean | null): void;
}

export const Floors: React.FC<IFloorsProps> = ({
  offerType,
  floors: { gte: floorsMin = null, lte: floorsMax = null },
  isPenthouse,
  onFloorsMinChange,
  onFloorsMaxChange,
  onIsPenthouseChange,
}) => {
  const isPenthouseAvailable = isUrban(offerType);

  const handleFloorsMinChange = useDebouncedCallback((gte: number | null) => {
    onFloorsMinChange(gte);
  }, 300);

  const handleFloorsMaxChange = useDebouncedCallback((lte: number | null) => {
    onFloorsMaxChange(lte);
  }, 300);

  return (
    <Filter id="advancedFilter_floors" label="Этажей в доме">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            min={1}
            max={200}
            valueMin={floorsMin}
            valueMax={floorsMax}
            onChangeMin={handleFloorsMinChange}
            onChangeMax={handleFloorsMaxChange}
          />
        </InlineFilter>
        {isPenthouseAvailable && (
          <InlineFilter>
            <Checkbox
              label="Пентхаус"
              checked={!!isPenthouse}
              onChange={event => onIsPenthouseChange(event.target.checked || null)}
            />
          </InlineFilter>
        )}
      </InlineFilterGroup>
    </Filter>
  );
};
