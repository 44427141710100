import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSubdomain } from 'shared/map-search/actions/results';
import { IFeaturesMap } from 'shared/map-search/types/map';
import { TMapBounds } from 'shared/map-search/types/mapBounds';
import { IApplicationState, TThunkDispatch } from 'shared/map-search/types/redux';
import { mode } from 'shared/map-search/utils/mode';

import { useFeaturesContext } from '../context';

export const SubdomainUpdater: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { rbush } = useFeaturesContext();
  const subdomain = useSelector<IApplicationState, string>(state => state.results.subdomain);
  const { bounds, precision } = useSelector<IApplicationState, TMapBounds>(state => state.mapBounds);
  const features = useSelector<IApplicationState, IFeaturesMap>(state =>
    pathOr<IFeaturesMap>({}, [precision, 'features'], state.features),
  );

  React.useEffect(() => {
    if (!bounds) {
      return;
    }

    const featuresInBbox = rbush.search({
      minX: bounds[0][0],
      minY: bounds[0][1],
      maxX: bounds[1][0],
      maxY: bounds[1][1],
    });

    const nextSubdomain = mode(featuresInBbox.map(f => f.feature.properties.subdomain));
    if (!nextSubdomain || nextSubdomain === subdomain) {
      return;
    }

    dispatch(setSubdomain(nextSubdomain));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, features]);

  return null;
};
