import classNames from 'clsx';
import * as React from 'react';

import { getAnimationDuration } from './helpers';
import * as styles from './style.css';

interface ITickerProps {
  text: string;
}

/* Компонент бегущей строки */
export const Ticker: React.FC<ITickerProps> = ({ text }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const tickerRef = React.useRef<HTMLDivElement>(null);
  const [animationDuration, setAnimationDuration] = React.useState(0);
  const [isInfinite, setInfinite] = React.useState<boolean>(false);
  const [isStatic, setStatic] = React.useState<boolean>(false);

  React.useEffect(() => {
    const time = getAnimationDuration(tickerRef.current, wrapperRef.current);

    if (!time) {
      setStatic(true);
    } else {
      setStatic(false);
      setAnimationDuration(time);
    }
  }, [text, isInfinite]);

  const onAnimationEnd = React.useCallback(() => {
    setInfinite(true);
  }, []);

  return (
    <div ref={wrapperRef} className={styles['container']}>
      <div
        ref={tickerRef}
        data-testid="tickerAnimationBlock"
        style={{ animationDuration: `${animationDuration}s`, WebkitAnimationDuration: `${animationDuration}s` }}
        onAnimationEnd={onAnimationEnd}
        className={classNames(
          styles['ticker'],
          isInfinite && styles['ticker--infinite'],
          isStatic && styles['ticker--static'],
        )}
      >
        <span className={styles['text']}>{text}</span>
      </div>
    </div>
  );
};
