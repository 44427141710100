import {
  CANDY_BUTTON_BORDER,
  CANDY_BUTTON_RADIUS,
  CANDY_PRIMARY_COLOR,
  CANDY_SECONDARY_COLOR,
  LOLLIPOP_BORDER,
  LOLLIPOP_INNER_RADIUS,
  LOLLIPOP_RADIUS,
  LOLLIPOP_STICK,
  LOLLIPOP_STICK_COLOR,
  SHADOW_BLUR,
  SHADOW_COLOR,
  SHADOW_OFFSET,
} from '../../../constants/infrastructureFeatures';
import { TILE_SIZE } from '../../../constants/map';
import { TInfrastructureFeature } from '../../../types/infrastructure';
import { getTilePixelCoords } from '../../tiles';
import { onYmapsDebug, YMAPS_DEBUG } from '../ymapsModulesDebug';

interface IDrawTileParameters {
  pointCoords: [number, number];
  zoom: number;
}

export interface ICreateTileClassOptions {
  mapProjection: YMaps.projection.wgs84Mercator;
  dpr: number;
  getFeaturesForTile(tileNumber: YMaps.TTileNumber, zoom: number): TInfrastructureFeature[];
}

export function createTileClass(options: ICreateTileClassOptions) {
  const { getFeaturesForTile, mapProjection, dpr } = options;

  return class TileClass implements YMaps.IDomTile {
    private tileNumber: YMaps.TTileNumber;
    private tileZoom: number;
    private canvasElement: HTMLCanvasElement | undefined;
    private canvasContext: CanvasRenderingContext2D | undefined;
    private attached: boolean;
    private hash: string;

    public constructor(
      _url: string | null,
      _container: YMaps.layer.tileContainer.DomContainer,
      options: YMaps.IDomTileOptions,
    ) {
      this.tileNumber = options.tileNumber;
      this.tileZoom = options.tileZoom;
      this.attached = false;
      this.hash = '';
    }

    public destroy() {
      if (this.canvasElement) {
        this.canvasElement.width = 0;
        this.canvasElement.height = 0;

        if (this.canvasElement.parentElement) {
          this.canvasElement.parentElement.removeChild(this.canvasElement);
        }
        this.canvasElement = undefined;
        this.canvasContext = undefined;
      }
      this.attached = false;
    }

    public isReady() {
      return true;
    }

    public renderAt(context: HTMLElement, bounds: [[number, number], [number, number]]) {
      const timerLabel = `генерация дополнительного тайла ${this.tileNumber},${this.tileZoom}`;

      onYmapsDebug(() => console.time(timerLabel));

      if (!this.canvasElement) {
        this.initCanvas();
      }

      const tileFeatures = getFeaturesForTile(this.tileNumber, this.tileZoom);
      tileFeatures.sort((a, b) => (a.id.split('').reverse().join('') < b.id.split('').reverse().join('') ? -1 : 1));
      const featuresHash = tileFeatures
        .map(f => f.geometry.coordinates.join(','))
        .sort()
        .join(' ');

      const tileHash = `${this.tileNumber};${this.tileZoom};${featuresHash}`;
      if (this.hash !== tileHash) {
        this.drawTile(tileFeatures, this.tileNumber, this.tileZoom);
        this.hash = tileHash;
      }

      const canvas = this.canvasElement as HTMLCanvasElement;

      canvas.style.top = bounds[0][1] + 'px';
      canvas.style.left = bounds[0][0] + 'px';
      canvas.style.width = bounds[1][0] - bounds[0][0] + 'px';
      canvas.style.height = bounds[1][1] - bounds[0][1] + 'px';

      if (!this.attached) {
        context.appendChild(canvas);
        this.attached = true;
      }

      onYmapsDebug(() => console.timeEnd(timerLabel));
    }

    private drawTile(tileFeatures: TInfrastructureFeature[], tileNumber: YMaps.TTileNumber, tileZoom: number) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      const tilePixelCoords = getTilePixelCoords(tileNumber);

      this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

      tileFeatures.forEach(tileFeature => {
        const tileFeatureCoords = this.getFeatureTileCoords(tileFeature, tilePixelCoords, tileZoom);
        if (tileFeature.geometry.type === 'Point') {
          this.drawPoint({
            pointCoords: tileFeatureCoords,
            zoom: tileZoom,
          });
        }
      });

      if (YMAPS_DEBUG) {
        this.canvasContext.beginPath();
        this.canvasContext.strokeStyle = '#000';
        this.canvasContext.strokeRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasContext.font = '64px serif';
        this.canvasContext.fillStyle = 'red';
        this.canvasContext.fillText(tileNumber.join(';'), 30, 64);
        this.canvasContext.closePath();
      }
    }

    private initCanvas() {
      this.canvasElement = document.createElement('canvas');
      this.canvasElement.width = TILE_SIZE * dpr;
      this.canvasElement.height = TILE_SIZE * dpr;
      this.canvasElement.style.position = 'absolute';
      this.canvasElement.style.textRendering = 'geometricPrecision';
      this.canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
    }

    private drawPoint(params: IDrawTileParameters) {
      if (params.zoom > 14) {
        this.drawLollipop(params);
      } else {
        this.drawCandyButton(params);
      }
    }

    private drawCandyButton({ pointCoords }: IDrawTileParameters) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      // Магия Яндекс Карт
      const [pointXRaw, pointYRaw] = pointCoords;
      const [pointX, pointY] = [Math.round(pointXRaw), Math.floor(pointYRaw)];

      const ctx = this.canvasContext;

      // рисуется бордер, по сути круг акцентного цвета
      this.startContextShadow(SHADOW_COLOR, SHADOW_BLUR, SHADOW_OFFSET);
      ctx.beginPath();
      ctx.fillStyle = CANDY_SECONDARY_COLOR;
      ctx.arc(pointX * dpr, pointY * dpr, CANDY_BUTTON_RADIUS * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
      this.disableContextShadow();

      // рисуется круг основного цвета
      ctx.beginPath();
      ctx.fillStyle = CANDY_PRIMARY_COLOR;
      ctx.arc(pointX * dpr, pointY * dpr, (CANDY_BUTTON_RADIUS - CANDY_BUTTON_BORDER) * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }

    private drawLollipop({ pointCoords }: IDrawTileParameters) {
      if (!this.canvasElement || !this.canvasContext) {
        return;
      }

      // Магия Яндекс Карт
      const [pointXRaw, pointYRaw] = pointCoords;
      const [pointX, pointY] = [Math.round(pointXRaw), Math.floor(pointYRaw)];
      const lollipopY = pointY - LOLLIPOP_STICK - LOLLIPOP_RADIUS;

      const ctx = this.canvasContext;

      // рисуется палочка
      ctx.beginPath();
      ctx.lineWidth = dpr;
      ctx.moveTo(pointX * dpr, pointY * dpr);
      ctx.lineTo(pointX * dpr, lollipopY * dpr);
      ctx.strokeStyle = LOLLIPOP_STICK_COLOR;
      ctx.stroke();
      ctx.closePath();

      // рисуется бордер, по сути круг акцентного цвета
      this.startContextShadow(SHADOW_COLOR, SHADOW_BLUR, SHADOW_OFFSET);
      ctx.beginPath();
      ctx.fillStyle = CANDY_SECONDARY_COLOR;
      ctx.arc(pointX * dpr, lollipopY * dpr, LOLLIPOP_RADIUS * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
      this.disableContextShadow();

      // рисуется круг основного цвета
      ctx.beginPath();
      ctx.fillStyle = CANDY_PRIMARY_COLOR;
      ctx.arc(pointX * dpr, lollipopY * dpr, (LOLLIPOP_RADIUS - LOLLIPOP_BORDER) * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();

      // рисуется второй внутренний круг, акцентного цвета
      ctx.beginPath();
      ctx.fillStyle = CANDY_SECONDARY_COLOR;
      ctx.arc(pointX * dpr, lollipopY * dpr, LOLLIPOP_INNER_RADIUS * dpr, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }

    private disableContextShadow() {
      if (!this.canvasContext) {
        return;
      }

      this.canvasContext.shadowColor = '';
      this.canvasContext.shadowBlur = 0;
      this.canvasContext.shadowOffsetY = 0;
    }

    private startContextShadow(color: string, blur: number, offsetY: number) {
      if (!this.canvasContext) {
        return;
      }

      this.canvasContext.shadowColor = color;
      this.canvasContext.shadowBlur = blur;
      this.canvasContext.shadowOffsetY = offsetY;
    }

    private getFeatureTileCoords(
      feature: TInfrastructureFeature,
      tilePixelCoords: YMaps.TPixelCoord,
      tileZoom: number,
    ): [number, number] {
      const [tilePixelCoordX, tilePixelCoordY] = tilePixelCoords;
      const [featureCoordX, featureCoordY] = feature.geometry.coordinates;

      const [featurePixelCoordX, featurePixelCoordY] = mapProjection.toGlobalPixels(
        [featureCoordX, featureCoordY],
        tileZoom,
      );

      return [featurePixelCoordX - tilePixelCoordX, featurePixelCoordY - tilePixelCoordY];
    }
  };
}
