import { ICianAnalytics, IPageViewData } from 'shared/map-search/types/analytics';

import { getPageViewData } from './pageViewEvent';
import { IDependencies } from './types';

export function ensurePageCa(dependencies: IDependencies) {
  const { logger } = dependencies;

  try {
    const pageviewData = getPageViewData(dependencies, window.ca.q);
    if (!pageviewData) {
      return;
    }

    let originalCa = window.ca;
    let caFunc = createCaWrapper(originalCa, pageviewData);

    Object.defineProperty(window, 'ca', {
      get() {
        return caFunc;
      },
      set(nextCa: ICianAnalytics) {
        originalCa = nextCa;
        caFunc = createCaWrapper(nextCa, pageviewData);

        return caFunc;
      },
    });
  } catch (error) {
    logger.error(error, { domain: 'browser.utils.ensurePageCa.ensurePageCa()' });
  }
}

export function createCaWrapper(ca: ICianAnalytics, pageviewData: IPageViewData): ICianAnalytics {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function wrapper(...args: any[]) {
    ca('page', pageviewData.page);

    return ca(...args);
  }

  (wrapper as ICianAnalytics).q = window.ca.q;

  return wrapper as ICianAnalytics;
}
