import { ca } from '@cian/analytics';

export function trackClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Click',
    label: 'Infrastructure',
  });
}

export function trackLayerSelect(infrastructure: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Select',
    label: 'Infrastructure',
    page: {
      extra: {
        infrastructure,
      },
    },
  });
}
