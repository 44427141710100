import { selectActiveType } from './selectActiveType';
import { IInfrastructureFeaturesMap } from '../../types/infrastructure';
import { IApplicationState } from '../../types/redux';

export function selectActiveFeatures(state: IApplicationState): IInfrastructureFeaturesMap {
  const activeLayer = selectActiveType(state);

  if (!activeLayer) {
    return {};
  }

  const layerData = state.infrastructure.data[activeLayer.id];

  return layerData?.features || {};
}
