import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackGoToApplicationForm } from './tracking';
import { DesktopBanner } from '../../components/Leads';
import { selectIsOpen } from '../../selectors/searchResults';

export interface ILeadsContainerProps {
  open: boolean;
  onClose(): void;
}

export const LeadsContainer: React.FC<ILeadsContainerProps> = ({ open, onClose }) => {
  const deviceType = useDeviceType();
  const isSearchResultsOpen = useSelector(selectIsOpen);

  /**
   * Показываем баннер заявок только когда:
   *  - он доступен (open)
   *  - десктопное устройство у пользователя
   */
  const isBannerVisible = open && deviceType === 'desktop';

  /**
   * Если открыли выдачу по пину и баннер отображался - скрываем баннер
   */
  React.useEffect(() => {
    if (open && isSearchResultsOpen) {
      onClose();
    }
  }, [open, isSearchResultsOpen, onClose]);

  if (isBannerVisible) {
    return <DesktopBanner onClick={trackGoToApplicationForm} onClose={onClose} />;
  }

  return null;
};

LeadsContainer.displayName = 'LeadsContainer';
