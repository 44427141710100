import { selectPromoInventory } from './selectPromoInventory';
import { INewbuildingForPromoInventory } from '../../types/promoInventory';
import { IApplicationState } from '../../types/redux';

export function selectCurrentNewbuildingForBanner(state: IApplicationState): INewbuildingForPromoInventory | null {
  const { activeBannerIndex, newbuildings } = selectPromoInventory(state);

  if (activeBannerIndex === null) {
    return null;
  }

  return newbuildings[activeBannerIndex];
}
