import { ResponseError } from 'shared/common/errors';
import {
  IGetActiveTypesByBboxResponse200,
  TGetActiveTypesByBboxResponse,
} from 'shared/common/repositories/infrastructure-caching/v1/get-active-types-by-bbox';

export function assertFetchGetActiveTypesByBboxResponse(
  response: TGetActiveTypesByBboxResponse,
): asserts response is IGetActiveTypesByBboxResponse200 {
  if (response.statusCode !== 200) {
    throw new ResponseError({
      message: response.response.message || 'Unknown error',
      domain: 'assertFetchGetActiveTypesResponse',
    });
  }
}
