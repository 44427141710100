import { HttpCancelError } from '@cian/peperrors/shared';

import { selectNewbuildingFeatures } from './selectNewbuildingFeatures';
import { setActiveNewbuildingPolygon } from './setActiveNewbuildingPolygon';
import { IJsonQueryGeo } from '../../../common/packages/api-models/common/json_query';
import { IBBoxSchema } from '../../../common/repositories/search-offers-index/entities/bbox/BBoxSchema';
import { preparePolygonFeatures } from '../../mappers/newbuildingPolygons';
import { fetchGetClustersForMap } from '../../services/clusters';
import { TThunkAction } from '../../types/redux';
import { combineBboxes } from '../../utils/geometry';
import { precisionToMinZoom, zoomToPrecision } from '../../utils/precision';
import { getVisibleTiles, tileToBbox } from '../../utils/tiles';

export function fetchNewbuildingClusters(
  newbuildingId: number,
  isAnyFicheringPlus: boolean,
): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      config,
      custom: { subdomain },
      logger,
    } = context;
    const {
      filters: { jsonQuery },
      mapBounds: { bounds, zoom: currentZoom },
      activeNewbuildingPolygon: { prevActiveGeoHashIds },
    } = getState();

    if (!bounds || !currentZoom) {
      logger.error('Either bounds or zoom is undefined', {
        domain: 'actions/newbuildingPolygon/fetchNewbuildingClusters',
      });

      return;
    }

    const geo = newbuildingIdToGeoJsonQuery(newbuildingId);

    const precision = zoomToPrecision(config, currentZoom);
    const zoom = precisionToMinZoom(config, precision);
    const visibleTiles = getVisibleTiles(bounds, zoom);

    try {
      const requiredBboxes = visibleTiles.map(t => tileToBbox(t, zoom));
      const combinedBbox = combineBboxes(requiredBboxes);

      const fetchClustersResponse = await fetchGetClustersForMap(context, {
        parameters: {
          zoom,
          bbox: bboxToApiSchema(combinedBbox),
          jsonQuery: {
            ...jsonQuery,
            geo,
          },
          extended: false,
          subdomain,
        },
      });

      const { response, statusCode } = fetchClustersResponse;

      const filtered = statusCode === 200 ? response.filtered : undefined;

      const { geohashIds, houseIds } = preparePolygonFeatures(filtered);

      dispatch(
        setActiveNewbuildingPolygon({ newbuildingId, geohashIds, houseIds, prevActiveGeoHashIds, isAnyFicheringPlus }),
      );
      dispatch(selectNewbuildingFeatures());
    } catch (error) {
      if (error instanceof HttpCancelError) {
        return;
      }

      logger.error(error);
    }
  };
}

export function bboxToApiSchema(coords: [YMaps.TCoord, YMaps.TCoord]): IBBoxSchema[] {
  const [[latMin, lngMin], [latMax, lngMax]] = coords;

  return [
    {
      bottomRight: { lat: latMin, lng: lngMax },
      topLeft: { lat: latMax, lng: lngMin },
    },
  ];
}

export function newbuildingIdToGeoJsonQuery(newbuildingId: number): IJsonQueryGeo {
  return { type: 'geo', value: [{ type: 'newobject', id: newbuildingId }] };
}
