import { TReduxActions } from '../../actions';
import { ECallPopupActionType } from '../../actions/callPopup/types';
import { INewbuildingContactsState } from '../../types/phone/phone';

export function callPopupReducer(
  state: INewbuildingContactsState | null = null,
  action: TReduxActions,
): INewbuildingContactsState | null {
  switch (action.type) {
    case ECallPopupActionType.Open:
      return {
        open: true,
        ...action.payload,
      };
    case ECallPopupActionType.Close:
      return null;
  }

  return state;
}
