import { ca } from '@cian/analytics';

export const trackCloseSidebar = (newbuildingId?: number | null, isFicheringPlus?: boolean) => {
  const product = newbuildingId
    ? {
        extra: { newobjectid: newbuildingId, is_promo: isFicheringPlus },
      }
    : undefined;

  ca('eventSite', {
    name: 'oldevent',
    category: 'Map',
    action: 'Close',
    label: 'Listing_sidebar',
    products: product ? [product] : [],
  });
};
