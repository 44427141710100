import { TFilter, TModifier } from '../../../common/packages/Filters';

export const QUICK_FILTERS_KEYS: TFilter[] = ['dealType', 'offerType', 'posessor', 'price', 'roomType'];

export const GEO_CHANGE_ACTIONS: TModifier['action'][] = [
  'clearGeo',
  'selectGeo',
  'removeGeo',
  'setBSCenter',
  'setRegion',
  'setVillage',
];
