import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

export function trackClearClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'clean_filters',
    category: 'Map',
  });
}

export function trackAdvancedFiltersClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'Map',
    label: 'Filters',
  });
}

interface ITrackFilterApply {
  jsonQuery: IJsonQuery;
  queryString?: string;
}

export function trackApplyClick({ jsonQuery, queryString }: ITrackFilterApply) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'button_search',
    label: queryString,
    category: 'Map',
    sc: jsonQuery,
  });
}

export function trackQuickFilterApply({ jsonQuery, queryString }: ITrackFilterApply) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'fast_filters_search',
    category: 'Map',
    label: queryString,
    sc: jsonQuery,
  });
}
