import { makeContextModule } from '@cian/react-utils';
import RBush from 'rbush';

import { IRBushItem } from 'shared/map-search/types/map';

export interface INewbuildingPromoPinsContext {
  rbush: RBush<IRBushItem>;
  newbuildingsPromoPinsManager: YMaps.NewbuildingsPromoPinsManager;
  popupManager: YMaps.NewbuildingPromoPopupManager;
}

export const {
  Context: NewbuildingPromoPinsContext,
  useContext: useNewbuildingPromoPinsContext,
  withContext: withNewbuildingPromoPinsContext,
} = makeContextModule<INewbuildingPromoPinsContext>({
  contextName: 'NewbuildingPromoPinsContext',
});
